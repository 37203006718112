import { SubmissionType } from '@common/enums/SubmissionType';
import { useState } from 'react';
import { Dialog, DialogContent, MenuItem, Select, Stack } from '@common-components';
import { UserRole } from 'enums';
import { useMutateSubmission, useSearchUser } from 'hooks';
import { messages } from 'i18n';
import { User } from 'types';
import Autocomplete from 'components/Autocomplete';
import { DialogFooter, DialogHeader } from 'components/Dialog';
import InputLabel from 'components/hookFormComponents/InputLabel';
import { generateSubmissionPath } from 'broker/broker-routes';
import { submissionTypeConfig } from 'broker/configuration-mappers/submission-type-config';
import { RouteKey } from 'broker/enums';
import { useNavigate } from 'broker/hooks';

interface CreateSubmissionDialogProps {
  isOpen: boolean;
  closeDialog: () => void;
}

export default function CreateSubmissionDialog({ isOpen, closeDialog }: CreateSubmissionDialogProps) {
  const { items: retailers } = useSearchUser({
    filter: { role: UserRole.Retailer },
  });

  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [selectedSubmissionType, setSelectedSubmissionType] = useState<SubmissionType>(SubmissionType.NewBusiness);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { createSubmission } = useMutateSubmission();
  const navigate = useNavigate();

  const onClose = () => {
    setSelectedUser(null);
    closeDialog();
  };

  const onCreateSubmission = async () => {
    setIsSubmitting(true);
    try {
      const submission = await createSubmission.mutateAsync({
        data: { userId: selectedUser!.id, type: selectedSubmissionType },
      });

      navigate(
        {
          // submission is not null here because we throw on error in the creation function
          pathname: generateSubmissionPath(submission!.id),
        },
        {
          routeKey: RouteKey.WorkspaceTab,
          state: undefined,
        },
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogHeader title={messages.createSubmissionModal.title} id="create-submission-dialog" onClose={onClose} />
      <DialogContent sx={{ minWidth: 400 }}>
        <Stack gap={1}>
          <Stack>
            <InputLabel
              id="customer-label"
              label={messages.createSubmissionModal.retailerLabel}
              htmlFor="customer"
              error={false}
            />
            <Autocomplete
              value={selectedUser}
              onChange={(value) => {
                setSelectedUser(value as User | null);
              }}
              options={retailers}
              getDisplayValues={(user) => ({
                title: `${user.firstName} ${user.lastName}`,
                subtitle: user.organization.name,
              })}
              placeholder={messages.createSubmissionModal.retailerPlaceholder}
            />
          </Stack>
          <Stack>
            <InputLabel
              id="customer-label"
              label={messages.createSubmissionModal.internalTypeLabel}
              htmlFor="submission-type"
              error={false}
            />
            <Select
              fullWidth
              size="small"
              labelId="submission-type-label"
              id="submission-type"
              value={selectedSubmissionType}
              onChange={(e) => setSelectedSubmissionType(e.target.value as SubmissionType)}
            >
              <MenuItem value={SubmissionType.NewBusiness}>
                {submissionTypeConfig[SubmissionType.NewBusiness].text}
              </MenuItem>
              <MenuItem value={SubmissionType.BOR}>{submissionTypeConfig[SubmissionType.BOR].text}</MenuItem>
            </Select>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogFooter
        disableElevation
        proceedButton={{
          children: messages.createSubmissionModal.submit,
          onClick: onCreateSubmission,
          loading: isSubmitting,
          disabled: isSubmitting || !selectedUser,
        }}
        cancelButton={{ children: messages.buttons.cancel, onClick: onClose }}
      />
    </Dialog>
  );
}
