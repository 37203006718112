import { getCoverageLinesText } from '@common/utils';
import { getTimezoneLessDisplayDate } from '@common/utils/date-utils';
import { BoxTemplateLabels, FileType } from 'enums';
import { PartialSubmission } from 'types';
import { QuoteFormCreate } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/Quote/form-methods/types';
import { getFlowQuoteFileName } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/Quote/utils';
import { getPdfBackCover } from 'broker/pages/SubmissionWorkspacePage/utils/pdf-creator/pdf-back-cover';
import { convertImageToBase64 } from 'broker/pages/SubmissionWorkspacePage/utils/pdf-creator/pdf-content-creator';
import { getPdfCover } from 'broker/pages/SubmissionWorkspacePage/utils/pdf-creator/pdf-cover';
import { usePDFCreateAndCombine } from 'broker/pages/SubmissionWorkspacePage/utils/pdf-creator/usePDFCreateAndCombine';
import { getInsuredName, getRetailerFullName, getTodayDate } from './helpers';
import { getPdfContent } from './pdf-content';
import { getPdfDetails } from './pdf-details';

interface FlowQuotePdfGeneratorProps {
  submission: PartialSubmission;
  values: QuoteFormCreate;
  marketQuoteFileIds: string[];
  marketName: string;
}
export function useGetFlowQuotePdfGenerator() {
  const createAndCombine = usePDFCreateAndCombine();

  return async ({ submission, values, marketName, marketQuoteFileIds }: FlowQuotePdfGeneratorProps) => {
    const leftDecorImage = await convertImageToBase64('/img/top-left-text-decor.png');
    const rightDecorImage = await convertImageToBase64('/img/bottom-right-text-decor.png');

    const coverPage = await getPdfCover({
      title: `Prepared on behalf of ${getRetailerFullName(submission.user)}`,
      subtitle1: `Coverage Lines: ${getCoverageLinesText(values.coverageLines)}`,
      subtitle2: `Insured: ${getInsuredName(submission.insuredName)}`,
      subtitle3: `Delivery Date: ${getTodayDate()}`,
    });
    const detailsPage = getPdfDetails({ submission, quoteForm: values, marketName, leftDecorImage, rightDecorImage });
    const contentPage = getPdfContent({ submission, quoteForm: values, marketName });
    const thankYouPage = await getPdfBackCover({
      headerContent: [
        { text: 'Quote Expiration Date: ', bold: true },
        { text: values.expiresAt ? getTimezoneLessDisplayDate(values.expiresAt) : '' },
      ],
      titleText: 'Thank You!',
      subtitleText:
        'We appreciate your business and hope that we can be of further service to you in the future. Please contact us with any questions.',
    });

    return createAndCombine(
      [
        {
          pdfContentProps: coverPage,
        },
        {
          pdfContentProps: {
            content: detailsPage,
          },
        },
        {
          pdfContentProps: {
            content: contentPage,
          },
        },
        ...marketQuoteFileIds.map((marketQuoteFileId) => ({
          boxFileId: marketQuoteFileId,
        })),
        {
          pdfContentProps: {
            content: thankYouPage,
          },
        },
      ],
      {
        parentFolderId: submission.boxFolderId,
        fileName: getFlowQuoteFileName(marketName),
        metadata: {
          [BoxTemplateLabels.FileType]: FileType.CapitolaQuote,
        },
      },
    );
  };
}
