import { compact, includes } from 'lodash';
import { StateCode } from '../enums';
import { AddressDetails } from '../types/address-details';
import { Address } from '../types/submission-extracted-data';
import { HeraldAddress } from '../types/herald';

export function formatAddress(address: Omit<AddressDetails, 'formattedAddress'>): string {
    const formatAddressArr = [
        address.line1,
        address.line2,
        address.city,
        `${address.state || ''}${address.postalCode ? ` ${address.postalCode}` : ''}`,
        address.country,
    ];
    return compact(formatAddressArr).join(', ');
}

export function convertAddressToAddressDetails(address: Address): AddressDetails {
    const structuredAddress = {
        line1: address.line1,
        line2: address.line2,
        city: address.city,
        state: includes(Object.values(StateCode), address.state) ? (address.state as StateCode) : undefined,
        postalCode: address.postal_code,
        country: address.country_code,
    };
    return {
        ...structuredAddress,
        formattedAddress: formatAddress(structuredAddress),
    };
}

export function buildDynamicFormAddressValue(addressDetails: AddressDetails): HeraldAddress {
    const { line1, line2, city, postalCode, state, country } = addressDetails;
    const mappedCountry = country === 'US' ? 'USA' : country;
    const result: HeraldAddress = {};
    if (line1) {
        result.line1 = line1;
    }
    if (line2) {
        result.line2 = line2;
    }
    if (city) {
        result.city = city;
    }
    if (postalCode) {
        result.postal_code = postalCode;
    }
    if (state) {
        result.state = state;
    }
    if (mappedCountry) {
        result.country_code = mappedCountry;
    }
    return result;
}
