import { ExtractedAttribute } from '@common/types';
import { filterValidNumeric, normalizeDateExtractionToFormValue } from '@common/utils/extraction-utils';
import { date } from 'yup';

export function getValidNumberSuggestion(
  extractedAttribute: ExtractedAttribute<string | number>,
  integerSchemaType = true,
): Pick<ExtractedAttribute<string>, 'value' | 'reason' | 'citations'> | undefined {
  const processedValue = extractedAttribute.value
    ? filterValidNumeric(extractedAttribute.value.toString(), integerSchemaType)
    : undefined;

  if (processedValue !== undefined) {
    return {
      reason: extractedAttribute.reason,
      citations: extractedAttribute.citations,
      value: processedValue,
    };
  }

  return undefined;
}

export function getValidDateSuggestion(
  extractedAttribute: ExtractedAttribute<string>,
): Pick<ExtractedAttribute<string>, 'value' | 'reason' | 'citations'> | undefined {
  if (extractedAttribute?.value && date().isValidSync(extractedAttribute.value)) {
    return {
      value: normalizeDateExtractionToFormValue(extractedAttribute.value),
      citations: extractedAttribute.citations,
      reason: extractedAttribute.reason,
    };
  }
  return undefined;
}

export function getValidOptionSuggestion<T extends string | string[]>(
  extractedAttribute: ExtractedAttribute<T>,
  options: string[] | number[],
): Pick<ExtractedAttribute<T>, 'value' | 'reason' | 'citations'> | undefined {
  let valid: string | number | string[] | undefined;

  if (typeof extractedAttribute.value === 'string') {
    const extractedAttributeValue =
      // if we are comparing number options we need to remove commas from the extracted value
      typeof options[0] === 'number' ? extractedAttribute.value.replace(/,/g, '') : extractedAttribute.value;
    valid = options.find(
      (option) =>
        (typeof option === 'string' ? option.toLowerCase() : String(option)) === extractedAttributeValue.toLowerCase(),
    );
  } else if (Array.isArray(extractedAttribute.value)) {
    const validValues = extractedAttribute.value.filter((value) =>
      options.some((option) => (typeof option === 'string' ? option.toLowerCase() : option) === value.toLowerCase()),
    );
    valid = validValues.length > 0 ? validValues : undefined;
  }

  return valid
    ? {
        value: valid as T,
        citations: extractedAttribute.citations,
        reason: extractedAttribute.reason,
      }
    : undefined;
}
