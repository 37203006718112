export const heraldQuestionId = {
    cvg_m18u_pl_effective_date: 'cvg_m18u_pl_effective_date',
    cvg_48oo_gl_effective_date: 'cvg_48oo_gl_effective_date',
    cvg_o3mw_cyb_effective_date: 'cvg_o3mw_cyb_effective_date',
    cvg_0em0_ml_effective_date: 'cvg_0em0_ml_effective_date',
    cvg_38ue_bop_effective_date: 'cvg_38ue_bop_effective_date',
    cvg_48od_wc_effective_date: 'cvg_48od_wc_effective_date',
    cvg_yc4u_prop_effective_date: 'cvg_yc4u_prop_effective_date',
    cvg_zr17_teo_cyb_effective_date: 'cvg_zr17_teo_cyb_effective_date',
    rsk_m4p9_insured_name: 'rsk_m4p9_insured_name',
    rsk_dy7r_domain_names: 'rsk_dy7r_domain_names',
    rsk_jsy2_primary_address: 'rsk_jsy2_primary_address',
    rsk_52it_fte_by_location: 'rsk_52it_fte_by_location',
    rsk_yor8_location: 'rsk_yor8_location',
    rsk_tvm3_mailing_address: 'rsk_tvm3_mailing_address',
    rsk_b3jm_2017_naics_index: 'rsk_b3jm_2017_naics_index',
    rsk_0ie7_number_of_fte: 'rsk_0ie7_number_of_fte',
    rsk_cog2_total_assets: 'rsk_cog2_total_assets',
    rsk_s7wq_corporate_structure: 'rsk_s7wq_corporate_structure',
    rsk_k39d_number_of_employees: 'rsk_k39d_number_of_employees',
    rsk_vrb1_total_annual_revenue: 'rsk_vrb1_total_annual_revenue',
    cvg_wsz8_gl_general_aggregate_limit: 'cvg_wsz8_gl_general_aggregate_limit',
    cvg_agj9_cyb_aggregate_limit: 'cvg_agj9_cyb_aggregate_limit',
    cvg_8yyn_teo_cyb_aggregate_limit: 'cvg_8yyn_teo_cyb_aggregate_limit',
    cvg_zlba_pl_aggregate_limit: 'cvg_zlba_pl_aggregate_limit',
    cvg_4k4d_ml_do_aggregate_limit: 'cvg_4k4d_ml_do_aggregate_limit',
    cvg_1x3s_ml_epli_aggregate_limit: 'cvg_1x3s_ml_epli_aggregate_limit',
    cvg_w1n8_ml_fid_aggregate_limit: 'cvg_w1n8_ml_fid_aggregate_limit',
    cvg_pl12_ml_kr_aggregate_limit: 'cvg_pl12_ml_kr_aggregate_limit',
    cvg_v1vv_ml_crm_aggregate_limit: 'cvg_v1vv_ml_crm_aggregate_limit',
    cvg_672w_bop_general_aggregate_limit: 'cvg_672w_bop_general_aggregate_limit',
    cvg_fk9q_ml_workplace_violence_aggregate_limit: 'cvg_fk9q_ml_workplace_violence_aggregate_limit',
    cvg_1tu1_ml_do_public_relations_expenses_aggregate_limit:
        'cvg_1tu1_ml_do_public_relations_expenses_aggregate_limit',
    cvg_bx1m_ml_do_terrorism_event_aggregate_limit: 'cvg_bx1m_ml_do_terrorism_event_aggregate_limit',
    cvg_th04_ml_do_additional_clause_a_aggregate_limit: 'cvg_th04_ml_do_additional_clause_a_aggregate_limit',
    cvg_zfn3_ml_do_securityholder_derivative_demand_aggregate_limit:
        'cvg_zfn3_ml_do_securityholder_derivative_demand_aggregate_limit',
    cvg_pu6d_ml_do_retired_independent_liability_aggregate_limit:
        'cvg_pu6d_ml_do_retired_independent_liability_aggregate_limit',
    rsk_jb26_cyb_has_claims_history: 'rsk_jb26_cyb_has_claims_history',
    rsk_2aep_pl_has_claim_history: 'rsk_2aep_pl_has_claim_history',
    rsk_16rg_number_of_pte: 'rsk_16rg_number_of_pte',
    rsk_a7he_total_payroll: 'rsk_a7he_total_payroll',
    rsk_4b4x_years_of_operation: 'rsk_4b4x_years_of_operation',
    rsk_7ahp_has_domain: 'rsk_7ahp_has_domain',
    rsk_837r_legal_entity: 'rsk_837r_legal_entity',
    rsk_14kt_insured_contact_phone: 'rsk_14kt_insured_contact_phone',
    rsk_t79b_insured_contact_name: 'rsk_t79b_insured_contact_name',
    rsk_5p6w_insured_contact_email: 'rsk_5p6w_insured_contact_email',
    rsk_voe4_cyb_security_officer: 'rsk_voe4_cyb_security_officer',
    rsk_yo5p_anticipates_or_completed_public_offerings: 'rsk_yo5p_anticipates_or_completed_public_offerings',
    rsk_sf46_anticipates_or_completed_private_placements: 'rsk_sf46_anticipates_or_completed_private_placements',
    rsk_mur2_has_outside_individual_ownership: 'rsk_mur2_has_outside_individual_ownership',
    rsk_e718_has_breached_debt_covenants: 'rsk_e718_has_breached_debt_covenants',
    rsk_3oiz_has_security_breach_requiring_notification: 'rsk_3oiz_has_security_breach_requiring_notification',
    rsk_sw1p_has_pending_litigation: 'rsk_sw1p_has_pending_litigation',
    rsk_p175_cyb_has_loss_in_unschedule_system_downtime: 'rsk_p175_cyb_has_loss_in_unschedule_system_downtime',
    rsk_2i59_ownership_type: 'rsk_2i59_ownership_type',
    rsk_e73e_cyb_authenticating_fund_transfers: 'rsk_e73e_cyb_authenticating_fund_transfers',
    rsk_zk4f_cyb_verifies_bank_accounts: 'rsk_zk4f_cyb_verifies_bank_accounts',
    rsk_5m1o_cyb_cloud_storage: 'rsk_5m1o_cyb_cloud_storage',
    rsk_6ril_cyb_security_training: 'rsk_6ril_cyb_security_training',
    rsk_s9i6_is_franchise: 'rsk_s9i6_is_franchise',
    rsk_2nge_is_business_broker: 'rsk_2nge_is_business_broker',
    rsk_nbd2_is_investment_bank: 'rsk_nbd2_is_investment_bank',
    rsk_2n3d_performs_merger_and_acquisition: 'rsk_2n3d_performs_merger_and_acquisition',
    rsk_4hz4_backup_security_measure_type: 'rsk_4hz4_backup_security_measure_type',
    rsk_9ljq_third_party_security_agreement: 'rsk_9ljq_third_party_security_agreement',
    rsk_bet9_tested_full_failover: 'rsk_bet9_tested_full_failover',
    rsk_9ty9_has_dmz_separation: 'rsk_9ty9_has_dmz_separation',
    rsk_rzc7_data_encryption_frequency: 'rsk_rzc7_data_encryption_frequency',
    rsk_331u_patch_frequency: 'rsk_331u_patch_frequency',
    rsk_fzej_has_incident_response_plan: 'rsk_fzej_has_incident_response_plan',
    rsk_8s6e_cyb_training_implementation: 'rsk_8s6e_cyb_training_implementation',
    rsk_na7l_perform_background_checks_employee: 'rsk_na7l_perform_background_checks_employee',
    rsk_ms88_bank_account_reconciliation: 'rsk_ms88_bank_account_reconciliation',
    rsk_0o4l_wire_transfer_authorization: 'rsk_0o4l_wire_transfer_authorization',
    rsk_77vv_vendor_verification_policy: 'rsk_77vv_vendor_verification_policy',
    rsk_u3u3_inventory_physical_count: 'rsk_u3u3_inventory_physical_count',
    rsk_ut7e_high_value_exposure: 'rsk_ut7e_high_value_exposure',
    rsk_3h4w_counter_signature_required: 'rsk_3h4w_counter_signature_required',
    rsk_u8u8_locations_abroad: 'rsk_u8u8_locations_abroad',
    rsk_ff4b_banking_transaction_control: 'rsk_ff4b_banking_transaction_control',
    rsk_ize4_expects_debt_soon: 'rsk_ize4_expects_debt_soon',
    rsk_0bi6_net_income: 'rsk_0bi6_net_income',
    rsk_ymb6_current_assets: 'rsk_ymb6_current_assets',
    rsk_5s10_earnings_before_interest_taxes: 'rsk_5s10_earnings_before_interest_taxes',
    rsk_ff62_cash_or_cash_equivalent_assets: 'rsk_ff62_cash_or_cash_equivalent_assets',
    rsk_ep1m_cash_flow_from_operations: 'rsk_ep1m_cash_flow_from_operations',
    rsk_t0a4_current_liabilities: 'rsk_t0a4_current_liabilities',
    rsk_5b5e_long_term_debt: 'rsk_5b5e_long_term_debt',
    rsk_d6iz_amount_of_expected_debt: 'rsk_d6iz_amount_of_expected_debt',
    rsk_faf9_percentage_shares_held_by_board: 'rsk_faf9_percentage_shares_held_by_board',
    rsk_gyj4_half_long_term_debt_matures: 'rsk_gyj4_half_long_term_debt_matures',
    rsk_phqr_total_liabilities: 'rsk_phqr_total_liabilities',
    rsk_0k1o_total_entities_own_shares: 'rsk_0k1o_total_entities_own_shares',
    rsk_tu74_percentage_held_by_trusts: 'rsk_tu74_percentage_held_by_trusts',
    rsk_6onk_entity_type: 'rsk_6onk_entity_type',
    rsk_9ux6_years_industry_management: 'rsk_9ux6_years_industry_management',
    rsk_tt0l_total_cash_exposure_outside: 'rsk_tt0l_total_cash_exposure_outside',
    rsk_tt8l_total_cash_exposure_inside: 'rsk_tt8l_total_cash_exposure_inside',
    cvg_0e4f_ml_selected_coverages: 'cvg_0e4f_ml_selected_coverages',
    cvg_ad5j_ml_include_do: 'cvg_ad5j_ml_include_do',
    cvg_am8n_ml_include_epli: 'cvg_am8n_ml_include_epli',
    cvg_h3rr_ml_include_fid: 'cvg_h3rr_ml_include_fid',
    cvg_k4cu_ml_include_crm: 'cvg_k4cu_ml_include_crm',
    cvg_ao0l_ml_include_kr: 'cvg_ao0l_ml_include_kr',
    rsk_h8oi_high_risk_industry_type: 'rsk_h8oi_high_risk_industry_type',
    rsk_a18w_stored_records_type: 'rsk_a18w_stored_records_type',
    rsk_da0x_dual_authenticate_banking_detail_update: 'rsk_da0x_dual_authenticate_banking_detail_update',
    rsk_tw5r_dual_authentication: 'rsk_tw5r_dual_authentication',
    rsk_d6el_secure_backup: 'rsk_d6el_secure_backup',
    rsk_glo9_secure_backup_frequency: 'rsk_glo9_secure_backup_frequency',
    rsk_y8ve_secure_backup_offline: 'rsk_y8ve_secure_backup_offline',
    rsk_64p7_data_encryption: 'rsk_64p7_data_encryption',
    rsk_9bjm_content_review: 'rsk_9bjm_content_review',
    rsk_un9n_network_authentication: 'rsk_un9n_network_authentication',
    rsk_2pt5_has_parent_entity: 'rsk_2pt5_has_parent_entity',
    rsk_a94l_do_24_12_months: 'rsk_a94l_do_24_12_months',
    rsk_ryx6_has_experienced_merger_and_acquisition: 'rsk_ryx6_has_experienced_merger_and_acquisition',
    rsk_wjbi_ml_past_future_material_changes: 'rsk_wjbi_ml_past_future_material_changes',
    rsk_ljip_change_in_voting_control_36_months: 'rsk_ljip_change_in_voting_control_36_months',
    rsk_hdcl_has_employee_stock_plan: 'rsk_hdcl_has_employee_stock_plan',
    rsk_yh15_has_revenue_from_government: 'rsk_yh15_has_revenue_from_government',
    rsk_dc0u_anticipates_or_experienced_layoffs: 'rsk_dc0u_anticipates_or_experienced_layoffs',
    rsk_gzu6_receives_employee_application: 'rsk_gzu6_receives_employee_application',
    rsk_e62k_publishes_employee_handbook: 'rsk_e62k_publishes_employee_handbook',
    rsk_8rqb_has_hr_department: 'rsk_8rqb_has_hr_department',
    rsk_ggy8_cyb_warranty: 'rsk_ggy8_cyb_warranty',
    rsk_rvyh_pl_has_warranty: 'rsk_rvyh_pl_has_warranty',
    rsk_h2x6_has_pl_coverage: 'rsk_h2x6_has_pl_coverage',
    rsk_ir30_has_prior_civil_suit: 'rsk_ir30_has_prior_civil_suit',
    rsk_rh5y_ml_has_claims_history_within_five_years: 'rsk_rh5y_ml_has_claims_history_within_five_years',
    rsk_7i0r_ml_warranty_claim: 'rsk_7i0r_ml_warranty_claim',
    rsk_w8j4_prior_eeoc_or_admin_proceeding: 'rsk_w8j4_prior_eeoc_or_admin_proceeding',
    rsk_o3q9_content_complaints_past_three_years: 'rsk_o3q9_content_complaints_past_three_years',
    rsk_8di2_offers_additional_services: 'rsk_8di2_offers_additional_services',
    rsk_q9am_has_privacy_claims: 'rsk_q9am_has_privacy_claims',
    rsk_22b1_prior_coverage_changed: 'rsk_22b1_prior_coverage_changed',
    rsk_l9ap_seeking_coverage_for_affiliate: 'rsk_l9ap_seeking_coverage_for_affiliate',
    rsk_1pab_has_continuing_eduction: 'rsk_1pab_has_continuing_eduction',
    rsk_6i3e_has_investigation_from_federal_agency: 'rsk_6i3e_has_investigation_from_federal_agency',
    rsk_0h8t_engages_in_cryptocurrency: 'rsk_0h8t_engages_in_cryptocurrency',
    rsk_su3n_option_to_invest: 'rsk_su3n_option_to_invest',
    rsk_ot4r_benefit_reduction: 'rsk_ot4r_benefit_reduction',
    rsk_c2ui_erisa_compliance: 'rsk_c2ui_erisa_compliance',
    rsk_5iyn_plan_violation: 'rsk_5iyn_plan_violation',
    rsk_un8m_delinquent_plan: 'rsk_un8m_delinquent_plan',
    rsk_3e4y_cancelled_nonrenew_ml_policy: 'rsk_3e4y_cancelled_nonrenew_ml_policy',
    rsk_2wq9_pte_by_location: 'rsk_2wq9_pte_by_location',
    rsk_7h1i_union_employees_by_location: 'rsk_7h1i_union_employees_by_location',
    rsk_2z10_independent_contractors_by_location: 'rsk_2z10_independent_contractors_by_location',
    rsk_3b0l_volunteers_by_location: 'rsk_3b0l_volunteers_by_location',
    rsk_4b8l_number_of_foreign_employees: 'rsk_4b8l_number_of_foreign_employees',
    rsk_fd7h_has_ml_coverage: 'rsk_fd7h_has_ml_coverage',

    rsk_7qec_anticipates_or_experienced_financial_restructing:
        'rsk_7qec_anticipates_or_experienced_financial_restructing',
};

export const heraldPrimaryAddressQuestionIds = [
    heraldQuestionId.rsk_jsy2_primary_address,
    heraldQuestionId.rsk_yor8_location,
];

export const heraldMailingAddressQuestionIds = [heraldQuestionId.rsk_tvm3_mailing_address];

export const heraldAddressQuestionIds = [...heraldPrimaryAddressQuestionIds, ...heraldMailingAddressQuestionIds];

export const heraldEffectiveDateQuestionIds = [
    heraldQuestionId.cvg_m18u_pl_effective_date,
    heraldQuestionId.cvg_48oo_gl_effective_date,
    heraldQuestionId.cvg_o3mw_cyb_effective_date,
    heraldQuestionId.cvg_0em0_ml_effective_date,
    heraldQuestionId.cvg_38ue_bop_effective_date,
    heraldQuestionId.cvg_48od_wc_effective_date,
    heraldQuestionId.cvg_yc4u_prop_effective_date,
    heraldQuestionId.cvg_zr17_teo_cyb_effective_date,
];

export const heraldLimitQuestionIds = [
    heraldQuestionId.cvg_wsz8_gl_general_aggregate_limit,
    heraldQuestionId.cvg_agj9_cyb_aggregate_limit,
    heraldQuestionId.cvg_8yyn_teo_cyb_aggregate_limit,
    heraldQuestionId.cvg_zlba_pl_aggregate_limit,
    heraldQuestionId.cvg_4k4d_ml_do_aggregate_limit,
    heraldQuestionId.cvg_1x3s_ml_epli_aggregate_limit,
    heraldQuestionId.cvg_w1n8_ml_fid_aggregate_limit,
    heraldQuestionId.cvg_pl12_ml_kr_aggregate_limit,
    heraldQuestionId.cvg_v1vv_ml_crm_aggregate_limit,
    heraldQuestionId.cvg_672w_bop_general_aggregate_limit,
];
