import { isNil } from 'lodash';
import { getSubmissionDataDefaultsConfig } from './default-values-config';
import { SubmissionDataDestination } from '../enums';
import { mapSourceToSubmissionDataDestination } from './utils';
import { DynamicFormDefaultValue, SubmissionDataDefaultsMetaData } from './types';

export const getSubmissionDataFromDefaultValuesByDestination = (
    destination: SubmissionDataDestination,
    metaData: SubmissionDataDefaultsMetaData,
): Record<string, { fieldName: string; value: any; parentFieldNames?: string[] }> =>
    getSubmissionDataDefaultsConfig(metaData).reduce((acc, config) => {
        if (destination === SubmissionDataDestination.SubmissionForm) {
            acc[('formFieldNameOverride' in config && config.formFieldNameOverride) || config.fieldName] = {
                fieldName: config.fieldName,
                value: !isNil(config.formValueOverride) ? config.formValueOverride : config.value,
                parentFieldNames: 'parentFieldNames' in config ? config.parentFieldNames : undefined,
            };
        } else if (mapSourceToSubmissionDataDestination(config.source) === destination) {
            acc[config.fieldName] = {
                fieldName: config.fieldName,
                value: config.value,
                parentFieldNames: 'parentFieldNames' in config ? config.parentFieldNames : undefined,
            };
        }
        return acc;
    }, {} as Record<string, { fieldName: string; value: any; parentFieldNames?: string[] }>);

export function getDefaultFieldValueMap(
    submissionDataDestination: SubmissionDataDestination,
    metaData: SubmissionDataDefaultsMetaData,
): Record<string, any> {
    const submissionDataDefaultsConfig = getSubmissionDataFromDefaultValuesByDestination(
        submissionDataDestination,
        metaData,
    );

    const defaultValues: Record<string, DynamicFormDefaultValue> = {};
    Object.entries(submissionDataDefaultsConfig).forEach(([fieldName, config]) => {
        defaultValues[fieldName] = config.value;
    });

    return defaultValues;
}
