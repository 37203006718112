import { isEmpty } from 'lodash';
import { isBorSubmission } from 'utils';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';

export function useIsBORFlow() {
  const { partialSubmission, markets } = useSubmissionsWorkspace();
  const isBOR = !!(partialSubmission && isBorSubmission(partialSubmission));
  return {
    isBOR,
    isBOROnboarding: isEmpty(markets) && isBOR,
  };
}
