import { useQueryClient } from '@tanstack/react-query';
import {
  AcknowledgmentContentRequestData,
  ArfRequestData,
  ArfSentData,
  MarketingEmailContentRequestData,
  MarketingUpdateContentRequestData,
  RiskAssessmentRequestData,
  SubmissionClient,
  SubmissionStatusUpdateData,
  SuggestedProductsRequestData,
} from 'clients';
import { QueryKeys } from 'enums';
import { useClient } from 'hooks/useClient';
// eslint-disable-next-line import/no-internal-modules
import { useToast } from 'hooks/useToast';
import { messages } from 'i18n';
import { AICreateThreadOutput, Submission, SubmissionCreate, SubmissionSearchItem } from 'types';
import { MutateProps, UseQueryBaseRetrieveOptions, UseQueryBaseSearchOptions } from './query/types';
import { useBulkCreate } from './query/useBulkCreate';
import { useMutate, useQueryMutation } from './query/useMutate';
import { useImperativeQuery, useQueryRetrieve, useQuerySearch } from './query/useQuery';
import { ResponseAsError } from './types';

export function useSearchSubmission(props?: UseQueryBaseSearchOptions<SubmissionSearchItem, Submission>) {
  const { filter, queryOptions } = props || {};
  return useQuerySearch({
    queryKey: QueryKeys.Submissions,
    clientClass: SubmissionClient,
    filter,
    queryOptions: {
      // Default submission search query options should be passed here for example:   cacheTime: 10 * 60 * 60 * 1000,
      ...queryOptions,
    },
  });
}

export function useRetrieveSubmission(props: UseQueryBaseRetrieveOptions<Submission>) {
  const { id, queryOptions, enabled } = props;
  return useQueryRetrieve({
    queryKey: QueryKeys.Submissions,
    clientClass: SubmissionClient,
    id,
    enabled,
    queryOptions: {
      // Default submission retrieve query options should be passed here for example:   cacheTime: 10 * 60 * 60 * 1000,
      ...queryOptions,
    },
  });
}

export function useMutateSubmission(options?: MutateProps) {
  const { showToast } = useToast();
  const {
    create: createSubmission,
    update: updateSubmission,
    destroy: deleteSubmission,
    client,
  } = useMutate<Submission, Submission, SubmissionCreate, SubmissionClient>({
    queryKey: QueryKeys.Submissions,
    clientClass: SubmissionClient,
    cancelInvalidation: options?.cancelInvalidation,
    createOptions: { throwOnError: true, passthroughStatusCodes: [409] },
    updateOptions: { throwOnError: true, passthroughStatusCodes: [409] },
    destroyOptions: { throwOnError: true },
    onSuccess: (response: Submission | null) => {
      const responseAsError = { ...response } as ResponseAsError;

      if (responseAsError.statusCode === 409) {
        // For a 409 error show a more meaningful message and throw so the usage can handle as any other error
        showToast('error', { message: messages.submissionModal.submissionExists });
        throw new Error(responseAsError.message);
      }
    },
  });

  const updateSubmissionTaskStatus = useQueryMutation<{}, SubmissionStatusUpdateData>(
    (data) => client.updateSubmissionTasks(data),
    QueryKeys.Submissions,
    true,
  );

  const sendArfRequest = useQueryMutation<{}, ArfRequestData>(
    (data) => client.sendArfRequest(data),
    QueryKeys.Submissions,
    true,
  );

  const arfSent = useQueryMutation<{}, ArfSentData>((data) => client.arfSent(data), QueryKeys.Submissions, true);

  return { createSubmission, updateSubmission, deleteSubmission, updateSubmissionTaskStatus, sendArfRequest, arfSent };
}

export function useBulkCreateSubmissions() {
  const { bulkCreate: bulkCreateSubmissions } = useBulkCreate<Submission, Submission, SubmissionClient>({
    queryKey: QueryKeys.Submissions,
    clientClass: SubmissionClient,
    createOptions: { throwOnError: true },
  });

  return { bulkCreateSubmissions };
}

export function useInvalidateSubmissionsCache() {
  const queryClient = useQueryClient();

  return async () => {
    await queryClient.invalidateQueries([QueryKeys.Submissions]);
  };
}

// Generates acknowledgment content for a submission, returns markdown content
export function useGenerateAcknowledgmentContent() {
  const submissionClient = useClient(SubmissionClient);

  const generateAcknowledgmentContent = useImperativeQuery<
    AICreateThreadOutput | null,
    AcknowledgmentContentRequestData
  >({
    queryFn: (data) => submissionClient.generateAcknowledgmentContent(data),
    queryKey: [QueryKeys.Submissions, 'generate-acknowledgment'],
  });

  return { generateAcknowledgmentContent };
}

// Generates marketing email content for a submission, returns markdown content
export function useGenerateMarketingEmailContent() {
  const submissionClient = useClient(SubmissionClient);

  const generateMarketingEmailContent = useImperativeQuery<
    AICreateThreadOutput | null,
    MarketingEmailContentRequestData
  >({
    queryFn: (data) => submissionClient.generateEmailMarketingContent(data),
    queryKey: [QueryKeys.Submissions, 'generate-marketing-email'],
  });

  return { generateMarketingEmailContent };
}

export function useGenerateSuggestedProductsContent() {
  const submissionClient = useClient(SubmissionClient);

  const generateSuggestedProductsContent = useImperativeQuery<
    AICreateThreadOutput | null,
    SuggestedProductsRequestData
  >({
    queryFn: (data) => submissionClient.generateSuggestedProductsContent(data),
    queryKey: [QueryKeys.Submissions, 'suggested-products'],
  });

  return { generateSuggestedProductsContent };
}

export function useGenerateRiskAssessment() {
  const submissionClient = useClient(SubmissionClient);

  const generateRiskAssessment = useImperativeQuery<AICreateThreadOutput | null, RiskAssessmentRequestData>({
    queryFn: (data) => submissionClient.generateRiskAssessment(data),
    queryKey: [QueryKeys.Submissions, 'risk-assessment'],
  });

  return { generateRiskAssessment };
}

export function useGenerateMarketingUpdateContent() {
  const submissionClient = useClient(SubmissionClient);

  const generateMarketingUpdateContent = useImperativeQuery<
    AICreateThreadOutput | null,
    MarketingUpdateContentRequestData
  >({
    queryFn: (data) => submissionClient.generateMarketingUpdateContent(data),
    queryKey: [QueryKeys.Submissions, 'generate-marketing-update-content'],
  });

  return { generateMarketingUpdateContent };
}
