import { isNull } from 'lodash';
import moment from 'moment-timezone';
import { HeraldData } from '../types/submission';
import { messages } from '../messages';
import { CoverageLine, HeraldCategoryType } from '../enums';
import {
    CoverageValuesUpdate,
    HeraldApiInvalidFormatItem,
    HeraldApplication,
    HeraldFormType,
    HeraldUpdateApplication,
    RiskValuesUpdate,
} from '../types/herald/herald-types';
import { heraldQuestionId } from '../types/herald-question-id';
import { convertDateStringToIsoFormat, parseTimezoneLessDate } from './date-utils';

export function areHeraldProductsEligibleToCreateSubmission(
    products: string[],
    heraldData?: HeraldData,
): { eligible: boolean; reason: string } {
    if (!heraldData?.applicationId || heraldData.isArchived) {
        return { eligible: false, reason: messages.heraldApi.noHeraldApplicationInCoverageLines };
    }

    const productsNotInApplication = products.filter((product) => !heraldData.products.includes(product));
    if (productsNotInApplication.length > 0) {
        return { eligible: false, reason: messages.heraldApi.productsNotInApplication(productsNotInApplication) };
    }
    if (heraldData.status !== 'complete') {
        return { eligible: false, reason: messages.heraldApi.applicationIncompleteStatus };
    }

    return { eligible: true, reason: '' };
}

export function getHeraldQuestionIdCategory(id: string) {
    return id.startsWith('rsk_') ? HeraldCategoryType.RiskValues : HeraldCategoryType.CoverageValues;
}

export function getHeraldUnAnsweredQuestions(heraldApplication: HeraldApplication) {
    const riskValuesUnAnswered = heraldApplication.risk_values.flatMap((riskValue) => {
        const unAnsweredQuestions: string[] = [];
        if (riskValue.required_for.includes(HeraldFormType.Quote) && isNull(riskValue.value)) {
            unAnsweredQuestions.push(riskValue.risk_parameter_id);
        }
        if (riskValue.child_risk_values) {
            riskValue.child_risk_values.forEach((childRiskValue) => {
                if (childRiskValue.required_for.includes(HeraldFormType.Quote) && isNull(childRiskValue.value)) {
                    unAnsweredQuestions.push(childRiskValue.risk_parameter_id);
                }
            });
        }
        return unAnsweredQuestions;
    });

    const coverageValuesUnAnswered = heraldApplication.coverage_values.flatMap((coverageValue) => {
        const unAnsweredQuestions: string[] = [];
        if (coverageValue.required_for.includes(HeraldFormType.Quote) && isNull(coverageValue.value)) {
            unAnsweredQuestions.push(coverageValue.coverage_parameter_id);
        }
        if (coverageValue.child_coverage_values) {
            coverageValue.child_coverage_values.forEach((childCoverageValue) => {
                if (
                    childCoverageValue.required_for.includes(HeraldFormType.Quote) &&
                    isNull(childCoverageValue.value)
                ) {
                    unAnsweredQuestions.push(childCoverageValue.coverage_parameter_id);
                }
            });
        }
        return unAnsweredQuestions;
    });

    return [...riskValuesUnAnswered, ...coverageValuesUnAnswered];
}

export const heraldSelectedCoverageLinesConfig = {
    [heraldQuestionId.cvg_ad5j_ml_include_do]: [CoverageLine.DirectorsAndOfficersLiability],
    [heraldQuestionId.cvg_am8n_ml_include_epli]: [CoverageLine.EPL],
    [heraldQuestionId.cvg_h3rr_ml_include_fid]: [CoverageLine.Fiduciary],
    [heraldQuestionId.cvg_k4cu_ml_include_crm]: [CoverageLine.Crime],
    [heraldQuestionId.cvg_ao0l_ml_include_kr]: [
        CoverageLine.KidnapRansomExecutiveProtection,
        CoverageLine.KidnapRansom,
    ],
};

export const getHeraldSelectedCoveragesQuestionsAnswers = (
    coverageLines?: CoverageLine[],
): { coverage_parameter_id: string; value: string }[] =>
    Object.entries(heraldSelectedCoverageLinesConfig).map(([key, values]) => ({
        coverage_parameter_id: key,
        value: coverageLines?.some((line) => values.includes(line)) ? 'yes' : 'no',
    }));

const heraldFieldErrorPathRegex = /(coverage_values|risk_values)\[(\d+)\]\.value/;
export function parseHeraldFieldError(
    fieldError: HeraldApiInvalidFormatItem,
): { category: string; index: number } | null {
    const match = fieldError.path.match(heraldFieldErrorPathRegex);

    if (match) {
        return {
            category: match[1], // "coverage_values" or "risk_values"
            index: parseInt(match[2], 10), // Converts "17" or "12" to a number
        };
    }

    return null; // Return null if the string doesn't match the expected format
}

// extraction are in US format MM/DD/YYYY, and we need to convert them to ISO format (same to the form value format of date components), we want to compare them in the same format to see if extraction/default suggestion is the same
export function convertDateExtractionToHeraldValue(value: string) {
    const timezoneLessDate = parseTimezoneLessDate(value);
    if (timezoneLessDate) {
        return moment(timezoneLessDate).format('YYYY-MM-DD');
    }
    return undefined;
}

export function convertDateFormValueToHeraldValue(date: string) {
    return convertDateStringToIsoFormat(date);
}

export function removeFieldErrorsQuestions(
    dataToUpdate: HeraldUpdateApplication,
    fieldErrors: HeraldApiInvalidFormatItem[],
) {
    const erroredRiskValues = [...(dataToUpdate.risk_values || [])];
    const erroredCoverageValues = [...(dataToUpdate.coverage_values || [])];
    // clean upsertData.risk_values and upsertData.coverage_values with the questions that had validation error and try upserting again
    // format of fieldErrors object path contains string coverage_values[19].value which means we need to delete the 19 element from erroredCoverageValues
    fieldErrors.forEach((fieldError) => {
        const parsedError = parseHeraldFieldError(fieldError);
        if (parsedError) {
            const { category, index } = parsedError;
            if (category === HeraldCategoryType.RiskValues) {
                const errorRiskValue = erroredRiskValues[index];
                if (errorRiskValue) {
                    dataToUpdate.risk_values = dataToUpdate.risk_values?.filter(
                        (riskValue) => riskValue.risk_parameter_id !== errorRiskValue.risk_parameter_id,
                    );
                }
            } else if (category === 'coverage_values') {
                const errorCoverageValue = erroredCoverageValues[index];
                if (errorCoverageValue) {
                    dataToUpdate.coverage_values = dataToUpdate.coverage_values?.filter(
                        (coverageValue) =>
                            coverageValue.coverage_parameter_id !== errorCoverageValue.coverage_parameter_id,
                    );
                }
            }
        }
    });

    return dataToUpdate;
}

export function convertRiskValueToLean(riskValue: RiskValuesUpdate): RiskValuesUpdate {
    return {
        risk_parameter_id: riskValue.risk_parameter_id,
        value: riskValue.value,
        instance: riskValue.instance,
        child_risk_values: riskValue.child_risk_values?.map(convertRiskValueToLean),
    };
}

export function convertCoverageValueToLean(coverageValue: CoverageValuesUpdate): CoverageValuesUpdate {
    return {
        coverage_parameter_id: coverageValue.coverage_parameter_id,
        value: coverageValue.value,
        instance: coverageValue.instance,
        child_coverage_values: coverageValue.child_coverage_values?.map(convertCoverageValueToLean),
    };
}
