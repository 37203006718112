import { PromptResultCreate } from '@common/types';
import { groupBy, isArray, isNil, isString } from 'lodash';
import { ExtractedDataFields, useMutatePromptResult } from 'hooks';
import { isNumeric } from 'utils';

interface PromptResultsReportParams {
  formState: Partial<Record<string, any>>;
  suggestedValueProps: ExtractedDataFields;
  submissionId: string;
  entityId: string;
  getValue?: (formValue: any) => any;
}

function removeDuplicateFeatures(promptResults: PromptResultCreate[]): PromptResultCreate[] {
  const groupedResults = groupBy(promptResults, 'feature');

  return Object.values(groupedResults).map((entries) => {
    // Try to find an entry where isHidden is false.
    const visibleEntry = entries.find((entry) => !entry.isHidden);
    return visibleEntry || entries[0];
  });
}
function getPromptResultsReport({
  formState,
  suggestedValueProps,
  submissionId,
  entityId,
  getValue,
}: PromptResultsReportParams): PromptResultCreate[] {
  const promptResults: PromptResultCreate[] = [];
  Object.keys(suggestedValueProps).forEach((suggestedField) => {
    const suggestedValueProp = suggestedValueProps[suggestedField];
    if (isNil(suggestedValueProp)) {
      return;
    }

    if (suggestedValueProp.promptResultFeature === null) {
      return;
    }
    const convertValueToString = (value: any) => {
      if (isNil(value)) {
        return '';
      }

      // check if a valid date - ignore a number string which can also be a timestamp
      if (!isNumeric(value) && new Date(value).toString() !== 'Invalid Date') {
        return new Date(value).toISOString();
      }
      return isString(value) ? value : JSON.stringify(value);
    };
    const formValue = formState[suggestedField];
    const getModifiedResult = () => {
      const value = getValue ? getValue(formValue) : formValue;
      if (isArray(value)) {
        return convertValueToString(value.filter((val) => !isNil(val)));
      }
      return convertValueToString(value);
    };

    promptResults.push({
      result: convertValueToString(suggestedValueProp.suggestion.value),
      input: {},
      modifiedResult: getModifiedResult(),
      feature: suggestedValueProp.promptResultFeature,
      submissionId,
      isHidden: !(suggestedField in formState),
      entityId,
      resultReason: suggestedValueProp.suggestion.reason,
    });
  });
  return removeDuplicateFeatures(promptResults);
}

export function useSendPromptReport() {
  const { batchCreatePromptResult } = useMutatePromptResult();

  return async (params: PromptResultsReportParams) => {
    const promptResults = getPromptResultsReport(params);
    await batchCreatePromptResult.mutateAsync(promptResults);
  };
}
