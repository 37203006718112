import { Submission } from 'types';
import {
    FlowQuestionId,
    heraldEffectiveDateQuestionIds,
    heraldLimitQuestionIds,
    heraldMailingAddressQuestionIds,
    heraldPrimaryAddressQuestionIds,
    heraldQuestionId,
    SubmissionAdditionalData,
} from '../../../../types';

interface HeraldProviderProps {
    id: string;
    instance?: string;
}

interface FlowSubmissionProviderProps {
    key: keyof Submission | keyof SubmissionAdditionalData;
    source: 'submission' | 'submissionAdditional';
}

interface FlowQuestionsConfigMapperProps {
    herald?: HeraldProviderProps[];
    submission: FlowSubmissionProviderProps;
}

export const flowQuestionsConfigMapper: Record<FlowQuestionId, FlowQuestionsConfigMapperProps> = {
    [FlowQuestionId.InsuredName]: {
        herald: [
            {
                id: heraldQuestionId.rsk_m4p9_insured_name,
            },
        ],
        submission: {
            key: 'insuredName',
            source: 'submission',
        },
    },
    [FlowQuestionId.Industry]: {
        herald: [
            {
                id: heraldQuestionId.rsk_b3jm_2017_naics_index,
            },
        ],
        submission: {
            key: 'industry',
            source: 'submission',
        },
    },
    [FlowQuestionId.PrimaryAddress]: {
        herald: heraldPrimaryAddressQuestionIds.map((id) => ({
            id,
            instance: id === heraldQuestionId.rsk_yor8_location ? 'location_1' : undefined,
        })),
        submission: {
            key: 'insuredPrimaryAddress',
            source: 'submission',
        },
    },
    [FlowQuestionId.MailingAddress]: {
        herald: heraldMailingAddressQuestionIds.map((id) => ({
            id,
        })),
        submission: {
            key: 'insuredMailingAddress',
            source: 'submission',
        },
    },
    [FlowQuestionId.InsuredCompanyType]: {
        submission: {
            key: 'insuredCompanyType',
            source: 'submission',
        },
    },
    [FlowQuestionId.InsuredEmployeeCount]: {
        herald: [
            {
                id: heraldQuestionId.rsk_k39d_number_of_employees,
            },
        ],
        submission: {
            key: 'insuredEmployeeCount',
            source: 'submission',
        },
    },
    [FlowQuestionId.InsuredAnnualRevenue]: {
        herald: [
            {
                id: heraldQuestionId.rsk_vrb1_total_annual_revenue,
            },
        ],
        submission: {
            key: 'insuredAnnualRevenue',
            source: 'submission',
        },
    },
    [FlowQuestionId.InsuredWebsite]: {
        herald: [
            {
                id: heraldQuestionId.rsk_dy7r_domain_names,
                instance: 'domain_names_1',
            },
        ],
        submission: {
            key: 'insuredWebsite',
            source: 'submission',
        },
    },
    [FlowQuestionId.Limit]: {
        herald: heraldLimitQuestionIds.map((heraldLimitQuestionId) => ({
            id: heraldLimitQuestionId,
        })),
        submission: {
            key: 'limit',
            source: 'submission',
        },
    },
    [FlowQuestionId.EffectiveDate]: {
        herald: heraldEffectiveDateQuestionIds.map((heraldEffectiveDateQuestionId) => ({
            id: heraldEffectiveDateQuestionId,
        })),
        submission: {
            key: 'dueDate',
            source: 'submission',
        },
    },

    [FlowQuestionId.NeedByDate]: {
        submission: {
            key: 'needByDate',
            source: 'submission',
        },
    },
    [FlowQuestionId.AttorneyNumber]: {
        submission: {
            key: 'attorneyNumber',
            source: 'submissionAdditional',
        },
    },
    [FlowQuestionId.AttorneyHireYears]: {
        submission: {
            key: 'attorneyHireYears',
            source: 'submissionAdditional',
        },
    },
    [FlowQuestionId.AreasOfPractice]: {
        submission: {
            key: 'areasOfPractice',
            source: 'submissionAdditional',
        },
    },
};
