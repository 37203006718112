import { isEmpty } from 'lodash';
import JsxParser from 'react-jsx-parser';
import { Suggestion } from 'types';
import {
  resolveCitations,
  stripAllAnnotations,
} from 'broker/pages/SubmissionWorkspacePage/contexts/ai/citations-utils';
import { AICitationLink } from './AICitationLink';

export const AIReason = ({ suggestion }: { suggestion: Suggestion }) => {
  const { reason, citations } = suggestion;

  if (!reason) {
    return null;
  }

  // The reason can contain AI generated citation placeholders even if the `citations` array is empty.
  const resolvedReason = isEmpty(citations) ? stripAllAnnotations(reason) : resolveCitations(reason, citations!);

  return (
    <JsxParser
      renderInWrapper={false}
      jsx={resolvedReason}
      // For some reason TS doesn't allow components that get props but it does work
      // (also according to library documentation (see: https://github.com/TroyAlford/react-jsx-parser/issues/209) -
      // @ts-expect-error
      components={{ AICitationLink }}
      showWarnings
    />
  );
};
