import { compact } from 'lodash';
// eslint-disable-next-line import/no-unresolved
import { Content } from 'pdfmake/interfaces';
import { convertImageToBase64, PdfContentProps } from './pdf-content-creator';

interface PdfCoverProps {
  title: string;
  subtitle1: string;
  subtitle2?: string;
  subtitle3?: string;
}
export const getPdfCover = async ({
  title,
  subtitle1,
  subtitle2,
  subtitle3,
}: PdfCoverProps): Promise<PdfContentProps> => {
  const backgroundImage = await convertImageToBase64('/img/pdf-cover-bg.png');
  const logoImage = await convertImageToBase64('/img/flow-logo-white.png');

  const bgImage: Content = {
    image: `data:image/png;base64,${backgroundImage}`,
    absolutePosition: { x: 0, y: 0 },
    width: 595,
    height: 842,
  };

  const logo: Content = {
    image: `data:image/png;base64,${logoImage}`,
    width: 160, // Change this value based on your logo size
    alignment: 'left',
    margin: [0, 200, 0, 0], // top margin positions the logo vertically
  };

  const titleText: Content = {
    text: title,
    alignment: 'left',
    font: 'Inter', // Make sure this matches your registered custom font name
    fontSize: 16,
    margin: [0, 10, 0, 0], // Adjust spacing as needed
    color: 'white',
    style: 'preparedTextBold',
  };

  const coverageLinesText: Content = {
    text: subtitle1,
    alignment: 'left',
    font: 'Inter', // Make sure this matches your registered custom font name
    fontSize: 12,
    margin: [0, 0, 0, 0], // Adjust spacing as needed
    color: 'white',
    style: 'preparedText',
  };

  const insuredText: Content | undefined = subtitle2
    ? {
        text: subtitle2,
        alignment: 'left',
        font: 'Inter', // Make sure this matches your registered custom font name
        fontSize: 12,
        margin: [0, 0, 0, 0], // Adjust spacing as needed
        color: 'white',
        style: 'preparedText',
      }
    : undefined;

  const deliveryDateText: Content | undefined = subtitle3
    ? {
        text: subtitle3,
        alignment: 'left',
        font: 'Inter', // Make sure this matches your registered custom font name
        fontSize: 12,
        margin: [0, 0, 0, 0], // Adjust spacing as needed
        color: 'white',
        style: 'preparedText',
      }
    : undefined;

  return {
    header: () => [],
    footer() {
      return [
        {
          text: 'Flow Specialty Insurance Services, LLC  |  CA License Number: 6008097',
          alignment: 'center',
          font: 'Inter', // custom font; ensure it's registered
          fontSize: 12,
          color: 'white',
          margin: [0, 20, 0, 0], // negative top margin to move text over the image
        },
      ];
    },
    content: compact([bgImage, logo, titleText, coverageLinesText, insuredText, deliveryDateText]),
  };
};
