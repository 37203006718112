import { isEmpty } from 'lodash';
import { Recipient, RecipientGroup, Submission, User } from '../types';
import { isCustomerOrganization, isMarketOrganization } from '../enums';

export function getCustomerFirstNames(submission: Submission, recipientGroup?: RecipientGroup) {
    let recipients: Recipient[] = [];

    if (!recipientGroup || !isCustomerOrganization(recipientGroup.type)) {
        if (submission.user) {
            recipients = [submission.user];
        }
    } else if (recipientGroup) {
        recipients = recipientGroup.recipients;
    }

    return recipients.map((recipientItem) => recipientItem.firstName).join(', ');
}

export function getSignatureOf(user: User) {
    return { value: user.signature ? user.signature : `${user.firstName} ${user.lastName}` };
}

export function getMarketFirstNames(recipientGroup?: RecipientGroup) {
    if (isEmpty(recipientGroup?.recipients) || !isMarketOrganization(recipientGroup?.type)) {
        return undefined;
    }

    const humanRecipients = recipientGroup!.recipients.filter((recipientItem) => !recipientItem.isSubmissionBox);

    return humanRecipients.map((recipientItem) => recipientItem.firstName).join(', ');
}
