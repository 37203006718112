//
// IMPORTANT! READ ME!
// ==========
// This enum is used in 4 tables: submission, quote, fast_track_market_coverage_line and insurance_product_coverage_line.
// This is because the test DB is automatically created with a copy of it
// for all three usages, so to enforce parity with the testing environment
// we decided to create this enum for each usage as well.
// When making a change to this enum's values, make sure to run a migration
// that changes all 4 database enums:
// 1. enum_submission_coverage_lines
// 2. enum_quote_coverage_line
// 3. enum_insurance_product_coverage_line_coverage_line
// 4. enum_fast_track_market_coverage_line
// For an example, see backend/src/migrations/20250201163652-new-coverage-lines.js
// !!! In addition, don't forget to update the enum in the lambdas/common/backend/enums/coverage-line.ts !!!!

export enum CoverageLine {
    PublicDnO = 'PublicDnO',
    PrivateDnO = 'PrivateDnO',
    NonProfitDnO = 'NonProfitDnO',
    EnO = 'Eno',
    Cyber = 'Cyber',
    EPL = 'Epl',
    Property = 'Property',
    Casualty = 'Casualty',
    KidnapRansom = 'KidnapRansom',
    Fiduciary = 'Fiduciary',
    Crime = 'Crime',
    ProfessionalLiability = 'ProfessionalLiability',
    CommercialAutomobile = 'CommercialAutomobile',
    ExcessCommercialAuto = 'ExcessCommercialAuto',
    ExcessLiability = 'ExcessLiability',
    ExcessUmbrella = 'ExcessUmbrella',
    GeneralLiability = 'GeneralLiability',
    GeneralLiabilityAndPollutionLiability = 'GeneralLiabilityAndPollutionLiability',
    GeneralLiabilityAndProfessionalLiability = 'GeneralLiabilityAndProfessionalLiability',
    ProductsLiability = 'ProductsLiability',
    ProductsRecallLiability = 'ProductsRecallLiability',
    Umbrella = 'Umbrella',
    WorkersCompOutOfState = 'WorkersCompOutOfState',
    WorkersCompensation = 'WorkersCompensation',
    PollutionLiability = 'PollutionLiability',
    HiredNonOwnedAuto = 'HiredNonOwnedAuto',
    MiscellaneousCommercial = 'MiscellaneousCommercial',
    BoilerAndMachinery = 'BoilerAndMachinery',
    BuildersRiskInstallation = 'BuildersRiskInstallation',
    Cargo = 'Cargo',
    DICDifferenceInCondition = 'DICDifferenceInCondition',
    EarthquakeCommercial = 'EarthquakeCommercial',
    EquipmentFloater = 'EquipmentFloater',
    ExcessProperty = 'ExcessProperty',
    MotorTruckCargo = 'MotorTruckCargo',
    OceanMarine = 'OceanMarine',
    Terrorism = 'Terrorism',
    FloodCommercial = 'FloodCommercial',
    ExportImportNavigationTransitOfGoodsSPECIAL = 'ExportImportNavigationTransitOfGoodsSPECIAL',
    ForeignPackagePolicy = 'ForeignPackagePolicy',
    BusinessAndManagementPackage = 'BusinessAndManagementPackage',
    DirectorsAndOfficersLiability = 'DirectorsAndOfficersLiability',
    ElectronicDataProcessing = 'ElectronicDataProcessing',
    ERISAOnCrimePolicyOrERISABond = 'ERISAOnCrimePolicyOrERISABond',
    ExcessDirectorsAndOfficersLiability = 'ExcessDirectorsAndOfficersLiability',
    BoatCommercial = 'BoatCommercial',
    GarageAndDealers = 'GarageAndDealers',
    MarineLiability = 'MarineLiability',
    ExcessMarineLiability = 'ExcessMarineLiability',
    CommercialPackage = 'CommercialPackage',
    ExcessProfessionalLiability = 'ExcessProfessionalLiability',
    Bond = 'Bond',
    BusinessOwnersPackage = 'BusinessOwnersPackage',
    EmployedLawyersLiability = 'EmployedLawyersLiability',
    MiscellaneousProfessionalLiability = 'MiscellaneousProfessionalLiability',
    AccountantsPl = 'AccountantsPl',
    ArchitectEngineersPl = 'ArchitectEngineersPl',
    ContractorsProfessional = 'ContractorsProfessional',
    EmploymentOrganizationsPl = 'EmploymentOrganizationsPl',
    InsuranceAgentsBrokersEnO = 'InsuranceAgentsBrokersEnO',
    ManufacturersEnO = 'ManufacturersEnO',
    LawyersProfessionalLiability = 'LawyersProfessionalLiability',
    FinancialAdvisorsInvestmentAdvisorsPl = 'FinancialAdvisorsInvestmentAdvisorsPl',
    MediaMultimedia = 'MediaMultimedia',
    TechnologyEnO = 'TechnologyEnO',
    FranchisorsEnO = 'FranchisorsEnO',
    Rnd = 'Rnd',
    SpecifiedProfessionalLiability = 'SpecifiedProfessionalLiability',
    PhysiciansMedicalMalpractice = 'PhysiciansMedicalMalpractice',
    AlliedHealthcareProfessionalLiability = 'AlliedHealthcareProfessionalLiability',
    ManagementLiability = 'ManagementLiability',
    PrivateEmploymentPracticesLiability = 'PrivateEmploymentPracticesLiability',
    PrivateCrime = 'PrivateCrime',
    PrivateFiduciary = 'PrivateFiduciary',
    PublicEmploymentPracticesLiability = 'PublicEmploymentPracticesLiability',
    PublicCrime = 'PublicCrime',
    PublicFiduciary = 'PublicFiduciary',
    NonProfitEmploymentPracticesLiability = 'NonProfitEmploymentPracticesLiability',
    NonProfitCrime = 'NonProfitCrime',
    NonProfitFiduciary = 'NonProfitFiduciary',
    ExcessManagementLiability = 'ExcessManagementLiability',
    ExcessCyber = 'ExcessCyber',
    EnvironmentalLiability = 'EnvironmentalLiability',
    EnvironmentalProfessionalsPackage = 'EnvironmentalProfessionalsPackage',
    ContractorsPollutionsLiability = 'ContractorsPollutionsLiability',
    StorageTankPollutionLiability = 'StorageTankPollutionLiability',
    SitePollutionLiability = 'SitePollutionLiability',
    UniqueOfferings = 'UniqueOfferings',
    IntellectualProperty = 'IntellectualProperty',
    SexualAbuseMolestation = 'SexualAbuseMolestation',
    KidnapRansomExecutiveProtection = 'KidnapRansomExecutiveProtection',
    TerrorismActiveAssailantShooter = 'TerrorismActiveAssailantShooter',
    AccountsReceivable = 'AccountsReceivable',
    RealEstateDevelopersPL = 'RealEstateDevelopersPL',
    TenantDiscrimination = 'TenantDiscrimination',
    EnvironmentalProfessionalsPL = 'EnvironmentalProfessionalsPL',
    MedicalBillersEnO = 'MedicalBillersEnO',
    LifeSciencesPL = 'LifeSciencesPL',
    TitleAgentsEnO = 'TitleAgentsEnO',
}

// IGNORE THE COMMENT ABOVE AT YOUR OWN PERIL!!!
