export enum SubmissionStatus {
    Processing = 'Processing',
    Review = 'Review',
    New = 'New',
    Quoting = 'Quoting',
    Issued = 'Issued',
    Binding = 'Binding',
    Issuing = 'Issuing',
    Archived = 'Archived',
    Closed = 'Closed',
    PendingClient = 'PendingClient',
    Quoted = 'Quoted',
    PendingRenewal = 'PendingRenewal', // Currently used only for demos
}

export type SubmissionTerminationStatus = SubmissionStatus.Closed | SubmissionStatus.Archived;

export function isSubmissionTerminated(status?: SubmissionStatus): boolean {
    if (!status) {
        return false;
    }

    return [SubmissionStatus.Closed, SubmissionStatus.Archived].includes(status);
}
