import { Subjectivities } from '@common/types/quote';
import { compact, map, union, without } from 'lodash';
import { useMemo } from 'react';
import { Params, useParams } from 'react-router-dom-latest';
import shortUUID from 'short-uuid';
import { ArfRequestData, ArfSentData, SubmissionStatusUpdateData } from 'clients';
import { ActivityType, BoxTemplateLabels, EmailTemplateType } from 'enums';
import {
  useCurrentUser,
  useInvalidateNotification,
  useMutateActivityLog,
  useMutateLayer,
  useMutateQuote,
  useMutateSubmission,
  useMutateSubmissionMarket,
  useMutateSubmissionMarketRequest,
} from 'hooks';
import { useMutateBoxItems } from 'hooks/api/box';
import useRetrieveExtendedSubmission from 'hooks/api/extended-submission/useRetrieveExtendedSubmission';
import { useMutateMarketIssuingCompany } from 'hooks/api/marketIssuingCompany';

import {
  ActivityLogCreate,
  Contact,
  CreateBorPolicy,
  CreateMarketIssuingCompany,
  CreateSubmissionMarketRequest,
  Layer,
  PartialSubmission,
  PolicyDetails,
  Quote,
  Submission,
  SubmissionMarket,
  SubmissionMarketCreate,
  SubmissionMarketRequest,
  UpdateSubmissionMarketRequest,
} from 'types';
import { MakeFieldsOptional } from 'utils';
import { emailTemplateTypeToActivityType } from 'broker/configuration-mappers/email-templates-activity-log-config';
import { getAreSubmissionMarketingBlockingFieldsFilled } from 'broker/utils';
import { getSubmissionWithDraft } from './getSubmissionWithDraft';
import { EmailActivity, SubmissionWorkspaceInitState, SubmissionWorkspaceProps } from './types';

export default function useSubmissionsWorkspace() {
  const { id: submissionId } = useParams<Params>();
  const invalidateNotification = useInvalidateNotification();

  const quoteApi = useMutateQuote({ cancelInvalidation: true });

  const submissionMarketApi = useMutateSubmissionMarket({
    cancelInvalidation: true,
  });

  const submissionApi = useMutateSubmission({ cancelInvalidation: true });

  const layerApi = useMutateLayer({ cancelInvalidation: true });

  const marketIssuingCompanyApi = useMutateMarketIssuingCompany({ cancelInvalidation: true });

  const submissionMarketRequestApi = useMutateSubmissionMarketRequest({
    cancelInvalidation: true,
  });

  const { addFileMetadata } = useMutateBoxItems();

  const { data, isFetching, isInitialLoading, fetchExtendedSubmission, clearCache } = useRetrieveExtendedSubmission({
    submissionId,
  });

  const { submission: fetchedSubmission, layers, quotes, markets, activities } = data || SubmissionWorkspaceInitState;

  // We need to use the submission with draft to ensure that the draft marketing update is fresh
  const submission = useMemo(() => getSubmissionWithDraft(fetchedSubmission), [fetchedSubmission]);

  const addQuoteMetadata = async (quote: Partial<Quote>, submissionMarketId: string) => {
    await Promise.all([
      ...(quote.capitolaQuoteFileIds?.map(async (quoteFileId) => {
        await addFileMetadata.mutateAsync({
          fileId: quoteFileId,
          metadata: {
            [BoxTemplateLabels.MarketId]: submissionMarketId,
            [BoxTemplateLabels.IsVisibleToRetailer]: 'True',
          },
        });
      }) ?? []),
      ...(quote.marketQuoteFileIds?.map(async (marketQuoteFileId) => {
        await addFileMetadata.mutateAsync({
          fileId: marketQuoteFileId,
          metadata: {
            [BoxTemplateLabels.MarketId]: submissionMarketId,
          },
        });
      }) ?? []),
    ]);
  };

  const createQuote = async (quote: Partial<Quote>): Promise<string | undefined> => {
    const updatedQuote = await quoteApi.createQuote.mutateAsync({
      data: { ...quote },
    });
    await addQuoteMetadata(quote, quote.submissionMarketId!);
    await fetchExtendedSubmission();
    return updatedQuote?.id;
  };

  const updateQuote = async (id: string, quote: Partial<Quote>): Promise<string | undefined> => {
    const updatedQuote = await quoteApi.updateQuote.mutateAsync({
      id,
      data: quote,
    });
    await addQuoteMetadata(quote, quote.submissionMarketId!);
    await fetchExtendedSubmission();
    return updatedQuote?.id;
  };

  const bindQuote = async (
    id: string,
    binderFileIds: string[],
    flowBinderFileIds: string[],
    policyDetails?: PolicyDetails,
    flowCommission?: number,
    binderSubjectivities?: Subjectivities,
    binderDate?: Date,
  ) => {
    const quoteToBind = quotes.find((quote) => quote.id === id);
    const submissionMarketId = quoteToBind?.submissionMarketId;

    await quoteApi.updateQuote.mutateAsync({
      id: `${id}/bind`,
      data: { binderFileIds, flowBinderFileIds, policyDetails, flowCommission, binderSubjectivities, binderDate },
    });

    await Promise.all([
      ...flowBinderFileIds.map(async (fileId) => {
        await addFileMetadata.mutateAsync({
          fileId,
          metadata: {
            [BoxTemplateLabels.MarketId]: submissionMarketId,
            [BoxTemplateLabels.IsVisibleToRetailer]: 'True',
          },
        });
      }),
      ...binderFileIds.map(async (binderFile) => {
        await addFileMetadata.mutateAsync({
          fileId: binderFile,
          metadata: {
            [BoxTemplateLabels.MarketId]: submissionMarketId!,
            [BoxTemplateLabels.IsVisibleToRetailer]: 'True',
          },
        });
      }),
    ]);

    return fetchExtendedSubmission();
  };

  const removeQuote = async (id: string) => {
    await quoteApi.destroyQuote.mutateAsync({
      id,
    });
    return fetchExtendedSubmission();
  };

  const rejectQuote = async (id: string) => {
    await quoteApi.updateQuote.mutateAsync({
      id: `${id}/reject`,
      data: {},
    });
    return fetchExtendedSubmission();
  };

  const issueQuote = async (
    id: string,
    policyFileIds: string[],
    policyDetails?: PolicyDetails,
    flowCommission?: number,
  ) => {
    const submissionMarketId = quotes.find((quote) => quote.id === id)?.submissionMarketId;

    await quoteApi.updateQuote.mutateAsync({
      id: `${id}/issue-policy`,
      data: { policyFileIds, policyDetails, flowCommission },
    });

    await Promise.all(
      policyFileIds.map(async (policyFile) => {
        await addFileMetadata.mutateAsync({
          fileId: policyFile,
          metadata: {
            [BoxTemplateLabels.MarketId]: submissionMarketId!,
            [BoxTemplateLabels.IsVisibleToRetailer]: 'True',
          },
        });
      }),
    );

    return fetchExtendedSubmission();
  };

  const createBorPolicy = async (policyData: CreateBorPolicy, submissionMarketId?: string) => {
    await quoteApi.createBorPolicy(policyData);

    await Promise.all(
      policyData.policyFileIds.map(async (policyFile) => {
        await addFileMetadata.mutateAsync({
          fileId: policyFile,
          metadata: {
            ...(submissionMarketId && { [BoxTemplateLabels.MarketId]: submissionMarketId }),
            [BoxTemplateLabels.IsVisibleToRetailer]: 'True',
          },
        });
      }),
    );

    return fetchExtendedSubmission();
  };

  const addSubmissionMarket = async (addedMarket: SubmissionMarketCreate, reFetchWorkspace = true) => {
    const addedContactIds = addedMarket.contactIds;

    return submissionMarketApi.createSubmissionMarket.mutateAsync(
      {
        data: {
          ...addedMarket,
          submissionId,
          contactIds: addedContactIds,
        },
      },
      {
        onSuccess: async () => {
          if (reFetchWorkspace) {
            await fetchExtendedSubmission();
          }
        },
      },
    );
  };

  const addSubmissionMarkets = async (marketsToAdd: SubmissionMarketCreate[], reFetchWorkspace = true) => {
    let refetchRequired = false;
    const addedMarkets = await Promise.all(
      marketsToAdd.map(async (marketToAdd) => {
        const addedContactIds = marketToAdd.contactIds;
        // Make sure that if we are trying to add an existing submission market,
        // we actually update it with the union of it's contact ids
        const existingMarket = markets.find((market) => market.userMarketId === marketToAdd.id);

        if (!existingMarket) {
          return submissionMarketApi.createSubmissionMarket.mutateAsync(
            {
              data: {
                ...marketToAdd,
                submissionId,
                contactIds: addedContactIds,
              },
            },
            {
              onSuccess: async () => {
                refetchRequired = true;
              },
            },
          );
        }

        const existingContactIds = existingMarket.contacts.map((contact) => contact.id);

        return submissionMarketApi.updateSubmissionMarket.mutateAsync(
          {
            id: existingMarket.id,
            data: {
              ...marketToAdd,
              contactIds: union(existingContactIds, addedContactIds),
            },
          },
          {
            onSuccess: async () => {
              refetchRequired = true;
            },
          },
        );
      }),
    );

    if (reFetchWorkspace && refetchRequired) {
      await fetchExtendedSubmission();
    }

    return compact(addedMarkets);
  };

  const addSubmissionMarketRequest = async (
    submissionMarketRequest: CreateSubmissionMarketRequest,
    reFetchWorkspace = true,
  ) => {
    await submissionMarketRequestApi.createSubmissionMarketRequest.mutateAsync({ data: submissionMarketRequest });

    if (reFetchWorkspace) {
      await fetchExtendedSubmission();
    }
  };

  const deleteSubmissionMarketRequest = async (id: string, reFetchWorkspace = true) => {
    await submissionMarketRequestApi.destroySubmissionMarketRequest.mutateAsync({ id });

    if (reFetchWorkspace) {
      await fetchExtendedSubmission();
    }
  };

  const updateSubmissionMarket = async (
    id: string,
    submissionMarket: Partial<SubmissionMarket>,
    reFetchWorkspace = true,
  ) => {
    await submissionMarketApi.updateSubmissionMarket.mutateAsync({
      id,
      data: submissionMarket,
      fetchOptions: { throwOnError: false },
    });

    if (reFetchWorkspace) {
      await fetchExtendedSubmission();
    }
  };

  const addContactsToSubmissionMarket = async (userMarketId: string, contacts: Contact[]) => {
    let contactAdded = false;
    const submissionMarket = markets.find((market) => market.userMarketId === userMarketId);
    if (submissionMarket) {
      const contactIds = submissionMarket.contacts.map((contact) => contact.id);
      contacts.forEach((contact) => {
        if (!contactIds.includes(contact.id)) {
          contactAdded = true;
          contactIds.push(contact.id);
        }
      });
      if (contactAdded) {
        await submissionMarketApi.updateSubmissionMarket.mutateAsync({
          id: submissionMarket.id,
          data: {
            contactIds,
          },
          fetchOptions: { throwOnError: false },
        });
        return fetchExtendedSubmission();
      }
    }
    return Promise.resolve();
  };

  const addSubmissionMarketRequests = async (
    marketRequests: CreateSubmissionMarketRequest[],
    reFetchWorkspace = true,
  ) => {
    const createdRequests = await Promise.all(
      marketRequests
        .map((marketRequest) =>
          submissionMarketRequestApi.createSubmissionMarketRequest.mutateAsync({ data: marketRequest }),
        )
        .filter(Boolean),
    );
    if (reFetchWorkspace) {
      await fetchExtendedSubmission();
    }
    return createdRequests as SubmissionMarketRequest[];
  };

  const updateSubmissionMarketRequest = async (
    id: string,
    submissionMarketRequest: UpdateSubmissionMarketRequest,
    reFetchWorkspace = true,
  ) => {
    await submissionMarketRequestApi.updateSubmissionMarketRequest.mutateAsync({
      id,
      data: submissionMarketRequest,
    });

    if (reFetchWorkspace) {
      await fetchExtendedSubmission();
    }
  };

  const updateLayer = async (id: string, layerData: Partial<Layer>) => {
    await layerApi.updateLayer.mutateAsync({ id, data: layerData });
    return fetchExtendedSubmission();
  };

  const deleteLayer = async (id: string) => {
    await layerApi.destroyLayer.mutateAsync({ id });
    return fetchExtendedSubmission();
  };

  const createLayer = async (layerData: Partial<Layer>) => layerApi.createLayer.mutateAsync({ data: layerData });

  const createMarketIssuingCompany = async (marketIssuingCompanyData: CreateMarketIssuingCompany) =>
    marketIssuingCompanyApi.createMarketIssuingCompany.mutateAsync({ data: marketIssuingCompanyData });

  const updateSubmission = async (id: string, submissionData: Partial<Submission>, reFetchWorkspace = true) => {
    await submissionApi.updateSubmission.mutateAsync({ id, data: submissionData });

    if (reFetchWorkspace) {
      await fetchExtendedSubmission();
      invalidateNotification();
    }
  };

  const updateSubmissionTasks = async (submissionStatusUpdateData: SubmissionStatusUpdateData) => {
    const { submissionId: id, task, status } = submissionStatusUpdateData;
    await submissionApi.updateSubmissionTaskStatus.mutateAsync({ submissionId: id, task, status });
    await fetchExtendedSubmission();
  };

  const { me } = useCurrentUser();

  const { createActivityLog } = useMutateActivityLog();

  const logSubmissionActivity = async (
    activity: MakeFieldsOptional<ActivityLogCreate, 'actorId' | 'submissionId' | 'metadata'>,
    reFetchWorkspace = false,
  ) => {
    await createActivityLog({
      data: {
        ...activity,
        actorId: activity.actorId ?? me?.id,
        submissionId: activity.submissionId ?? submission?.id,
        metadata: activity.metadata,
      },
    });
    if (reFetchWorkspace) {
      await fetchExtendedSubmission();
    }
  };

  const logEmailActivities = async (
    emailTemplateType: EmailTemplateType | undefined,
    emailActivities: EmailActivity[],
    reFetchWorkspace = true,
  ) => {
    const activityType = !emailTemplateType
      ? ActivityType.MarketEmailSent
      : emailTemplateTypeToActivityType[emailTemplateType];

    const groupKey = shortUUID().new();
    await Promise.all(
      emailActivities?.flatMap(async (activity) => {
        const { submissionMarketId, ...rest } = activity;

        if (activityType) {
          await logSubmissionActivity({
            activityType,
            submissionMarketId,
            metadata: { ...rest },
            groupKey,
          });
        }
      }),
    );

    if (reFetchWorkspace) {
      await fetchExtendedSubmission();
    }
  };

  const removeContactFromSubmissionMarket = async (submissionMarketId: string, contact: Contact) => {
    const submissionMarket = markets.find((market) => market.id === submissionMarketId);
    if (!submissionMarket) {
      return;
    }

    await updateSubmissionMarket(submissionMarket.id, {
      contactIds: without(map(submissionMarket.contacts, 'id'), contact.id),
    });
  };

  const arfSent = async (arfSentData: ArfSentData) => {
    await submissionApi.arfSent.mutateAsync(arfSentData);
    await fetchExtendedSubmission();
  };

  const arfRequest = async (arfRequestData: ArfRequestData) => {
    await submissionApi.sendArfRequest.mutateAsync(arfRequestData);
    await fetchExtendedSubmission();
  };

  const submissionWorkspaceAction: SubmissionWorkspaceProps = {
    createBorPolicy,
    updateQuote,
    bindQuote,
    removeQuote,
    rejectQuote,
    issueQuote,
    addSubmissionMarket,
    addSubmissionMarkets,
    updateSubmissionMarket,
    addContactsToSubmissionMarket,
    addSubmissionMarketRequests,
    addSubmissionMarketRequest,
    updateSubmissionMarketRequest,
    deleteSubmissionMarketRequest,
    updateLayer,
    createQuote,
    createLayer,
    createMarketIssuingCompany,
    deleteLayer,
    updateSubmission,
    logEmailActivities,
    logSubmissionActivity,
    reFetchWorkspace: fetchExtendedSubmission,
    removeContactFromSubmissionMarket,
    clearCache,
    submission: submission && getAreSubmissionMarketingBlockingFieldsFilled(submission) ? submission : null,
    partialSubmission: submission as PartialSubmission,
    layers,
    markets,
    quotes,
    activities,
    isReFetching: isFetching && !isInitialLoading,
    arfSent,
    arfRequest,
    updateSubmissionTasks,
  };

  return submissionWorkspaceAction;
}
