import { SubmissionDataDestination } from '../enums';

export function mapSourceToSubmissionDataDestination(source: string): SubmissionDataDestination {
    switch (source) {
        case 'herald':
            return SubmissionDataDestination.Herald;
        case 'submission':
            return SubmissionDataDestination.Submission;
        case 'submissionAdditionalData':
            return SubmissionDataDestination.SubmissionAdditionalData;
        default:
            throw new Error(`Unknown source: ${source}`);
    }
}
