import { IconButton, NewMenu } from '@common-components';
import { MoreVert, OpenInNew } from '@icons';
import { SubmissionStatus, SubmissionTerminationStatus } from 'enums';
import { useConditionallyRenderedModal, useOptionsMenu } from 'hooks';
import { messages } from 'i18n';
import { InboundEmailMessageSearchItem, PartialSubmission } from 'types';
import { MenuItemProps } from 'components/menu';
import TerminateSubmissionDialog from 'broker/dialogs/TerminateSubmissionDialog';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { useDownloadMenu } from './useDownloadMenu';

const websiteBaseUrl = process.env.REACT_APP_WEBSITE_BASE_URL;

type TerminationDialogProps = {
  targetStatus: SubmissionTerminationStatus;
};

export interface SubmissionHeaderMenuProps {
  submission: PartialSubmission;
  submissionCreationEmail?: InboundEmailMessageSearchItem;
}

export function SubmissionHeaderMenu({ submission, submissionCreationEmail }: SubmissionHeaderMenuProps) {
  const optionsMenuState = useOptionsMenu();
  const { downloadMenuItems, isDownloading } = useDownloadMenu({ submission, submissionCreationEmail });
  const { reFetchWorkspace } = useSubmissionsWorkspace();

  const {
    openModal: openTerminationDialog,
    closeModal: closeTerminationDialog,
    modalState: terminationDialogState,
  } = useConditionallyRenderedModal<TerminationDialogProps>({});

  const onArchiveClick = () => {
    openTerminationDialog({ targetStatus: SubmissionStatus.Archived });
  };

  const onCloseSubmissionClick = () => {
    openTerminationDialog({ targetStatus: SubmissionStatus.Closed });
  };

  const getMenuItems = (): MenuItemProps[] => {
    const menuItems: MenuItemProps[] = [];
    const closeSubmissionMenuItem: MenuItemProps = {
      label: messages.submissionWorkspace.termination.closeMenuLabel,
      disabled: submission.status === SubmissionStatus.Closed,
      onClick: onCloseSubmissionClick,
    };
    const archiveMenuItem: MenuItemProps = {
      label: messages.submissionWorkspace.termination.archiveMenuLabel,
      disabled: submission.status === SubmissionStatus.Archived,
      onClick: onArchiveClick,
    };
    const downloadMenuItem: MenuItemProps = {
      label: messages.submissionWorkspace.downloadFilesAndEmails.menuLabel,
      subMenu: downloadMenuItems,
    };
    menuItems.push(closeSubmissionMenuItem);
    menuItems.push(archiveMenuItem);
    if (submission.priorSubmissionId) {
      menuItems.push({
        label: messages.submissionWorkspace.renewalSubmission.openPriorSubmissionMenuLabel,
        endAdornment: OpenInNew,
        onClick: () => {
          window.open(`${websiteBaseUrl}/broker/submission/${submission.priorSubmissionId}`, '_blank');
        },
      });
    }
    menuItems.push(downloadMenuItem);
    return menuItems;
  };

  return (
    <>
      <IconButton
        icon={MoreVert}
        onClick={optionsMenuState.openMenu}
        color="secondary"
        variant="text"
        loading={isDownloading}
      />
      <NewMenu
        optionsMenuState={optionsMenuState}
        subMenuPlacement="left"
        menuItems={[
          {
            key: 'submission-header-menu',
            items: getMenuItems(),
          },
        ]}
      />
      {terminationDialogState.shouldRender && (
        <TerminateSubmissionDialog
          showTerminationConfirmation={terminationDialogState.isOpen}
          closeTerminationConfirmation={closeTerminationDialog}
          submission={submission}
          targetStatus={terminationDialogState.modalProps!.targetStatus}
          onUpdateSuccess={reFetchWorkspace}
        />
      )}
    </>
  );
}
