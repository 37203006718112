import { StateCompliance } from '@common/types';
import { Box, Link, Stack, Typography } from '@common-components';
import { FormFieldsConfigProps } from 'hooks';
import { messages } from 'i18n';
import FormEditor from 'components/hookFormComponents/FormEditor';
import { DetailsFieldsNames } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/Quote/form-methods/enums';

interface StateComplianceFieldProps {
  disabled?: boolean;
  formFieldProps: FormFieldsConfigProps<DetailsFieldsNames>;
  stateCompliance?: StateCompliance;
  setStateCompliance: (stateCompliance: string) => void;
}

export default function StateComplianceField({
  disabled,
  formFieldProps,
  stateCompliance,
  setStateCompliance,
}: StateComplianceFieldProps) {
  return (
    <Box position="relative" height={300}>
      <FormEditor
        optional
        disabled={disabled}
        {...formFieldProps}
        helperTextContent={
          !disabled && stateCompliance?.quoteLetter ? (
            <Stack direction="row" gap={0.5}>
              <Typography variant="caption">{messages.addQuotePage.stateCompliance.helperText}</Typography>
              <Link
                className="populate-state-compliance"
                sx={{ cursor: 'pointer' }}
                underline="hover"
                onClick={() => setStateCompliance(stateCompliance.quoteLetter!)}
              >
                {messages.addQuotePage.stateCompliance.action}
              </Link>
            </Stack>
          ) : undefined
        }
      />
    </Box>
  );
}
