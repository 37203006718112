import { Transition } from 'history';
import { useRef, useState } from 'react';
import { Stack } from '@common-components';
import { LocalStorage } from 'enums';
import { useLocalStorage } from 'hooks';
import { messages } from 'i18n';
import { PartialSubmission } from 'types';
import { DirtyRouteGuard } from 'broker/components/DirtyContentGuard';
import DynamicForm from 'broker/pages/SubmissionWorkspacePage/components/DynamicForm';
import { DynamicFormImperativeHandle } from 'broker/pages/SubmissionWorkspacePage/components/DynamicForm/DynamicFormLayout/types';
import InnerPageLayout from 'broker/pages/SubmissionWorkspacePage/components/InnerPage/InnerPageLayout';
import { SubmissionStep } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/EditSubmissionNew/types';
import { SetNextStepsProps } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/EditSubmissionNew/useSetNextStep';
import { useGetMainAreaDimensionsState, useIsAcknowledgmentFlow } from 'broker/pages/SubmissionWorkspacePage/hooks';
import { RenewalBanner } from './RenewalBanner';

interface SubmissionFormInnerPageLayoutProps {
  setNextStep: (props?: SetNextStepsProps) => void;
  submission: PartialSubmission;
  applySuggestionsOnEmptyFields: boolean;
}

export default function SubmissionFormInnerPageLayout({
  setNextStep,
  submission,
  applySuggestionsOnEmptyFields,
}: SubmissionFormInnerPageLayoutProps) {
  const [isDirty, setIsDirty] = useState(false);
  const { isAcknowledgmentFlow } = useIsAcknowledgmentFlow();
  const dynamicFormRef = useRef<DynamicFormImperativeHandle | null>(null);
  const mainAreaDimensionsState = useGetMainAreaDimensionsState();
  const [reviewRenewalSubmissionDismissed, setReviewRenewalSubmissionDismissed] = useLocalStorage<string[]>(
    LocalStorage.ReviewRenewalSubmissionDismissed,
    [],
  );

  const showRenewalBanner = !!submission.priorSubmissionId && !reviewRenewalSubmissionDismissed.includes(submission.id);

  const onRenewalBannerDismiss = () => {
    setReviewRenewalSubmissionDismissed([...reviewRenewalSubmissionDismissed, submission.id]);
  };

  const beforeNavigation = async (transition: Transition) => {
    // allow navigating to routes that include the submission form (like navigating in toolbox tabs inside the page)
    if (transition.location.pathname.includes(`/${SubmissionStep.SubmissionForm}`)) {
      return true;
    }
    try {
      if (dynamicFormRef.current) {
        await dynamicFormRef.current.submitForm();
      }
    } catch (e) {
      return false;
    }
    return true;
  };

  const titleText = () => {
    if (isAcknowledgmentFlow) {
      return messages.acknowledgmentFlow.title;
    }
    return messages.editSubmission.formTitle;
  };

  return (
    <DirtyRouteGuard isDirty={isDirty} beforeNavigation={beforeNavigation}>
      <InnerPageLayout
        hideBackButton={isAcknowledgmentFlow}
        title={titleText()}
        className="submission-form-inner-page-layout"
        sx={{ padding: 0, overflow: 'hidden' }}
      >
        {() => (
          <Stack width={1} gap={3} height={1}>
            {showRenewalBanner && <RenewalBanner onBannerDismiss={onRenewalBannerDismiss} />}
            <DynamicForm
              setNextStep={setNextStep}
              ref={dynamicFormRef}
              applySuggestionsOnEmptyFields={applySuggestionsOnEmptyFields}
              isAcknowledgmentFlow={isAcknowledgmentFlow}
              mainAreaDimensionsState={mainAreaDimensionsState}
              setIsDirty={setIsDirty}
              onClose={setNextStep}
              submission={submission}
            />
          </Stack>
        )}
      </InnerPageLayout>
    </DirtyRouteGuard>
  );
}
