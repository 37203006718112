export enum FileClassificationType {
    // eslint-disable-next-line custom-rules/equal-enum-keys-and-values
    EAndOApplication = 'E&OApplication',
    RansomwareApplication = 'RansomwareApplication',
    RenewalApplication = 'RenewalApplication',
    ExpiringPolicy = 'ExpiringPolicy',
    LossRuns = 'LossRuns',
    Quote = 'Quote',
    Binder = 'Binder',
    Policy = 'Policy',
    MarketInvoice = 'Market Invoice',
    Unclassified = 'Unclassified',
    // file classification was too similar to another classification category
    Inconclusive = 'Inconclusive',
    PolicySpecimen = 'PolicySpecimen',
}
