import { ReactNode } from 'react';
import { Stack, Typography } from '@common-components';
import { getTimezoneLessDisplayDate } from 'utils';

export function AIBubbleItem({ description, date }: { description: ReactNode; date: Date }) {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      borderBottom={1}
      borderColor="divider"
      borderRadius={1}
      padding={1}
      gap={1}
    >
      <Typography variant="body2">{description}</Typography>
      <Typography variant="caption" alignSelf="flex-end">
        {getTimezoneLessDisplayDate(date)}
      </Typography>
    </Stack>
  );
}
