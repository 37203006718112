import { AnyObject, doesObjectContainNonEmptyValues } from '@common/utils';
import { isEmpty } from 'lodash';
import { TestContext } from 'yup';
import { messages } from 'i18n';

export function testRequiredNestedObject(requiredFields: string[]) {
  return (value: AnyObject, context: TestContext) => {
    const { path, createError } = context;

    if (doesObjectContainNonEmptyValues(value)) {
      const fields = Object.keys(value);
      // eslint-disable-next-line no-restricted-syntax
      for (const field of fields) {
        if (requiredFields.includes(field) && isEmpty(value[field])) {
          return createError({
            path: `${path}.${field}`, // Specify the path for each specific field
            message: 'Required',
          });
        }
      }
    }

    return true;
  };
}

// ['risk_values',index] or ['coverage_values',index]
export function parseUpdateErrorInvalidFormatItem(str: string) {
  // Regular expression pattern to match "propertyName[index]"
  const regex = /(\w+)\[(\d+)\]/;

  // Match the string against the regular expression
  const match = str.match(regex);

  // If there's a match, return an array with property name and index
  if (match) {
    const [, propertyName, indexStr] = match;
    const index = parseInt(indexStr, 10);
    return [propertyName, index];
  }

  // If no match, return null or handle as needed
  return null;
}

export const convertExactDigitsToReadableError = (pattern: string) => {
  const match = pattern.match(/^\^\\d\{(\d+)\}\$$/);
  if (match) {
    return messages.errors.patternTestExactDigits(match[1]);
  }
  return '';
};
