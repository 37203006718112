import { buildDynamicFormAddressValue, convertIndustryObjectToOption, getIsHeraldSubmission } from '../../../utils';
import { CoverageLine } from '../../../enums';
import {
    Address,
    FlowQuestionId,
    heraldEffectiveDateQuestionIds,
    heraldMailingAddressQuestionIds,
    heraldPrimaryAddressQuestionIds,
    heraldQuestionId,
    Submission,
} from '../../../types';
import { DynamicFormQuestionProvider, DynamicQuestion } from '../../types';
import { DynamicInputType } from '../../enums';
import { DynamicFormCategoryType } from '../enums';
import { HeraldSchemaFormat } from '../../../types/herald/herald-types';
import { messages } from '../../../messages';
import { DynamicFormAddressSchema } from './schema-utils';

function isLPLSubmission(submission: Submission): boolean {
    return !!submission.coverageLines && submission.coverageLines.includes(CoverageLine.LawyersProfessionalLiability);
}

function hideIfNotLPL(_heraldNormalizedParameters: DynamicQuestion[], submission: Submission): boolean {
    return !isLPLSubmission(submission);
}

interface FlowQuestionConfigProps extends Omit<DynamicQuestion, 'value'> {
    // value should be according to the schema, value can be an object which mean the schema is an object with properties (see address)
    getValue: (submission: Submission) => any;
    isHidden?: (heraldDynamicFormQuestions: DynamicQuestion[], submission: Submission) => boolean;
}

const submissionFormMessages = messages.submissionForm;

export const flowQuestionsConfig: FlowQuestionConfigProps[] = [
    {
        getValue: (submission: Submission) => submission?.insuredName,
        id: FlowQuestionId.InsuredName,
        fieldLabel: submissionFormMessages.insuredName,
        section: submissionFormMessages.sections.basicInformation,
        isFormArrayField: false,
        inputType: DynamicInputType.ShortText,
        category: DynamicFormCategoryType.RiskValues,
        requiredForCompletion: true,
        isHidden: (_heraldNormalizedParameters: DynamicQuestion[], submission) => getIsHeraldSubmission(submission),
        provider: DynamicFormQuestionProvider.Flow,
        schema: null,
    },
    {
        getValue: (submission: Submission) =>
            submission.industry ? convertIndustryObjectToOption(submission.industry) : undefined,
        id: FlowQuestionId.Industry,
        fieldLabel: submissionFormMessages.industry,
        section: submissionFormMessages.sections.basicInformation,
        isFormArrayField: false,
        inputType: DynamicInputType.Industry,
        category: DynamicFormCategoryType.RiskValues,
        requiredForCompletion: true,
        isHidden: (_heraldNormalizedParameters: DynamicQuestion[], submission) => getIsHeraldSubmission(submission),
        provider: DynamicFormQuestionProvider.Flow,
        schema: null,
    },
    {
        getValue: (submission: Submission) => submission?.insuredCompanyType,
        id: FlowQuestionId.InsuredCompanyType,
        fieldLabel: submissionFormMessages.insuredCompanyType,
        section: submissionFormMessages.sections.basicInformation,
        isFormArrayField: false,
        inputType: DynamicInputType.SelectOne,
        category: DynamicFormCategoryType.RiskValues,
        requiredForCompletion: true,
        isHidden: (_heraldNormalizedParameters: DynamicQuestion[], submission) => getIsHeraldSubmission(submission),
        provider: DynamicFormQuestionProvider.Flow,
        schema: {
            type: 'string',
            enum: ['Public', 'Private'],
        },
    },
    {
        getValue: (submission: Submission) => submission?.insuredEmployeeCount,
        id: FlowQuestionId.InsuredEmployeeCount,
        fieldLabel: submissionFormMessages.insuredEmployeeCount,
        section: submissionFormMessages.sections.basicInformation,
        isFormArrayField: false,
        inputType: DynamicInputType.Integer,
        category: DynamicFormCategoryType.RiskValues,
        requiredForCompletion: true,
        isHidden: (_heraldNormalizedParameters: DynamicQuestion[], submission) => {
            const isHeraldSubmission = getIsHeraldSubmission(submission);
            const isMonoLineLPL = submission.coverageLines?.length === 1 && isLPLSubmission(submission);
            return isHeraldSubmission || isMonoLineLPL;
        },
        provider: DynamicFormQuestionProvider.Flow,
        schema: {
            type: 'integer',
            minimum: 0,
        },
    },
    {
        getValue: (submission: Submission) => submission?.insuredAnnualRevenue,
        id: FlowQuestionId.InsuredAnnualRevenue,
        fieldLabel: submissionFormMessages.insuredAnnualRevenue,
        section: submissionFormMessages.sections.basicInformation,
        isFormArrayField: false,
        inputType: DynamicInputType.Currency,
        category: DynamicFormCategoryType.RiskValues,
        requiredForCompletion: true,
        isHidden: (_heraldNormalizedParameters: DynamicQuestion[], submission) => getIsHeraldSubmission(submission),
        provider: DynamicFormQuestionProvider.Flow,
        schema: {
            type: 'integer',
            minimum: 0,
        },
    },
    {
        getValue: (submission: Submission): Address | undefined =>
            submission?.insuredPrimaryAddress?.line1
                ? buildDynamicFormAddressValue(submission.insuredPrimaryAddress)
                : undefined,
        id: FlowQuestionId.PrimaryAddress,
        fieldLabel: submissionFormMessages.insuredPrimaryAddress,
        section: submissionFormMessages.sections.basicInformation,
        isFormArrayField: false,
        affectsOtherQuestions: false,
        inputType: DynamicInputType.Address,
        category: DynamicFormCategoryType.RiskValues,
        requiredForCompletion: true,
        isHidden: (heraldNormalizedParameters: DynamicQuestion[], submission) =>
            getIsHeraldSubmission(submission) &&
            heraldNormalizedParameters.some((heraldNormalizedParameter) =>
                heraldPrimaryAddressQuestionIds.includes(heraldNormalizedParameter.id),
            ),
        provider: DynamicFormQuestionProvider.Flow,
        schema: DynamicFormAddressSchema,
        showAfterOneOfTheseQuestions: [heraldQuestionId.rsk_m4p9_insured_name],
    },
    {
        getValue: (submission: Submission): Address | undefined =>
            submission?.insuredMailingAddress?.line1
                ? buildDynamicFormAddressValue(submission.insuredMailingAddress)
                : undefined,
        id: FlowQuestionId.MailingAddress,
        fieldLabel: submissionFormMessages.insuredMailingAddress,
        section: submissionFormMessages.sections.basicInformation,
        isFormArrayField: false,
        affectsOtherQuestions: false,
        inputType: DynamicInputType.Address,
        category: DynamicFormCategoryType.RiskValues,
        requiredForCompletion: false,
        isHidden: (heraldNormalizedParameters: DynamicQuestion[], submission) =>
            getIsHeraldSubmission(submission) &&
            heraldNormalizedParameters.some((heraldNormalizedParameter) =>
                heraldMailingAddressQuestionIds.includes(heraldNormalizedParameter.id),
            ),
        provider: DynamicFormQuestionProvider.Flow,
        schema: DynamicFormAddressSchema,
        showAfterOneOfTheseQuestions: [heraldQuestionId.rsk_m4p9_insured_name],
    },
    {
        getValue: (submission: Submission) => submission?.insuredWebsite,
        id: FlowQuestionId.InsuredWebsite,
        fieldLabel: submissionFormMessages.insuredWebsite,
        section: submissionFormMessages.sections.basicInformation,
        isFormArrayField: false,
        inputType: DynamicInputType.Domain,
        category: DynamicFormCategoryType.RiskValues,
        requiredForCompletion: true,
        isHidden: (_heraldNormalizedParameters: DynamicQuestion[], submission) => getIsHeraldSubmission(submission),
        provider: DynamicFormQuestionProvider.Flow,
        schema: {
            type: 'string',
            format: HeraldSchemaFormat.Hostname,
        },
    },
    {
        getValue: (submission: Submission) => submission?.additionalData?.attorneyNumber,
        id: FlowQuestionId.AttorneyNumber,
        fieldLabel: submissionFormMessages.numberOfAttorneys,
        section: submissionFormMessages.sections.basicInformation,
        isFormArrayField: false,
        affectsOtherQuestions: false,
        inputType: DynamicInputType.Integer,
        category: DynamicFormCategoryType.RiskValues,
        requiredForCompletion: true,
        isHidden: hideIfNotLPL,
        provider: DynamicFormQuestionProvider.Flow,
        schema: {
            type: 'integer',
            minimum: 0,
        },
        showAfterOneOfTheseQuestions: [heraldQuestionId.rsk_b3jm_2017_naics_index],
    },
    {
        getValue: (submission) => submission?.additionalData?.attorneyHireYears?.map((date) => date.toString()),
        id: FlowQuestionId.AttorneyHireYears,
        fieldLabel: submissionFormMessages.attorneyHireYears,
        section: submissionFormMessages.sections.basicInformation,
        isFormArrayField: true,
        affectsOtherQuestions: false,
        inputType: DynamicInputType.Year,
        category: DynamicFormCategoryType.RiskValues,
        requiredForCompletion: true,
        isHidden: hideIfNotLPL,
        provider: DynamicFormQuestionProvider.Flow,
        schema: {
            title: '',
            type: 'string',
        },
        showAfterOneOfTheseQuestions: [heraldQuestionId.rsk_b3jm_2017_naics_index],
    },
    {
        getValue: (submission) =>
            submission?.additionalData?.areasOfPractice?.map((areaOfPractice) => ({
                ...areaOfPractice,
                percentage: areaOfPractice.percentage.toString(),
            })),
        id: FlowQuestionId.AreasOfPractice,
        fieldLabel: submissionFormMessages.areasOfPractice,
        section: submissionFormMessages.sections.basicInformation,
        isFormArrayField: true,
        affectsOtherQuestions: false,
        inputType: DynamicInputType.AreaOfPractice,
        category: DynamicFormCategoryType.RiskValues,
        requiredForCompletion: true,
        isHidden: hideIfNotLPL,
        provider: DynamicFormQuestionProvider.Flow,
        schema: {
            title: '',
            type: 'object',
            properties: {
                area: {
                    type: 'string',
                    title: 'Area',
                },
                percentage: {
                    type: 'number',
                    title: 'Percentage',
                    minimum: 1,
                    maximum: 100,
                },
            },
            required: ['area', 'percentage'],
        },
        showAfterOneOfTheseQuestions: [heraldQuestionId.rsk_b3jm_2017_naics_index],
    },
    {
        getValue: (submission: Submission) => submission?.dueDate,
        id: FlowQuestionId.EffectiveDate,
        fieldLabel: submissionFormMessages.dueDate,
        section: submissionFormMessages.sections.coverages,
        isFormArrayField: false,
        inputType: DynamicInputType.Date,
        category: DynamicFormCategoryType.CoverageValues,
        requiredForCompletion: true,
        provider: DynamicFormQuestionProvider.Flow,
        isHidden: (dynamicQuestion: DynamicQuestion[], submission) =>
            // hide if herald submission
            getIsHeraldSubmission(submission) &&
            // and if there is an effective date question by herald
            dynamicQuestion.some((question) => heraldEffectiveDateQuestionIds.includes(question.id)),
        schema: {
            type: 'string',
            format: HeraldSchemaFormat.Date,
        },
    },
    {
        getValue: (submission: Submission) => submission?.needByDate,
        id: FlowQuestionId.NeedByDate,
        fieldLabel: submissionFormMessages.needByDate,
        section: submissionFormMessages.sections.coverages,
        isFormArrayField: false,
        affectsOtherQuestions: false,
        inputType: DynamicInputType.Date,
        category: DynamicFormCategoryType.CoverageValues,
        requiredForCompletion: true,
        provider: DynamicFormQuestionProvider.Flow,
        schema: {
            type: 'string',
            format: HeraldSchemaFormat.Date,
        },
        showAfterOneOfTheseQuestions: heraldEffectiveDateQuestionIds,
    },
    {
        getValue: (submission: Submission) => submission?.limit?.toString(),
        id: FlowQuestionId.Limit,
        fieldLabel: submissionFormMessages.limit,
        section: submissionFormMessages.sections.coverages,
        isFormArrayField: false,
        inputType: DynamicInputType.Currency,
        category: DynamicFormCategoryType.CoverageValues,
        requiredForCompletion: false,
        isHidden: (_heraldNormalizedParameters: DynamicQuestion[], submission) => getIsHeraldSubmission(submission),
        provider: DynamicFormQuestionProvider.Flow,
        schema: {
            type: 'integer',
            minimum: 0,
        },
    },
];
