import { DynamicInputType } from '@common/dynamic-form';

interface DynamicInputTypeConfigProp {
  placeholder?: string;
  fieldType:
    | 'TextField'
    | 'Checkbox'
    | 'AutoComplete'
    | 'MultiSelectAutocomplete'
    | 'DatePicker'
    | 'Address'
    | 'ClaimEvent'
    | 'AreaOfPractice'
    | 'IndustryAutoComplete'
    | 'TBD';
  isObjectValue?: boolean;

  // for TextField
  numberFormat?: boolean;
  isInteger?: boolean;
  isDecimal?: boolean;
  isMoney?: boolean;

  allowLeadingZeros?: boolean;
  thousandSeparator?: boolean;

  // for DatePicker
  yearsOnly?: boolean;
}

export const dynamicFormInputTypesConfig: Record<DynamicInputType, DynamicInputTypeConfigProp> = {
  [DynamicInputType.SelectOne]: { fieldType: 'AutoComplete', placeholder: 'Select' },
  [DynamicInputType.SelectMany]: { fieldType: 'MultiSelectAutocomplete', placeholder: 'Select all that apply' },
  [DynamicInputType.AgreeTo]: { fieldType: 'Checkbox' },
  [DynamicInputType.ShortText]: { fieldType: 'TextField', placeholder: 'Short Text' },
  [DynamicInputType.LongText]: { fieldType: 'TextField', placeholder: 'Long Text' },
  // todo: herald check live
  [DynamicInputType.Number]: { fieldType: 'TextField', numberFormat: true, isDecimal: true, placeholder: '1000.0' },
  [DynamicInputType.Integer]: { fieldType: 'TextField', numberFormat: true, isInteger: true, placeholder: '1,000' },
  [DynamicInputType.Currency]: {
    fieldType: 'TextField',
    numberFormat: true,
    isInteger: true,
    isMoney: true,
    placeholder: '$1,000',
  },
  [DynamicInputType.Date]: { fieldType: 'DatePicker' },
  [DynamicInputType.Industry]: { fieldType: 'IndustryAutoComplete' },
  [DynamicInputType.Address]: { fieldType: 'Address' },
  [DynamicInputType.ClaimEvent]: { fieldType: 'ClaimEvent' },
  [DynamicInputType.AreaOfPractice]: { fieldType: 'AreaOfPractice' },
  [DynamicInputType.Phone]: {
    fieldType: 'TextField',
    numberFormat: true,
    placeholder: '5557654321',
    allowLeadingZeros: true,
    thousandSeparator: false,
  },
  [DynamicInputType.Email]: { fieldType: 'TextField', placeholder: 'name@domain.com' },
  [DynamicInputType.Domain]: { fieldType: 'TextField', placeholder: 'domain.com' },
  [DynamicInputType.File]: { fieldType: 'TBD' },
  [DynamicInputType.Year]: { fieldType: 'DatePicker', placeholder: 'YYYY', yearsOnly: true },
};
