import { sortBy } from 'lodash';
import { MouseEvent, ReactNode, useEffect, useState } from 'react';
import { Box, NewMenu } from '@common-components';
import { isMarketOrganization } from 'enums';
import { useOptionsMenu } from 'hooks';
import { messages } from 'i18n';
import { MenuItemProps } from 'components/menu';
import { Recipient, RecipientGroup } from 'broker/components/Emails/recipient-utils';
import RecipientGroupContacts from 'broker/components/RecipientGroupContacts';
import { recipientFullName } from './utils';

interface MarketRecipientTagProps {
  recipientGroup: RecipientGroup;
  availableRecipients: Recipient[];
  selected?: boolean;
  onToggleRecipient: (recipient: Recipient, removeIfLast?: boolean) => void;
  newRecipients: Recipient[];
  onAddRecipient: (userMarketId: string) => void;
  onClearNewRecipients: () => void;
  onRemoveGroup?: (groupId: string) => void;
  bottomSpacing?: number;
  icon?: ReactNode;
  onClick?: (recipientGroupId: string) => void;
  isNewEmailEditor?: boolean;
  shouldBlockRemove?: boolean;
}

export default function RecipientGroupTag({
  recipientGroup,
  availableRecipients,
  selected,
  newRecipients,
  onClearNewRecipients,
  onRemoveGroup,
  onToggleRecipient,
  onAddRecipient,
  bottomSpacing = 1,
  icon,
  onClick,
  isNewEmailEditor = false,
  shouldBlockRemove = false,
}: MarketRecipientTagProps) {
  const optionsMenu = useOptionsMenu();

  const [search, setSearch] = useState('');

  const isMarket = isMarketOrganization(recipientGroup.type);

  const getSortedAvailableContacts = () => {
    const selectedRecipientIds = new Set(recipientGroup.recipients.map((contact) => contact.id));

    return sortBy(
      availableRecipients.filter((recipient) =>
        !search ? true : recipientFullName(recipient).toLowerCase().startsWith(search.toLowerCase()),
      ),
      (recipient) => !selectedRecipientIds.has(recipient.id),
      (recipient) => recipientFullName(recipient),
    );
  };

  const [sortedAvailableContacts, setSortedAvailableContacts] = useState<Recipient[]>([]);

  useEffect(() => {
    setSortedAvailableContacts(getSortedAvailableContacts());
    // only when available or new contacts list are updated do we want to sort and reorder the contact list in addition to the sorting then the recipient group box is opened
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newRecipients.length, availableRecipients.length, search]);

  const onCloseMenu = () => {
    optionsMenu.closeMenu();
    onClearNewRecipients?.();
  };

  const onOpenMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setSortedAvailableContacts(getSortedAvailableContacts());
    optionsMenu.openMenu(event);
  };

  const onButtonClick = () => {
    onClick?.(recipientGroup.id);
  };

  const tagColor = isNewEmailEditor ? 'common.white' : 'blue.100';

  const getMenuItems = (
    menuAvailableContacts: Recipient[],
    selectedContacts: Recipient[],
    onItemClick: (recipient: Recipient) => void,
  ): MenuItemProps[] =>
    menuAvailableContacts.map((recipient) => ({
      label: recipient.disabled
        ? `${recipientFullName(recipient)} (${messages.general.removed})`
        : recipientFullName(recipient),
      textColor: recipient.disabled ? 'error.main' : undefined,
      onClick: () => onItemClick(recipient),
      isSelected: selectedContacts.some((selectedContact) => selectedContact.id === recipient.id),
      tooltipContent: recipient.email,
      tooltipPlacement: 'right',
      subLabel: newRecipients.some((newRecipient) => newRecipient.id === recipient.id)
        ? messages.general.newLabel
        : undefined,
    }));

  const getMoreActionsForMarket = () => {
    const actions = [{ text: messages.emailEditor.addContact, onClick: () => onAddRecipient?.(recipientGroup.id) }];
    if (isMarket && !shouldBlockRemove) {
      actions.push({
        text: messages.emailEditor.removeMarket(recipientGroup.name),
        onClick: () => onRemoveGroup?.(recipientGroup.id),
      });
    }
    return actions;
  };

  return (
    <Box className="cap-recipient-group-tag">
      <Box
        aria-label="market menu"
        onClick={onButtonClick}
        color="inherit"
        sx={{ p: 0, mr: 1, mb: bottomSpacing, backgroundColor: tagColor, borderRadius: 4, cursor: 'pointer' }}
      >
        <RecipientGroupContacts
          onMenuTriggerClick={onOpenMenu}
          recipientGroup={recipientGroup}
          isMenu
          selected={selected}
          icon={icon}
          isNewEmailEditor={isNewEmailEditor}
        />
      </Box>
      <NewMenu
        optionsMenuState={{ ...optionsMenu, closeMenu: onCloseMenu }}
        searchProps={
          availableRecipients.length > 10
            ? {
                onChange: (searchTerm) => {
                  setSearch(searchTerm);
                },
              }
            : undefined
        }
        menuItems={[
          {
            key: 'market-menu-popover',
            selectionType: 'checkbox',
            items: getMenuItems(sortedAvailableContacts, recipientGroup.recipients, (recipient: Recipient) =>
              onToggleRecipient(recipient, isMarket),
            ),
            ...(isMarket && {
              moreActions: {
                items: getMoreActionsForMarket(),
              },
            }),
          },
        ]}
      />
    </Box>
  );
}
