import { ChangeEvent, Dispatch, SetStateAction, useCallback, useState } from 'react';
import { useBoolean } from 'hooks';
import { FilterElementProps } from 'utils';

export interface ProductFilterAttributes {
  isAllSelected: boolean;
  onFilterCheckboxChanged: (event: ChangeEvent<HTMLInputElement>, key: string) => void;
  onFilterToggle: (filterToUpdate: string) => void;
  handleSelectAll: () => void;
  filters: FilterElementProps[];
  setFilters: Dispatch<SetStateAction<FilterElementProps[]>>;
}

interface useProductFilterAttributes {
  baseFiltersList: FilterElementProps[];
  defaultSelectAll?: boolean;
  paginationReset: () => void;
}
export default function useProductFilter({
  baseFiltersList,
  paginationReset,
  defaultSelectAll = true,
}: useProductFilterAttributes): ProductFilterAttributes {
  const [filters, setFilters] = useState(baseFiltersList);
  const [isAllSelected, { set: setAllSelected }] = useBoolean(defaultSelectAll);

  function updateCheckedFilter(existingFilters: FilterElementProps[], key: string, isChecked: boolean) {
    const changedFilter = existingFilters.find((filter) => filter.key === key);
    if (changedFilter) {
      changedFilter.checked = isChecked;
    }
    return existingFilters;
  }

  const handleSelectAll = useCallback(() => {
    setFilters((prevFilters) => prevFilters.map((filter) => ({ ...filter, checked: false })));
    setAllSelected(true);
  }, [setAllSelected, setFilters]);

  const afterFilterStateChanged = useCallback(() => {
    paginationReset();
    setAllSelected(filters.every((filter) => !filter.checked));
  }, [filters, paginationReset, setAllSelected]);

  const onFilterCheckboxChanged = useCallback(
    (event: ChangeEvent<HTMLInputElement>, key: string) => {
      setFilters((prevFilters) => updateCheckedFilter([...prevFilters], key, event.target.checked));
      afterFilterStateChanged();
    },
    [afterFilterStateChanged],
  );

  const onFilterToggle = useCallback(
    (filterToUpdate: string) => {
      // toggle from previous state
      setFilters((prevFilters) =>
        prevFilters.map((filter) => (filter.key === filterToUpdate ? { ...filter, checked: !filter.checked } : filter)),
      );
      afterFilterStateChanged();
    },
    [afterFilterStateChanged],
  );

  return {
    filters,
    setFilters,
    isAllSelected,
    handleSelectAll,
    onFilterCheckboxChanged,
    onFilterToggle,
  };
}
