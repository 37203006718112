export enum SubmissionStep {
  ReviewAgent = 'ReviewAgent',
  PreForm = 'PreForm',
  SubmissionForm = 'SubmissionForm',
  RiskAssessment = 'RiskAssessment',
}

export interface NewSubmissionEditLocationState {
  preForm: boolean;
}
