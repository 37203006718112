import { SubmissionType } from '@common/enums/SubmissionType';
import { messages } from 'i18n';

export interface SubmissionTypeConfigProps {
  text: string;
  sortPriority: number;
}

export const submissionTypeConfig: Record<SubmissionType, SubmissionTypeConfigProps> = {
  [SubmissionType.NewBusiness]: {
    text: messages.createSubmissionModal.submissionType.newBusiness,
    sortPriority: 1,
  },
  [SubmissionType.Renewal]: {
    text: messages.createSubmissionModal.submissionType.renewal,
    sortPriority: 2,
  },
  [SubmissionType.BOR]: {
    text: messages.createSubmissionModal.submissionType.bor,
    sortPriority: 3,
  },
};

export const submissionTypes = Object.keys(SubmissionType)
  .map((typeName) => typeName as SubmissionType)
  .sort((a, b) => submissionTypeConfig[a].sortPriority - submissionTypeConfig[b].sortPriority);
