import { FileClassificationType } from '../enums';
import { Citation } from './citation';

export type ExtractedDataAttributesKeys = keyof Omit<ExtractedData, 'fileClassification'>;

export interface ToolExtractedAttribute<T> extends ExtractedAttribute<T> {
    source: string;
}

export interface ExtractedAttribute<T> {
    value: T | undefined;
    reason?: string;
    citations?: Citation[];
    // version of prompt infrastructure used to extract the attribute
    version?: string;
    additionalResults?: ToolExtractedAttribute<T>[];
}

export interface Address {
    line1?: string;
    line2?: string;
    line3?: string;
    organization?: string;
    city?: string;
    state?: string;
    postal_code?: string;
    country_code?: string;
}

export interface ExtractedAddressValue {
    primary?: Address;
    mailing?: Address;
}

export interface ExtractedAreaOfPractice {
    area?: string;
    percentage?: string;
}

export enum NaicsVersion {
    Naics2017 = 'Naics2017',
    Unknown = 'Unknown',
}

export interface MappedIndexEntry {
    heraldId: string;
    indexEntryDescription: string;
    naicsCode: string;
    naicsDescription: string;
    naicsVersion: NaicsVersion;
}

export interface FileClassificationScore {
    classification: FileClassificationType;
    score: number;
}

export interface FileClassifierMetadata {
    classifier: string;
    scores?: FileClassificationScore[];
}

export interface ExtractedFileClassificationAttribute {
    boxFileId: string;
    fileType: FileClassificationType;
    metadata: FileClassifierMetadata;
}

export interface ExtractedIndustryValue {
    rawValue: string; // this is the raw value extracted from the Application file (e.g. "123456 - Software Development")
    mappedIndexEntry?: MappedIndexEntry[];
}

export interface ExtractedData {
    fileClassification?: ExtractedFileClassificationAttribute[];
    insuredName?: ExtractedAttribute<string>;
    // suppose to contain array of CoverageLine enum in the value of the extracted attribute, but since we want to keep the data as it was extracted for reference the consumer of the extracted data should validate the value
    coverageLines?: ExtractedAttribute<string[]>;
    partTimeEmployees?: ExtractedAttribute<string>;
    fullTimeEmployees?: ExtractedAttribute<string>;
    employeesNumber?: ExtractedAttribute<string>;
    totalPayroll?: ExtractedAttribute<string>;
    totalAnnualRevenue?: ExtractedAttribute<string>;
    totalAssets?: ExtractedAttribute<string>;
    yearsOfOperation?: ExtractedAttribute<string>;
    applicationDomainName?: ExtractedAttribute<string>;
    industry?: ExtractedAttribute<ExtractedIndustryValue>;
    address?: ExtractedAttribute<ExtractedAddressValue>;
    // suppose to contain LegalEntity enum in the value of the extracted attribute, but since we want to keep the data as it was extracted for reference the consumer of the extracted data should validate the value
    legalEntity?: ExtractedAttribute<string>;
    // suppose to contain OrganizationsCorporateStructure enum in the value of the extracted attribute, but since we want to keep the data as it was extracted for reference the consumer of the extracted data should validate the value
    organizationsCorporateStructure?: ExtractedAttribute<string>;
    attorneyNumber?: ExtractedAttribute<string>;
    phoneNumber?: ExtractedAttribute<string>;
    lossRunIndicatorCyber?: ExtractedAttribute<string>;
    lossRunIndicatorPL?: ExtractedAttribute<string>;
    effectiveDatePL?: ExtractedAttribute<string>;
    effectiveDateGL?: ExtractedAttribute<string>;
    effectiveDateCyber?: ExtractedAttribute<string>;
    effectiveDateML?: ExtractedAttribute<string>;
    needByDate?: ExtractedAttribute<string>;
    applicantContactName?: ExtractedAttribute<string>;
    applicantEmailAddress?: ExtractedAttribute<string>;
    contactAgreedToInformationSecurity?: ExtractedAttribute<string>;
    publiclyTradedSecurities?: ExtractedAttribute<string[]>;
    privatePlacementInvestment?: ExtractedAttribute<string[]>;
    shareholderBoardRepresentation?: ExtractedAttribute<string>;
    debtCovenantBreach?: ExtractedAttribute<string>;
    securityBreachNotification?: ExtractedAttribute<string>;
    pendingLitigation?: ExtractedAttribute<string>;
    unscheduledDowntimeLoss?: ExtractedAttribute<string>;
    ownershipType?: ExtractedAttribute<string>;
    sensitiveCloudStorage?: ExtractedAttribute<string>;
    infoSecTraining?: ExtractedAttribute<string>;
    franchiseStatus?: ExtractedAttribute<string>;
    businessBrokerServices?: ExtractedAttribute<string>;
    capitalRaisingServices?: ExtractedAttribute<string>;
    mergerAcquisitionServices?: ExtractedAttribute<string>;
    backupSecurityMeasures?: ExtractedAttribute<string[]>;
    thirdPartySecurityAgreements?: ExtractedAttribute<string>;
    failoverTesting?: ExtractedAttribute<string>;
    systemSegregation?: ExtractedAttribute<string>;
    dataEncryption?: ExtractedAttribute<string>;
    updateFrequency?: ExtractedAttribute<string>;
    incidentResponsePlan?: ExtractedAttribute<string>;
    securityTrainingImplementation?: ExtractedAttribute<string>;
    newHireBackgroundChecks?: ExtractedAttribute<string>;
    bankAccountReconciliation?: ExtractedAttribute<string>;
    financialControlSegregation?: ExtractedAttribute<string>;
    paymentInstructionVerificationPolicy?: ExtractedAttribute<string>;
    inventoryPhysicalCount?: ExtractedAttribute<string>;
    highValueInventoryExposure?: ExtractedAttribute<string>;
    checkCountersignatureRequirement?: ExtractedAttribute<string>;
    domesticLocations?: ExtractedAttribute<string>;
    bankingTransactionSeparation?: ExtractedAttribute<string>;
    expectsDebtSoon?: ExtractedAttribute<string>;
    netIncome?: ExtractedAttribute<string>;
    currentAssets?: ExtractedAttribute<string>;
    earningsBeforeInterestTaxes?: ExtractedAttribute<string>;
    cashOrCashEquivalentAssets?: ExtractedAttribute<string>;
    cashFlowFromOperations?: ExtractedAttribute<string>;
    currentLiabilities?: ExtractedAttribute<string>;
    longTermDebt?: ExtractedAttribute<string>;
    amountOfExpectedDebt?: ExtractedAttribute<string>;
    percentageSharesHeldByBoard?: ExtractedAttribute<string>;
    halfLongTermDebtMatures?: ExtractedAttribute<string>;
    totalLiabilities?: ExtractedAttribute<string>;
    totalEntitiesOwnShares?: ExtractedAttribute<string>;
    percentageHeldByTrusts?: ExtractedAttribute<string>;
    entityType?: ExtractedAttribute<string>;
    yearsIndustryManagement?: ExtractedAttribute<string>;
    totalCashExposureOutside?: ExtractedAttribute<string>;
    totalCashExposureInside?: ExtractedAttribute<string>;
    limitPL?: ExtractedAttribute<string>;
    limitGL?: ExtractedAttribute<string>;
    limitDO?: ExtractedAttribute<string>;
    limitCyber?: ExtractedAttribute<string>;
    limitML?: ExtractedAttribute<string>;
    attorneyHireYears?: ExtractedAttribute<string[]>;
    areaOfPractice?: ExtractedAttribute<ExtractedAreaOfPractice[]>;
}

export interface SubmissionExtractedData {
    id: string;
    submissionId: string;
    extractedData?: ExtractedData;
}
