import { useCallback, useState } from 'react';
import { useServerPushNotifications } from 'utils';

/**
 * Hook to track file extractions and notify when they are ready
 * @param onExtractionReady callback to call when extraction is ready for a file
 */
export function useTrackFileExtractions(onExtractionReady: (fileId: string) => Promise<void>) {
  const [extractingFileIds, setExtractingFileIds] = useState<Set<string>>(new Set());
  const hasPendingExtractions = extractingFileIds.size > 0;

  const startTrackingFileExtraction = useCallback((fileId: string) => {
    setExtractingFileIds((prev) => new Set(prev).add(fileId));
  }, []);

  const stopTrackingFileExtraction = useCallback((fileId: string) => {
    setExtractingFileIds((prev) => {
      const newSet = new Set(prev);
      newSet.delete(fileId);
      return newSet;
    });
  }, []);

  const clearAllTrackedExtractions = useCallback(() => {
    setExtractingFileIds(new Set());
  }, []);

  useServerPushNotifications(async ({ event, data }) => {
    if (event !== 'quote-extraction-complete') {
      return;
    }

    const parsedMessage = JSON.parse(data.message);
    const { fileId } = parsedMessage;

    if (fileId && extractingFileIds.has(fileId)) {
      try {
        await onExtractionReady(fileId);
      } finally {
        stopTrackingFileExtraction(fileId);
      }
    }
  }, 'quote-extraction');

  return {
    hasPendingExtractions,
    startTrackingFileExtraction,
    stopTrackingFileExtraction,
    clearAllTrackedExtractions,
  };
}
