import * as yup from 'yup';
import { messages } from 'i18n';
import { customYupValidateDomain } from 'utils';
import { AgencyFormFieldsNames, FormFieldsConfig } from './types';

const { requiredField } = messages.formMessages;

export enum UrlParam {
  Id = 'id',
}

export enum FormMode {
  Create = 'create',
  Update = 'update',
}

export const formFieldsConfig: FormFieldsConfig = {
  [AgencyFormFieldsNames.Name]: {
    label: messages.agenciesPage.agencyForm.labels.name,
    placeholder: messages.agenciesPage.agencyForm.placeholders.name,
    name: AgencyFormFieldsNames.Name,
    id: AgencyFormFieldsNames.Name,
    optional: false,
  },
  [AgencyFormFieldsNames.Domain]: {
    label: messages.agenciesPage.agencyForm.labels.domain,
    placeholder: messages.agenciesPage.agencyForm.placeholders.domain,
    name: AgencyFormFieldsNames.Domain,
    id: AgencyFormFieldsNames.Domain,
    optional: false,
  },
  [AgencyFormFieldsNames.AssigneeId]: {
    label: messages.agenciesPage.agencyForm.labels.assignee,
    placeholder: messages.agenciesPage.agencyForm.placeholders.assignee,
    name: AgencyFormFieldsNames.AssigneeId,
    id: AgencyFormFieldsNames.AssigneeId,
    optional: true,
  },
  [AgencyFormFieldsNames.LeadAssigneeId]: {
    label: messages.agenciesPage.agencyForm.labels.leadAssignee,
    placeholder: messages.agenciesPage.agencyForm.placeholders.leadAssignee,
    name: AgencyFormFieldsNames.LeadAssigneeId,
    id: AgencyFormFieldsNames.LeadAssigneeId,
    optional: false,
  },
};

export const schema = yup
  .object()
  .shape({
    name: yup.string().required(requiredField),
    domain: yup
      .array()
      .of(
        yup
          .string()
          .default('')
          .test('customYupValidateURL', 'customYupValidateURL', customYupValidateDomain(formFieldsConfig.domain.name))
          .required(requiredField),
      ),
    assigneeId: yup.string().default(null).trim().nullable(),
    leadAssigneeId: yup.string().required(requiredField),
  })
  .required();
