export enum FormStateBaseProp {
  Main = 'main',
  Children = 'children',
}

// each value in the form has this base type. and if the question is a repeater (isFormArrayField) then the value in the state will be an array of FormStateBaseProps, as each repeated question can have his own child questions
export interface FormStateBaseProps {
  [FormStateBaseProp.Main]: any;
  [FormStateBaseProp.Children]?: Record<string, FormStateBaseProps | FormStateBaseProps[] | undefined>;
}

export type DynamicFormState = Record<string, FormStateBaseProps | FormStateBaseProps[] | undefined>;

export type AnsweredDynamicQuestionsBySections = { [key in string]: { answered: number; total: number } };

export interface DynamicFormImperativeHandle {
  submitForm: () => Promise<void>;
}
