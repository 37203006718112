import { SubmissionExtractedData } from '@common/types';
import { SubmissionExtractedDataClient } from 'clients';
import { QueryKeys, SubmissionStatus } from 'enums';
// eslint-disable-next-line import/no-internal-modules
import { UseQueryBaseSearchOptions } from './query/types';
import { useQuerySearch } from './query/useQuery';

interface SubmissionExtractedDataQuery {
  includedSubmissionStatuses?: SubmissionStatus[];
}

export function useSearchSubmissionExtractedData(
  props?: UseQueryBaseSearchOptions<SubmissionExtractedData, SubmissionExtractedDataQuery>,
) {
  const { filter, queryOptions, enabled } = props || {};
  return useQuerySearch({
    queryKey: QueryKeys.SubmissionExtractedData,
    clientClass: SubmissionExtractedDataClient,
    filter,
    enabled,
    queryOptions: {
      ...queryOptions,
    },
  });
}
