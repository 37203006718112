import { findLast } from 'lodash';
import { Backdrop, Box, MarkedText, Tooltip, Typography } from '@common-components';
import { SubmissionEmailLabel, SubmissionMarketRequestStatus } from 'enums';
import { useBoolean } from 'hooks';

import { messages } from 'i18n';
import { ExtendedMarketRequest } from 'types';
import { ConditionalWrap } from 'utils';
import { DirtyActionsGuard } from 'broker/components/DirtyContentGuard/DirtyActionsGuard';
import { getDeclinationMetadataByType } from 'broker/configuration-mappers/declination-config';
import useGetSubmissionRelatedEmails from 'broker/pages/SubmissionWorkspacePage/components/Toolbox/components/Email/useGetSubmissionRelatedEmails';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import IndicationForm from './IndicationForm';

interface IndicationProps {
  marketRequest: ExtendedMarketRequest;
}

export default function Indication({ marketRequest }: IndicationProps) {
  const [isOpen, { off: close, on: open }] = useBoolean(false);
  const indicationContent = () => {
    if (marketRequest.status === SubmissionMarketRequestStatus.Declined) {
      const reason = marketRequest.declination?.declinationReasons[0];
      const reasonMetadata = reason ? getDeclinationMetadataByType(reason.type)?.reason : undefined;
      const details = reason?.details;

      return reasonMetadata
        ? `${reasonMetadata}${details ? `: ${details}` : ''}`
        : details || messages.general.noDeclinationReason;
    }
    return marketRequest.indication?.value || messages.general.noIndicationReason;
  };
  const { partialSubmission } = useSubmissionsWorkspace();

  const { submissionEmailsList } = useGetSubmissionRelatedEmails({ submissionId: partialSubmission?.id });
  const emailWithIndication = findLast(
    submissionEmailsList,
    (email) => email.submissionMarketRequestId === marketRequest.id && email.label === SubmissionEmailLabel.Indication,
  );

  const emailWithDeclination = findLast(
    submissionEmailsList,
    (email) => email.submissionMarketRequestId === marketRequest.id && email.label === SubmissionEmailLabel.Declination,
  );

  const emailByStatus = () => {
    if (marketRequest.status === SubmissionMarketRequestStatus.Declined && emailWithDeclination) {
      return emailWithDeclination;
    }
    return emailWithIndication;
  };

  const indicationText = () => {
    if (marketRequest.status === SubmissionMarketRequestStatus.Declined) {
      return marketRequest.declination?.declinationReasons[0]?.details;
    }
    return marketRequest.indication?.value;
  };

  const showWrapper = () => {
    if (marketRequest.status === SubmissionMarketRequestStatus.Declined) {
      return !!marketRequest.declination?.isAIExtracted;
    }
    if (marketRequest.status === SubmissionMarketRequestStatus.PendingCustomer) {
      return false;
    }
    return !!marketRequest.indication?.isAIExtracted;
  };

  return (
    <DirtyActionsGuard>
      {({ closeAttemptWithAction, closeWithoutPromptWithAction, isContentDirty, setIsContentDirty }) => (
        <Tooltip
          open={isOpen}
          tooltipContent={
            <IndicationForm
              status={marketRequest.status}
              indicationEmail={emailByStatus()}
              isContentDirty={isContentDirty}
              setIsContentDirty={setIsContentDirty}
              closeWithoutPrompt={() => closeWithoutPromptWithAction(close)}
              id={marketRequest.id}
              indicationText={indicationText()}
            />
          }
        >
          <Box>
            <Typography
              onClick={open}
              sx={{
                '&: hover': {
                  textDecoration: 'underline',
                },
              }}
              variant="body2"
              color="text.primary"
            >
              <ConditionalWrap
                condition={showWrapper()}
                wrap={(wrappedChildren) => <MarkedText>{wrappedChildren}</MarkedText>}
              >
                {indicationContent()}
              </ConditionalWrap>
            </Typography>
            <Backdrop
              invisible
              open={isOpen}
              sx={{ zIndex: (theme) => theme.zIndex.tooltip - 1 }}
              onClick={() => closeAttemptWithAction(close)}
            />
          </Box>
        </Tooltip>
      )}
    </DirtyActionsGuard>
  );
}
