import { BoxItem } from 'box-ui-elements/es';
import { compact, isString } from 'lodash';
import { PDFDocument } from 'pdf-lib';
import { BoxTemplateLabels } from 'enums';
import { ITEM_NAME_TO_LONG_ERROR, useBoxApi, useMutateBoxItems } from 'hooks/api/box';
import { logger, RequireAtLeastOne } from 'utils';
import { useGetBoxPdfRepresentationAsBlob } from 'utils/box-utils';
import { extractExtensionFromFile } from 'utils/files-utils';
import { PdfContentProps } from './pdf-content-creator';
import { useGeneratePDF } from './pdf-creator-util';

export class EncryptedFileError extends Error {
  constructor(public fileName: string, public fileId: string) {
    super('Pdf-Lib loading encryption error');
  }
}

export function usePDFCreateAndCombine() {
  const { uploadFileWithMetadata, downloadFileAsBlob } = useMutateBoxItems();
  const { getBoxItemById } = useBoxApi();
  const getBoxPdfRepresentationAsBlob = useGetBoxPdfRepresentationAsBlob();
  const generatePDF = useGeneratePDF();

  async function combineFiles(files: { boxItem?: BoxItem; blob: Blob }[]) {
    logger.log('info', { message: 'start combineFiles' });

    // Create a new PDFDocument
    const pdfDoc = await PDFDocument.create();

    // Convert all blobs (first content PDFs, then file PDFs) into PDFDocuments
    const pdfDocuments = await Promise.all(
      files.map(async ({ blob, boxItem }) => {
        const buffer = await blob.arrayBuffer();
        const pdfBytes = new Uint8Array(buffer);
        try {
          return await PDFDocument.load(pdfBytes);
        } catch (e: any) {
          logger.log('info', { message: 'Failed to load PDF document', boxItem, error: e });
          if (
            boxItem &&
            isString(e.message) &&
            e.message.toLowerCase().includes('input document to `pdfdocument.load` is encrypted')
          ) {
            const encryptedFileError = new EncryptedFileError(boxItem.name, boxItem.id);
            logger.log('info', { message: encryptedFileError.message, boxItem, error: e });
            throw encryptedFileError;
          } else {
            throw e;
          }
        }
      }),
    );

    logger.log('info', { message: 'combineFiles loaded blobs to pdfDocuments' });

    // Copy pages from all loaded PDFs into the final PDF document
    for (const doc of pdfDocuments) {
      // eslint-disable-next-line no-await-in-loop
      const pages = await pdfDoc.copyPages(doc, doc.getPageIndices());
      pages.forEach((page) => {
        pdfDoc.addPage(page);
      });
    }

    logger.log('info', { message: 'combineFiles copied all pages' });

    // Serialize the PDFDocument to bytes (a Uint8Array)
    const pdfBytesToSave = await pdfDoc.save();
    const response = new Blob([pdfBytesToSave], { type: 'application/pdf' });

    logger.log('info', { message: 'end combineFiles' });

    return response;
  }

  type FilePdfList = RequireAtLeastOne<{
    pdfContentProps?: PdfContentProps;
    boxFileId?: string;
  }>;

  async function generateAndUploadFlowPDF(
    files: FilePdfList[],
    generatedFileDetails: {
      parentFolderId: string;
      fileName: string;
      // will retry to upload to box with this name if the original name is too long
      alternativeShortFileName?: string;
      metadata: Partial<Record<BoxTemplateLabels, string>>;
    },
  ) {
    // Generate PDFs for all content pages
    logger.log('info', {
      message: 'start generateAndUploadFlowPDF convert quoteFileIds to blobs',
    });

    const pdfBlobs = compact(
      await Promise.all(
        files.map(async (file) => {
          if (file.pdfContentProps) {
            const response = await generatePDF(file.pdfContentProps);
            return {
              blob: response,
            };
          }
          if (file.boxFileId) {
            const boxItem = (await getBoxItemById(file.boxFileId))!;
            if (extractExtensionFromFile(boxItem.name) === 'pdf') {
              return { boxItem, blob: await downloadFileAsBlob(boxItem.id) };
            }
            const pdfRepresentationAsBlob = await getBoxPdfRepresentationAsBlob(boxItem.id);
            if (pdfRepresentationAsBlob) {
              return { boxItem, blob: pdfRepresentationAsBlob };
            }

            throw new Error(
              `Failed to get PDF representation for file: ${boxItem.name} (ID: ${boxItem.id}). Unable to generate PDF.`,
            );
          }

          return null;
        }),
      ),
    );

    logger.log('info', { message: 'end generateAndUploadFlowPDF convert fileIds to blobs' });

    // Combine content PDFs first, then add file PDFs
    const combinedBlob = await combineFiles(pdfBlobs);

    let uploadedFlowQuoteToBoxResponse: BoxItem | null = null;

    try {
      // Upload the final combined PDF
      uploadedFlowQuoteToBoxResponse = await uploadFileWithMetadata({
        parentFolderId: generatedFileDetails.parentFolderId,
        fileName: generatedFileDetails.fileName,
        file: combinedBlob,
        retryWithDate: true,
        metadata: generatedFileDetails.metadata,
      });
    } catch (error) {
      const e = error as Error;
      if (generatedFileDetails.alternativeShortFileName && e.message === ITEM_NAME_TO_LONG_ERROR) {
        uploadedFlowQuoteToBoxResponse = await uploadFileWithMetadata({
          parentFolderId: generatedFileDetails.parentFolderId,
          fileName: generatedFileDetails.alternativeShortFileName,
          file: combinedBlob,
          retryWithDate: true,
          metadata: generatedFileDetails.metadata,
        });
      } else {
        throw error;
      }
    }

    logger.log('log', { message: 'end generateAndUploadFlowPDF', uploadedFlowQuoteToBoxResponse });

    return uploadedFlowQuoteToBoxResponse;
  }

  return generateAndUploadFlowPDF;
}
