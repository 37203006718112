import WithRouteLocationState from 'broker/components/WithRouteLocationState';
import { RouteKey } from 'broker/enums';
import EditSubmissionContent from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/EditSubmissionNew/EditSubmissionContent';

export default function SubmissionEditBOR() {
  return (
    <WithRouteLocationState routeKey={RouteKey.SubmissionEditBOR} isStateRequired={false}>
      {() => <EditSubmissionContent />}
    </WithRouteLocationState>
  );
}
