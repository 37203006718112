export enum DynamicInputType {
    SelectOne = 'SelectOne',
    SelectMany = 'SelectMany',
    AgreeTo = 'AgreeTo',
    ShortText = 'ShortText',
    LongText = 'LongText',
    Number = 'Number',
    Integer = 'Integer',
    Currency = 'Currency',
    Date = 'Date',
    Address = 'Address',
    ClaimEvent = 'ClaimEvent',
    AreaOfPractice = 'AreaOfPractice',
    Phone = 'Phone',
    Email = 'Email',
    Domain = 'Domain',
    File = 'File',
    Year = 'Year',
    Industry = 'Industry',
}
