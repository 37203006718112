import { FormFieldsConfigProps } from 'hooks';

export interface AddAAgencyDialogLocationState {
  scrollTo?: AgencyFormFieldsNames;
}

export interface AgencyFormDataProps {
  name: string;
  domain: string[];
  assigneeId?: string;
  leadAssigneeId: string;
}

export enum AgencyFormFieldsNames {
  Name = 'name',
  Domain = 'domain',
  AssigneeId = 'assigneeId',
  LeadAssigneeId = 'leadAssigneeId',
}

export type FormFieldsConfig = Record<AgencyFormFieldsNames, FormFieldsConfigProps<AgencyFormFieldsNames>>;
