import { AICreateThreadOutput, SubmissionMarketRequest } from 'types';
import { HttpMethods } from 'utils/http';
import { CapitolaClient } from './base';

export interface MarketViaApiBody {
  submissionId: string;
  marketRequestIds: string[];
}

export default class SubmissionMarketRequestClient extends CapitolaClient<SubmissionMarketRequest> {
  public get resourceName(): string {
    return 'submission-market-request';
  }

  public async marketViaApi(data: MarketViaApiBody): Promise<AICreateThreadOutput | null> {
    return this.custom({
      httpMethod: HttpMethods.POST,
      body: { ...data },
      path: `market-via-api`,
      fetchOptions: { throwOnError: true },
    });
  }
}
