//
// IMPORTANT! READ ME!
// ==========
// This enum is used in 2 tables: submission_email and submission_inbound_email
// This is because the test DB is automatically created with a copy of it
// for all usages, so to enforce parity with the testing environment
// we decided to create this enum for each usage as well.
// When making a change to this enum's values, make sure to run a migration
// that changes both database enums:
// 1. enum_submission_email_label
// 2. enum_submission_inbound_email_label
// For an example, see backend/src/migrations/20240721112917-merge-email-labels.js

export enum SubmissionEmailLabel {
    SubmissionMarketing = 'SubmissionMarketing',
    Proposal = 'Proposal',
    InformationRequest = 'InformationRequest',
    Declination = 'Declination',
    TowerUpdate = 'TowerUpdate',
    MarketingUpdate = 'MarketingUpdate',
    BinderRequested = 'BinderRequested',
    PolicyRequested = 'PolicyRequested',
    SubmissionUpdate = 'SubmissionUpdate',
    CannotPursue = 'CannotPursue',
    PositiveExpectation = 'PositiveExpectation',
    Acknowledgment = 'Acknowledgment',
    Quote = 'Quote',
    Indication = 'Indication',
    LossRuns = 'LossRuns',
    SubmissionBox = 'SubmissionBox',
    Correspondence = 'Correspondence',
    SurplusDocuments = 'SurplusDocuments',
    Binder = 'Binder',
    Policy = 'Policy',
    AdditionalInfo = 'AdditionalInfo',
    PaymentRequested = 'PaymentRequested',
    Endorsement = 'Endorsement',
}
