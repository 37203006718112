import { getSubmissionCreateData } from '@common/submission-data';
import { isEmpty, omit } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom-latest';
import { LoaderOverlay } from '@common-components';
import { UserStatus } from 'enums';
import { useMatchNavigation } from 'hooks';
import { PartialSubmission } from 'types';
import { generateSubmissionPath } from 'broker/broker-routes';
import { RouteKey } from 'broker/enums';
import { useNavigate, useNavigateToStep } from 'broker/hooks';
import { useIsBORFlow, useWorkspaceUrls } from 'broker/pages/SubmissionWorkspacePage/hooks';
import { useUpdateHeraldApplicationWithSubmissionCoverageLines } from 'broker/pages/SubmissionWorkspacePage/hooks/useUpdateHeraldApplicationWithSubmissionCoverageLines';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { PostSubmissionCoverageLineUpdateProps } from 'broker/pages/SubmissionWorkspacePage/utils/types';
import PreForm from './Screens/PreForm';
import ReviewAgentForm from './Screens/ReviewAgentForm';
import RiskAssessmentStep from './Screens/RiskAssessmentStep';
import SubmissionFormInnerPageLayout from './Screens/SubmissionDynamicForm';
import { NewSubmissionEditLocationState, SubmissionStep } from './types';
import useSetNextStep, { SetNextStepsProps } from './useSetNextStep';

interface EditSubmissionContentProps {
  locationState?: NewSubmissionEditLocationState;
}

export default function EditSubmissionContent({ locationState }: EditSubmissionContentProps) {
  const step = useMatchNavigation(SubmissionStep);
  const navigateToStep = useNavigateToStep();
  const navigate = useNavigate();
  const [firstVisit, setFirstVisit] = useState<boolean | undefined>(undefined);
  const [didSubmissionChangeFromNonHeraldToHerald, setDidSubmissionChangeFromNonHeraldToHerald] = useState(false);
  const { partialSubmission, markets, updateSubmission } = useSubmissionsWorkspace();
  const { isBOR, isBOROnboarding } = useIsBORFlow();
  const { selectProductsUrl } = useWorkspaceUrls();

  const setStep = useCallback(
    (stepItem: SubmissionStep, replace = false) => {
      navigateToStep(stepItem, { routeKey: RouteKey.SubmissionEdit, replace });
    },
    [navigateToStep],
  );

  const setNextStep = useSetNextStep(partialSubmission, setStep);

  const navigateForwardAfterBorSubmissionForm = useCallback(() => {
    if (isBOROnboarding) {
      navigate(selectProductsUrl, {
        routeKey: RouteKey.SelectProducts,
        state: undefined,
      });
      return;
    }
    navigate(
      {
        pathname: generateSubmissionPath(partialSubmission!.id),
      },
      {
        routeKey: RouteKey.WorkspaceTab,
        state: undefined,
      },
    );
  }, [isBOROnboarding, navigate, partialSubmission, selectProductsUrl]);

  const onNextStep = useCallback(
    (setNextStepsProps?: SetNextStepsProps) => {
      const navigateBack = setNextStepsProps?.navigateBack === true;
      const isNavigateForwardAfterBorSubmissionForm =
        isBOR && step === SubmissionStep.SubmissionForm && !setNextStepsProps?.forceStep && !navigateBack;
      if (isNavigateForwardAfterBorSubmissionForm) {
        navigateForwardAfterBorSubmissionForm();
        return;
      }
      setNextStep(setNextStepsProps);
    },
    [isBOR, navigateForwardAfterBorSubmissionForm, setNextStep, step],
  );

  const updateHeraldApplicationWithSubmissionCoverageLines = useUpdateHeraldApplicationWithSubmissionCoverageLines();

  useEffect(() => {
    // If we got here without a selected step, calculate it based on the data
    if (!step && partialSubmission && markets) {
      if (isBOR) {
        setStep(isBOROnboarding ? SubmissionStep.PreForm : SubmissionStep.SubmissionForm, true);
      } else if (locationState?.preForm) {
        setStep(SubmissionStep.PreForm, true);
      } else if (partialSubmission.user?.status === UserStatus.PendingReview) {
        setStep(SubmissionStep.ReviewAgent, true);
      } else if (partialSubmission.coverageLines?.length) {
        setStep(SubmissionStep.SubmissionForm, true);
      } else {
        setStep(SubmissionStep.PreForm, true);
      }
    }
  }, [isBOR, isBOROnboarding, locationState?.preForm, markets, partialSubmission, setStep, step]);

  useEffect(() => {
    if (!isBOR && firstVisit === undefined && partialSubmission) {
      setFirstVisit(isEmpty(partialSubmission?.coverageLines));
    }
  }, [firstVisit, isBOR, partialSubmission]);

  const onPreFormSent = async (data: PostSubmissionCoverageLineUpdateProps) => {
    const heraldProductsInSubmission = await updateHeraldApplicationWithSubmissionCoverageLines(data);
    if (heraldProductsInSubmission !== undefined) {
      if (!data.wasHeraldSubmission && !isEmpty(heraldProductsInSubmission)) {
        setDidSubmissionChangeFromNonHeraldToHerald(true);
      }

      if (firstVisit) {
        const submissionCreateDataBasedOnExtractionAndDefaults = getSubmissionCreateData(
          partialSubmission?.submissionExtractedData?.extractedData,
          data.updatedCoverageLines,
        ) as Partial<PartialSubmission>;

        await updateSubmission(partialSubmission!.id, {
          // omitting coverageLines as they should not be updated based on the extracted data as they are already updated one step before with the user coverage lines choice
          ...omit(submissionCreateDataBasedOnExtractionAndDefaults, 'coverageLines'),
        });
      }
      setStep(SubmissionStep.SubmissionForm);
    }
  };

  return !partialSubmission ? (
    <LoaderOverlay />
  ) : (
    <Routes>
      <Route
        path={`${SubmissionStep.SubmissionForm}/*`}
        element={
          <SubmissionFormInnerPageLayout
            submission={partialSubmission!}
            setNextStep={onNextStep}
            applySuggestionsOnEmptyFields={!!firstVisit || didSubmissionChangeFromNonHeraldToHerald}
          />
        }
      />
      <Route
        path={`${SubmissionStep.ReviewAgent}/*`}
        element={<ReviewAgentForm submission={partialSubmission!} setNextStep={onNextStep} />}
      />
      <Route
        path={`${SubmissionStep.PreForm}/*`}
        element={
          <PreForm
            isBorFlow={isBOR}
            submission={partialSubmission!}
            afterSubmissionUpdated={onPreFormSent}
            firstVisit={!!firstVisit}
          />
        }
      />
      <Route
        path={`${SubmissionStep.RiskAssessment}/*`}
        element={<RiskAssessmentStep submission={partialSubmission!} setNextStep={onNextStep} />}
      />
    </Routes>
  );
}
