import { Box } from '@common-components';
import { RouteKey } from 'broker/enums';
import useOverlays from 'broker/hooks/useOverlays';
import InformationRequestedPage from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/InformationRequested';

export default function InnerOverlay() {
  const overlays = useOverlays();

  function getOverlayComponent(key: string) {
    switch (key) {
      case RouteKey.InformationRequest:
        return <InformationRequestedPage />;
      default:
        return null;
    }
  }

  return (
    <>
      {overlays.map((overlay) => {
        const component = getOverlayComponent(overlay);
        if (component) {
          return (
            <Box
              key={overlay}
              sx={{ position: 'absolute', zIndex: 2, top: 0, left: 0, right: 0, bottom: 0, bgcolor: 'common.white' }}
            >
              {component}
            </Box>
          );
        }
        return null;
      })}
    </>
  );
}
