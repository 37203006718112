import { SyntheticEvent, useEffect, useState } from 'react';
import { Box, Tab, Tabs, tabsClasses } from '@common-components';
import { Check as CheckIcon, WarningAmber as WarningAmberIcon } from '@icons';
import { SubmissionStatus } from 'enums';
import { messages } from 'i18n';
import { WORKSPACE_TABS_ROUTES, WorkspaceTabsRoutesValues } from 'broker/broker-routes';
import { getTabOrder, navigationTabConfig } from 'broker/configuration-mappers/navigation-tabs-config';
import { RouteKey } from 'broker/enums';
import { useNavigate } from 'broker/hooks';
import { useIsAcknowledgmentFlow, useWorkspaceUrls } from 'broker/pages/SubmissionWorkspacePage/hooks';
import { useIsBORFlow } from 'broker/pages/SubmissionWorkspacePage/hooks/useIsBORFlow';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { isIncompleteApiApplication } from 'broker/utils';

interface NavigationTabsProps {
  activeTab: WorkspaceTabsRoutesValues;
}

export default function NavigationTabs({ activeTab }: NavigationTabsProps) {
  const navigate = useNavigate();
  const { partialSubmission, submission, quotes } = useSubmissionsWorkspace();
  const { proposalFlowUrl } = useWorkspaceUrls();

  const { isAcknowledgmentFlow } = useIsAcknowledgmentFlow();
  const { isBOR } = useIsBORFlow();

  // if no submission in context it means it doesn't contain the required fields for the marketing
  const isSubmissionBlockForMarketing = !submission || isAcknowledgmentFlow;

  const handleChange = (_: SyntheticEvent<Element, Event>, value: string | number) => {
    navigate(value.toString(), { routeKey: RouteKey.WorkspaceTab, state: undefined });
  };

  const [maxTabOrderChecked, setMaxTabOrderChecked] = useState(0);

  useEffect(() => {
    if (partialSubmission?.status === SubmissionStatus.Quoting) {
      setMaxTabOrderChecked(getTabOrder(WORKSPACE_TABS_ROUTES.PRE_MARKETING));
    } else if (partialSubmission?.stepsMetadata[WORKSPACE_TABS_ROUTES.MARKETING]?.markedAsDone) {
      setMaxTabOrderChecked(getTabOrder(WORKSPACE_TABS_ROUTES.MARKETING));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partialSubmission, quotes]);

  const getIsCheckedStatus = (tabRoute: WorkspaceTabsRoutesValues) => getTabOrder(tabRoute) <= maxTabOrderChecked;

  const showPreMarketingWarningIcon =
    activeTab !== WORKSPACE_TABS_ROUTES.PRE_MARKETING && isIncompleteApiApplication(partialSubmission);

  const iconToShow = (tabRoute: WorkspaceTabsRoutesValues) => {
    if (tabRoute === WORKSPACE_TABS_ROUTES.PRE_MARKETING && showPreMarketingWarningIcon) {
      return <WarningAmberIcon fontSize="small" color="error" />;
    }

    return getIsCheckedStatus(tabRoute) ? <CheckIcon fontSize="small" /> : undefined;
  };

  return (
    <Box flex={1}>
      <Tabs
        sx={{
          [`& .${tabsClasses.indicator}`]: {
            height: 4,
          },
        }}
        onChange={handleChange}
        value={activeTab}
      >
        {!isBOR &&
          Object.values(WORKSPACE_TABS_ROUTES)
            .filter((tabRoute) => tabRoute !== WORKSPACE_TABS_ROUTES.BOR)
            .map((tabRoute) => (
              <Tab
                key={tabRoute}
                iconPosition="start"
                value={tabRoute}
                label={navigationTabConfig[tabRoute].text}
                icon={iconToShow(tabRoute)}
                disabled={tabRoute === WORKSPACE_TABS_ROUTES.PRE_MARKETING ? false : isSubmissionBlockForMarketing}
                sx={{
                  minHeight: 52,
                }}
              />
            ))}

        {isBOR && (
          <Tab
            iconPosition="start"
            label="BOR"
            value={WORKSPACE_TABS_ROUTES.BOR}
            sx={{
              minHeight: 52,
            }}
          />
        )}

        {!isBOR && (
          <Tab
            iconPosition="start"
            label={messages.general.proposal}
            sx={{
              minHeight: 52,
            }}
            onClick={() => {
              navigate(proposalFlowUrl, {
                routeKey: RouteKey.ProposalFlow,
                state: {},
              });
            }}
          />
        )}
      </Tabs>
    </Box>
  );
}
