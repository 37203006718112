import { ExtractedAttribute } from '@common/types';
import { PromptResultFeatureType } from 'enums';
import { SuggestedValueProps } from 'hooks';
import { getValidOptionSuggestion } from 'broker/utils';
import { buildSuggestedValueProps } from './suggestion-values-utils';

// Overload signatures

// single string option from a list of options
export function extractValidOptionsSuggestion(
  extractedAttribute: ExtractedAttribute<string>,
  options: string[],
  promptResultFeature: PromptResultFeatureType,
): SuggestedValueProps | undefined;

// multiple string options from a list of options
export function extractValidOptionsSuggestion(
  extractedAttribute: ExtractedAttribute<string[]>,
  options: string[],
  promptResultFeature: PromptResultFeatureType,
): SuggestedValueProps | undefined;

// single numeric option from a list of options
export function extractValidOptionsSuggestion(
  extractedAttribute: ExtractedAttribute<string>,
  options: number[],
  promptResultFeature: PromptResultFeatureType,
): SuggestedValueProps | undefined;

export function extractValidOptionsSuggestion(
  extractedAttribute: ExtractedAttribute<string | string[]>,
  options: string[] | number[],
  promptResultFeature: PromptResultFeatureType,
): SuggestedValueProps | undefined {
  const validSuggestion = getValidOptionSuggestion(extractedAttribute, options);
  if (validSuggestion) {
    return buildSuggestedValueProps(validSuggestion, promptResultFeature);
  }

  return undefined;
}
