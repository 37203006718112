export enum QueryKeys {
  Submissions = 'Submissions',
  Layers = 'Layers',
  SubmissionMarkets = 'SubmissionMarkets',
  Slack = 'Slack',
  SubmissionFileMetadata = 'SubmissionFileMetadata',
  SubmissionMarketRequest = 'SubmissionMarketRequest',
  StateCompliance = 'StateCompliance',
  SubmissionInboundEmail = 'SubmissionInboundEmail',
  Quotes = 'Quotes',
  PromptResult = 'PromptResult',
  AI = 'AI',
  HeraldQuotes = 'HeraldQuotes',
  Contacts = 'Contacts',
  Insureds = 'Insureds',
  UserMarkets = 'UserMarkets',
  Markets = 'Markets',
  Pdf = 'Pdf',
  EmailTemplates = 'EmailTemplates',
  EmailMessageBatch = 'EmailMessageBatch',
  Organizations = 'Organizations',
  OrganizationDomains = 'OrganizationDomains',
  Users = 'Users',
  InboundEmailMessage = 'InboundEmailMessage',
  ExtendedSubmission = 'ExtendedSubmission',
  BoxItems = 'BoxItems',
  BoxItem = 'BoxItem',
  BoxPdfRepresentation = 'BoxPdfRepresentation',
  BoxDownloadPdfRepresentation = 'BoxDownloadPdfRepresentation',
  SubmissionEmail = 'SubmissionEmail',
  DlqStatus = 'DlqStatus',
  NylasUser = 'NylasUser',
  MarketIssuingCompany = 'MarketIssuingCompany',
  ActivityLog = 'ActivityLog',
  HeraldApiApplication = 'HeraldApiApplication',
  HeraldApiSubmission = 'HeraldApiSubmission',
  HeraldApiQuote = 'HeraldApiQuote',
  HeraldApiIndustryIndexSearch = 'HeraldApiIndustryIndexSearch',
  HeraldApiIndustryIndexRetrieve = 'HeraldApiIndustryIndexRetrieve',
  HeraldAccessToken = 'HeraldAccessToken',
  InsuranceProduct = 'InsuranceProduct',
  Teams = 'Teams',
  Notification = 'Notification',
  Agency = 'Agency',
  SubmissionExtractedData = 'SubmissionExtractedData',
}
