import { messages } from 'i18n';
import { WORKSPACE_TABS_ROUTES, WorkspaceTabsRoutesValues } from 'broker/broker-routes';

export type tabMetaData = {
  value: WorkspaceTabsRoutesValues;
  text: string;
};

type NavigationTabConfig = Record<WorkspaceTabsRoutesValues, tabMetaData>;

export const navigationTabConfig: NavigationTabConfig = {
  [WORKSPACE_TABS_ROUTES.PRE_MARKETING]: {
    value: WORKSPACE_TABS_ROUTES.PRE_MARKETING,
    text: messages.submissionWorkspace.tabs.preMarketing.title,
  },
  [WORKSPACE_TABS_ROUTES.MARKETING]: {
    value: WORKSPACE_TABS_ROUTES.MARKETING,
    text: messages.submissionWorkspace.tabs.marketing.title,
  },
  [WORKSPACE_TABS_ROUTES.BOR]: {
    value: WORKSPACE_TABS_ROUTES.BOR,
    text: messages.submissionWorkspace.tabs.bor.title,
  },
};
export const getTabOrder = (tabsRoutesValue: WorkspaceTabsRoutesValues) =>
  Object.values(WORKSPACE_TABS_ROUTES).indexOf(tabsRoutesValue);

export const getPreviousTab = (tabsRoutesValue: WorkspaceTabsRoutesValues) =>
  Object.values(WORKSPACE_TABS_ROUTES)[getTabOrder(tabsRoutesValue) - 1];
