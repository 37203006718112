import { BoxTemplateLabels, FileType, SubmissionEmailLabel } from 'enums';
import { useSearchBoxItems } from 'hooks/api/box';
import { PartialSubmission } from 'types';
import { getFileMetadataField, Nullable } from 'utils';
import useGetSubmissionRelatedEmails from 'broker/pages/SubmissionWorkspacePage/components/Toolbox/components/Email/useGetSubmissionRelatedEmails';

export function useGetFilesAndEmails(partialSubmission: Nullable<PartialSubmission>) {
  const { items: submissionFiles, isLoading: submissionFilesLoading } = useSearchBoxItems({
    filter: { chunkSize: 20, folderId: partialSubmission?.boxFolderId || '' },
    enabled: !!partialSubmission?.boxFolderId,
  });

  const { submissionEmailsList, isLoading: submissionEmailsLoading } = useGetSubmissionRelatedEmails({
    submissionId: partialSubmission?.id,
  });

  const expiringPolicy = submissionFiles.find(
    (item) => getFileMetadataField(item, BoxTemplateLabels.FileType) === FileType.ExpiringProgram,
  );

  const application = submissionFiles.find(
    (item) => getFileMetadataField(item, BoxTemplateLabels.FileType) === FileType.Submission,
  );

  const solicitationEmail = submissionEmailsList.find(
    (email) => email.label === SubmissionEmailLabel.InformationRequest,
  );

  const marketingEmails = submissionEmailsList.filter(
    (email) => email.label === SubmissionEmailLabel.SubmissionMarketing,
  );

  const proposalEmail = submissionEmailsList.find((email) => email.label === SubmissionEmailLabel.Proposal);

  const quoteComparison = submissionFiles.find(
    (item) => getFileMetadataField(item, BoxTemplateLabels.FileType) === FileType.QuoteComparison,
  );

  const marketQuotes = submissionFiles.filter(
    (item) => getFileMetadataField(item, BoxTemplateLabels.FileType) === FileType.MarketQuote,
  );

  return {
    application,
    expiringPolicy,
    solicitationEmail,
    marketingEmails,
    marketQuotes,
    quoteComparison,
    proposalEmail,
    isLoading: submissionFilesLoading || submissionEmailsLoading,
  };
}
