// These are the base (partial) types that are shared between the client and server.
// They do not contain all the fields, only the ones that are currently needed for the shared code.
export * from './herald-question-id';
export * from './recipient';
export * from './recipient-group';
export * from './user';
export * from './submission';
export * from './email-template-context';
export * from './insurance-product-coverage-lines';
export * from './herald';
export * from './herald-quote';
export * from '../enums/assistant-streaming-event';
export * from './submission-extracted-data';
export * from './prompt-result';
export * from './email-labels';
export * from './submission-file-metadata';
export * from './citation';
export * from './address-details';
export * from './state-compliance';
export * from './submission-market-request-summary';
export * from './flow-question-id';
