import { useSearchUser } from 'hooks';
import { convertUserToRecipient, Recipient } from './recipient-utils';

/**
 * Hook to get all members of a team as Recipient objects
 * @param enabled - query is enabled if both teamId is available and enabled is set to true
 * @param teamId - teamId to fetch members for
 */
export function useGetAllTeamMembersAsRecipients(
  enabled: boolean,
  teamId?: string,
): {
  isLoading: boolean;
  allTeamMembersAsRecipients?: Recipient[];
} {
  const isEnabled = enabled && !!teamId;
  const { items: teamMembers, isInitialLoading } = useSearchUser({
    enabled: isEnabled,
    filter: { teamId, enabled: true },
  });

  return {
    isLoading: isInitialLoading,
    allTeamMembersAsRecipients: teamMembers.map((user) => convertUserToRecipient(user)),
  };
}
