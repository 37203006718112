import { compact } from 'lodash';
// eslint-disable-next-line import/no-unresolved
import { Content } from 'pdfmake/interfaces';
import { convertImageToBase64 } from './pdf-content-creator';

interface PdfContentProps {
  headerContent: Content;
  titleText: string;
  subtitleText: string;
}
export const getPdfBackCover = async ({
  titleText,
  subtitleText,
  headerContent,
}: PdfContentProps): Promise<Content> => {
  const leftDecorImage = await convertImageToBase64('/img/top-left-text-decor.png');
  const rightDecorImage = await convertImageToBase64('/img/bottom-right-text-decor.png');

  const header: Content = {
    text: headerContent,
    alignment: 'right',
    font: 'Inter',
    fontSize: 12,
    margin: [0, 0, 0, 0], // [left, top, right, bottom]
    color: 'black',
  };

  const leftBracket: Content = {
    image: `data:image/png;base64,${leftDecorImage}`,
    width: 18,
    margin: [0, -10, 0, 0],
  };

  // Center text
  const title: Content = {
    text: titleText,
    font: 'Inter',
    fontSize: 16,
    bold: true,
    color: '#330A89',
    alignment: 'center',
    margin: [0, 0, 0, 0],
  };

  // Right bracket, slightly lower with positive top margin
  const rightBracket: Content = {
    image: `data:image/png;base64,${rightDecorImage}`,
    width: 18,
    margin: [0, 15, 0, 0], // Moves it down by 10 points
  };

  // Use three columns: left bracket, center text, right bracket
  // - The middle column has '*' width, so it expands, pushing the brackets out.
  // - 'auto' keeps the brackets at their natural width.
  // - margin: [0, 200, 0, 0] places the entire row ~200 points from the top (adjust as needed).
  const bracketedRow: Content = {
    columns: [
      { width: '*', text: '' },
      { width: 'auto', stack: [leftBracket], alignment: 'left' },
      { width: 'auto', stack: [title] },
      { width: 'auto', stack: [rightBracket], alignment: 'right' },
      { width: '*', text: '' },
    ],
    alignment: 'center',
    margin: [0, 200, 0, 0],
    columnGap: 5, // Spacing between columns
  };

  // Message below the title.
  const subtitle: Content = {
    text: subtitleText,
    alignment: 'center',
    font: 'Inter',
    fontSize: 12,
    margin: [60, 30, 60, 0],
    color: 'black',
  };

  return compact([header, bracketedRow, subtitle]);
};
