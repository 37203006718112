import { isEmpty } from 'lodash';
import { useMemo } from 'react';
import { Paper, Stack } from '@common-components';
import { ExtendedMarketRequest } from 'types';
import MainScrollArea from 'broker/hoc/MainScrollArea';
import EmptyState from 'broker/pages/SubmissionWorkspacePage/components/TabsPanel/common/MarketRequestsWorkspace/components/EmptyState';
import MarketRequestsList from 'broker/pages/SubmissionWorkspacePage/components/TabsPanel/common/MarketRequestsWorkspace/components/MarketRequestsList';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { submissionMarketsToExtendedMarketRequests } from 'broker/utils/submission-utills';
import Header from './Header';

export default function BorWorkspace() {
  const { markets, activities, submission } = useSubmissionsWorkspace();

  const MainScrollAreaId = 'marketsDataGridScrollArea';

  const extendedMarketRequests: ExtendedMarketRequest[] = useMemo(
    () => submissionMarketsToExtendedMarketRequests(markets, submission),
    [markets, submission],
  );

  return (
    <>
      <Header />
      <Stack component={Paper} bgcolor="grey.50" borderRadius={0} variant="outlined" overflow="hidden" flex="1 1 0">
        <MainScrollArea
          id={MainScrollAreaId}
          sx={{
            overflow: 'auto',
            p: 2,
            flex: 1,
            gap: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {isEmpty(extendedMarketRequests) ? (
            <EmptyState emptyState={isEmpty(extendedMarketRequests)} />
          ) : (
            <MarketRequestsList
              selectedMarketRequests={{}}
              multipleSelection
              onMarketRequestSelected={() => {}}
              marketRequests={extendedMarketRequests}
              activities={activities}
              showSelectionCheck={false}
            />
          )}
        </MainScrollArea>
      </Stack>
    </>
  );
}
