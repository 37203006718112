import { notificationAssigneeTypeConfig, notificationTypeConfig } from '@common/config';
import { Chip, Stack, Typography } from '@common-components';
import { NotificationLevelType } from 'enums';
import { messages } from 'i18n';
import { SubmissionSearchItem } from 'types';
import { getTimezoneLessDisplayDate, truncateMultiLineText } from 'utils';
import { notificationLevelConfig } from 'broker/config';

interface SubmissionTableReminderProps
  extends Pick<SubmissionSearchItem, 'urgentNotificationsCount' | 'mostUrgentNotification'> {}

export default function SubmissionTableReminder({
  urgentNotificationsCount,
  mostUrgentNotification,
}: SubmissionTableReminderProps) {
  if (!mostUrgentNotification) {
    return (
      <Stack p={1.5} width={1} height={1} justifyContent="center">
        <Typography color="text.secondary" variant="body2">
          {messages.general.noReminders}
        </Typography>
      </Stack>
    );
  }

  return (
    <Stack
      position="relative"
      justifyContent="center"
      width={1}
      height={1}
      bgcolor={notificationLevelConfig[mostUrgentNotification.level].color}
      p={1.5}
      gap={1}
    >
      {urgentNotificationsCount && urgentNotificationsCount > 1 && (
        <Stack
          position="absolute"
          top={8}
          right={8}
          direction="row"
          alignItems="center"
          justifyContent="center"
          borderRadius="50%"
          bgcolor={mostUrgentNotification.level === NotificationLevelType.Alert ? 'error.main' : 'warning.main'}
          width={20}
          height={20}
          gap={1}
          sx={{
            cursor: 'pointer',
          }}
        >
          <Typography
            color={mostUrgentNotification.level === NotificationLevelType.Alert ? 'common.white' : 'common.black'}
            variant="captionBold"
          >
            {urgentNotificationsCount}
          </Typography>
        </Stack>
      )}
      <Typography variant="body2" sx={{ ...truncateMultiLineText(1) }}>
        {mostUrgentNotification.title}
      </Typography>
      <Stack direction="row" gap={0.5}>
        <Chip color="secondary" size="small" label={notificationTypeConfig[mostUrgentNotification.type].text} />
        <Chip
          color="secondary"
          size="small"
          label={notificationAssigneeTypeConfig[mostUrgentNotification.assigneeType].text}
        />
        <Chip color="secondary" size="small" label={getTimezoneLessDisplayDate(mostUrgentNotification.dueDate)} />
      </Stack>
    </Stack>
  );
}
