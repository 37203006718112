export enum FileType {
    ExpiringProgram = 'Expiring Program',
    // eslint-disable-next-line custom-rules/equal-enum-keys-and-values
    MarketQuote = 'Primary Quote', // Displayed as "Market Quote" in the UI
    CapitolaQuote = 'Capitola Quote', // Displayed as "Flow Quote" in the UI
    Submission = 'Submission', // Displayed as "Application" in the UI
    MarketingReport = 'Marketing Report',
    ConceptualTower = 'Conceptual Tower',
    // eslint-disable-next-line custom-rules/equal-enum-keys-and-values
    CapitolaBinder = 'Binder', // Displayed as "Flow Binder" in the UI
    MarketBinder = 'Market Binder',
    Policy = 'Policy',
    PolicyCoverLetter = 'Policy Cover Letter',
    CustomerEmail = 'Customer Email',
    MarketingEmail = 'Marketing Email',
    ReplyEmail = 'Reply Email',
    InboundEmail = 'Inbound Email',
    PastApplication = 'Past Application',
    LossRuns = 'Loss Runs',
    Supplement = 'Supplement',
    Proposal = 'Proposal',
    RansomwareApplication = 'Ransomware Application',
    MarketInvoice = 'Market Invoice',
    CapitolaInvoice = 'Capitola Invoice',
    Financials = 'Financials',
    SignedApplication = 'Signed Application',
    DueDiligence = 'Due Diligence',
    Declination = 'Declination',
    // eslint-disable-next-line custom-rules/equal-enum-keys-and-values
    OtherSurplusForms = 'Other Surplus Line Forms',
    PolicySpecimen = 'Policy Specimen',
    Endorsement = 'Endorsement',
    BOR = 'BOR',
    QuoteComparison = 'Quote Comparison',
    Subjectivities = 'Subjectivities',
    NoLossLetter = 'No Loss Letter',
    CarrierSurplusForm = 'Carrier Surplus Form',
    FlowPolicy = 'Flow Policy',
    AOR = 'AOR',
    StateCompliance = 'State Compliance',
    DNNR = 'DNNR',
    DNCR = 'DNCR',
    CompetingQuote = 'Competing Quote',
    PriorLossRuns = 'Prior Loss Runs',
    ExpiringEndorsement = 'Expiring Endorsement',
    UnderlyingPolicy = 'Underlying Policy',
}
