import { getIsHeraldSubmission } from '@common/utils';
import { Box, Stack } from '@common-components';
import { allExpectedSubmissionFileTypes } from 'broker/config/submission-tasks-file-types-config';
import useSubmissionMissingFiles from 'broker/hooks/useSubmissionMissingFiles';
import MissingInformationBanner from 'broker/pages/SubmissionWorkspacePage/components/MissingInformationBanner';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { getStaticSubmissionMissingOptionalFields, isSubmissionMissingRequiredFields } from 'broker/utils';
import { TasksList } from './components/TasksList';

export default function PreMarketing() {
  const { partialSubmission } = useSubmissionsWorkspace();
  const { getMissingFileTypes, isLoading: isLoadingMissingFiles } = useSubmissionMissingFiles(partialSubmission);

  if (!partialSubmission) {
    return null;
  }

  const isMissingRequiredFields = isSubmissionMissingRequiredFields(partialSubmission);

  const isMissingOptionalFields =
    // dynamic application only have required fields
    getIsHeraldSubmission(partialSubmission) || getStaticSubmissionMissingOptionalFields(partialSubmission).length > 0;

  const isMissingInformation =
    isSubmissionMissingRequiredFields(partialSubmission) ||
    isMissingOptionalFields ||
    getMissingFileTypes(allExpectedSubmissionFileTypes).length > 0;

  return (
    <Box className="cap-pre-marketing-tab" width={1} overflow="auto" p={0} display="flex">
      <Stack direction="column" flex={1} px={3} pt={2}>
        {isMissingInformation && (
          <MissingInformationBanner
            isLoadingMissingInformation={isLoadingMissingFiles}
            level={isMissingRequiredFields ? 'error' : 'warning'}
          />
        )}
        <TasksList submission={partialSubmission} getMissingFileTypes={getMissingFileTypes} />
      </Stack>
    </Box>
  );
}
