import { getTimezoneLessDisplayDate } from '@common/utils/date-utils';
import { BoxTemplateLabels, FileType } from 'enums';
import { PartialSubmission } from 'types';
import {
  getRetailerFullName,
  getTodayDate,
} from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/Quote/components/formSteps/FlowQuote/pdf-creator/helpers';
import { getPdfCover } from 'broker/pages/SubmissionWorkspacePage/utils/pdf-creator/pdf-cover';
import { usePDFCreateAndCombine } from 'broker/pages/SubmissionWorkspacePage/utils/pdf-creator/usePDFCreateAndCombine';
import { getPdfContent } from './pdf-content';

function getFileName() {
  return `Marketing_Update_${getTimezoneLessDisplayDate(new Date())}.pdf`;
}

export function useGenerateAndUploadMarketingReportPDF() {
  const pdFCreateAndCombine = usePDFCreateAndCombine();

  async function generateAndUploadMarketingReportPDF(submission: PartialSubmission, editorContent?: string) {
    const coverPage = await getPdfCover({
      title: `Marketing Report \n Prepared on behalf of ${getRetailerFullName(submission.user)}`,
      subtitle1: `Delivery Date: ${getTodayDate()}`,
    });
    const content = getPdfContent({ submission, editorContent });
    return pdFCreateAndCombine(
      [
        {
          pdfContentProps: coverPage,
        },
        {
          pdfContentProps: {
            content,
          },
        },
      ],
      {
        parentFolderId: submission.boxFolderId,
        fileName: getFileName(),
        metadata: { [BoxTemplateLabels.FileType]: FileType.MarketingReport },
      },
    );
  }

  return generateAndUploadMarketingReportPDF;
}
