import { BoxItem } from 'box-ui-elements/es';
import { compact, isEmpty } from 'lodash';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  BoxTemplateLabels,
  FileType,
  FormMode,
  PromptResultFeature,
  QuoteFileIdType,
  SubmissionMarketRequestStatus,
} from 'enums';
import { useMutatePromptResult, useToast } from 'hooks';
import { useBoxApi, useMutateBoxItems, useSearchBoxItems } from 'hooks/api/box';
import { messages } from 'i18n';
import { dialogTransitionDurationValue } from 'themes';
import { Layer, Quote, SubmissionMarket, UserMarket } from 'types';
import { addFileMarketTypeLabel, attachmentPointText, getFileType, isMarketIdInFileMarketTypeLabel } from 'utils';
import { asyncHandleSubmit } from 'utils/form-hooks-utils';
import { QuoteFormCreate } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/Quote/form-methods/types';
import { useSendPromptReport } from 'broker/utils/use-send-prompt-report';
import { useGetFlowQuotePdfGenerator } from './formSteps/FlowQuote/pdf-creator/use-flow-quote-pdf-generator';
import { QuoteFormProps } from './types';

interface QuoteDataToSendProps {
  data: QuoteFormCreate;
  userMarket: UserMarket;
  layer: Layer;
  hideToast?: boolean;
  saveForLaterFlowQuoteId?: string;
  submissionMarket: SubmissionMarket;
}

export interface UseSubmitQuoteFormProps extends QuoteFormProps {
  userMarkets: UserMarket[];
}

export default function useSubmitQuoteForm({
  submission,
  marketQuoteFileIds,
  initialProductId,
  submissionMarketRequestId,
  isDirty,
  mode,
  fileIds,
  flowQuoteIds,
  selectedQuote,
  userMarkets,
  products,
  submissionMarkets,
  layers,
  createQuoteApi,
  updateQuoteApi,
  createLayerApi,
  addSubmissionMarketApi,
  updateSubmissionMarketRequestApi,
  setIsDirty,
  fileType,
  insightPopulatedByAI,
  suggestedValueProps,
  stateCompliance,
  isNonAdmittedProduct,
}: UseSubmitQuoteFormProps) {
  const { copyFile, addFileMetadata, updateFileMetadata } = useMutateBoxItems();
  const getFlowQuotePdfGenerator = useGetFlowQuotePdfGenerator();

  const { getBoxItemById } = useBoxApi();
  const { items: boxItems } = useSearchBoxItems({
    filter: { folderId: submission?.boxFolderId || '' },
  });
  const { showDelayedToast } = useToast();
  const sendPromptReport = useSendPromptReport();
  const quoteMethods = useFormContext();
  const { createPromptResult } = useMutatePromptResult();
  const [quoteSubmitting, setQuoteSubmitting] = useState(false);

  const getFormatDataToSend = ({ data, layer, saveForLaterFlowQuoteId }: QuoteDataToSendProps): Partial<Quote> => {
    const dataToSend: Partial<Quote> = {
      expiresAt: isEmpty(data.expiresAt) ? null : data.expiresAt,
      layerId: layer.id,
      submissionMarketId: data.submissionMarketId,
      insuranceProductId: data.insuranceProductId,
      premium: Number(data.premium),
      limit: data.limit ? Number(data.limit) : null,
      agentCommission: data.agentCommission ? Number(data.agentCommission) : null,
      // @ts-ignore
      ...(data?.primaryQuoteId && { primaryQuoteId: data.primaryQuoteId }),
      comments: data.comments,
      insights: data.insights,
      date: isEmpty(data.date) ? null : data.date,
      subLimits: data.subLimits,
      triaPremium: data.triaPremium ? Number(data.triaPremium) : null,
      carrierFee: data.carrierFee ? Number(data.carrierFee) : null,
      flowBrokerFee: data.flowBrokerFee ? Number(data.flowBrokerFee) : null,
      surplusLineTax: data.surplusLineTax ? Number(data.surplusLineTax) : null,
      surplusStampingFee: data.surplusStampingFee ? Number(data.surplusStampingFee) : null,
      // map back to array of strings
      subjectivities: {
        carrierSpecific: data.subjectivities?.map((subjectivity) => subjectivity.value) || [],
        stateCompliance: data.stateCompliance,
      },
      binderSubjectivities: {
        carrierSpecific: data.binderSubjectivities?.map((subjectivity) => subjectivity.value) || [],
        stateCompliance: data.binderStateCompliance,
      },
      coverageLines: data.coverageLines,
    };
    if (fileType === QuoteFileIdType.MarketQuote) {
      dataToSend.marketQuoteFileIds = fileIds;
      dataToSend.capitolaQuoteFileIds = saveForLaterFlowQuoteId ? [saveForLaterFlowQuoteId] : flowQuoteIds;
    }
    return dataToSend;
  };

  const updateStateComplianceFiles = async (submissionMarket: SubmissionMarket) => {
    if (isNonAdmittedProduct && stateCompliance?.forms) {
      const stateComplianceFileType = FileType.StateCompliance;
      const existingStateComplianceDocInSubmission = boxItems.filter(
        (boxItem) => getFileType(boxItem) === stateComplianceFileType,
      );

      if (isEmpty(existingStateComplianceDocInSubmission)) {
        const submissionStateComplianceBoxItems = compact(
          await Promise.all(
            [...(stateCompliance.forms.stateSpecific || []), ...(stateCompliance.forms.diligentEffort || [])].map(
              async (form) => {
                try {
                  return await copyFile.mutateAsync({ boxItemId: form, folderId: submission.boxFolderId });
                } catch (e: any) {
                  // if the file already exists in the submission folder, we won't copy it and assume that it is the state compliance file (as there file names seem to be long and unique)
                  if (e.status === 409 && e.code === 'item_name_in_use' && e?.context_info?.conflicts?.id) {
                    const conflictedBoxItem = await getBoxItemById(e.context_info.conflicts.id);
                    if (conflictedBoxItem) {
                      return conflictedBoxItem;
                    }
                  }
                  throw e;
                }
              },
            ),
          ),
        );
        // add new files to be labeled as state compliance with the marketId label
        submissionStateComplianceBoxItems.map((boxItem) =>
          addFileMetadata.mutateAsync({
            fileId: boxItem.id,
            metadata: {
              [BoxTemplateLabels.MarketId]: addFileMarketTypeLabel(boxItem, submissionMarket.id),
              [BoxTemplateLabels.FileType]: stateComplianceFileType,
            },
          }),
        );
      } else {
        // update existing files labeled as state compliance with the marketId label
        await Promise.all(
          existingStateComplianceDocInSubmission
            .filter((boxItem) => !isMarketIdInFileMarketTypeLabel(boxItem, submissionMarket.id))
            .map((boxItem) =>
              updateFileMetadata.mutateAsync({
                fileId: boxItem.id,
                metadata: {
                  [BoxTemplateLabels.MarketId]: addFileMarketTypeLabel(boxItem, submissionMarket.id),
                  [BoxTemplateLabels.FileType]: stateComplianceFileType,
                },
              }),
            ),
        );
      }
    }
  };

  const createQuote = async (quoteDataToSendProps: QuoteDataToSendProps): Promise<string | undefined> => {
    const { hideToast, userMarket, layer } = quoteDataToSendProps;
    const formatData = getFormatDataToSend(quoteDataToSendProps);
    const quote = await createQuoteApi?.(formatData);

    if (!hideToast) {
      // noinspection ES6MissingAwait
      showDelayedToast(
        'success',
        {
          message: messages.addQuotePage.addQuoteToastMessage(
            attachmentPointText(layer.attachmentPoint),
            userMarket.marketName,
          ),
        },
        dialogTransitionDurationValue,
      );
    }

    return quote;
  };

  const updateQuote = async (quoteDataToSendProps: QuoteDataToSendProps) => {
    if (updateQuoteApi) {
      const formatData = getFormatDataToSend(quoteDataToSendProps);
      const quote = await updateQuoteApi(selectedQuote!.id!, formatData);

      const { userMarket } = quoteDataToSendProps;
      // noinspection ES6MissingAwait
      showDelayedToast(
        'success',
        { message: messages.addQuotePage.updateQuoteToastMessage(userMarket.marketName) },
        dialogTransitionDurationValue,
      );
      return quote;
    }
    return undefined;
  };

  const createQuoteInNewLayer = async (quoteDataToSendProps: Omit<QuoteDataToSendProps, 'layer'>) => {
    const { data } = quoteDataToSendProps;
    const newLayer = await createLayerApi?.(submission.id, Number(data.attachmentPoint));
    if (newLayer) {
      if (mode === FormMode.edit) {
        return updateQuote({ ...quoteDataToSendProps, layer: newLayer });
      }
      return createQuote({ ...quoteDataToSendProps, layer: newLayer });
    }
    return undefined;
  };

  const sendApiRequests = async (
    quoteFieldsState: QuoteFormCreate,
    saveForLaterFlowQuoteId?: string,
  ): Promise<string | undefined> => {
    const product = products.find((productItem) => productItem.id === quoteFieldsState.insuranceProductId);
    const userMarket = userMarkets.find((userMarketItem) => userMarketItem.marketId === product?.marketId)!;
    // search for submission market by userMarketId
    let submissionMarket = submissionMarkets.find(
      (submissionMarketItem) => submissionMarketItem.userMarketId === userMarket.id,
    );

    if (!submissionMarket) {
      // addSubmissionMarketApi is not send only when form is disabled
      const response = await addSubmissionMarketApi!(userMarket, quoteFieldsState.insuranceProductId);
      submissionMarket = response!;
    }

    if (submissionMarketRequestId) {
      // If the selected product is different from the one that was initially set, we need to update the market request first
      const newInsuranceProductId =
        !!quoteFieldsState?.insuranceProductId && initialProductId !== quoteFieldsState.insuranceProductId
          ? quoteFieldsState.insuranceProductId
          : undefined;

      // Ensure status is 'QuoteReady' (Quoted) after editing if fileType is MarketQuote,
      // as users can manually change it to 'Indication' status before editing.
      const newSubmissionMarketRequestStatus =
        mode === FormMode.edit && fileType === QuoteFileIdType.MarketQuote
          ? SubmissionMarketRequestStatus.QuoteReady
          : undefined;

      if (newInsuranceProductId || newSubmissionMarketRequestStatus) {
        await updateSubmissionMarketRequestApi?.(submissionMarketRequestId, {
          insuranceProductId: newInsuranceProductId,
          status: newSubmissionMarketRequestStatus,
        });
      }
    }

    const ownQuoteFormProps: QuoteFormCreate = {
      ...quoteFieldsState,
      submissionMarketId: submissionMarket.id,
    };

    const layer = layers.find((layerItem) => layerItem.id === quoteMethods.getValues().attachmentPoint);
    let quoteId: string | undefined;
    if (layer) {
      // Capitola quote is added in a "view" mode so that all the quote fields are disabled.
      if (mode === FormMode.edit || mode === FormMode.view) {
        quoteId = await updateQuote({
          data: ownQuoteFormProps,
          userMarket,
          layer,
          saveForLaterFlowQuoteId,
          submissionMarket,
        });
      } else {
        quoteId = await createQuote({
          data: ownQuoteFormProps,
          userMarket,
          layer,
          saveForLaterFlowQuoteId,
          submissionMarket,
        });
      }
    } else {
      quoteId = await createQuoteInNewLayer({
        data: ownQuoteFormProps,
        userMarket,
        saveForLaterFlowQuoteId,
        submissionMarket,
      });
    }
    // don't wait for creating state compliance files
    updateStateComplianceFiles(submissionMarket);

    return quoteId;
  };

  const submitForm = async (generateFlowQuote = false): Promise<string | undefined> => {
    let flowQuoteBoxItem: BoxItem | undefined | null;
    setQuoteSubmitting(true);
    try {
      if (generateFlowQuote) {
        if (mode === FormMode.create || (mode === FormMode.edit && (isDirty || isEmpty(flowQuoteIds)))) {
          const quoteFormValue = quoteMethods.getValues() as QuoteFormCreate;
          const marketName =
            products.find((product) => product.id === quoteFormValue.insuranceProductId)?.marketName ?? '';

          flowQuoteBoxItem = await getFlowQuotePdfGenerator({
            submission,
            values: quoteFormValue,
            marketName,
            marketQuoteFileIds,
          });
        }
      }
      const quoteFieldsState = (await asyncHandleSubmit(quoteMethods)) as QuoteFormCreate;
      setIsDirty?.(false);
      const quoteId = await sendApiRequests(quoteFieldsState, flowQuoteBoxItem?.id);
      //
      if (insightPopulatedByAI) {
        // run api in the background to create a prompt result (no need to wait for it)
        createPromptResult.mutateAsync({
          data: {
            result: insightPopulatedByAI.result,
            input: insightPopulatedByAI.input,
            modifiedResult: quoteFieldsState.insights || '',
            feature: PromptResultFeature.QuoteInsight,
            submissionId: submission.id,
          },
        });
      }
      if (quoteId && suggestedValueProps) {
        await sendPromptReport({
          formState: quoteFieldsState,
          suggestedValueProps,
          submissionId: submission.id,
          entityId: quoteId,
        });
      }
      return quoteId;
    } catch (e) {
      setQuoteSubmitting(false);
      setIsDirty?.(true);

      return undefined;
    }
  };

  return {
    submitForm,
    quoteSubmitting,
    setQuoteSubmitting,
  };
}
