import { DynamicQuestion } from '@common/dynamic-form';
import { every, isArray, isNil, isPlainObject } from 'lodash';
import { useCallback, useMemo } from 'react';

import {
  AnsweredDynamicQuestionsBySections,
  FormStateBaseProp,
  FormStateBaseProps,
} from 'broker/pages/SubmissionWorkspacePage/components/DynamicForm/DynamicFormLayout/types';

interface GetAnsweredDynamicQuestionsBySectionsProps {
  dynamicQuestionsBySections: Record<string, DynamicQuestion[]>;
  formValues: Record<string, FormStateBaseProps | FormStateBaseProps[] | undefined>;
}

export function isDynamicFormValueEmpty(value: any) {
  const isConsideredEmpty = (val: any) => isNil(val) || val === '' || (isArray(val) && val.length === 0);

  return isConsideredEmpty(value) || (isPlainObject(value) && every(value, (val) => isConsideredEmpty(val)));
}

export function getAllRequiredKeysAsSet(dynamicQuestionsBySections: Record<string, DynamicQuestion[]>) {
  const allRequiredParams = new Set<string>();

  const addRequiredParams = (param: DynamicQuestion) => {
    if (param.requiredForCompletion) {
      allRequiredParams.add(param.id);
    }
    if (param.childValues) {
      param.childValues.forEach((child) => {
        addRequiredParams(child);
      });
    }
  };

  Object.values(dynamicQuestionsBySections).forEach((paramsArray) => {
    paramsArray.forEach((param) => addRequiredParams(param));
  });
  return allRequiredParams;
}

export function useGetAnsweredDynamicQuestionsBySections({
  dynamicQuestionsBySections,
  formValues,
}: GetAnsweredDynamicQuestionsBySectionsProps) {
  const allRequiredParameters: Set<string> = useMemo(
    () => getAllRequiredKeysAsSet(dynamicQuestionsBySections),
    [dynamicQuestionsBySections],
  );

  const isDynamicQuestionEmpty = useCallback(
    (dynamicFormState: FormStateBaseProps | FormStateBaseProps[] | undefined): boolean => {
      if (!dynamicFormState) {
        return true;
      }
      if (Array.isArray(dynamicFormState)) {
        const allEmpty = dynamicFormState.every((element) => isDynamicQuestionEmpty(element));

        return allEmpty;
      }

      const isMainEmpty = isDynamicFormValueEmpty(dynamicFormState[FormStateBaseProp.Main]);
      if (isMainEmpty) {
        return true;
      }

      if (dynamicFormState[FormStateBaseProp.Children]) {
        // if any of the *required* children is "empty", then the parent is considered "empty"
        return Object.keys(dynamicFormState[FormStateBaseProp.Children]).some((key) => {
          const isChildRequired = allRequiredParameters.has(key);
          const childFormState = dynamicFormState[FormStateBaseProp.Children]?.[key];
          return isChildRequired && isDynamicQuestionEmpty(childFormState);
        });
      }

      return false;
    },
    [allRequiredParameters],
  );

  const unAnsweredQuestions = useMemo(
    () => Object.keys(formValues).filter((key) => isDynamicQuestionEmpty(formValues[key])),
    [isDynamicQuestionEmpty, formValues],
  );

  const answeredDynamicQuestionsBySections: AnsweredDynamicQuestionsBySections = useMemo(() => {
    // iterate over dynamicQuestionsBySections to create an object with the same keys but with only  the number of answered questions
    const ownAnsweredDynamicQuestionsBySections: AnsweredDynamicQuestionsBySections = {};
    Object.keys(dynamicQuestionsBySections).forEach((key) => {
      const requiredDynamicQuestionsBySection = dynamicQuestionsBySections[key].filter(
        (param) => param.requiredForCompletion,
      );
      ownAnsweredDynamicQuestionsBySections[key] = {
        answered: requiredDynamicQuestionsBySection.filter((param) => !unAnsweredQuestions.includes(param.id)).length,
        total: requiredDynamicQuestionsBySection.length,
      };
    });
    return ownAnsweredDynamicQuestionsBySections;
  }, [dynamicQuestionsBySections, unAnsweredQuestions]);

  return answeredDynamicQuestionsBySections;
}
