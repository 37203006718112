import { submissionStatusConfig } from 'broker/configuration-mappers/submission-status-config';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { useIsBORFlow } from './useIsBORFlow';

export function useIsAcknowledgmentFlow() {
  const { partialSubmission } = useSubmissionsWorkspace();
  const { isBOR } = useIsBORFlow();
  const isAcknowledgmentFlow =
    !isBOR && !!(partialSubmission?.status && submissionStatusConfig[partialSubmission?.status].preflight);
  return {
    isAcknowledgmentFlow,
  };
}
