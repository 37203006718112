import { getCoverageLinesText } from '@common/utils';
import { useRef } from 'react';
import { useHover } from 'usehooks-ts';
import { Stack, Typography } from '@common-components';
import { messages } from 'i18n';
import { PartialSubmission } from 'types';
import { getDotSeparated, getTimezoneLessDisplayDate } from 'utils';
import { RouteKey } from 'broker/enums';
import { useNavigate } from 'broker/hooks';
import { useIsBORFlow, useWorkspaceUrls } from 'broker/pages/SubmissionWorkspacePage/hooks';

interface SubmissionDetailsProps {
  partialSubmission: PartialSubmission;
}

export default function SubmissionDetails({ partialSubmission }: SubmissionDetailsProps) {
  const coverageLines = getCoverageLinesText(partialSubmission?.coverageLines);
  const dueDate = partialSubmission?.dueDate
    ? messages.submissionWorkspace.header.details.effectiveDate(getTimezoneLessDisplayDate(partialSubmission.dueDate))
    : '';
  const needByDate = partialSubmission?.needByDate
    ? messages.submissionWorkspace.header.details.needByDate(getTimezoneLessDisplayDate(partialSubmission.needByDate))
    : '';
  const navigate = useNavigate();
  const { editSubmissionUrl, editBorUrl } = useWorkspaceUrls();
  const hoverRef = useRef(null);
  const isHover = useHover(hoverRef);

  const { isBOR } = useIsBORFlow();

  return (
    <Stack direction="row" gap={1} alignItems="center">
      <Typography
        sx={{ textDecoration: isHover ? 'underline' : 'none', cursor: 'pointer' }}
        onClick={() => {
          if (isBOR) {
            navigate(editBorUrl, {
              routeKey: RouteKey.SubmissionEditBOR,
              state: undefined,
            });
          } else {
            navigate(editSubmissionUrl, {
              routeKey: RouteKey.SubmissionEdit,
              state: undefined,
            });
          }
        }}
        ref={hoverRef}
        variant="body2Bold"
        color="typography.primary"
        flex={1}
      >
        {getDotSeparated([coverageLines, dueDate, needByDate])}
      </Typography>
    </Stack>
  );
}
