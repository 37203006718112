import { HeraldIndexEntry, HeraldSchema } from '../types/herald/herald-types';
import { DynamicInputType } from './enums';
import { DynamicFormCategoryType } from './submission/enums/dynamic-form-category-type';

export enum DynamicFormQuestionProvider {
    Herald = 'Herald',
    Flow = 'Flow',
}

export interface DynamicQuestion {
    id: string;
    value?: any;
    // appears in DynamicQuestion of arrayElements (isFormArrayField : true) - todo: herald after task completed may not be needed
    instance?: string;
    fieldLabel: string;
    section: string;
    affectsOtherQuestions?: boolean;
    isFormArrayField: boolean;
    schema: HeraldSchema | null;
    // if DynamicQuestion isFormArrayField then don't look for DynamicQuestion.childValues and look for DynamicQuestion.arrayElements[i].childValues
    childValues?: DynamicQuestion[];
    category: DynamicFormCategoryType;
    // for isFormArrayField
    arrayElements?: DynamicQuestion[];
    // just for 'rsk_b3jm_2017_naics_index' id question
    industryDetails?: HeraldIndexEntry;
    requiredForCompletion: boolean;
    additionalContent?: string;
    helperText?: string;
    // DynamicFormQuestionSource.Herald question isn't sent to api (herald) -  it is just used for storing data in our database
    provider: DynamicFormQuestionProvider;
    showAfterOneOfTheseQuestions?: string[];
    inputType: DynamicInputType;
}
