import { Badge, Button, Stack, Typography } from '@common-components';
import { WarningAmber as WarningAmberIcon } from '@icons';
import { messages } from 'i18n';

interface RenewalBannerProps {
  onBannerDismiss: () => void;
}

export function RenewalBanner({ onBannerDismiss }: RenewalBannerProps) {
  const { message, description, dismiss } = messages.submissionWorkspace.renewalSubmission.banner;
  return (
    <Stack direction="row" p={1} justifyContent="space-between" bgcolor="warning.light">
      <Stack direction="row" gap={1}>
        <Badge>
          <WarningAmberIcon fontSize="small" sx={{ color: 'warning.main' }} />
        </Badge>
        <Stack direction="column">
          <Typography variant="body2Bold">{message}</Typography>
          <Typography variant="caption">{description}</Typography>
        </Stack>
      </Stack>
      <Button variant="text" color="inherit" onClick={onBannerDismiss}>
        {dismiss}
      </Button>
    </Stack>
  );
}
