// all states regrading DynamicFormLayout but needs to be saved in parent component, as the DynamicFormLayout is unmounted after each submit
import { useRef, useState } from 'react';
import { useScrollSections } from 'hooks';

export function useDynamicFormPersistentState() {
  // state that increments on every herald api update in order to rebuild the DynamicForm component and populate the form with updated default values returned from herald
  const [key, setKey] = useState(0);

  const [extractionValidationFields, setExtractionValidationFields] = useState<Record<string, boolean>>({});
  // impacted by submitting a conditional question that triggers the dynamicForm to mount again, it will scroll to the last position of the form so that there will be no scroll and the user will stay at the same place
  const [scrollPositionOnMount, setScrollPositionOnMount] = useState<number | undefined>();
  // impacted by changing section it changes the scroll position to the top of the section and then goes back to be undefined
  const [scrollPosition, setScrollPosition] = useState<number | undefined>();
  const [activeSection, setActiveSection] = useState<string | undefined>(undefined);
  // Ref to the scrollable container of the form
  const sectionRefs = useRef<Record<string, HTMLDivElement | null>>({});

  // Use onSetActiveSection for sidebar stepper click
  const onSetActiveSection = (section?: string) => {
    setActiveSection(section);
    if (section && sectionRefs.current[section]) {
      sectionRefs.current[section]?.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const scrollableDivRef = useRef<HTMLDivElement | null>(null);

  useScrollSections({
    scrollableDivRef,
    sectionRefs,
    activeSection,
    setActiveSection,
  });

  return {
    key,
    setKey,
    extractionValidationFields,
    setExtractionValidationFields,
    scrollPositionOnMount,
    setScrollPositionOnMount,
    scrollPosition,
    setScrollPosition,
    activeSection,
    setActiveSection,
    sectionRefs,
    scrollableDivRef,
    onSetActiveSection,
  };
}
