import { ExtractedAttribute } from '@common/types';
import { isEmpty, isEqual, isNil, isPlainObject } from 'lodash';
import { PromptResultFeatureType } from 'enums';
import { SuggestedValueProps } from 'hooks';
import { Suggestion } from 'types';
import {
  FormStateBaseProp,
  FormStateBaseProps,
} from 'broker/pages/SubmissionWorkspacePage/components/DynamicForm/DynamicFormLayout/types';

const areExistingObjectPropsEqual = (suggestionValue: any, formValue: FormStateBaseProps): boolean =>
  // if an object verify that comparing only properties that are participated in the form (not nil)
  isPlainObject(formValue.main) &&
  isPlainObject(suggestionValue) &&
  Object.keys(formValue.main).every(
    (key) =>
      isNil(formValue.main[key]) ||
      (isEmpty(formValue.main[key]) && isEmpty(suggestionValue[key])) ||
      isEqual(formValue.main[key], suggestionValue[key]),
  );

export function buildSuggestedValueProps(
  extractedAttribute: ExtractedAttribute<any>,
  promptResultFeature: PromptResultFeatureType,
): SuggestedValueProps {
  function setFormFieldSuggestedValue(value: any, oldValue?: FormStateBaseProps): FormStateBaseProps {
    if (oldValue) {
      return {
        ...oldValue,
        [FormStateBaseProp.Main]: value,
      };
    }
    return {
      [FormStateBaseProp.Main]: value,
    };
  }

  function isCurrentFormFieldEmpty(value?: FormStateBaseProps): any {
    return isPlainObject(value?.[FormStateBaseProp.Main])
      ? // check if all properties in object are empty
        Object.values(value?.[FormStateBaseProp.Main]).every((val) => isEmpty(val))
      : // check if value is not a number and is empty (number returns true for isEmpty)
        typeof value?.[FormStateBaseProp.Main] !== 'number' && isEmpty(value?.[FormStateBaseProp.Main]);
  }

  function isEqualToFormValue(suggestion: Suggestion, formValue?: FormStateBaseProps): boolean {
    return !!formValue && isPlainObject(formValue.main)
      ? areExistingObjectPropsEqual(suggestion.value, formValue)
      : isEqual(suggestion.value, formValue?.main);
  }

  return {
    suggestion: extractedAttribute,
    setSuggestedValue: setFormFieldSuggestedValue,
    isFormFieldEmpty: isCurrentFormFieldEmpty,
    isEqualToFormValue,
    promptResultFeature,
  };
}

export function buildSuggestedValueForArrayProps(
  extractedAttribute: Omit<ExtractedAttribute<any[]>, 'additionalResults'>,
  promptResultFeature: PromptResultFeatureType,
): SuggestedValueProps {
  function setFormArrayFieldSuggestedValue(values: any[], oldValue?: FormStateBaseProps[]): FormStateBaseProps[] {
    const valueElement = values.map((value) => ({
      [FormStateBaseProp.Main]: value,
    }));
    return oldValue ? [...valueElement, ...oldValue] : valueElement;
  }

  function isEqualToFormValue(suggestion: Suggestion, formValues?: FormStateBaseProps[]): boolean {
    return (
      Array.isArray(formValues) &&
      Array.isArray(suggestion.value) &&
      formValues.every(
        (formValue, index) =>
          suggestion.value[index] &&
          (isPlainObject(formValue.main)
            ? areExistingObjectPropsEqual(suggestion.value[index], formValue)
            : isEqual(suggestion.value[index], formValue.main)),
      )
    );
  }

  function isCurrentFormArrayFieldEmpty(value?: FormStateBaseProps[]): any {
    return !value || value.length === 0;
  }

  return {
    suggestion: extractedAttribute,
    setSuggestedValue: setFormArrayFieldSuggestedValue,
    isFormFieldEmpty: isCurrentFormArrayFieldEmpty,
    isEqualToFormValue,
    promptResultFeature,
  };
}
