import { MouseEvent, ReactNode } from 'react';
import { alpha, Badge, badgeClasses, ButtonBase, Divider, Stack, Theme, Tooltip, Typography } from '@common-components';
import { Group as GroupIcon, KeyboardArrowDown as KeyboardArrowDownIcon, Star as StarIcon } from '@icons';
import { isMarketOrganization } from 'enums';
import { iconFontSize } from 'themes';
import { isExternalRecipientGroup, Recipient, RecipientGroup } from 'broker/components/Emails/recipient-utils';
import { recipientFullName } from './Emails/utils';
import { getMarketNameWithType } from './UserMarkets/components/utils';

function getSingleRecipientDetails(recipientGroup: RecipientGroup) {
  if (isExternalRecipientGroup(recipientGroup)) {
    // If the recipient group is external, the name is the email address
    return recipientFullName(recipientGroup.recipients[0]);
  }
  return `${getMarketNameWithType(recipientGroup.name, recipientGroup.type)}, ${recipientFullName(
    recipientGroup.recipients[0],
  )}`;
}

function getSingleRecipientTooltip(recipient: Recipient) {
  return `${recipientFullName(recipient)}, ${recipient.email}`;
}

function getBorderColor(error: boolean, selected: boolean | undefined) {
  if (error) {
    return 'error.main';
  }

  return selected ? 'primary.main' : 'divider';
}

function getBackgroundColor(theme: Theme, error: boolean, selected: boolean | undefined) {
  if (error && selected) {
    return alpha(theme.palette.error.main, 0.12);
  }
  if (selected) {
    return alpha(theme.palette.primary.main, 0.06);
  }

  return 'inherit';
}

function getTextColor(error: boolean, selected: boolean | undefined) {
  if (error && selected) {
    return 'error.main';
  }

  return selected ? 'primary.main' : 'text.secondary';
}

function getHoverColor(theme: Theme, error: boolean, selected: boolean | undefined) {
  if (error && selected) {
    return alpha(theme.palette.error.main, 0.12);
  }
  if (error) {
    return alpha(theme.palette.error.main, 0.04);
  }

  return 'blueGrey.100';
}

const MarketStyles = (theme: Theme, error: boolean, selected?: boolean) => {
  const borderColor = getBorderColor(error, selected);
  return {
    border: 1,
    color: getTextColor(error, selected),
    borderColor,
    px: 1,
    py: '2px',
    borderRadius: '20px',
    maxWidth: '360px',
    height: '28px',
    alignItems: 'center',
    backgroundColor: getBackgroundColor(theme, error, selected),
    '&:hover': {
      backgroundColor: getHoverColor(theme, error, selected),
    },
  } as const;
};

interface RecipientGroupContactsProps {
  recipientGroup: RecipientGroup;
  isMenu?: boolean;
  hideContactsCount?: boolean;
  selected?: boolean;
  icon?: ReactNode;
  isNewEmailEditor?: boolean;
  onMenuTriggerClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  isSingleRecipient?: boolean;
}

export default function RecipientGroupContacts({
  recipientGroup,
  isMenu,
  hideContactsCount = false,
  selected = false,
  icon,
  isNewEmailEditor = false,
  onMenuTriggerClick,
}: RecipientGroupContactsProps) {
  const isMarket = isMarketOrganization(recipientGroup.type);

  const hasContacts = recipientGroup.recipients.length > 0;

  const getIcon = () => {
    if (icon) {
      return icon;
    }

    if (isNewEmailEditor) {
      return undefined;
    }

    return isMarket ? (
      <GroupIcon sx={{ color: hasContacts ? 'text.secondary' : 'error.main', fontSize: iconFontSize }} />
    ) : (
      <StarIcon sx={{ color: 'green.400', fontSize: iconFontSize }} />
    );
  };

  const borderColor = getBorderColor(!hasContacts, selected);

  return (
    <Tooltip
      tooltipContent={isMenu ? '' : getSingleRecipientTooltip(recipientGroup.recipients[0])}
      placement="right"
      arrow
    >
      <Stack key={recipientGroup.id} direction="row" sx={(theme: Theme) => MarketStyles(theme, !hasContacts, selected)}>
        {getIcon()}
        <>
          <Typography variant="captionBold" noWrap ml={0.5}>
            {isMenu
              ? getMarketNameWithType(recipientGroup.name, recipientGroup.type)
              : getSingleRecipientDetails(recipientGroup)}
          </Typography>
          {!hideContactsCount && <Divider flexItem orientation="vertical" sx={{ mx: 1, my: '-2px', borderColor }} />}
        </>

        {!hideContactsCount ? (
          <ButtonBase onClick={onMenuTriggerClick} disableRipple>
            <Badge
              badgeContent={recipientGroup.recipients.length}
              showZero
              sx={{
                [`& .${badgeClasses.badge}`]: {
                  position: 'relative',
                  transform: 'inherit',
                  backgroundColor: borderColor,
                  color: selected || !hasContacts ? 'common.white' : 'text.primary',
                },
              }}
            />
            {isMenu && <KeyboardArrowDownIcon sx={{ ml: '2px' }} />}
          </ButtonBase>
        ) : null}
      </Stack>
    </Tooltip>
  );
}
