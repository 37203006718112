import { ExtractedData } from '../../types';
import { normalizeDateExtraction } from './extraction-normalizers';

export function getFirstEffectiveDateExtraction(extractedData: ExtractedData) {
    return (
        (extractedData.effectiveDateCyber && normalizeDateExtraction(extractedData.effectiveDateCyber)) ||
        (extractedData.effectiveDateGL && normalizeDateExtraction(extractedData.effectiveDateGL)) ||
        (extractedData.effectiveDatePL && normalizeDateExtraction(extractedData.effectiveDatePL)) ||
        (extractedData.effectiveDateML && normalizeDateExtraction(extractedData.effectiveDateML))
    );
}
