import { compact, isEmpty, toNumber } from 'lodash';
// eslint-disable-next-line import/no-unresolved
import { Content } from 'pdfmake/interfaces';
import { PartialSubmission } from 'types';
import { formatPrice, htmlToPdfMakeWrapper, logger } from 'utils';
import { QuoteFormCreate } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/Quote/form-methods/types';
import { getExpiresAtDate } from './helpers';

interface PdfContentProps {
  submission: PartialSubmission;
  quoteForm: QuoteFormCreate;
  marketName: string;
}
const currency = '$';

export const getPdfContent = ({ submission, quoteForm, marketName }: PdfContentProps): Content => {
  logger.log('info', { message: 'getPdfContent arguments', submissionId: submission.id, quoteForm, marketName });
  return compact([
    {
      text: [{ text: 'Quote Expiration Date: ', bold: true }, { text: getExpiresAtDate(quoteForm.expiresAt) }],
      alignment: 'right',
      font: 'Inter', // Make sure Inter is registered with pdfmake
      fontSize: 12,
      margin: [0, 0, 0, 10], // [left, top, right, bottom]
      color: 'black',
    },
    {
      text: 'Premium',
      bold: true,
    },
    {
      text: [{ text: 'Premium: ' }, { text: currency + formatPrice(Number(quoteForm.premium), 2) }],
    },
    quoteForm.triaPremium && {
      text: [{ text: 'TRIA premium: ' }, { text: currency + formatPrice(Number(quoteForm.triaPremium), 2) }],
    },
    {
      text: '\n',
      lineHeight: 1.1,
    },
    {
      text: 'Taxes & Fees',
      bold: true,
    },
    quoteForm.carrierFee && {
      text: [{ text: 'Capacity provider fee: ' }, { text: currency + formatPrice(Number(quoteForm.carrierFee), 2) }],
    },
    quoteForm.flowBrokerFee && {
      text: [{ text: 'Flow broker fee: ' }, { text: currency + formatPrice(Number(quoteForm.flowBrokerFee), 2) }],
    },
    quoteForm.surplusLineTax && {
      text: [{ text: 'Surplus line tax: ' }, { text: currency + formatPrice(Number(quoteForm.surplusLineTax), 2) }],
    },
    quoteForm.surplusStampingFee && {
      text: [
        { text: 'Surplus stamping fee: ' },
        { text: currency + formatPrice(Number(quoteForm.surplusStampingFee), 2) },
      ],
    },
    {
      text: '\n',
      lineHeight: 1.1,
    },
    {
      text: [
        { text: 'Total: ', bold: true },
        {
          text:
            currency +
            formatPrice(
              toNumber(quoteForm.premium || 0) +
                toNumber(quoteForm.triaPremium || 0) +
                toNumber(quoteForm.carrierFee || 0) +
                toNumber(quoteForm.flowBrokerFee || 0) +
                toNumber(quoteForm.surplusLineTax || 0) +
                toNumber(quoteForm.surplusStampingFee || 0),
              2,
            ),
        },
      ],
    },
    {
      text: '\n',
      lineHeight: 1.1,
    },
    {
      text: 'Please refer to the attached for a full breakdown of limits, retentions, and applicable clauses.',
    },
    {
      text: '\n',
      lineHeight: 1.1,
    },
    quoteForm.insights && {
      text: 'Quote Analysis:',
      bold: true,
    },
    quoteForm.insights ? htmlToPdfMakeWrapper(quoteForm.insights) : undefined,
    {
      text: '\n',
      lineHeight: 1.1,
    },
    quoteForm.subjectivities?.some((subjectivity) => !isEmpty(subjectivity.value)) && {
      text: `Subjectivities:`,
      bold: true,
    },
    {
      ul: compact(quoteForm.subjectivities?.map((subjectivity) => subjectivity.value) || []),
    },
    {
      text: '\n',
      lineHeight: 1.1,
    },
    quoteForm.stateCompliance && {
      text: 'State Compliance:',
      bold: true,
    },
    quoteForm.stateCompliance ? htmlToPdfMakeWrapper(quoteForm.stateCompliance) : undefined,
    {
      text: '\n',
      lineHeight: 1.1,
    },
    {
      text: 'Subjectivities/conditions must be submitted for review within the timeframe specified. This quote is issued based on the truthfulness and accuracy of the information provided on the insurance application. The insurer reserves the right to modify the final terms and conditions or withdraw this offer based on review of the application and any other subjectivities.',
    },
    {
      text: '\n',
      lineHeight: 1.1,
    },
    {
      text: [
        { text: 'Cancellation: ', bold: true },
        {
          text: 'Insurance coverage cannot be cancelled flat; premium must be paid for the time insurance has been in force. ',
        },
        {
          text: 'Coverage may be cancelled by either the insured or the insurer by written notice to the other and is subject to cancellation terms specified in the attached.',
          decoration: 'underline',
        },
      ],
    },
    {
      text: '\n',
      lineHeight: 1.1,
    },
    {
      text: 'Thank you for the opportunity to partner with you. We appreciate your business and hope that we can be of further service to you in the future.  Please contact us with any questions.',
    },
  ]);
};
