import { useEffect } from 'react';
import { HotjarEvents } from 'utils/hotjar-events';
import { submissionTypeConfig, submissionTypes } from 'broker/configuration-mappers/submission-type-config';
import useSubmissionFilter from './useSubmissionFilter';
import { SubmissionBoxStorageKeys, SubmissionsViewStorageKeys, SubmissionViewMode } from './utils';

const getBaseTypeFiltersList = () =>
  submissionTypes.map((type) => ({
    key: type,
    checked: true,
    label: submissionTypeConfig[type].text,
  }));

export function useTypeFilter({ mode, paginationReset }: { paginationReset: () => void; mode: SubmissionViewMode }) {
  const storageKey =
    mode === SubmissionViewMode.General ? SubmissionsViewStorageKeys.type : SubmissionBoxStorageKeys.type;

  const baseTypeFilters = getBaseTypeFiltersList();

  const typeFilter = useSubmissionFilter({
    baseFiltersList: baseTypeFilters,
    storageKey,
    hotjarEvent: HotjarEvents.SubmissionsTypeFilter,
    paginationReset,
  });

  // First time the filters are loaded from the local storage, we need to make sure that all the type filters are present
  // This is relevant when we add a new type in the code that didn't exist before, thus missing in the local storage
  // By default, the new type filter will be turned off
  useEffect(() => {
    if (typeFilter.filters.length !== baseTypeFilters.length) {
      typeFilter.setFilters((prev) =>
        baseTypeFilters.map((type) => ({
          key: type.key,
          checked: prev.find((localStorageType) => localStorageType.key === type.key)?.checked ?? false,
          label: type.label,
        })),
      );
    }
  }, [typeFilter, baseTypeFilters]);

  return typeFilter;
}
