import { getIsHeraldSubmission } from '@common/utils';
import { isEmpty } from 'lodash';
import { HeraldStatus } from 'enums';
import { PartialSubmission, SubmissionBlockingMarketingFields } from 'types';
import { SubmissionFieldConfigProps, submissionFieldsConfig } from './submission-fields-config';

interface SubmissionFieldsConfigListItem extends SubmissionFieldConfigProps {
  fieldName: keyof PartialSubmission;
  isBlocking: boolean;
}
export const submissionFieldsConfigList: SubmissionFieldsConfigListItem[] = (
  Object.keys(submissionFieldsConfig) as (keyof PartialSubmission)[]
).map((fieldName) => ({
  isStaticSubmissionRequiredField: submissionFieldsConfig[fieldName]!.isStaticSubmissionRequiredField,
  label: submissionFieldsConfig[fieldName]!.label,
  fieldName,
  isBlocking: Object.values(SubmissionBlockingMarketingFields).some((val) => val === fieldName),
}));

export const isValueFilled = (value: any) =>
  // isEmpty for number/boolean always return true
  typeof value === 'number' || typeof value === 'boolean' ? true : !isEmpty(value);

export const isFieldFilled = <T>(entity: T, field: keyof T) => {
  const fieldVal = entity[field];
  // isEmpty for number/boolean always return true
  return isValueFilled(fieldVal);
};

// util in order to get all the fields that are blocking the submission from marketing (the one's that are empty)
export const getSubmissionBlockingMarketingFields = (submission: PartialSubmission) =>
  submissionFieldsConfigList.filter((config) => config.isBlocking && !isFieldFilled(submission, config.fieldName));

// util in order to get all the fields that are classified as required for static submission (red banner in pre-marketing will appear if they are missing)
export const getStaticSubmissionMissingRequiredFields = (submission: PartialSubmission) =>
  submissionFieldsConfigList.filter(
    (config) => config.isStaticSubmissionRequiredField && !isFieldFilled(submission, config.fieldName),
  );

// util in order to get all the fields that are classified as optional for static submission (orange banner in pre-marketing will appear if they are missing)
export const getStaticSubmissionMissingOptionalFields = (submission: PartialSubmission) =>
  submissionFieldsConfigList.filter(
    (config) => !config.isStaticSubmissionRequiredField && !isFieldFilled(submission, config.fieldName),
  );

export const getAreSubmissionMarketingBlockingFieldsFilled = (submission: PartialSubmission) =>
  !getSubmissionBlockingMarketingFields(submission).length;

export const isSubmissionMissingRequiredFields = (partialSubmission: PartialSubmission) =>
  getIsHeraldSubmission(partialSubmission)
    ? partialSubmission.heraldData?.status !== HeraldStatus.Complete
    : getStaticSubmissionMissingRequiredFields(partialSubmission).length > 0;

export const isIncompleteApiApplication = (partialSubmission: PartialSubmission | null) =>
  !!(
    partialSubmission &&
    getIsHeraldSubmission(partialSubmission) &&
    partialSubmission.heraldData?.status !== HeraldStatus.Complete
  );
