import { isEmpty } from 'lodash';
import { useState } from 'react';
import { flushSync } from 'react-dom';
import { LoaderOverlay, Stack, Typography } from '@common-components';
import { BoxTemplateLabels, FileType, FormMode, SubmissionStatus } from 'enums';
import { useMount, useMutatePdf, useToast } from 'hooks';
import { useMutateBoxItems } from 'hooks/api/box';
import { messages } from 'i18n';
import { Submission } from 'types';
import { logger } from 'utils';
import Banner, { BannerMode } from 'broker/components/Banner';
import ContentPreview from 'broker/components/box/ContentPreview';
import { useNavigateBack } from 'broker/hooks';
import InnerPageLayout from 'broker/pages/SubmissionWorkspacePage/components/InnerPage/InnerPageLayout';
import { FlowGenerationStatus } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/Quote/types';
import { useStatusChangeDialog } from 'broker/pages/SubmissionWorkspacePage/dialogs/SubmissionStatusChangeDialog/useStatusChangeDialog';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import {
  EncryptedFileError,
  usePDFCreateAndCombine,
} from 'broker/pages/SubmissionWorkspacePage/utils/pdf-creator/usePDFCreateAndCombine';

import { getPdfContent } from './pdf-creator/pdf-content';
import { FlowBinderLocationState } from './utils';

interface ContentProps {
  locationState: FlowBinderLocationState;
  submission: Submission;
}

export default function Content({ locationState, submission }: ContentProps) {
  const { selectedQuote, marketName, marketBinderFileIds, policyDetails, flowCommission, mode, binderDate } =
    locationState;
  const { deleteFile } = useMutateBoxItems();
  const createAndCombinePDF = usePDFCreateAndCombine();
  // undefined means error
  const [errorMessage, setErrorMessage] = useState('');
  const { decryptPdf } = useMutatePdf();
  const navigateBack = useNavigateBack();

  const removeDecryptionFromFile = async (fileDetails: { fileId: string; fileName: string }) => {
    logger.log('info', {
      message: 'Encrypted File - Attempting to Decrypt',
      ...fileDetails,
    });
    await decryptPdf.mutateAsync({
      boxItemId: fileDetails.fileId,
      folderId: submission?.boxFolderId || '',
    });
    logger.log('info', { message: 'Successfully decrypted file', ...fileDetails });
  };
  const [flowGenerationStatus, setFlowGenerationStatus] = useState<FlowGenerationStatus>(FlowGenerationStatus.Idle);
  const [flowBinderIds, setFlowBinderIds] = useState<string[]>(selectedQuote?.flowBinderFileIds || []);
  const hasError = flowGenerationStatus === FlowGenerationStatus.Error;
  const { showToast } = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDirty, setIsDirty] = useState(mode === FormMode.create);

  const { bindQuote } = useSubmissionsWorkspace();

  const { showStatusChangeDialog } = useStatusChangeDialog();

  const onCreateFlowBinder = async () => {
    setIsSubmitting(true);
    try {
      await bindQuote(
        selectedQuote.id,
        marketBinderFileIds,
        flowBinderIds,
        policyDetails,
        flowCommission,
        {
          stateCompliance: locationState.binderStateCompliance,
          carrierSpecific: locationState.binderSubjectivities,
        },
        isEmpty(binderDate) ? undefined : binderDate,
      );
      showToast('success', {
        message: messages.quoteStatusModal.bound.successMessage(selectedQuote.marketName!),
      });
      // Force React to flush the dirty flag update before we navigate away
      flushSync(() => {
        setIsDirty(false);
      });
      showStatusChangeDialog({
        current: submission!.status,
        newStatus: SubmissionStatus.Issuing,
      });
      navigateBack();
    } catch (e) {
      setIsSubmitting(false);
    }
  };

  const createFlowBinder = async () => {
    if (mode !== FormMode.view) {
      setFlowGenerationStatus(FlowGenerationStatus.Loading);

      const content = getPdfContent({ submission, details: locationState, marketName });

      createAndCombinePDF(
        [
          {
            pdfContentProps: {
              content,
            },
          },
          ...marketBinderFileIds.map((boxFileId) => ({ boxFileId })),
        ],
        {
          parentFolderId: submission.boxFolderId,
          fileName: `flow_binder_${marketName.toLowerCase()}.pdf`,
          metadata: { [BoxTemplateLabels.FileType]: FileType.CapitolaBinder },
        },
      )
        .then((flowBinderBoxItem) => {
          if (flowBinderBoxItem) {
            setFlowBinderIds([flowBinderBoxItem.id]);
            setFlowGenerationStatus(FlowGenerationStatus.Idle);
          } else {
            logger.log('error', {
              message: 'generateAndUploadFlowPDF has ended with no boxItem',
              flowQuoteBoxItem: flowBinderBoxItem,
            });
            setFlowGenerationStatus(FlowGenerationStatus.Error);
          }
        })
        .catch(async (e) => {
          if (e instanceof EncryptedFileError) {
            const fileDetails = { fileId: e.fileId, fileName: e.fileName };
            try {
              await removeDecryptionFromFile(fileDetails);
              await createFlowBinder();
            } catch (_e) {
              logger.log('error', { message: 'Failed to decrypt file', ...fileDetails });
              setFlowGenerationStatus(FlowGenerationStatus.Error);
              setErrorMessage(messages.addQuotePage.flowQuoteError.getEncryptedFileError(fileDetails.fileName));
              throw _e;
            }
          } else {
            setFlowGenerationStatus(FlowGenerationStatus.Error);
            throw e;
          }
        });
    }
  };

  // Execute the PDF download on component mount
  useMount(() => {
    createFlowBinder();
  });

  const getContent = () => {
    if (hasError) {
      return (
        <Stack mt={2}>
          <Banner
            title={messages.addQuotePage.flowQuoteError.title}
            subtitle={errorMessage || messages.addQuotePage.flowQuoteError.defaultDescription}
            mode={BannerMode.Error}
          />
        </Stack>
      );
    }
    if (flowGenerationStatus === FlowGenerationStatus.Loading) {
      return <LoaderOverlay hideOverlay />;
    }
    return flowBinderIds ? <ContentPreview fileId={flowBinderIds[0]} hasHeader={false} sx={{ height: 1 }} /> : null;
  };

  return (
    <InnerPageLayout
      outerDirtyState={{
        isDirty,
        setIsDirty,
      }}
      onDiscardCallback={async () => {
        if (flowBinderIds.length > 0 && mode === FormMode.create) {
          await Promise.all(flowBinderIds.map((flowBinderId) => deleteFile.mutateAsync({ fileId: flowBinderId })));
          setFlowBinderIds([]);
        }
      }}
      className="cap-flow-binder"
      title={`${messages.general.flowBinder} ${marketName}`}
      {...(mode !== FormMode.view && {
        footerButtons: {
          proceedButton: () => ({
            children: messages.buttons.saveFlowBinder,
            onClick: () => onCreateFlowBinder(),
            loading: isSubmitting,
            disabled: !flowBinderIds.length,
          }),
          cancelButton: (innerPageProps) => ({
            children: messages.buttons.cancel,
            onClick: () => {
              innerPageProps.onClose();
            },
          }),
        },
      })}
    >
      {() => (
        <Stack height={1} py={4} px={12}>
          <Stack ml={hasError ? 0 : 2} mb={hasError ? 1 : 3}>
            <Typography variant="body1Bold">{messages.general.flowBinder}</Typography>
          </Stack>
          {getContent()}
        </Stack>
      )}
    </InnerPageLayout>
  );
}
