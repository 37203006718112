import { Icon, IconButton, Stack, Typography } from '@common-components';
import { AutoAwesomeOutlined, Close } from '@icons';

export function AIBubbleHeader({ onClick }: { onClick: () => void }) {
  return (
    <Stack
      direction="row"
      position="sticky"
      top={0}
      justifyContent="space-between"
      alignItems="center"
      height={48}
      bgcolor="green.300"
      p={1}
    >
      <Stack direction="row">
        <Icon component={AutoAwesomeOutlined} sx={{ fontSize: 16, mr: 1, alignSelf: 'center' }} />
        <Typography variant="h4">Submission AI Summary</Typography>
      </Stack>
      <IconButton icon={Close} onClick={onClick} variant="text" color="secondary" />
    </Stack>
  );
}
