import { compact, first, partition } from 'lodash';
import { useCallback } from 'react';
import { InboundEmailMessageStatus } from 'enums';
import { useBoxClient } from 'hooks';
import { useSearchInboundEmailMessage } from 'hooks/api/inboundEmailMessage';
import { ProductEmailSignals } from 'types';
import { getFileLabel, logger } from 'utils';
import { useGetSubmissionCreationEmail } from 'broker/pages/SubmissionWorkspacePage/hooks/useGetSubmissionCreationEmail';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { EnrichedFile, ThreadEnrichmentData } from './types';

export const useResolveEnrichmentData = () => {
  const { submissionCreationEmail } = useGetSubmissionCreationEmail();

  const { partialSubmission } = useSubmissionsWorkspace();
  const { items } = useSearchInboundEmailMessage({
    filter: {
      submissionId: partialSubmission?.id,
      excludedStatus: InboundEmailMessageStatus.Processing,
    },
    enabled: !!partialSubmission?.id,
  });

  const boxClient = useBoxClient();

  return useCallback(
    async (
      uploadedFileIds?: string[],
      inboundEmailIds?: string[],
      productsEmailSignals?: ProductEmailSignals[],
    ): Promise<ThreadEnrichmentData> => {
      const [inboundEmails, submissionEmails] = partition(
        items.filter((email) => inboundEmailIds?.includes(email.id)),
        (email) => email.id !== submissionCreationEmail?.id,
      );

      const fetchedFiles = (await Promise.all(
        compact(
          uploadedFileIds?.map(async (fileId) => {
            const file = await boxClient.getFile(fileId, { includeMetadata: true });
            if (!file) {
              logger.log('warning', `File with id ${fileId} not found in Box. Skipping.`);
              return undefined;
            }
            return { id: fileId, name: file.name, label: getFileLabel(file) };
          }),
        ) ?? [],
      )) as Array<EnrichedFile>;

      return {
        inboundEmails,
        submissionCreationEmail: first(submissionEmails),
        files: fetchedFiles,
        productsEmailSignals,
      };
    },
    [boxClient, items, submissionCreationEmail],
  );
};
