import { InboundEmailMessageClient } from 'clients';
import { QueryKeys } from 'enums';
import {
  InboundEmailMessage,
  InboundEmailMessageAssignToSubmission,
  InboundEmailMessageSearchItem,
  InboundEmailMessageSearchParams,
} from 'types';
import { UseQueryBaseRetrieveOptions, UseQueryBaseSearchOptions } from './query/types';
import { useMutate, useQueryMutation } from './query/useMutate';
import { useQueryRetrieve, useQuerySearch } from './query/useQuery';

export function useSearchInboundEmailMessage(
  props?: UseQueryBaseSearchOptions<InboundEmailMessageSearchItem, InboundEmailMessageSearchParams>,
) {
  const { filter, queryOptions, enabled } = props || {};
  return useQuerySearch({
    queryKey: QueryKeys.InboundEmailMessage,
    clientClass: InboundEmailMessageClient,
    filter,
    enabled,
    queryOptions: {
      ...queryOptions,
    },
  });
}

export function useRetrieveInboundEmailMessage(props: UseQueryBaseRetrieveOptions<InboundEmailMessage>) {
  const { id, queryOptions, enabled } = props;
  return useQueryRetrieve({
    queryKey: QueryKeys.InboundEmailMessage,
    clientClass: InboundEmailMessageClient,
    id,
    enabled,
    queryOptions: {
      ...queryOptions,
    },
  });
}

export function useMutateInboundEmailMessage() {
  const {
    update: updateInboundEmailMessage,
    destroy: deleteInboundEmailMessage,
    client,
  } = useMutate<InboundEmailMessage, InboundEmailMessageSearchItem, InboundEmailMessage, InboundEmailMessageClient>({
    queryKey: QueryKeys.InboundEmailMessage,
    clientClass: InboundEmailMessageClient,
    createOptions: { throwOnError: true },
  });

  const assignInboundEmailToSubmission = useQueryMutation<InboundEmailMessage, InboundEmailMessageAssignToSubmission>(
    ({ submissionId, inboundEmailMessageId }) => client.assignToSubmission({ submissionId, inboundEmailMessageId }),
    QueryKeys.InboundEmailMessage,
  );

  return {
    updateInboundEmailMessage,
    assignEmailToSubmission: assignInboundEmailToSubmission,
    deleteInboundEmailMessage,
  };
}
