import boxMessages from 'box-ui-elements/i18n/en-US';
import { messages } from 'i18n';

export default function useBoxMessages() {
  return {
    ...boxMessages,
    // The below messages are no longer in use since we are not using the Box files uploader anymore
    // keeping it here as an example of how to override the default Box messages
    'be.uploadEmptyWithFolderUploadDisabled': messages.box.dragAndDrop,
    'be.upload': messages.box.uploadFiles,
    'be.uploadEmptyFileInput': messages.box.uploadEmptyFileInput,
  };
}
