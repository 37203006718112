import { BoxTemplateLabels, FileType } from 'enums';
import { PartialSubmission, Quote } from 'types';
import {
  getRetailerFullName,
  getTodayDate,
} from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/Quote/components/formSteps/FlowQuote/pdf-creator/helpers';
import { InsightsData } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/QuoteComparisonInsights/form-methods/types';
import { getPdfBackCover } from 'broker/pages/SubmissionWorkspacePage/utils/pdf-creator/pdf-back-cover';
import { getPdfCover } from 'broker/pages/SubmissionWorkspacePage/utils/pdf-creator/pdf-cover';
import { usePDFCreateAndCombine } from 'broker/pages/SubmissionWorkspacePage/utils/pdf-creator/usePDFCreateAndCombine';
import { getPdfContent } from './pdf-content';

function getFileName(quotes: Quote[]) {
  return `Comparison_${quotes.map((quote) => quote.marketName?.toLowerCase()).join('_')}.pdf`;
}

function getShortFileName(quotes: Quote[]) {
  return `Comparison_${quotes.length}_quotes.pdf`;
}

function getMarketIds(quotes: Quote[]) {
  return quotes.map((quote) => quote.submissionMarketId).join(',');
}

export function useGenerateAndUploadComparisonPDF() {
  const pdFCreateAndCombine = usePDFCreateAndCombine();

  async function generateAndUploadComparisonPDF(
    submission: PartialSubmission,
    quotes: Quote[],
    insights: InsightsData,
  ) {
    const marketIds = getMarketIds(quotes);
    const coverPage = await getPdfCover({
      title: `Quote Comparison \n Prepared on behalf of ${getRetailerFullName(submission.user)}`,
      subtitle1: `Delivery Date: ${getTodayDate()}`,
    });
    const nextStepsPage = await getPdfBackCover({
      headerContent: [],
      titleText: 'Next Steps!',
      subtitleText: 'Please let us know your decisions or if you need any help presenting to your client.',
    });
    const content = getPdfContent({ submission, quotes, insights });
    return pdFCreateAndCombine(
      [
        {
          pdfContentProps: coverPage,
        },
        {
          pdfContentProps: {
            content,
          },
        },
        {
          pdfContentProps: {
            content: nextStepsPage,
          },
        },
      ],
      {
        parentFolderId: submission.boxFolderId,
        fileName: getFileName(quotes),
        alternativeShortFileName: getShortFileName(quotes),
        metadata: { [BoxTemplateLabels.FileType]: FileType.QuoteComparison, [BoxTemplateLabels.MarketId]: marketIds },
      },
    );
  }

  return generateAndUploadComparisonPDF;
}
