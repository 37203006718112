import { EmailMessageRecipientType } from 'enums';
import { messages } from 'i18n';
import { Recipient } from './recipient-utils';

export const extractEmailsAndNames = (recipients: string, emailMessageRecipientType: EmailMessageRecipientType) => {
  const recipientsArray = recipients.split(',').map((recipient) => recipient.trim());

  return recipientsArray.map((recipient) => {
    // extract name and address, supporting the following formats:
    // 1. "John Doe <jhon@example.com>"
    // 2. "<jhon@example.com>"
    // 3. "jhon@example.com"
    const match = recipient.match(/(.*)<(.*)>/);

    // If there is no match this is the third case above, only email address
    if (!match) {
      return { name: '', address: recipient.toLocaleLowerCase(), type: emailMessageRecipientType };
    }

    const name = match[1].trim() || '';
    const address = match[2].trim().toLocaleLowerCase();

    return { name, address, type: emailMessageRecipientType };
  });
};

export function recipientFullName(recipient: Recipient) {
  const { firstName, lastName, isSubmissionBox } = recipient;

  if (isSubmissionBox && !firstName && !lastName) {
    return messages.general.submissionBox;
  }

  if (!firstName && !lastName) {
    return recipient.email;
  }

  return `${firstName} ${lastName}`;
}
