import { Stack } from '@common-components';
import { messages } from 'i18n';
import InformationTooltip from 'broker/components/InformationTooltip/InformationTooltip';
import StartMarketingButton from 'broker/pages/SubmissionWorkspacePage/components/TabsPanel/common/MarketRequestsWorkspace/components/StartMarketingButton';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { useUiStoreActions, useUiStoreState } from 'broker/pages/SubmissionWorkspacePage/ui-store/uiStoreProvider';

export default function Header() {
  const { startMarketingIndicator } = useUiStoreState();

  const { setStartMarketingIndicator } = useUiStoreActions();
  const { partialSubmission } = useSubmissionsWorkspace();

  return (
    <Stack py={2} px={2} gap={1}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        {messages.submissionWorkspace.tabs.bor.title}
        <Stack gap={1} direction="row" alignItems="center" justifyContent="center">
          <InformationTooltip
            isOpen={startMarketingIndicator.showStartMarketingIndicator && !partialSubmission?.isCopilotEnabled}
            onClose={() => {
              setStartMarketingIndicator({ showStartMarketingIndicator: false, submissionId: partialSubmission!.id });
            }}
            title={messages.submissionWorkspace.marketRequestsWorkspace.startMarketingInfoToolbox.title}
            message={messages.submissionWorkspace.marketRequestsWorkspace.startMarketingInfoToolbox.message}
            width={328}
          >
            <StartMarketingButton placement="bottom-right" buttonProps={{ variant: 'contained' }} />
          </InformationTooltip>
        </Stack>
      </Stack>
    </Stack>
  );
}
