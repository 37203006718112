import { Link, LoaderOverlay, Popover, Stack, Theme, Typography } from '@common-components';
import { useCurrentUser } from 'hooks';
import { activeTabUrlParamValues, BrokerUrlParams } from 'broker/broker-routes';
import { useUpdateQueryParam } from 'broker/hooks';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { AIBubbleHeader } from './AIBubbleHeader';
import { AIBubbleItem } from './AIBubbleItem';
import { useGetFilesAndEmails } from './useGetFilesAndEmails';

interface RenewalDemoAIBubbleProps {
  anchorEl: HTMLElement | null;
  open: boolean;
  onClose: () => void;
}

const PopupMinHeight = 720;
const PopupMaxHeight = '80vh';
const PopupWidth = 720;

const INCUMBENT_MARKET = 'Cowbell';
const QUOTING_MARKETS = ['CFC', 'Coalition'];
const DECLINED_MARKETS = ['GAIG', 'Travelers'];

export function RenewalDemoAIBubble({ anchorEl, open, onClose }: RenewalDemoAIBubbleProps) {
  const { me } = useCurrentUser();
  const { partialSubmission } = useSubmissionsWorkspace();
  const {
    application,
    isLoading: submissionFilesAndEmailsLoading,
    solicitationEmail,
    expiringPolicy,
    marketingEmails,
    quoteComparison,
    marketQuotes,
    proposalEmail,
  } = useGetFilesAndEmails(partialSubmission);

  const updateQueryParam = useUpdateQueryParam();

  const onLinkClicked = (entityId: string | undefined, type: 'email' | 'file') => {
    if (!entityId) {
      return;
    }

    const activeTab = type === 'email' ? activeTabUrlParamValues.EMAIL : activeTabUrlParamValues.FILES;
    const paramName = type === 'email' ? BrokerUrlParams.SELECTED_EMAIL : BrokerUrlParams.SELECTED_FILE;

    const addParams = [
      { queryParam: BrokerUrlParams.ACTIVE_TAB, value: activeTab },
      { queryParam: paramName, value: entityId },
    ];

    updateQueryParam({
      removeParams: [BrokerUrlParams.ACTIVE_TAB, BrokerUrlParams.SELECTED_EMAIL, BrokerUrlParams.SELECTED_FILE],
      addParams,
    });

    onClose();
  };

  return (
    <Popover
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      PaperProps={{
        sx: {
          width: PopupWidth,
          minHeight: PopupMinHeight,
          maxHeight: PopupMaxHeight,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
      sx={{
        zIndex: (theme: Theme) => theme.zIndex.tooltip,
        left: -10,
      }}
    >
      <AIBubbleHeader onClick={onClose} />
      {submissionFilesAndEmailsLoading ? (
        <LoaderOverlay />
      ) : (
        <Stack m={2} gap={1}>
          <Typography
            variant="body2"
            mb={2}
          >{`Hey ${me?.firstName}, let’s get you up to speed on this submission.`}</Typography>
          <AIBubbleItem
            description={
              <Typography variant="body2">
                {`I imported and processed the account information, including the `}
                <Link
                  underline="hover"
                  sx={{ cursor: 'pointer' }}
                  onClick={() => onLinkClicked(expiringPolicy?.id, 'file')}
                >
                  expiring policy
                </Link>
                .
              </Typography>
            }
            date={new Date('2025-03-04T00:00:00Z')}
          />
          <AIBubbleItem
            description={
              <Typography variant="body2">
                {`I noticed no Renewal Application exists yet, so I sent a `}
                <Link
                  underline="hover"
                  sx={{ cursor: 'pointer' }}
                  onClick={() => onLinkClicked(solicitationEmail?.id, 'email')}
                >
                  solicitation email
                </Link>
                {` to the insured. They responded with a `}
                <Link
                  underline="hover"
                  sx={{ cursor: 'pointer' }}
                  onClick={() => onLinkClicked(application?.id, 'file')}
                >
                  Renewal Application
                </Link>
                .
              </Typography>
            }
            date={new Date('2025-03-18T00:00:00Z')}
          />
          <AIBubbleItem
            description={
              <Typography variant="body2">
                {`Based on the risk, existing coverage, and the placement process, we should start with the incumbent before deciding whether to submit to more markets. Here is the remarketing `}
                <Link
                  underline="hover"
                  sx={{ cursor: 'pointer' }}
                  onClick={() =>
                    onLinkClicked(
                      marketingEmails.find((email) => email.recipientOrganization?.name.includes(INCUMBENT_MARKET))?.id,
                      'email',
                    )
                  }
                >
                  email
                </Link>
                {` to the incumbent, ${INCUMBENT_MARKET}.`}
              </Typography>
            }
            date={new Date('2025-03-18T00:00:00Z')}
          />
          <AIBubbleItem
            description={
              <Typography variant="body2">
                {`The incumbent’s (${INCUMBENT_MARKET}) `}
                <Link
                  underline="hover"
                  sx={{ cursor: 'pointer' }}
                  onClick={() =>
                    onLinkClicked(marketQuotes.find((file) => file.name.includes(INCUMBENT_MARKET))?.id, 'file')
                  }
                >
                  renewal quote
                </Link>
                {` is 7% premium increase, which according to the configuration, requires submitting to new markets. Therefore, I re-marketed it to ${[
                  ...QUOTING_MARKETS,
                  ...DECLINED_MARKETS,
                ].join(', ')}.`}
              </Typography>
            }
            date={new Date('2025-03-18T00:00:00Z')}
          />
          <AIBubbleItem
            description={
              <Typography variant="body2">
                {'I received a quote from '}
                <Link
                  underline="hover"
                  sx={{ cursor: 'pointer' }}
                  onClick={() =>
                    onLinkClicked(marketQuotes.find((file) => file.name.includes(QUOTING_MARKETS[0]))?.id, 'file')
                  }
                >
                  {QUOTING_MARKETS[0]}
                </Link>
                {' and '}
                <Link
                  underline="hover"
                  sx={{ cursor: 'pointer' }}
                  onClick={() =>
                    onLinkClicked(marketQuotes.find((file) => file.name.includes(QUOTING_MARKETS[1]))?.id, 'file')
                  }
                >
                  {QUOTING_MARKETS[1]}
                </Link>
                {`, and a declination has been obtained from ${DECLINED_MARKETS.join(
                  ' and ',
                )}. The renewal date is in 30 days, so I prepared a `}
                <Link
                  underline="hover"
                  sx={{ cursor: 'pointer' }}
                  onClick={() => onLinkClicked(quoteComparison?.id, 'file')}
                >
                  proposal
                </Link>
                {` and `}
                <Link
                  underline="hover"
                  sx={{ cursor: 'pointer' }}
                  onClick={() => onLinkClicked(proposalEmail?.id, 'email')}
                >
                  sent
                </Link>
                {` it to ${partialSubmission?.user?.firstName}.`}
              </Typography>
            }
            date={new Date('2025-03-27T00:00:00Z')}
          />
        </Stack>
      )}
    </Popover>
  );
}
