export enum FlowQuestionId {
    // eslint-disable-next-line custom-rules/equal-enum-keys-and-values
    InsuredName = 'flow_insured_name',
    // eslint-disable-next-line custom-rules/equal-enum-keys-and-values
    Industry = 'flow_industry',
    // eslint-disable-next-line custom-rules/equal-enum-keys-and-values
    PrimaryAddress = 'flow_primary_address',
    // eslint-disable-next-line custom-rules/equal-enum-keys-and-values
    MailingAddress = 'flow_mailing_address',
    // eslint-disable-next-line custom-rules/equal-enum-keys-and-values
    InsuredCompanyType = 'flow_insured_company_type',
    // eslint-disable-next-line custom-rules/equal-enum-keys-and-values
    InsuredEmployeeCount = 'flow_insured_employee_count',
    // eslint-disable-next-line custom-rules/equal-enum-keys-and-values
    InsuredAnnualRevenue = 'flow_insured_annual_revenue',
    // eslint-disable-next-line custom-rules/equal-enum-keys-and-values
    InsuredWebsite = 'flow_insured_website',
    // eslint-disable-next-line custom-rules/equal-enum-keys-and-values
    Limit = 'flow_limit',
    // eslint-disable-next-line custom-rules/equal-enum-keys-and-values
    NeedByDate = 'flow_need_by_date',
    // eslint-disable-next-line custom-rules/equal-enum-keys-and-values
    EffectiveDate = 'flow_effective_date',
    // eslint-disable-next-line custom-rules/equal-enum-keys-and-values
    AttorneyNumber = 'flow_attorney_number',
    // eslint-disable-next-line custom-rules/equal-enum-keys-and-values
    AttorneyHireYears = 'flow_attorney_hire_years',
    // eslint-disable-next-line custom-rules/equal-enum-keys-and-values
    AreasOfPractice = 'flow_areas_of_practice',
}
