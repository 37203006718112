import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { Grid, Stack } from '@common-components';
import { useFormProvider, useMutateOrganization, useMutateTeam, useSearchUserTeam } from 'hooks';
import { useMutateAgency } from 'hooks/api/agency';
import { useMutateOrganizationDomain } from 'hooks/api/organization-domain';
import { messages } from 'i18n';
import { Agency, OrganizationDomain, User } from 'types';
import { scrollToElement } from 'utils';
import { DialogContent, DialogFooter } from 'components/Dialog';
import FormAutocomplete from 'components/hookFormComponents/FormAutocomplete/FormAutocomplete';
import { FormMultiSelectAutocomplete } from 'components/hookFormComponents/FormMultiSelectAutocomplete';
import FormTextField from 'components/hookFormComponents/FormTextField';
import { BaseRoutedDialogContentProps } from 'broker/components/RoutedDialog';
import { AgencyFormDataProps, AgencyFormFieldsNames } from './types';
import { formFieldsConfig, FormMode, schema } from './utils';

const focusOnRef = (ref: MutableRefObject<HTMLInputElement | null>) => {
  setTimeout(() => {
    ref.current?.focus();
  }, 200);
};

interface FormContentProps
  extends Pick<BaseRoutedDialogContentProps, 'onClose' | 'closeWithoutPrompt' | 'setDialogIsDirty'> {
  defaultValues: AgencyFormDataProps;
  existingDomains: OrganizationDomain[];
  mode: FormMode;
  agency: Agency | null;
  onAgentCreated?: (user: User) => void;
  scrollTo?: AgencyFormFieldsNames;
}

export default function FormContent({
  setDialogIsDirty,
  onClose,
  closeWithoutPrompt,
  agency,
  existingDomains,
  defaultValues,
  mode,
  scrollTo,
}: FormContentProps) {
  const { methods } = useFormProvider({ defaultValues, schema, setIsDirty: setDialogIsDirty });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { items: users, isLoading: loadingUsers } = useSearchUserTeam({ filter: { enabled: true } });
  const userOptions = users.map((user) => ({ label: `${user.firstName} ${user.lastName}`, value: user.id }));
  const { id: agencyId, organizationId } = agency ?? { id: '', organizationId: '' };

  const domainRef = useRef<HTMLInputElement | null>(null);

  const { updateOrganization } = useMutateOrganization();
  const { updateAgency, createAgencyByName } = useMutateAgency();

  const { deleteOrganizationDomain, createOrganizationDomain } = useMutateOrganizationDomain();

  const { createTeam } = useMutateTeam();

  useEffect(() => {
    if (scrollTo) {
      setTimeout(() => {
        scrollToElement(scrollTo, { block: 'center' });
      }, 100);
      if (scrollTo === AgencyFormFieldsNames.Domain) {
        focusOnRef(domainRef);
      }
    }
  }, [scrollTo]);

  const onSubmitOrganization = async (data: AgencyFormDataProps) => {
    const { name, domain, assigneeId, leadAssigneeId } = data;
    setIsSubmitting(true);
    setDialogIsDirty(false);
    try {
      if (mode === FormMode.Update && agency) {
        await updateOrganization.mutateAsync({
          id: organizationId,
          data: {
            name,
          },
        });
        await updateAgency.mutateAsync({
          id: agencyId,
          data: {
            assigneeId,
            leadAssigneeId,
          } as Partial<Agency>,
        });

        const domainsToDelete = existingDomains.filter((d) => !domain.includes(d.domain));
        const domainsToAdd = domain.filter((d) => !existingDomains.map((ed) => ed.domain).includes(d));

        await Promise.all(domainsToDelete.map((d) => deleteOrganizationDomain.mutateAsync({ id: d.id })));

        await Promise.all(
          domainsToAdd.map((d) =>
            createOrganizationDomain.mutateAsync({
              data: {
                organizationId,
                domain: d,
              },
            }),
          ),
        );
      } else {
        const res = await createAgencyByName.mutateAsync({
          data: {
            name,
            assigneeId,
            leadAssigneeId,
          },
        });

        if (res) {
          await Promise.all(
            domain.map((d) =>
              createOrganizationDomain.mutateAsync({
                data: {
                  organizationId: res?.organizationId,
                  domain: d,
                },
              }),
            ),
          );

          await createTeam.mutateAsync({
            data: {
              name,
              organizationId: res?.organizationId,
            },
          });
        }
      }
      closeWithoutPrompt();
    } catch (e) {
      setDialogIsDirty(true);
      throw e;
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <DialogContent>
        <Stack maxWidth={640} margin="auto" gap={1}>
          <Grid columnSpacing={1} container>
            <Grid item xs={12}>
              <FormTextField {...formFieldsConfig[AgencyFormFieldsNames.Name]} fullWidth />
            </Grid>
            <Grid item xs={12}>
              <FormMultiSelectAutocomplete
                inputRef={domainRef}
                options={[]}
                hideOptionalLabel
                freeSolo
                fullWidth
                defaultValue={defaultValues.domain ?? []}
                {...formFieldsConfig[AgencyFormFieldsNames.Domain]}
                createOption={(value) => ({ label: `${messages.buttons.add}: ${value}`, value })}
              />
            </Grid>
            <Grid item xs={6}>
              <FormAutocomplete
                disabled={loadingUsers}
                isLoading={loadingUsers}
                options={userOptions}
                {...formFieldsConfig[AgencyFormFieldsNames.LeadAssigneeId]}
              />
            </Grid>
            <Grid item xs={6}>
              <FormAutocomplete
                disabled={loadingUsers}
                isLoading={loadingUsers}
                options={userOptions}
                {...formFieldsConfig[AgencyFormFieldsNames.AssigneeId]}
              />
            </Grid>
          </Grid>
        </Stack>
      </DialogContent>
      <DialogFooter
        proceedButton={{
          children: messages.agenciesPage.agencyForm.submit[mode],
          onClick: methods.handleSubmit(onSubmitOrganization),
          loading: isSubmitting,
        }}
        cancelButton={{
          children: messages.buttons.cancel,
          onClick: onClose,
        }}
      />
    </FormProvider>
  );
}
