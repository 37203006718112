import { useEffect, useRef, useState } from 'react';
import { Button, ClickAwayListener, Fade, IconButton, Stack, TextField, Typography } from '@common-components';
import { Edit as EditIcon } from '@icons';
import { SubmissionMarketRequestStatus } from 'enums';
import { useBoolean } from 'hooks';
import { messages } from 'i18n';
import { SubmissionMarketRequest } from 'types';
import { InformationRequestedLocationState } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/InformationRequested/types';
import { MarketingReportNoteOverrideType } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/MarketingReport/utils';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';

export interface AdditionalInfoProps {
  additionalInfoInitValue: string;
  submissionMarketRequest: SubmissionMarketRequest;
  showReportLoader: () => void;
  hideReportLoader: () => void;
  setMarketIdInEditMode: (id: string) => void;
  marketIdInEditMode: string;
  marketingReportNoteOverrideType?: MarketingReportNoteOverrideType;
  openInformationRequestedModal: (modalProps: InformationRequestedLocationState) => void;
}

function AdditionalInfo({
  additionalInfoInitValue,
  marketingReportNoteOverrideType,
  submissionMarketRequest,
  showReportLoader,
  hideReportLoader,
  setMarketIdInEditMode,
  marketIdInEditMode,
  openInformationRequestedModal,
}: AdditionalInfoProps) {
  const { updateSubmissionMarketRequest } = useSubmissionsWorkspace();
  const [editTextNote, setEditTextNote] = useState(additionalInfoInitValue);
  const isInternalEditMode = marketIdInEditMode === submissionMarketRequest.id;
  const noteInput = useRef<HTMLInputElement | undefined>();
  const isFreeTextInput = marketingReportNoteOverrideType === undefined;

  function setInternalEditMode() {
    setMarketIdInEditMode(submissionMarketRequest.id);
    // push the focus activation to be last on callstack to happen after the area expand
    setTimeout(() => noteInput.current?.focus(), 0);
  }

  const [isInternalLoading, { on: startInternalLoading, off: stopInternalLoading }] = useBoolean(false);

  useEffect(() => {
    setEditTextNote(additionalInfoInitValue);
  }, [additionalInfoInitValue]);

  function onEditButtonClicked() {
    if (marketingReportNoteOverrideType) {
      if (marketingReportNoteOverrideType === MarketingReportNoteOverrideType.InformationRequested) {
        openInformationRequestedModal({
          selectedSubmissionMarketRequest: submissionMarketRequest,
        });
      }
    } else {
      setInternalEditMode();
    }
  }

  async function setNotesToServer() {
    if (additionalInfoInitValue !== editTextNote) {
      startInternalLoading();
      showReportLoader();
      await updateSubmissionMarketRequest(submissionMarketRequest.id, {
        notes: editTextNote,
      });
      setMarketIdInEditMode('');
      hideReportLoader();
      stopInternalLoading();
    }
  }

  function onClickAway() {
    setMarketIdInEditMode('');
    setNotesToServer();
  }

  useEffect(() => {
    if (isFreeTextInput && marketIdInEditMode !== submissionMarketRequest.id) {
      // This call handel click on other "edit/add note" and close by change market id in edit mode
      setNotesToServer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketIdInEditMode, isFreeTextInput]);

  function onCancelEditing() {
    setEditTextNote(additionalInfoInitValue);
    setMarketIdInEditMode('');
  }

  return (
    <Stack
      gap={1}
      sx={{
        width: 1,
        height: 1,
        py: 1.3,
      }}
    >
      {isInternalEditMode ? (
        <ClickAwayListener onClickAway={onClickAway}>
          <Fade in={isInternalEditMode} timeout={{ enter: 250, exit: 0 }}>
            <Stack>
              <TextField
                inputRef={noteInput}
                fullWidth
                multiline
                value={editTextNote}
                onChange={(e) => {
                  setEditTextNote(e.target.value);
                }}
                //  maxRows={10}
                InputProps={{ sx: { typography: 'body2' } }}
              />

              {/* add fade to prevent reflation on other buttons on screen */}

              <Stack direction="row" alignItems="center" gap={1} pt={1}>
                <Button onClick={onCancelEditing}>{messages.marketingReportPage.cancel}</Button>
                <Button
                  loading={isInternalLoading}
                  disabled={additionalInfoInitValue === editTextNote}
                  onClick={setNotesToServer}
                >
                  {messages.marketingReportPage.save}
                </Button>
              </Stack>
            </Stack>
          </Fade>
        </ClickAwayListener>
      ) : (
        <Stack onClick={onEditButtonClicked} sx={{ cursor: 'pointer' }} direction="row" alignItems="flex-start" gap={1}>
          {editTextNote.length > 0 && (
            <Typography sx={{ mt: 0.75, px: 1, whiteSpace: 'pre-line' }} variant="body2">
              {editTextNote}
            </Typography>
          )}
          {[SubmissionMarketRequestStatus.Declined, SubmissionMarketRequestStatus.PendingCustomer].includes(
            submissionMarketRequest.status,
          ) && <IconButton icon={EditIcon} variant="text" color="secondary" />}
        </Stack>
      )}
    </Stack>
  );
}

export default AdditionalInfo;
