import { FlowQuestionId, heraldQuestionId } from '@common/types';
import { uniq } from 'lodash';
import { CoverageLine } from 'enums';

const effectiveDateQuestions = [
  heraldQuestionId.cvg_m18u_pl_effective_date,
  heraldQuestionId.cvg_48oo_gl_effective_date,
  heraldQuestionId.cvg_o3mw_cyb_effective_date,
  heraldQuestionId.cvg_0em0_ml_effective_date,
  FlowQuestionId.EffectiveDate,
];

const annualRevenueQuestions = [heraldQuestionId.rsk_vrb1_total_annual_revenue, FlowQuestionId.InsuredAnnualRevenue];

const insuredNameQuestions = [heraldQuestionId.rsk_m4p9_insured_name, FlowQuestionId.InsuredName];

const industryQuestions = [heraldQuestionId.rsk_b3jm_2017_naics_index, FlowQuestionId.Industry];

export const baseExtractionValidationFieldsConfigList = [
  ...insuredNameQuestions,
  heraldQuestionId.rsk_yor8_location,
  FlowQuestionId.PrimaryAddress,
  ...effectiveDateQuestions,
  FlowQuestionId.NeedByDate,
];

const DnOFields = [
  heraldQuestionId.rsk_0ie7_number_of_fte,
  heraldQuestionId.rsk_cog2_total_assets,
  ...annualRevenueQuestions,
  heraldQuestionId.rsk_s7wq_corporate_structure,
];

export const getExtractionValidationFields = (coverageLine: CoverageLine[]) => {
  const extractionValidationFieldsByCoverageLine: Partial<Record<CoverageLine, string[]>> = {
    [CoverageLine.Cyber]: [...industryQuestions, ...annualRevenueQuestions],
    [CoverageLine.EPL]: [...industryQuestions, heraldQuestionId.rsk_0ie7_number_of_fte],
    [CoverageLine.PrivateDnO]: DnOFields,
    [CoverageLine.PublicDnO]: DnOFields,
    [CoverageLine.NonProfitDnO]: DnOFields,
    [CoverageLine.Crime]: [...industryQuestions, ...annualRevenueQuestions, heraldQuestionId.rsk_0ie7_number_of_fte],
    [CoverageLine.LawyersProfessionalLiability]: [
      FlowQuestionId.AreasOfPractice,
      FlowQuestionId.AttorneyNumber,
      FlowQuestionId.AttorneyHireYears,
    ],
    [CoverageLine.TechnologyEnO]: [...industryQuestions, ...annualRevenueQuestions],
    [CoverageLine.MiscellaneousProfessionalLiability]: [...industryQuestions, ...annualRevenueQuestions],
  };

  const coverageFields = coverageLine
    .map((line) => extractionValidationFieldsByCoverageLine[line])
    .filter((fields): fields is string[] => Array.isArray(fields));

  // Flatten and return final array
  return uniq([...baseExtractionValidationFieldsConfigList, ...coverageFields.flat()]);
};
