import { ExtractedAreaOfPractice, ExtractedAttribute } from '@common/types';
import { useMemo } from 'react';
import { Stack } from '@common-components';
import FormTextField from 'components/hookFormComponents/FormTextField';
import { DynamicObjectFieldsComponentProps } from './DynamicObjectWrapper';

export default function DynamicFormAreaOfPractice({
  onBlur,
  ownBuildName,
  suggestion,
  suggestionValidation,
}: DynamicObjectFieldsComponentProps) {
  const suggestionValue = suggestion as ExtractedAttribute<ExtractedAreaOfPractice> | undefined;

  const areaSuggestion = useMemo(
    () =>
      suggestionValue?.value?.area ? { value: suggestionValue.value.area, reason: suggestion?.reason } : undefined,
    [suggestion?.reason, suggestionValue?.value?.area],
  );

  const percentageSuggestion = useMemo(
    () =>
      suggestionValue?.value?.percentage
        ? { value: suggestionValue.value.percentage, reason: suggestion?.reason }
        : undefined,
    [suggestion?.reason, suggestionValue?.value?.percentage],
  );

  return (
    <Stack direction="row" gap={3}>
      <FormTextField
        suggestion={areaSuggestion}
        suggestionValidation={suggestionValidation}
        enhancedRequired
        onBlur={onBlur}
        {...ownBuildName('area', 'Area')}
      />
      <FormTextField
        enhancedRequired
        isNumberFormat
        numberFormatProps={{
          min: 1,
          max: 100,
          prefix: '',
          suffix: '%',
        }}
        onBlur={onBlur}
        {...ownBuildName('percentage', 'Percentage')}
        suggestion={percentageSuggestion}
        suggestionValidation={suggestionValidation}
      />
    </Stack>
  );
}
