import { DynamicQuestion } from '@common/dynamic-form';
import { HeraldData } from '@common/types';
import { useMemo } from 'react';
import { HeraldStatus, SubmissionTask, SubmissionTaskStatus } from 'enums';
import useRetrieveExtendedSubmission from 'hooks/api/extended-submission/useRetrieveExtendedSubmission';
import { PartialSubmission } from 'types';
import { DynamicFormState } from 'broker/pages/SubmissionWorkspacePage/components/DynamicForm/DynamicFormLayout/types';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { mapDynamicFormToPartialSubmission } from './mappers/submission-to-dynamic-question-mapper';
import { areAllFlowRequiredQuestionsFilled, getFlowDynamicQuestions } from './utils';

interface GetFlowProviderProps {
  submission: PartialSubmission;
  isHeraldSubmission: boolean;
  heraldDynamicQuestions: DynamicQuestion[];
  isLoadingHeraldApplication: boolean;
}
export function useGetFlowProvider({
  submission,
  isHeraldSubmission,
  heraldDynamicQuestions,
  isLoadingHeraldApplication,
}: GetFlowProviderProps) {
  const { reFetchWorkspace, updateSubmission, updateSubmissionTasks } = useSubmissionsWorkspace();
  const { refetch: refetchExtendedSubmission } = useRetrieveExtendedSubmission({ submissionId: submission.id });
  const flowDynamicQuestions = useMemo(
    () => (isLoadingHeraldApplication ? [] : getFlowDynamicQuestions(heraldDynamicQuestions, submission)),
    [isLoadingHeraldApplication, heraldDynamicQuestions, submission],
  );

  const isSubmissionComplete = async (heraldData?: HeraldData) => {
    if (isHeraldSubmission) {
      return heraldData?.status === HeraldStatus.Complete;
    }
    const fetchedSubmission = await refetchExtendedSubmission();

    return fetchedSubmission.data?.submission
      ? areAllFlowRequiredQuestionsFilled(fetchedSubmission.data.submission)
      : false;
  };

  const updateSubmissionWithFormValues = async (
    formState: DynamicFormState,
    dynamicQuestions: DynamicQuestion[],
    updatedHeraldData?: Partial<HeraldData>,
  ) => {
    const submissionUpdateFields = mapDynamicFormToPartialSubmission(formState, dynamicQuestions);
    const heraldData = submission.heraldData ? { ...submission.heraldData, ...updatedHeraldData } : undefined;

    await updateSubmission(submission.id, {
      ...submissionUpdateFields,
      ...(heraldData && {
        heraldData,
      }),
    });

    if (await isSubmissionComplete(heraldData)) {
      await updateSubmissionTasks({
        submissionId: submission.id,
        status: SubmissionTaskStatus.Done,
        task: SubmissionTask.ReviewSubmission,
      });
    }
    await reFetchWorkspace();
  };

  return {
    flowDynamicQuestions,
    updateSubmissionWithFormValues,
  };
}
