import { compact } from 'lodash';
import {
    FlowQuestionId,
    heraldAddressQuestionIds,
    heraldEffectiveDateQuestionIds,
    heraldQuestionId,
    Submission,
    SubmissionAdditionalData,
} from '../../types';
import {
    normalizeDateExtractionToFormValue,
    normalizeDateExtractionToSubmissionModel,
} from '../../utils/extraction-utils';
import { DisplayDateFormat, getUTCStartOfDayFromTimezone } from '../../utils/date-utils';
import { DynamicFormDefaultValue, noneOfTheAboveOption, SubmissionDataDefaultsMetaData } from './types';
import {
    convertCoverageLinesTextToEnum,
    convertDateFormValueToHeraldValue,
    getDefaultSubmissionNeedByDate,
} from '../../utils';
import { CompanyType, CoverageLine } from '../../enums';

type HeraldDefaultConfig = {
    fieldName: string;
    value: DynamicFormDefaultValue;
    // ids of the questions ids, defaults will be auto populate for only if the parent question has an answer
    parentFieldNames?: string[];
    // if the value in the form is displayed different from how it is saved, usually numbers are converted to strings
    formValueOverride?: DynamicFormDefaultValue;
    source: 'herald';
};
type SubmissionDefaultConfig = {
    fieldName: keyof Submission;
    value: Submission[keyof Submission];
    // the way this value is displayed in the dynamic form, in submission it appears in FlowQuestionId enum
    formFieldNameOverride?: FlowQuestionId;
    // if the value in the form is displayed different from how it is saved, usually numbers are converted to strings
    formValueOverride?: DynamicFormDefaultValue;
    source: 'submission';
};

type SubmissionAdditionalDataDefaultConfig = {
    fieldName: keyof SubmissionAdditionalData;
    value: SubmissionAdditionalData[keyof SubmissionAdditionalData];
    // the way this value is displayed in the dynamic form, in submission additional data it appears in FlowQuestionId enum
    formFieldNameOverride?: FlowQuestionId;
    // if the value in the form is displayed different from how it is saved, usually numbers are converted to strings
    formValueOverride?: DynamicFormDefaultValue;
    source: 'submissionAdditionalData';
};

type SubmissionDataDefaultsConfig =
    | HeraldDefaultConfig
    | SubmissionDefaultConfig
    | SubmissionAdditionalDataDefaultConfig;

function createHeraldDefaultEntry(
    fieldName: string,
    defaultValue: any,
    formValueOverride?: DynamicFormDefaultValue,
): HeraldDefaultConfig {
    return {
        fieldName,
        value: defaultValue,
        formValueOverride,
        source: 'herald',
    };
}

function createNeedByDateDefaultEntry(): SubmissionDefaultConfig | undefined {
    const needByDateDefaultValue = getDefaultSubmissionNeedByDate();
    const needByDateInFormFormat = normalizeDateExtractionToFormValue(needByDateDefaultValue.format(DisplayDateFormat));

    if (needByDateInFormFormat) {
        const needByDateInSubmissionFormat = normalizeDateExtractionToSubmissionModel(needByDateInFormFormat);
        if (needByDateInFormFormat) {
            return {
                fieldName: 'needByDate',
                value: needByDateInSubmissionFormat,
                formFieldNameOverride: FlowQuestionId.NeedByDate,
                formValueOverride: needByDateInFormFormat,
                source: 'submission',
            };
        }
    }

    return undefined;
}

function getDefaultEffectiveDateInFormFormat() {
    const effectiveDateDefaultValue = getUTCStartOfDayFromTimezone().add(1, 'month');

    return normalizeDateExtractionToFormValue(effectiveDateDefaultValue.format(DisplayDateFormat));
}

function createHeraldEffectiveDateDefaultEntry(heraldFieldName: string): HeraldDefaultConfig | undefined {
    const effectiveDateInFormFormat = getDefaultEffectiveDateInFormFormat();

    if (effectiveDateInFormFormat) {
        if (effectiveDateInFormFormat) {
            return createHeraldDefaultEntry(
                heraldFieldName,
                convertDateFormValueToHeraldValue(effectiveDateInFormFormat),
                effectiveDateInFormFormat,
            );
        }
    }
    return undefined;
}

function createSubmissionEffectiveDateDefaultEntry(): SubmissionDefaultConfig | undefined {
    const effectiveDateInFormFormat = getDefaultEffectiveDateInFormFormat();

    if (effectiveDateInFormFormat) {
        if (effectiveDateInFormFormat) {
            return {
                fieldName: 'dueDate',
                value: normalizeDateExtractionToSubmissionModel(effectiveDateInFormFormat),
                formFieldNameOverride: FlowQuestionId.EffectiveDate,
                formValueOverride: effectiveDateInFormFormat,
                source: 'submission',
            };
        }
    }

    return undefined;
}

const getMetadataCoverageLines = (submissionDataDefaultsMetaData: SubmissionDataDefaultsMetaData) => {
    const { selectedCoverageLines, extractedData } = submissionDataDefaultsMetaData;
    const extractedCoverageLines = extractedData?.coverageLines?.value
        ? convertCoverageLinesTextToEnum(extractedData?.coverageLines.value)
        : [];
    return selectedCoverageLines || extractedCoverageLines;
};

const createHeraldIndustryDefaultValue = (
    submissionDataDefaultsMetaData: SubmissionDataDefaultsMetaData,
): HeraldDefaultConfig | undefined => {
    const coverageLines = getMetadataCoverageLines(submissionDataDefaultsMetaData);
    if (coverageLines && coverageLines.includes(CoverageLine.LawyersProfessionalLiability)) {
        return createHeraldDefaultEntry(heraldQuestionId.rsk_b3jm_2017_naics_index, 'j8de53');
    }
    return undefined;
};

const createSubmissionIndustryDefaultValue = (
    submissionDataDefaultsMetaData: SubmissionDataDefaultsMetaData,
): SubmissionDefaultConfig | undefined => {
    const coverageLines = getMetadataCoverageLines(submissionDataDefaultsMetaData);

    if (coverageLines && coverageLines.includes(CoverageLine.LawyersProfessionalLiability)) {
        return {
            fieldName: 'industry',
            value: { heraldId: 'j8de53', naicsCode: '541110', description: 'Law firms' },
            formFieldNameOverride: FlowQuestionId.Industry,
            source: 'submission',
        };
    }

    return undefined;
};

const createSubmissionCompanyTypeDefaultValue = (): SubmissionDefaultConfig | undefined => ({
    fieldName: 'insuredCompanyType',
    value: CompanyType.Private,
    formFieldNameOverride: FlowQuestionId.InsuredCompanyType,
    source: 'submission',
});

export function buildHeraldAddressChildDefaults(
    config: Omit<HeraldDefaultConfig, 'source' | 'parentFieldNames'>,
): HeraldDefaultConfig {
    return {
        ...createHeraldDefaultEntry(config.fieldName, config.value, config.formValueOverride),
        parentFieldNames: [...heraldAddressQuestionIds],
    };
}

export const getSubmissionDataDefaultsConfig = (
    submissionDataDefaultsMetaData: SubmissionDataDefaultsMetaData,
): SubmissionDataDefaultsConfig[] =>
    compact([
        buildHeraldAddressChildDefaults({
            fieldName: heraldQuestionId.rsk_2wq9_pte_by_location,
            value: 0,
            formValueOverride: '0',
        }),
        buildHeraldAddressChildDefaults({
            fieldName: heraldQuestionId.rsk_7h1i_union_employees_by_location,
            value: 0,
            formValueOverride: '0',
        }),
        buildHeraldAddressChildDefaults({
            fieldName: heraldQuestionId.rsk_2z10_independent_contractors_by_location,
            value: 0,
            formValueOverride: '0',
        }),
        buildHeraldAddressChildDefaults({
            fieldName: heraldQuestionId.rsk_3b0l_volunteers_by_location,
            value: 0,
            formValueOverride: '0',
        }),
        createHeraldIndustryDefaultValue(submissionDataDefaultsMetaData),
        createSubmissionIndustryDefaultValue(submissionDataDefaultsMetaData),
        createSubmissionCompanyTypeDefaultValue(),
        createHeraldDefaultEntry(heraldQuestionId.rsk_4b8l_number_of_foreign_employees, 0, '0'),
        createHeraldDefaultEntry(heraldQuestionId.rsk_fd7h_has_ml_coverage, [noneOfTheAboveOption]),
        createHeraldDefaultEntry(heraldQuestionId.rsk_7ahp_has_domain, 'yes'),
        createNeedByDateDefaultEntry(),
        ...heraldEffectiveDateQuestionIds.map((heraldEffectiveDateQuestionId) =>
            createHeraldEffectiveDateDefaultEntry(heraldEffectiveDateQuestionId),
        ),
        createSubmissionEffectiveDateDefaultEntry(),
        createHeraldDefaultEntry(heraldQuestionId.rsk_h8oi_high_risk_industry_type, [noneOfTheAboveOption]),
        createHeraldDefaultEntry(heraldQuestionId.rsk_a18w_stored_records_type, [noneOfTheAboveOption]),
        createHeraldDefaultEntry(heraldQuestionId.rsk_da0x_dual_authenticate_banking_detail_update, 'yes'),
        createHeraldDefaultEntry(heraldQuestionId.rsk_tw5r_dual_authentication, 'For all requests'),
        createHeraldDefaultEntry(heraldQuestionId.rsk_d6el_secure_backup, 'yes'),
        createHeraldDefaultEntry(heraldQuestionId.rsk_glo9_secure_backup_frequency, 'Weekly or more'),
        createHeraldDefaultEntry(heraldQuestionId.rsk_y8ve_secure_backup_offline, 'yes'),
        createHeraldDefaultEntry(heraldQuestionId.rsk_64p7_data_encryption, [
            'De-identify sensitive data at rest',
            'Encrypt sensitive data at rest (including on laptops, computers, and other portable media devices)',
            'Encrypt sensitive data in emails sent to external parties',
        ]),
        createHeraldDefaultEntry(heraldQuestionId.rsk_un9n_network_authentication, [
            'All web-based email accounts',
            'All remote access to the network including any remote desktop protocol connections',
            'Local and remote access to privileged user/network administrator accounts',
            'Internal and external access to cloud-based back-ups',
            'Mission critical systems',
        ]),
        createHeraldDefaultEntry(heraldQuestionId.rsk_9bjm_content_review, 'yes'),
        createHeraldDefaultEntry(heraldQuestionId.rsk_2pt5_has_parent_entity, 'no'),
        createHeraldDefaultEntry(heraldQuestionId.rsk_a94l_do_24_12_months, 'no'),
        createHeraldDefaultEntry(heraldQuestionId.rsk_ryx6_has_experienced_merger_and_acquisition, [
            noneOfTheAboveOption,
        ]),
        createHeraldDefaultEntry(heraldQuestionId.rsk_wjbi_ml_past_future_material_changes, [noneOfTheAboveOption]),
        createHeraldDefaultEntry(heraldQuestionId.rsk_ljip_change_in_voting_control_36_months, 'no'),
        createHeraldDefaultEntry(heraldQuestionId.rsk_hdcl_has_employee_stock_plan, 'no'),
        createHeraldDefaultEntry(heraldQuestionId.rsk_yh15_has_revenue_from_government, 'no'),
        createHeraldDefaultEntry(heraldQuestionId.rsk_dc0u_anticipates_or_experienced_layoffs, 'no'),
        createHeraldDefaultEntry(heraldQuestionId.rsk_gzu6_receives_employee_application, 'yes'),
        createHeraldDefaultEntry(heraldQuestionId.rsk_e62k_publishes_employee_handbook, 'yes'),
        createHeraldDefaultEntry(heraldQuestionId.rsk_8rqb_has_hr_department, 'yes'),
        createHeraldDefaultEntry(heraldQuestionId.rsk_jb26_cyb_has_claims_history, 'no'),
        createHeraldDefaultEntry(heraldQuestionId.rsk_ggy8_cyb_warranty, 'no'),
        createHeraldDefaultEntry(heraldQuestionId.rsk_2aep_pl_has_claim_history, 'no'),
        createHeraldDefaultEntry(heraldQuestionId.rsk_rvyh_pl_has_warranty, 'no'),
        createHeraldDefaultEntry(heraldQuestionId.rsk_h2x6_has_pl_coverage, 'no'),
        createHeraldDefaultEntry(heraldQuestionId.rsk_ir30_has_prior_civil_suit, 'no'),
        createHeraldDefaultEntry(heraldQuestionId.rsk_rh5y_ml_has_claims_history_within_five_years, 'no'),
        createHeraldDefaultEntry(heraldQuestionId.rsk_7i0r_ml_warranty_claim, 'no'),
        createHeraldDefaultEntry(heraldQuestionId.rsk_w8j4_prior_eeoc_or_admin_proceeding, 'no'),
        createHeraldDefaultEntry(heraldQuestionId.rsk_o3q9_content_complaints_past_three_years, 'no'),
        createHeraldDefaultEntry(heraldQuestionId.rsk_8di2_offers_additional_services, 'no'),
        createHeraldDefaultEntry(heraldQuestionId.rsk_q9am_has_privacy_claims, 'no'),
        createHeraldDefaultEntry(heraldQuestionId.rsk_22b1_prior_coverage_changed, 'no'),
        createHeraldDefaultEntry(heraldQuestionId.rsk_l9ap_seeking_coverage_for_affiliate, 'no'),
        createHeraldDefaultEntry(heraldQuestionId.rsk_1pab_has_continuing_eduction, 'no'),
        createHeraldDefaultEntry(heraldQuestionId.rsk_6i3e_has_investigation_from_federal_agency, 'no'),
        createHeraldDefaultEntry(heraldQuestionId.rsk_0h8t_engages_in_cryptocurrency, 'no'),
        createHeraldDefaultEntry(heraldQuestionId.rsk_su3n_option_to_invest, 'no'),
        createHeraldDefaultEntry(heraldQuestionId.rsk_ot4r_benefit_reduction, 'no'),
        createHeraldDefaultEntry(heraldQuestionId.rsk_c2ui_erisa_compliance, 'no'),
        createHeraldDefaultEntry(heraldQuestionId.rsk_5iyn_plan_violation, 'no'),
        createHeraldDefaultEntry(heraldQuestionId.rsk_un8m_delinquent_plan, 'no'),
        createHeraldDefaultEntry(heraldQuestionId.rsk_7qec_anticipates_or_experienced_financial_restructing, 'no'),
        createHeraldDefaultEntry(heraldQuestionId.rsk_3e4y_cancelled_nonrenew_ml_policy, 'no'),
        createHeraldDefaultEntry(heraldQuestionId.rsk_voe4_cyb_security_officer, 'yes'),
        createHeraldDefaultEntry(heraldQuestionId.rsk_yo5p_anticipates_or_completed_public_offerings, [
            noneOfTheAboveOption,
        ]),
        createHeraldDefaultEntry(heraldQuestionId.rsk_sf46_anticipates_or_completed_private_placements, [
            noneOfTheAboveOption,
        ]),
        createHeraldDefaultEntry(heraldQuestionId.rsk_faf9_percentage_shares_held_by_board, 100),
        createHeraldDefaultEntry(heraldQuestionId.rsk_gyj4_half_long_term_debt_matures, 'no'),
        createHeraldDefaultEntry(heraldQuestionId.rsk_mur2_has_outside_individual_ownership, 'no'),
        createHeraldDefaultEntry(heraldQuestionId.rsk_e718_has_breached_debt_covenants, 'no'),
        createHeraldDefaultEntry(heraldQuestionId.rsk_tu74_percentage_held_by_trusts, 0),
        createHeraldDefaultEntry(heraldQuestionId.rsk_3oiz_has_security_breach_requiring_notification, 'no'),
        createHeraldDefaultEntry(heraldQuestionId.rsk_sw1p_has_pending_litigation, 'no'),
        createHeraldDefaultEntry(heraldQuestionId.rsk_p175_cyb_has_loss_in_unschedule_system_downtime, 'no'),
        createHeraldDefaultEntry(heraldQuestionId.rsk_6onk_entity_type, 'Independent'),
        createHeraldDefaultEntry(heraldQuestionId.rsk_2i59_ownership_type, 'Private'),
        createHeraldDefaultEntry(heraldQuestionId.rsk_e73e_cyb_authenticating_fund_transfers, 'yes'),
        createHeraldDefaultEntry(heraldQuestionId.rsk_zk4f_cyb_verifies_bank_accounts, 'yes'),
        createHeraldDefaultEntry(heraldQuestionId.rsk_5m1o_cyb_cloud_storage, 'no'),
        createHeraldDefaultEntry(heraldQuestionId.rsk_6ril_cyb_security_training, 'yes'),
        createHeraldDefaultEntry(heraldQuestionId.rsk_s9i6_is_franchise, 'no'),
        createHeraldDefaultEntry(heraldQuestionId.rsk_2nge_is_business_broker, 'no'),
        createHeraldDefaultEntry(heraldQuestionId.rsk_nbd2_is_investment_bank, 'no'),
        createHeraldDefaultEntry(heraldQuestionId.rsk_2n3d_performs_merger_and_acquisition, 'no'),
        createHeraldDefaultEntry(heraldQuestionId.rsk_4hz4_backup_security_measure_type, [
            'MFA',
            'Segmentation',
            'Immutable',
            'Virus/Malware scanning',
            'Encryption',
            'Test',
            'Online or Designated Cloud Service',
        ]),
        createHeraldDefaultEntry(heraldQuestionId.rsk_9ljq_third_party_security_agreement, 'yes'),
        createHeraldDefaultEntry(heraldQuestionId.rsk_bet9_tested_full_failover, 'yes'),
        createHeraldDefaultEntry(heraldQuestionId.rsk_9ty9_has_dmz_separation, 'yes'),
        createHeraldDefaultEntry(heraldQuestionId.rsk_rzc7_data_encryption_frequency, 'yes'),
        createHeraldDefaultEntry(heraldQuestionId.rsk_331u_patch_frequency, 'Monthly'),
        createHeraldDefaultEntry(heraldQuestionId.rsk_fzej_has_incident_response_plan, 'yes'),
        createHeraldDefaultEntry(heraldQuestionId.rsk_8s6e_cyb_training_implementation, 'yes'),
        createHeraldDefaultEntry(heraldQuestionId.rsk_9ux6_years_industry_management, 10),
        createHeraldDefaultEntry(heraldQuestionId.rsk_na7l_perform_background_checks_employee, 'yes'),
        createHeraldDefaultEntry(heraldQuestionId.rsk_ms88_bank_account_reconciliation, 'yes'),
        createHeraldDefaultEntry(heraldQuestionId.rsk_0o4l_wire_transfer_authorization, 'yes'),
        createHeraldDefaultEntry(heraldQuestionId.rsk_77vv_vendor_verification_policy, 'yes'),
        createHeraldDefaultEntry(heraldQuestionId.rsk_u3u3_inventory_physical_count, 'yes'),
        createHeraldDefaultEntry(heraldQuestionId.rsk_ut7e_high_value_exposure, 'no'),
        createHeraldDefaultEntry(heraldQuestionId.rsk_3h4w_counter_signature_required, 'yes'),
        createHeraldDefaultEntry(heraldQuestionId.rsk_u8u8_locations_abroad, 'yes'),
        createHeraldDefaultEntry(heraldQuestionId.rsk_ff4b_banking_transaction_control, 'yes'),
    ]);
