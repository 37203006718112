import { escape } from 'lodash';
import { Citation } from 'types';

function citationToLink(citation: Citation): string {
  return `<AICitationLink sourceId="${citation.reference.id}" sourceType="${citation.reference.type}" title="${
    citation.reference.title
  }" quotedText="${escape(citation.quotedText?.text) ?? ''}" quotedPage="${citation.quotedText?.page}" />`;
}

// Currently citations support only link to file without a location
// However, openAI can return two adjacent citations to the same file, which has not value to the user.
// This function merges adjacent citations to the same file and creates a merged placeholder.
function mergeAdjacentCitations(citations: Citation[]) {
  return citations
    .toSorted((c1, c2) => (c1.startIndex > c2.startIndex ? 1 : -1))
    .reduce((acc, citation) => {
      const prevCitation = acc[acc.length - 1];

      if (
        prevCitation &&
        prevCitation.reference.id === citation.reference.id &&
        prevCitation.endIndex === citation.startIndex
      ) {
        prevCitation.endIndex = citation.endIndex;
        prevCitation.placeholder += citation.placeholder;
      } else {
        acc.push({ ...citation });
      }
      return acc;
    }, [] as Citation[]);
}

export function stripAllAnnotations(message: string): string {
  return message.replace(/(?:【.*?】|⌈.*?⌉)/g, '');
}

export function resolveCitations(message: string, citations: Citation[]): string {
  const mergedCitations = mergeAdjacentCitations(citations);

  const resolvedCitations = mergedCitations.reduce(
    (acc, citation) => acc.replace(citation.placeholder, citationToLink(citation)),
    message,
  );

  // Remove remaining citations that could not be resolved.
  // This can happen if we failed to create the Citations on the server, but the AI already inserted the placeholders.
  return stripAllAnnotations(resolvedCitations);
}

export function removeCitations(message: string, citations: Citation[]): string {
  return citations.reduce((acc, citation) => acc.replace(citation.placeholder, ''), message);
}
