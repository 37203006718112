import { isNil } from 'lodash';
import { CoverageValuesUpdate, HeraldCreateApplication, RiskValuesUpdate } from '../../types/herald/herald-types';
import { SubmissionDataDefaultsMetaData } from './types';
import { SubmissionDataDestination } from '../enums';
import { getHeraldQuestionIdCategory } from '../../utils';
import { HeraldCategoryType } from '../../enums';
import { getSubmissionDataFromDefaultValuesByDestination } from './submission-defaults-getters';

function addDefaultToCoverageValueWithNoParent(
    existingCoverageValues: CoverageValuesUpdate[],
    fieldName: string,
    config: { fieldName: string; value: any },
) {
    const existingCoverageValueIndex = existingCoverageValues.findIndex(
        (coverageValue) => coverageValue.coverage_parameter_id === fieldName,
    );
    if (existingCoverageValueIndex === -1) {
        existingCoverageValues.push({
            coverage_parameter_id: fieldName,
            value: config.value,
        });
    } else if (isNil(existingCoverageValues[existingCoverageValueIndex].value)) {
        existingCoverageValues[existingCoverageValueIndex].value = config.value;
    }

    return existingCoverageValues;
}

function addDefaultToRiskValueWithNoParent(
    existingRiskValues: RiskValuesUpdate[],
    fieldName: string,
    config: { fieldName: string; value: any },
) {
    const existingRiskValueIndex = existingRiskValues.findIndex(
        (riskValue) => riskValue.risk_parameter_id === fieldName,
    );
    if (existingRiskValueIndex === -1) {
        existingRiskValues.push({
            risk_parameter_id: fieldName,
            value: config.value,
        });
    } else if (isNil(existingRiskValues[existingRiskValueIndex].value)) {
        existingRiskValues[existingRiskValueIndex].value = config.value;
    }

    return existingRiskValues;
}

function addDefaultToValueWithNoParent(
    existingRiskValues: RiskValuesUpdate[],
    existingCoverageValues: CoverageValuesUpdate[],
    fieldName: string,
    config: { fieldName: string; value: any },
) {
    const isRiskValueCategory = getHeraldQuestionIdCategory(fieldName) === HeraldCategoryType.RiskValues;

    return isRiskValueCategory
        ? addDefaultToRiskValueWithNoParent(existingRiskValues, fieldName, config)
        : addDefaultToCoverageValueWithNoParent(existingCoverageValues, fieldName, config);
}

function addDefaultToRiskValueWithParent(
    existingRiskValues: RiskValuesUpdate[],
    fieldName: string,
    config: { fieldName: string; value: any; parentFieldNames: string[] },
) {
    config.parentFieldNames.forEach((parentFieldName) => {
        const parentRiskValueIndex = existingRiskValues.findIndex(
            (riskValue) => parentFieldName === riskValue.risk_parameter_id,
        );

        // only if the parent has an answer add default to the child
        if (parentRiskValueIndex !== -1 && !isNil(existingRiskValues[parentRiskValueIndex].value)) {
            const parentRiskValue = existingRiskValues[parentRiskValueIndex];

            // child is added to the parent
            if (!parentRiskValue.child_risk_values) {
                parentRiskValue.child_risk_values = [
                    {
                        risk_parameter_id: fieldName,
                        value: config.value,
                    },
                ];
            }
            // child already exists, only if it is nill, update it with the default
            else {
                const existingChildRiskValueIndex = parentRiskValue.child_risk_values.findIndex(
                    (childRiskValue) => childRiskValue.risk_parameter_id === fieldName,
                );
                if (existingChildRiskValueIndex === -1) {
                    parentRiskValue.child_risk_values.push({
                        risk_parameter_id: fieldName,
                        value: config.value,
                    });
                } else if (isNil(parentRiskValue.child_risk_values[existingChildRiskValueIndex])) {
                    parentRiskValue.child_risk_values[existingChildRiskValueIndex] = {
                        risk_parameter_id: fieldName,
                        value: config.value,
                    };
                }
            }
        }
    });
}

function addDefaultToCoverageValueWithParent(
    existingCoverageValues: CoverageValuesUpdate[],
    fieldName: string,
    config: { fieldName: string; value: any; parentFieldNames: string[] },
) {
    config.parentFieldNames.forEach((parentFieldName) => {
        const parentCoverageValueIndex = existingCoverageValues.findIndex(
            (coverageValue) => parentFieldName === coverageValue.coverage_parameter_id,
        );

        // only if the parent has an answer add default to the child
        if (parentCoverageValueIndex !== -1 && !isNil(existingCoverageValues[parentCoverageValueIndex].value)) {
            const parentCoverageValue = existingCoverageValues[parentCoverageValueIndex];

            // child is added to the parent
            if (!parentCoverageValue.child_coverage_values) {
                parentCoverageValue.child_coverage_values = [
                    {
                        coverage_parameter_id: fieldName,
                        value: config.value,
                    },
                ];
            } else {
                const existingChildCoverageValueIndex = parentCoverageValue.child_coverage_values.findIndex(
                    (childCoverageValue) => childCoverageValue.coverage_parameter_id === fieldName,
                );
                if (existingChildCoverageValueIndex === -1) {
                    parentCoverageValue.child_coverage_values.push({
                        coverage_parameter_id: fieldName,
                        value: config.value,
                    });
                    // if the child already exists, only if it is nill, update it with the default
                } else if (isNil(parentCoverageValue.child_coverage_values[existingChildCoverageValueIndex])) {
                    parentCoverageValue.child_coverage_values[existingChildCoverageValueIndex] = {
                        coverage_parameter_id: fieldName,
                        value: config.value,
                    };
                }
            }
        }
    });
}

function addDefaultToValueWithParent(
    existingRiskValues: RiskValuesUpdate[],
    existingCoverageValues: CoverageValuesUpdate[],
    fieldName: string,
    config: { fieldName: string; value: any; parentFieldNames: string[] },
) {
    const isRiskValueCategory = getHeraldQuestionIdCategory(fieldName) === HeraldCategoryType.RiskValues;

    return isRiskValueCategory
        ? addDefaultToRiskValueWithParent(existingRiskValues, fieldName, config)
        : addDefaultToCoverageValueWithParent(existingCoverageValues, fieldName, config);
}

export function addDefaultsToHeraldApplication(
    heraldApplication: Pick<HeraldCreateApplication, 'risk_values' | 'coverage_values'>,
    metaData: SubmissionDataDefaultsMetaData,
): Required<Pick<HeraldCreateApplication, 'risk_values' | 'coverage_values'>> {
    const submissionDataDefaultsConfig = getSubmissionDataFromDefaultValuesByDestination(
        SubmissionDataDestination.Herald,
        metaData,
    );

    const riskValues = [...(heraldApplication.risk_values || [])];
    const coverageValues = [...(heraldApplication.coverage_values || [])];

    // first add defaults to values with no parent
    Object.entries(submissionDataDefaultsConfig).forEach(([fieldName, config]) => {
        if (!config.parentFieldNames) {
            addDefaultToValueWithNoParent(riskValues, coverageValues, fieldName, config);
        }
    });

    // then add defaults to values with parent - so that if there was a parent value, the default value is already in the application
    Object.entries(submissionDataDefaultsConfig).forEach(([fieldName, config]) => {
        const { parentFieldNames } = config;
        if (parentFieldNames) {
            addDefaultToValueWithParent(riskValues, coverageValues, fieldName, {
                ...config,
                parentFieldNames,
            });
        }
    });

    return {
        risk_values: riskValues,
        coverage_values: coverageValues,
    };
}
