import { useEffect, useMemo, useRef } from 'react';
import { SearchBar, Stack } from '@common-components';
import { HeraldApplicationResponse } from 'clients/types';
import { AIFeature } from 'enums';
import { usePagination, useUnmount } from 'hooks';
import { messages } from 'i18n';
import { InsuranceProduct } from 'types';
import { RouteKey } from 'broker/enums';
import { useNavigate } from 'broker/hooks';
import InnerPageLayout from 'broker/pages/SubmissionWorkspacePage/components/InnerPage/InnerPageLayout';
import { InnerPageProps } from 'broker/pages/SubmissionWorkspacePage/components/InnerPage/types';
import { Products } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/AddProducts/Components/Products';
import { SelectedProductsMap } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/AddProducts/types';
import { useProductFilters } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/AddProducts/useProductFilters';
import { useProductsState } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/AddProducts/useProductsState';
import CoverageLinesChips from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/EditSubmissionNew/components/CoverageLinesChips';
import { SingleAIFeatureContextProvider } from 'broker/pages/SubmissionWorkspacePage/contexts/ai/SingleAIFeatureContextProvider';
import { useIsBORFlow, useWorkspaceUrls } from 'broker/pages/SubmissionWorkspacePage/hooks';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { Footer } from './Footer';

export interface SelectProductsProps {
  products: InsuranceProduct[];
  isLoading: boolean;
  initSelectedProducts: SelectedProductsMap;
  heraldApplicationResponse?: HeraldApplicationResponse;
}
export default function SelectProducts({
  initSelectedProducts,
  isLoading,
  products,
  heraldApplicationResponse,
}: SelectProductsProps) {
  const { markets: submissionMarkets, partialSubmission, updateSubmission } = useSubmissionsWorkspace();
  const { page, setPage, rowsPerPage, setRowsPerPage, paginationReset } = usePagination();

  const navigate = useNavigate();

  const { isBOR } = useIsBORFlow();

  const { editSubmissionUrl, editBorUrl } = useWorkspaceUrls();

  const acknowledgementSelectedProducts = useMemo(() => {
    const filteredSelectedProducts = products.filter((product) =>
      partialSubmission?.draftAckSelectedProducts?.includes(product.id),
    );
    if (!filteredSelectedProducts.length) {
      return undefined;
    }
    return filteredSelectedProducts.reduce((acc, product) => {
      acc[product.id] = product;
      return acc;
    }, {} as SelectedProductsMap);
  }, [partialSubmission?.draftAckSelectedProducts, products]);

  const productsFilters = useProductFilters(products, isLoading, paginationReset, false);
  const { selectedProducts, onProductSelected, onProductRemoved } = useProductsState(
    acknowledgementSelectedProducts || initSelectedProducts,
  );
  // sync selectedProducts in selectedProductsRef, so that useUnmount will have access to the latest selectedProducts
  const selectedProductsRef = useRef(selectedProducts);

  useEffect(() => {
    selectedProductsRef.current = selectedProducts;
  }, [selectedProducts]);

  useUnmount(() => {
    updateSubmission(partialSubmission!.id, {
      draftAckSelectedProducts: selectedProductsRef.current ? Object.keys(selectedProductsRef.current) : [],
    });
  });

  useEffect(() => {
    // this hook used to clean initial selected that doesn't appear in the available products. Filtering by search should not trigger this hook
    if (!isLoading && !productsFilters.search) {
      // check if the selected products appear in the filtered list and if not remove them
      const selectedProductsIds = Object.keys(selectedProducts);
      const filteredProductsIds = productsFilters.sortedAndFilteredItems.map((product) => product.id);
      const selectedProductsToRemove = selectedProductsIds.filter(
        (productId) => !filteredProductsIds.includes(productId),
      );
      selectedProductsToRemove.forEach((productId) => onProductRemoved(productId));
    }
  }, [selectedProducts, productsFilters.sortedAndFilteredItems, onProductRemoved, isLoading, productsFilters.search]);

  return (
    <InnerPageLayout
      title={messages.acknowledgmentFlow.title}
      sx={{ paddingX: 0, paddingTop: 1, paddingBottom: 0 }}
      footer={(innerPageProps: InnerPageProps) => (
        <Footer innerPageProps={innerPageProps} selectedProducts={selectedProducts} />
      )}
    >
      {() => (
        <SingleAIFeatureContextProvider feature={AIFeature.ProductRecommendation}>
          <Products
            heraldApplicationResponse={heraldApplicationResponse}
            items={products}
            isLoadingProducts={isLoading}
            sortedAndFilteredItems={productsFilters.sortedAndFilteredItems}
            search={productsFilters.search}
            setPage={setPage}
            page={page}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            selectedProducts={selectedProducts}
            onProductSelected={onProductSelected}
            onProductRemoved={onProductRemoved}
            submissionMarkets={submissionMarkets}
            header={
              <Stack mt={2} px={2} direction="row" gap={2}>
                <SearchBar
                  value={productsFilters.search}
                  onChange={productsFilters.setSearch}
                  placeholder={messages.addProductsPage.search}
                  id="search-products"
                  sx={{ minWidth: '40%' }}
                />
                <CoverageLinesChips
                  coverageLines={partialSubmission?.coverageLines || []}
                  setNextStep={() => {
                    if (isBOR) {
                      navigate(editBorUrl, {
                        routeKey: RouteKey.SubmissionEditBOR,
                        state: undefined,
                      });
                    } else {
                      navigate(editSubmissionUrl, {
                        routeKey: RouteKey.SubmissionEdit,
                        state: {
                          preForm: true,
                        },
                      });
                    }
                  }}
                />
              </Stack>
            }
          />
        </SingleAIFeatureContextProvider>
      )}
    </InnerPageLayout>
  );
}
