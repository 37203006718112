import { SubmissionExtractedData } from '@common/types';
import { groupBy, keyBy } from 'lodash';
import moment, { max } from 'moment';
import { QuoteStatus, SubmissionMarketRequestStatus } from 'enums';
import { ActivityLog, Layer, Quote, Submission, SubmissionMarket, SubmissionMarketRequest } from 'types';
import { Nullable } from 'utils';

function getComputedMarketRequests(
  market: SubmissionMarket,
  quotes: Quote[],
  layers: Layer[],
  primaryLayer?: Layer,
): SubmissionMarketRequest[] {
  return market.marketRequests.map((submissionMarketRequest) => {
    const marketRequestQuote = submissionMarketRequest.quoteId
      ? quotes.find((quoteItem) => quoteItem.id === submissionMarketRequest.quoteId)
      : undefined;

    if (marketRequestQuote) {
      submissionMarketRequest.updatedAt = marketRequestQuote.updatedAt;
    } else if (submissionMarketRequest.status === SubmissionMarketRequestStatus.PendingCustomer) {
      submissionMarketRequest.updatedAt = market.updatedAt;
    }

    return {
      ...submissionMarketRequest,
      isPrimary: submissionMarketRequest?.layerId === primaryLayer?.id,
      layer: layers.find((layer) => layer.id === submissionMarketRequest?.layerId),
      quote: marketRequestQuote,
      incumbentInfo: market.incumbentInfo,
      market,
      marketName: market.marketName,
    };
  });
}

export const addExtractedDataToSubmission = (
  submission: Nullable<Submission>,
  extractedData: Nullable<SubmissionExtractedData>,
): Nullable<Submission> => {
  if (!extractedData || !submission) {
    return submission;
  }

  return {
    ...submission,
    submissionExtractedData: extractedData,
  };
};

export const addComputedFieldsToQuote = (quotes: Quote[], markets: SubmissionMarket[], layers: Layer[]) => {
  const nonRejectedQuotes = quotes.filter((quote) => quote.status !== QuoteStatus.Rejected);
  const layerNonRejectedQuotes = groupBy(nonRejectedQuotes, (quote) => quote.layerId);

  const localQuotes = quotes.map((quote) => {
    const quoteMarket = markets.find((market) => market.id === quote.submissionMarketId);
    const quoteLayer = layers.find((layerItem) => layerItem.id === quote.layerId)!;
    return {
      ...quote,
      marketName: quoteMarket?.marketName,
      insuranceProduct: quoteMarket?.marketRequests.find((marketRequest) => marketRequest.quoteId === quote.id)
        ?.insuranceProduct,
      layer: quoteLayer,
      isSingleInLayer: quote.status !== QuoteStatus.Rejected && layerNonRejectedQuotes[quote.layerId].length === 1,
    };
  });

  return localQuotes.map((quote) => ({
    ...quote,
    primaryQuote: localQuotes.find((quoteItem) => quoteItem.id === quote.primaryQuoteId),
  }));
};

export const addSelectedQuoteLimitToLayer = (quotes: Quote[], layers: Layer[]) =>
  layers.map((layer) => ({
    ...layer,
    limit: quotes.find((quote) => quote.layerId === layer.id && quote.status === QuoteStatus.Selected)?.limit,
  }));

export const addCalculatedDataToMarket = (
  layers: Layer[],
  markets: SubmissionMarket[],
  quotes: Quote[],
): SubmissionMarket[] => {
  const primaryLayer = layers.find((layer) => layer.attachmentPoint === undefined);
  const nonRejectedQuotes = quotes.filter((quote) => quote.status !== QuoteStatus.Rejected);

  return markets.map((market) => {
    const marketQuote = nonRejectedQuotes.find((quote) => quote.submissionMarketId === market.id);

    const computedMarketRequests = getComputedMarketRequests(market, quotes, layers, primaryLayer);

    const lastUpdated = max([
      ...computedMarketRequests.map((computedMarketRequest) => moment(computedMarketRequest.updatedAt)),
    ]).toISOString();

    return {
      ...market,
      isPrimary: marketQuote?.layerId === primaryLayer?.id,
      activeQuotes: nonRejectedQuotes.filter((quote) => quote.submissionMarketId === market.id),
      quote: marketQuote,
      updatedAt: lastUpdated,
      marketRequests: computedMarketRequests,
    };
  });
};

export const resolveActivities = (activities: ActivityLog[], layers: Layer[], quotes: Quote[]) => {
  const quoteById = keyBy(quotes, 'id');
  activities.forEach((activity) => {
    if (activity.metadata?.quoteId) {
      activity.quote = quoteById[activity.metadata.quoteId];
    }
    if (activity.metadata?.layerId) {
      const activityLayerId = activity.metadata.layerId;
      activity.layer = layers.find((layer) => layer.id === activityLayerId);
    }
  });

  return activities;
};
