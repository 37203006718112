import moment from 'moment-timezone';

export const DisplayDateFormat = 'MM/DD/YYYY';
export const DisplayYearFormat = 'YYYY';
export const DisplayDateFormatAlt = 'DD-MM-YYYY';
export const DisplayDateWithHoursFormat = 'MM/DD/YYYY hh:mm A';
export const DisplayHoursFormat = 'hh:mm A';
export const DisplayMonthAndDay = 'MMM Do';

// This function should only be used to format dates that are not timezone specific
// as they don't have a time component, only date. E.g. a date that was selected from a date picker
// Users in different timezones will see the same date
export function formatDateWithoutTime(date: string | Date, format: string): string {
    return moment.utc(date).format(format);
}

/**
 * Converts a US-formatted date (MM/DD/YYYY) to ISO format (YYYY-MM-DD).
 * @param date - The date string in MM/DD/YYYY format.
 * @returns The date string in ISO format (YYYY-MM-DD) or an empty string if invalid.
 */
export function convertDateStringToIsoFormat(date: string): string {
    return formatDateWithoutTime(date, 'YYYY-MM-DD');
}

export function getTimezoneLessDisplayDate(date: string | Date): string {
    return formatDateWithoutTime(date, DisplayDateFormat);
}

export function parseTimezoneLessDate(
    date: string,
    format: string = DisplayDateFormat,
    fallbackToUnknownFormat = true,
): Date | undefined {
    let momentObj = moment.utc(date, format);
    if (momentObj.isValid()) {
        return momentObj.toDate();
    }
    if (fallbackToUnknownFormat) {
        momentObj = moment.utc(date);
        if (momentObj.isValid()) {
            return momentObj.toDate();
        }
    }
    return undefined;
}

interface UTCStartOfDayFromTimezoneParams {
    timezone?: string;
    date?: moment.Moment;
}

/**
 * Gets the start of the day in UTC for a given date and timezone.
 *
 * This function converts a given date to the start of the day (00:00:00) in UTC,
 * based on the date in the specified timezone. This ensures that the returned date
 * represents the start of the day in UTC, corresponding to the local date in the
 * provided timezone.
 * @returns The moment object representing the start of the day in UTC.
 *
 * @example
 * // If the input date is "2024-08-16T02:00:00+03:00" and the timezone is 'America/New_York',
 * // the function will return "2024-08-15T00:00:00Z".
 * const startOfDayUTC = getUTCStartOfDayFromTimezone('America/New_York', moment("2024-08-16T02:00:00+03:00"));
 * console.log(startOfDayUTC.format()); // Output: "2024-08-15T00:00:00Z"
 */
export function getUTCStartOfDayFromTimezone(params?: UTCStartOfDayFromTimezoneParams): moment.Moment {
    const { timezone = 'America/New_York', date = moment() } = { ...params };
    // Get the current time in timezone
    const timeZoneTime = date.tz(timezone);

    // Extract the date in 'YYYY-MM-DD' format
    const timeZoneDate = timeZoneTime.format('YYYY-MM-DD');

    return moment.utc(timeZoneDate);
}

export function addXBusinessDaysFromDate(date: moment.Moment, xBusinessDays: number): moment.Moment {
    let businessDays = 0;
    let currentDate = date;
    while (businessDays < xBusinessDays) {
        currentDate = currentDate.add(1, 'days');
        if (currentDate.isoWeekday() < 6) {
            businessDays += 1;
        }
    }
    return currentDate;
}
