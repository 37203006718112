import { parseTimezoneLessDate } from '../date-utils';

export function normalizeDateExtractionToSubmissionModel(date: string) {
    return parseTimezoneLessDate(date);
}

// extraction are in US format MM/DD/YYYY, and we need to convert them to ISO format (same to the form value format of date components), we want to compare them in the same format to see if extraction/default suggestion is the same
export function normalizeDateExtractionToFormValue(value: string) {
    return parseTimezoneLessDate(value)?.toISOString();
}
