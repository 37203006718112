import { IconButton } from '@common-components';
import { FastForwardOutlined as FastForwardOutlinedIcon } from '@icons';
import { useOptionsMenu } from 'hooks';
import { RenewalDemoAIBubble } from './RenewalDemoAIBubble';

export default function RenewalDemoIconButton() {
  const { anchorEl, isMenuOpen, openMenu, closeMenu } = useOptionsMenu();
  return (
    <>
      <IconButton bgcolor="green.300" color="inherit" size="small" icon={FastForwardOutlinedIcon} onClick={openMenu} />
      {isMenuOpen && <RenewalDemoAIBubble anchorEl={anchorEl} open={isMenuOpen} onClose={closeMenu} />}
    </>
  );
}
