import { getTimezoneLessDisplayDate } from '@common/utils/date-utils';
import { UserDetails } from 'types';
import { QuoteFormCreate } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/Quote/form-methods/types';

export const getRetailerFullName = (user: UserDetails | undefined) =>
  `${user?.firstName || ''} ${user?.lastName || ''}`;

export const getTodayDate = () => getTimezoneLessDisplayDate(new Date());

export const getInsuredName = (insuredName: string | undefined) => insuredName || '';

export const getExpiresAtDate = (effectiveDate: Date | undefined) =>
  effectiveDate ? getTimezoneLessDisplayDate(effectiveDate) : '';

export const getPremium = (quoteForm: QuoteFormCreate) =>
  Number(quoteForm.premium || 0) + Number(quoteForm.triaPremium || 0);

export const getTaxAndFees = (quoteForm: QuoteFormCreate) =>
  Number(quoteForm.surplusLineTax || 0) +
  Number(quoteForm.surplusStampingFee || 0) +
  Number(quoteForm.carrierFee || 0) +
  Number(quoteForm.flowBrokerFee || 0);

export const getCommission = (quoteForm: QuoteFormCreate) =>
  quoteForm.agentCommission ? `${quoteForm.agentCommission}%` : '';

export const currency = '$';
