import { isCustomerOrganization, isMarketOrganization } from 'enums';
import { SubmissionMarket, UserMarket } from 'types';
import { getAvailableContactsForUserMarket } from 'broker/components/EmailEditor/store/utils';
import { RecipientsGroupsAvailableRecipients } from 'broker/components/EmailEditor/types';
import { convertContactToRecipient, Recipient, RecipientGroup } from 'broker/components/Emails/recipient-utils';

function getRecipientGroupAvailableRecipients(
  recipientGroup: RecipientGroup,
  markets: UserMarket[],
  submissionMarkets: SubmissionMarket[],
) {
  const recipientGroupUserMarket = markets.find((market) => market.id === recipientGroup.id);
  if (!recipientGroupUserMarket) {
    return [];
  }
  return (
    getAvailableContactsForUserMarket(recipientGroupUserMarket, submissionMarkets).map((contact) =>
      convertContactToRecipient(contact),
    ) || []
  );
}
export function createRecipientsGroupsAvailableRecipients(
  recipientGroups: RecipientGroup[],
  userMarkets: UserMarket[],
  submissionMarkets: SubmissionMarket[],
  preSelectedRecipientsGroup: RecipientGroup[] = [],
  retailerTeamMembers?: Recipient[],
): RecipientsGroupsAvailableRecipients {
  const recipientsGroupsAvailableRecipients: RecipientsGroupsAvailableRecipients = {};
  recipientGroups.forEach((recipientGroup) => {
    if (isMarketOrganization(recipientGroup.type)) {
      recipientsGroupsAvailableRecipients[recipientGroup.id] = getRecipientGroupAvailableRecipients(
        recipientGroup,
        userMarkets,
        submissionMarkets,
      );
    } else if (retailerTeamMembers && isCustomerOrganization(recipientGroup.type)) {
      // if we received list of retailerTeamMembers then they should be used as the available recipients for retailer email
      recipientsGroupsAvailableRecipients[recipientGroup.id] = retailerTeamMembers;
    } else {
      // default case is the available recipients group that started with preSelected
      recipientsGroupsAvailableRecipients[recipientGroup.id] =
        preSelectedRecipientsGroup.find(
          (preSelectedRecipientGroup) => preSelectedRecipientGroup.id === recipientGroup.id,
        )?.recipients || [];
    }
  });
  return recipientsGroupsAvailableRecipients;
}
