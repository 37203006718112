import { compact } from 'lodash';
// eslint-disable-next-line import/no-unresolved
import { PartialSubmission, Quote } from 'types';
import { getTimezoneLessDisplayDate, htmlToPdfMakeWrapper, logger } from 'utils';
import { InsightsData } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/Quote/form-methods/types';

interface PdfContentProps {
  submission: PartialSubmission;
  quotes: Quote[];
  insights: InsightsData;
}

export const getPdfContent = ({ submission, quotes, insights }: PdfContentProps) => {
  logger.log('info', {
    message: 'getPdfContent arguments',
    submissionId: submission.id,
    quotesIds: quotes.map((quote) => quote.id),
  });

  const marketNames = quotes.map((quote) => quote.marketName).join(', ');

  return compact([
    {
      style: 'tableExample',
      layout: 'noBorders',
      table: {
        widths: ['*', '*'],
        body: [
          [
            (submission.user?.firstName || '') + (submission.user?.lastName ? ` ${submission.user?.lastName}` : ''),
            { text: getTimezoneLessDisplayDate(new Date()), alignment: 'right' },
          ],
          [submission.organizationName || '', ''],
        ],
      },
    },
    {
      text: '\n\n',
      lineHeight: 1.1,
    },
    {
      text: `Quote Comparison Analysis - ${submission.insuredName} - ${marketNames}:`,
      bold: true,
    },
    insights.insights ? htmlToPdfMakeWrapper(insights.insights) : undefined,
    {
      text: '\n\n',
      lineHeight: 1.1,
    },
    { text: '------------------------------------', bold: true },
    {
      text: 'This summary is for illustrative purposes only and is not a contract. It is intended to provide a general overview of the coverage quotations asked to be compared',
      bold: true,
      color: 'red',
    },
  ]);
};
