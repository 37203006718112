import { SubmissionType } from '@common/enums/SubmissionType';
import * as commonTypes from '@common/types';
import { SubmissionExtractedData } from '@common/types';
import {
  CompanyType,
  SubmissionStatus,
  SubmissionTask,
  SubmissionTaskStatus,
  SubmissionTerminationReason,
} from 'enums';
import { MakeFieldsRequired } from 'utils/typescript-utils';
import { WorkspaceTabsRoutesValues } from 'broker/broker-routes';
import Contact from './contact';
import { CopilotSettings } from './copilot';
import { Notification } from './notification';
import { Organization } from './organization';
import { Proposal } from './proposal';
import { Team } from './team';
import { UserDetails } from './user-details';

// submission entity that can be in any phase/status (meaning many fields are optional and not necessarily defined)
// should be used in components that are not specific to after the pre-marketing phase
export interface PartialSubmission extends commonTypes.Submission {
  createdAt: Date;
  deletedAt: Date | null;
  updatedAt: Date;
  insuredId: string | null;
  team?: Team;
  organizationId: string;
  organization?: Organization;
  user?: UserDetails;
  // assignee must exist as we auto assign once we get a submission
  assignee: UserDetails;
  leadAssignee?: UserDetails;
  status: SubmissionStatus;
  // used for filtering from server by status
  includedStatuses?: SubmissionStatus[];
  contacts?: Contact[];
  organizationName: string;
  insuredCompanyType?: CompanyType | null;
  proposal?: Proposal | null;
  copilotSettings?: CopilotSettings;
  stepsMetadata: StepsMetadata;
  // this is used to update the submission user, but it's not part of the submission DTO
  tasks: Tasks;
  archiveReason?: ArchiveReason;
  draftMarketingUpdate?: DraftMarketingUpdate;
  isDemo: boolean;
  isCopilotEnabled: boolean;
  boxFolderId: string;
  isFastTrack: boolean;

  // This property is set on the client side, as part of useRetrieveExtendedSubmission
  submissionExtractedData?: SubmissionExtractedData;
}

// fields that are required to turn a submission from partial to a submission (ready to start marketing)
export enum SubmissionBlockingMarketingFields {
  Assignee = 'assignee',
  CoverageLines = 'coverageLines',
}

// submission type ready for marketing
export type Submission = MakeFieldsRequired<PartialSubmission, SubmissionBlockingMarketingFields>;

export interface SubmissionSearchItem extends PartialSubmission {
  urgentNotificationsCount?: number;
  mostUrgentNotification?: Notification;
}

// api interface for creating a new submission
export interface SubmissionCreate {
  userId: string;
  type: SubmissionType;
}

export type StepsMetadata = Record<WorkspaceTabsRoutesValues, { markedAsDone: boolean }>;

export interface TaskMetadata {
  status: SubmissionTaskStatus;
}

export type Tasks = {
  [task in SubmissionTask]?: TaskMetadata;
};

export interface ArchiveReason {
  reason: SubmissionTerminationReason;
  details?: string;
  archivedAt?: Date;
  archivedBy?: string;
}

export interface DraftMarketingUpdate {
  updatedAt?: Date;
  pdfContent?: string;
  pdfFileId?: string;
  emailSubject?: string;
  emailBody?: string;
  attachmentsFileIds?: string[];
}
