import { StateCompliance } from '@common/types';
import { isEmpty } from 'lodash';
import { useCallback, useState } from 'react';
import { Route, Routes } from 'react-router-dom-latest';
import { Button, LoaderOverlay } from '@common-components';
import { ExtractionType, FileType, QuoteFileIdType } from 'enums';
import { useMutateQuote, useSearchSubmissionFileMetadata } from 'hooks';
import { messages } from 'i18n';
import { Layer, Suggestion } from 'types';
import { getFileType } from 'utils';
import { QuoteFormRoutes } from 'broker/broker-routes';
import { ExtendedBoxItem } from 'broker/components/FilesExplorer/types';
import { getFileWithSyncMetadata } from 'broker/components/FilesExplorer/utils';
import { QuoteConfig } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/Quote/config';
import { useUiStoreActions, useUiStoreState } from 'broker/pages/SubmissionWorkspacePage/ui-store/uiStoreProvider';
import Details from './formSteps/Details';
import FlowQuote from './formSteps/FlowQuote';
import QuoteInsights from './formSteps/QuoteInsights';
import Upload from './formSteps/Upload';
import { QuoteFormProps } from './types';
import useGetSelectProducts from './useGetSelectProducts';
import { useTrackFileExtractions } from './useTrackFileExtractions';

interface QuoteFormFieldsProps extends Omit<QuoteFormProps, 'fileIds' | 'onClose'> {
  marketQuoteFileIds: string[];
  setFlowQuoteIds: (flowQuoteId: string[]) => void;
  extractedDataSuggestions?: Record<string, Suggestion>;
  marketName: string;
  isDirty: boolean;
  quoteConfig: QuoteConfig;
  stateCompliance?: StateCompliance;
}

export default function QuoteFormFields(quoteFormProps: QuoteFormFieldsProps) {
  const {
    currentStep,
    mode,
    submission,
    submissionMarkets,
    layers,
    fileType,
    products,
    productsLoading,
    initialProductId,
    marketQuoteFileIds,
    flowQuoteIds,
    setFlowQuoteIds,
    flowGenerationStatus,
    setFlowGenerationStatus,
    hasMarketQuoteFileLabel,
    isRequiredLabelMissing,
    extractedDataSuggestions,
    setInsightPopulatedByAI,
    isDirty,
    selectedLayer,
    quoteConfig,
    selectedQuote,
    stateCompliance,
    isNonAdmittedProduct,
    marketName,
  } = quoteFormProps;

  const selectProducts = useGetSelectProducts({ submissionMarkets, insuranceProducts: products });

  const [formLayers, setLayers] = useState(layers);
  const {
    filesExplorer: { selectedFiles },
  } = useUiStoreState();
  const { setFilesExplorer } = useUiStoreActions();

  const setSelectedFiles = useCallback(
    (boxItems: ExtendedBoxItem[]) => setFilesExplorer({ selectedFiles: boxItems, isLoaded: true }),
    [setFilesExplorer],
  );

  const { refetch: fetchFilesMetadata } = useSearchSubmissionFileMetadata({
    filter: {
      submissionId: submission.id,
    },
  });

  const updateFilesWithNewMetadata = async () => {
    const { data } = await fetchFilesMetadata();
    if (!isEmpty(selectedFiles)) {
      setSelectedFiles(selectedFiles.map((file) => getFileWithSyncMetadata(file, data?.items)));
    }
  };

  const { startTrackingFileExtraction, clearAllTrackedExtractions, hasPendingExtractions } =
    useTrackFileExtractions(updateFilesWithNewMetadata);

  const { extractQuoteData } = useMutateQuote();

  const requestExtraction = async (boxFile: ExtendedBoxItem) => {
    const boxFileType = getFileType(boxFile);
    const fileId = boxFile.id;

    // We don't want the user to wait for Policy Specimen extraction since the results are not used in the UI
    if (boxFileType !== FileType.PolicySpecimen) {
      startTrackingFileExtraction(fileId);
    }
    const data = {
      submissionId: submission.id,
      fileId,
    };

    // FileType is the quote form type (e.g. MarketQuote, Policy, Binder)
    if (fileType === QuoteFileIdType.MarketQuote) {
      if (boxFileType === FileType.PolicySpecimen) {
        await extractQuoteData({ ...data, extractionType: ExtractionType.PolicySpecimen });
      } else {
        await extractQuoteData({ ...data, extractionType: ExtractionType.Quote });
      }
    } else if (fileType === QuoteFileIdType.Policy) {
      await extractQuoteData({ ...data, extractionType: ExtractionType.Policy });
    }
  };

  const addLayer = (layer: Layer) => {
    setLayers(formLayers.concat(layer));
  };

  return (
    <>
      <Routes>
        <Route
          path={`${QuoteFormRoutes.Upload}/*`}
          element={
            <Upload
              quoteConfig={quoteConfig}
              currentStep={currentStep}
              fileType={fileType}
              mode={mode}
              submission={submission}
              marketName={marketName}
              isRequiredLabelMissing={isRequiredLabelMissing}
              onContinue={async () => {
                const { filesToExtract } = quoteConfig;
                if (filesToExtract.length) {
                  const filesWithNoExtractions = filesToExtract.filter((file) => !file.syncMetadata);
                  await Promise.all(filesWithNoExtractions.map((file) => requestExtraction(file)));
                }
              }}
            />
          }
        />
        <Route
          path={`${QuoteFormRoutes.Details}/*`}
          element={
            <Details
              quoteConfig={quoteConfig}
              fileType={fileType}
              mode={mode}
              layers={formLayers}
              selectProducts={selectProducts}
              productsLoading={productsLoading}
              initialProductId={initialProductId}
              initialLayer={selectedLayer}
              onAddNewLayer={addLayer}
              extractedDataSuggestions={extractedDataSuggestions}
              marketQuoteFileIds={marketQuoteFileIds}
              setInsightPopulatedByAI={setInsightPopulatedByAI}
              submission={submission}
              selectedQuote={selectedQuote}
              hasMarketQuoteFileLabel={hasMarketQuoteFileLabel}
              stateCompliance={stateCompliance}
              isNonAdmittedProduct={isNonAdmittedProduct}
            />
          }
        />
        <Route
          path={`${QuoteFormRoutes.Insights}/*`}
          element={<QuoteInsights mode={mode} hasMarketQuoteFileLabel={hasMarketQuoteFileLabel} />}
        />

        <Route
          path={`${QuoteFormRoutes.FlowQuote}/*`}
          element={
            <FlowQuote
              mode={mode}
              submission={submission}
              marketQuoteFileIds={marketQuoteFileIds}
              marketName={marketName}
              flowQuoteIds={flowQuoteIds}
              setFlowQuoteIds={setFlowQuoteIds}
              flowGenerationStatus={flowGenerationStatus}
              setFlowGenerationStatus={setFlowGenerationStatus}
              isDirty={isDirty}
            />
          }
        />
      </Routes>
      {hasPendingExtractions && (
        <LoaderOverlay
          text={messages.addQuotePage.extractingData}
          extraContent={<Button onClick={clearAllTrackedExtractions}>{messages.general.abort}</Button>}
        />
      )}
    </>
  );
}
