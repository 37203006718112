import { compact, isEmpty, round, toNumber } from 'lodash';
import moment from 'moment';
import { Address, ExtractedAddressValue, ExtractedAreaOfPractice, ExtractedAttribute } from '../../types';
import { convertCoverageLinesTextToEnum, isValidDomain, isValidEmail } from '..';
import { DisplayDateFormat } from '../date-utils';

// Removes currency symbols, commas, percentage signs, spaces, line breaks, and 'usd' (case-insensitive) from a numeric string
function cleanNumericString(value: string): string {
    return value.replace(/(?:[,\n% $-]|usd)/gi, '').trim();
}

/**
 * Filters and validates a numeric string by removing currency symbols, commas, and percentage signs.
 * Optionally returns the number as an integer or a floating-point string based on the `integerSchemaType` flag.
 *
 * @param value - The input string to filter and validate.
 * @param integerSchemaType - If true (default), rounds the number to the nearest integer.
 *                             If false, returns the floating-point number as-is.
 * @returns A filtered numeric string if valid, otherwise undefined.
 *
 * @example
 * filterValidNumeric('$1,234.56')          // '1235' (rounded to integer by default)
 * filterValidNumeric('$1,234.56', false)   // '1234.56' (returns floating-point value)
 * filterValidNumeric('USD 100%')           // '100'
 * filterValidNumeric('abc')                // undefined
 */
export function filterValidNumeric(value: string, integerSchemaType = true): string | undefined {
    const filteredValue = cleanNumericString(value);

    if (!Number.isNaN(Number(filteredValue))) {
        const numberValue = toNumber(filteredValue);
        return (integerSchemaType ? round(numberValue) : numberValue).toString();
    }

    return undefined;
}

export function normalizeNumericExtraction(
    extractedAttribute: ExtractedAttribute<string>,
    isInteger = true,
): ExtractedAttribute<string> | undefined {
    const filteredValue = extractedAttribute.value
        ? filterValidNumeric(extractedAttribute.value, isInteger)
        : undefined;
    if (filteredValue) {
        return {
            ...extractedAttribute,
            value: filteredValue,
        };
    }
    return undefined;
}

export function normalizeMultipleNumericExtractions(
    extractedAttribute: ExtractedAttribute<string[]>,
): ExtractedAttribute<string[]> | undefined {
    const numericValues = compact(extractedAttribute.value?.map((val) => filterValidNumeric(val)));
    if (isEmpty(numericValues)) {
        return undefined;
    }
    return {
        ...extractedAttribute,
        value: numericValues,
    };
}

export function normalizeDomainExtraction(
    extractedAttribute: ExtractedAttribute<string>,
): ExtractedAttribute<string> | undefined {
    if (extractedAttribute.value && isValidDomain(extractedAttribute.value)) {
        return extractedAttribute;
    }
    return undefined;
}

export function normalizeOptionExtraction<T extends string | number>(
    extractedAttribute: ExtractedAttribute<string>,
    options: T[],
): ExtractedAttribute<string> | undefined {
    const value =
        // if we are comparing number options we need to filterValidNumeric value
        typeof options[0] === 'number' && extractedAttribute?.value
            ? filterValidNumeric(extractedAttribute.value)
            : extractedAttribute.value;

    const foundOption = options.find(
        (option) => (typeof option === 'string' ? option.toLowerCase() : String(option)) === value?.toLowerCase(),
    );

    if (foundOption) {
        extractedAttribute.value = String(foundOption);
        return extractedAttribute;
    }
    return undefined;
}

export function normalizeMultipleOptionsExtraction(
    extractedAttribute: ExtractedAttribute<string[]>,
    options: string[],
): ExtractedAttribute<string[]> | undefined {
    extractedAttribute.value = compact(
        extractedAttribute.value?.map((value) =>
            options.find((option) => option.toLowerCase() === value.toLowerCase()),
        ),
    );
    if (isEmpty(extractedAttribute.value)) {
        return undefined;
    }
    return extractedAttribute;
}

export function normalizeDateExtraction(
    extractedAttribute: ExtractedAttribute<string>,
    // used only once using the extraction in order to build submission data (fast track/saving the submission form for the first time and populating submission data)
    onlyFutureDates = false,
): ExtractedAttribute<string> | undefined {
    if (
        extractedAttribute?.value &&
        moment(extractedAttribute.value, DisplayDateFormat).isValid() &&
        (!onlyFutureDates || moment(extractedAttribute.value, DisplayDateFormat).isAfter(moment()))
    ) {
        return extractedAttribute;
    }
    return undefined;
}

export function normalizeExtractionOnlyFutureDates(extractedAttribute: ExtractedAttribute<string>) {
    return normalizeDateExtraction(extractedAttribute, true);
}

export function normalizeEmailExtraction(
    extractedAttribute: ExtractedAttribute<string>,
): ExtractedAttribute<string> | undefined {
    if (extractedAttribute.value && isValidEmail(extractedAttribute.value)) {
        return extractedAttribute;
    }
    return undefined;
}

export function normalizeCoverageLineExtraction(
    extractedAttribute: ExtractedAttribute<string[]>,
): ExtractedAttribute<string[]> | undefined {
    if (extractedAttribute?.value) {
        return {
            ...extractedAttribute,
            value: convertCoverageLinesTextToEnum(extractedAttribute.value),
        };
    }
    return undefined;
}

export const normalizeAddress = (address: Address) => {
    if (!isEmpty(address.line1) && !isEmpty(address.city) && !isEmpty(address.postal_code) && !isEmpty(address.state)) {
        return {
            line1: address.line1,
            line2: address.line2,
            city: address.city,
            state: address.state,
            postal_code: address.postal_code,
            // country_code has only 1 option in herald form, no need to extract it (if the rest of the address is valid, the country is valid)
            country_code: 'USA',
        };
    }
    return undefined;
};

export function normalizeAddressExtraction(
    extractedAttribute: ExtractedAttribute<ExtractedAddressValue>,
): ExtractedAttribute<ExtractedAddressValue> | undefined {
    const addressValue = extractedAttribute.value;

    if (addressValue) {
        const normalizedPrimaryAddress = addressValue?.primary ? normalizeAddress(addressValue.primary) : undefined;
        const normalizedMailingAddress = addressValue?.mailing ? normalizeAddress(addressValue.mailing) : undefined;

        return {
            ...extractedAttribute,
            value: {
                // if no primary address is provided, the mailing address is used as the primary address
                primary: normalizedPrimaryAddress || normalizedMailingAddress,
                // if no mailing address is provided, the primary address is used as the mailing address
                mailing: normalizedMailingAddress || normalizedPrimaryAddress,
            },
        };
    }

    return undefined;
}

export function normalizeAreaOfPracticeExtraction(
    extractedAttribute: ExtractedAttribute<ExtractedAreaOfPractice[]>,
): ExtractedAttribute<ExtractedAreaOfPractice[]> | undefined {
    if (extractedAttribute.value) {
        const values = compact(
            extractedAttribute.value.map((item) => {
                const percentage = item.percentage ? filterValidNumeric(item.percentage) : undefined;
                if (item.area && percentage) {
                    return {
                        percentage,
                        area: item.area,
                    };
                }
                return undefined;
            }),
        );
        if (!isEmpty(values)) {
            return {
                ...extractedAttribute,
                value: values,
            };
        }
    }
    return undefined;
}
