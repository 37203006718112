import { isEmpty } from 'lodash';
import { AnyObject } from './typescript-utils';
import { DynamicQuestion } from '../dynamic-form/types';

export function doesObjectContainNonEmptyValues(object: AnyObject) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return Object.entries(object).some(([_key, val]) => !isEmpty(val));
}

export function isDynamicQuestionParameterNotAnswered(dynamicQuestionNormalizedParameter: DynamicQuestion): boolean {
    if (dynamicQuestionNormalizedParameter.arrayElements?.length) {
        return dynamicQuestionNormalizedParameter.arrayElements.some((element) =>
            isDynamicQuestionParameterNotAnswered(element),
        );
    }

    if (dynamicQuestionNormalizedParameter.childValues?.length) {
        return dynamicQuestionNormalizedParameter.childValues.some((element) =>
            isDynamicQuestionParameterNotAnswered(element),
        );
    }

    // if dynamicQuestionNormalizedParameter is optional then it is ok even of not answered
    return (
        !dynamicQuestionNormalizedParameter.requiredForCompletion || dynamicQuestionNormalizedParameter.value !== null
    );
}
