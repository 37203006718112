import { useQuery } from '@tanstack/react-query';
import { ForbiddenError } from 'clients/errors/ForbiddenError';
import { HeraldApplicationResponse, HeraldUpsertParams } from 'clients/types';
import { QueryKeys } from 'enums';
// eslint-disable-next-line import/no-internal-modules
import { useHeraldApiClient } from 'hooks/useHeraldApiClient';
import { Features, isFeatureEnabled } from 'utils/features-config';
import { useCurrentUser } from './currentUser';
import { UseQueryBaseRetrieveOptions } from './query/types';
import { useQueryMutation } from './query/useMutate';
import { defaultQueryOptions, useImperativeQuery } from './query/useQuery';

export function useRetrieveHeraldApplication(
  props: Omit<UseQueryBaseRetrieveOptions<HeraldApplicationResponse>, 'id'> & { id: string },
) {
  const heraldClient = useHeraldApiClient();

  const { id, queryOptions, enabled } = props;
  return useQuery([QueryKeys.HeraldApiApplication, { id }], async () => heraldClient.getApplicationById(id), {
    ...defaultQueryOptions,
    cacheTime: Infinity,
    staleTime: Infinity,
    enabled: enabled && !!id,
    ...queryOptions,
  });
}

export function useHeraldIndustryClassificationApi() {
  const heraldClient = useHeraldApiClient();

  const retrieveIndustryClassificationByHeraldId = useImperativeQuery({
    queryFn: (heraldId: string) => heraldClient.retrieveIndustryClassificationByHeraldId(heraldId),
    queryKey: QueryKeys.HeraldApiIndustryIndexRetrieve,
  });

  const searchIndexEntries = useImperativeQuery({
    queryFn: (filterValue: string) => heraldClient.searchIndexEntries(filterValue),
    queryKey: QueryKeys.HeraldApiIndustryIndexSearch,
  });

  return { retrieveIndustryClassificationByHeraldId, searchIndexEntries };
}

export const useHeraldApplicationApi = (cancelInvalidation = false) => {
  const client = useHeraldApiClient();
  const { me } = useCurrentUser();

  const allowHeraldMutations = !!me && isFeatureEnabled(Features.EditHeraldData, me);

  const getApplicationById = useImperativeQuery({
    queryFn: async (id: string) => {
      const response = await client.getApplicationById(id);
      return response.application;
    },
    queryKey: QueryKeys.HeraldApiApplication,
  });

  const upsertApplication = useQueryMutation(
    (params: HeraldUpsertParams) => {
      if (!allowHeraldMutations) {
        throw new ForbiddenError();
      }

      return client.upsertApplication(params);
    },
    QueryKeys.HeraldApiApplication,
    cancelInvalidation,
  );

  return {
    upsertApplication,
    getApplicationById,
  };
};

export const useHeraldSubmissionApi = (cancelInvalidation = false) => {
  const client = useHeraldApiClient();
  const { me } = useCurrentUser();

  const allowHeraldMutations = !!me && isFeatureEnabled(Features.EditHeraldData, me);

  const createSubmission = useQueryMutation(
    (heraldApplicationId: string) => {
      if (!allowHeraldMutations) {
        throw new ForbiddenError();
      }

      return client.createSubmission(heraldApplicationId);
    },
    QueryKeys.HeraldApiSubmission,
    cancelInvalidation,
  );

  return {
    createSubmission,
  };
};
