import { MouseEvent } from 'react';
import { Box, NewMenu, Selector, SelectorProps } from '@common-components';
import { useOptionsMenu } from 'hooks';
import { messages } from 'i18n';
import { User, UserDetails } from 'types';
import { userFullName } from 'utils';

export type OnAssigneeChange = (submissionId: string, userId: string) => Promise<void>;

interface SubmissionAssigneeListProps {
  submissionId: string;
  usersList: User[];
  onAssigneeChange: OnAssigneeChange;
  selectedUser: UserDetails;
  selectorProps?: Partial<SelectorProps>;
  disabled?: boolean;
  prefix?: boolean;
  viewOnlyMode?: boolean;
}

export default function SubmissionAssigneeList({
  submissionId,
  usersList,
  selectedUser,
  onAssigneeChange,
  selectorProps,
  disabled,
  prefix,
  viewOnlyMode,
}: SubmissionAssigneeListProps) {
  const optionsMenu = useOptionsMenu();

  const onButtonClick = (e?: MouseEvent<HTMLButtonElement>) => {
    e?.stopPropagation();
    optionsMenu.openMenu(e);
  };

  return (
    <Box position="relative" className="cap-submission-assignee-menu">
      <Selector
        viewMode={viewOnlyMode}
        rounded
        content={`${prefix ? messages.submissionsView.assignee : ''}${userFullName(selectedUser, true)}`}
        onClick={onButtonClick}
        tooltipContent={userFullName(selectedUser)}
        size="small"
        fullWidth
        disabled={disabled}
        {...selectorProps}
      />
      <NewMenu
        optionsMenuState={optionsMenu}
        menuItems={[
          {
            key: 'submission-assignee-menu-popover',
            selectionType: 'radio',
            items: usersList.map((user) => ({
              label: userFullName(user),
              isSelected: user.id === selectedUser?.id,
              onClick: () => {
                optionsMenu.closeMenu();
                onAssigneeChange(submissionId, user.id);
              },
            })),
          },
        ]}
      />
    </Box>
  );
}
