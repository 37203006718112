import { SubmissionExtractedData } from '@common/types';
import { HttpMethods } from 'utils/http';
import { CapitolaClient } from './base';

export class SubmissionExtractedDataClient extends CapitolaClient<SubmissionExtractedData> {
  public get resourceName(): string {
    return 'submission-extracted-data';
  }

  public async getBySubmissionId(submissionId: string): Promise<SubmissionExtractedData | null> {
    return this.custom({
      httpMethod: HttpMethods.GET,
      path: 'by-submission-id',
      query: { submissionId },
      fetchOptions: { throwOnError: true, ignoreStatusCodes: [404] },
    });
  }
}
