import { first } from 'lodash';
import { CAPITOLA_SITE_ROLES, ContactRole, QuoteFileIdType, UserStatus } from 'enums';
import { messages } from 'i18n';
import { Contact, UserDetails } from 'types';
import { formatBigNumber, formatPrice } from './money-utils';

interface contactFullNameProps {
  showTitle?: boolean;
}
export function attachmentPointText(attachmentPoint?: number, lowercase = false): string {
  if (attachmentPoint) {
    return `${messages.general.xs} $${formatBigNumber(attachmentPoint)}`;
  }

  return lowercase ? messages.general.primary.toLowerCase() : messages.general.primary;
}

export function layerTitle(
  quoteLimit?: number,
  quotePremium?: number,
  quoteMarketName?: string,
  attachmentPoint?: number,
): string {
  const quoteLimitText = quoteLimit === undefined ? '' : `$${formatBigNumber(quoteLimit)}`;
  const quotePremiumText =
    quotePremium === undefined ? '' : `@ $${formatPrice(quotePremium)} ${messages.general.premium}`;

  const quoteMarketNameText = quoteMarketName === undefined ? '' : `[${messages.general.xsOfMarket(quoteMarketName)}]`;

  return `${quoteLimitText} ${attachmentPointText(attachmentPoint)} ${quotePremiumText} ${quoteMarketNameText}`;
}

export function excessLayerTitle(
  quoteLimit?: number,
  quotePremium?: number,
  quoteMarketName?: string,
  attachmentPoint?: number,
): string {
  if (!attachmentPoint) {
    // Not an excess layer
    return '';
  }
  return layerTitle(quoteLimit, quotePremium, quoteMarketName, attachmentPoint);
}

export function contactFullName(contact: Contact, additionalProps?: contactFullNameProps) {
  const { role, firstName, lastName, title } = contact;

  if (role === ContactRole.SubmissionBox && !firstName && !lastName) {
    return messages.general.submissionBox;
  }

  // external contacts have no name
  if (!firstName && !lastName) {
    return contact.email;
  }
  const fullName = `${firstName} ${lastName}`;
  // Non submission box contacts are expected to always have a name
  return additionalProps?.showTitle && title ? `${fullName}, ${title}` : fullName;
}

export function userFullName(user: UserDetails, shortenLastName = false) {
  const { firstName, lastName, role, status } = user;
  if (CAPITOLA_SITE_ROLES.includes(role)) {
    return messages.indicators.capitola;
  }

  if (status === UserStatus.PendingReview && !firstName && !lastName) {
    return messages.general.unknownUserName;
  }
  if (shortenLastName) {
    return `${firstName} ${first(lastName)}.`;
  }

  return `${firstName} ${lastName}`;
}

export function quoteRemovedToast(marketName: string, quoteType: QuoteFileIdType) {
  return `${marketName}'s ${messages.market.filesTypeName[quoteType].toLowerCase()} has been successfully removed.`;
}

export function attachmentPointRemovedToast(attachmentPoint: number) {
  return `${formatBigNumber(attachmentPoint)} attachment point has been successfully removed.`;
}

export function extractErrorMessage(err: unknown) {
  return err instanceof Error ? err.message : messages.general.operationFailed;
}

export function formatPriceOrNA(price: number | undefined, minimumDecimalDigits?: number) {
  return price ? `$${formatPrice(price, minimumDecimalDigits)}` : 'N/A';
}
