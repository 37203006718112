import { SubmissionType } from '@common/enums/SubmissionType';
import { BoxItem } from 'box-ui-elements/es';
import shortUUID from 'short-uuid';
import {
  EmailMessageRecipientType,
  EmailTemplateType,
  isCustomerOrganization,
  isMarketOrganization,
  MarketRequestType,
  SubmissionEmailLabel,
  SubmissionMarketRequestStatus,
  SubmissionMethod,
} from 'enums';
import { useInvalidateNotification } from 'hooks';
import { useMutateSubmissionEmail } from 'hooks/api/submissionEmail';
import { EmailMessage, EmailMessageBatch, SubmissionMarketCreate, User, UserMarket } from 'types';
import { isDefined, logger } from 'utils';
import { buildSubmissionMarketIdToEmailId } from 'broker/components/EmailEditor/log-email-activity-util';
import { RecipientGroup, RecipientGroups } from 'broker/components/Emails/recipient-utils';
import { useSendDraftEmail } from 'broker/components/Emails/useSendDraftEmail';
import { EmailTemplateConfig } from 'broker/configuration-mappers/email-template-config';
import {
  EmailTemplateContext,
  EmailType,
  RecipientGroupToDraft,
  SubmissionsWorkspaceActions,
  SubmissionsWorkspaceState,
  TemplateInstances,
} from './store/types';
import { PostEmailInformation } from './types';
import { ResolveForSend } from './useResolveDraft';
import { getTemplateInstanceLookup } from './utils/template-instance-utils';

interface usePrepareAndSendEmailProps {
  postEmailsSent?: (postEmailInformation: PostEmailInformation) => Promise<void> | void;
  preSelectedTemplateType?: EmailTemplateType;
  emailLabel?: SubmissionEmailLabel;
  emailRecipientType: EmailType;
  marketRequestType?: MarketRequestType;
  recipientGroups: RecipientGroups;
  recipientGroupsArray: RecipientGroup[];
  cc: User[];
  userMarkets: UserMarket[];
  setIsEditingContentDirty: (state: boolean) => void;
  recipientGroupToDraft: RecipientGroupToDraft;
  templateInstances: TemplateInstances;
  templateContextData: EmailTemplateContext;
  attachedFiles: BoxItem[];
  resolveForSend: ResolveForSend;
  setIsSubmittingEmail: (isSubmitting: boolean) => void;
  isSubmittingEmail: boolean;
  setEmailsSent: (wasSent: boolean) => void;
  submissionState: SubmissionsWorkspaceState;
  submissionActions: SubmissionsWorkspaceActions;
  inReplyToMessageId?: string;
  previewSubject: string;
  previewBody: string;
  addTask?: boolean;
}

export const usePrepareAndSendEmail = ({
  postEmailsSent,
  preSelectedTemplateType,
  emailLabel,
  emailRecipientType,
  recipientGroups,
  recipientGroupsArray,
  cc,
  userMarkets,
  recipientGroupToDraft,
  templateInstances,
  templateContextData,
  attachedFiles,
  resolveForSend,
  setIsSubmittingEmail,
  isSubmittingEmail,
  setEmailsSent,
  marketRequestType,
  submissionState: { submission, markets },
  submissionActions: { addSubmissionMarkets, reFetchWorkspace, addSubmissionMarketRequests },
  inReplyToMessageId,
  previewSubject,
  previewBody,
  addTask,
}: usePrepareAndSendEmailProps) => {
  const { createSubmissionEmail } = useMutateSubmissionEmail();
  const invalidateNotification = useInvalidateNotification();
  const isBySubmissionType = emailRecipientType !== EmailType.UserNotification;

  const getTemplateInstance = getTemplateInstanceLookup(recipientGroupToDraft, templateInstances);

  const { sendEmail, resolvedMessages } = useSendDraftEmail(
    attachedFiles,
    recipientGroups,
    cc,
    resolveForSend,
    templateContextData,
    getTemplateInstance,
    submission!.organizationId,
    isBySubmissionType,
    inReplyToMessageId,
  );

  const isBOR = submission?.type === SubmissionType.BOR;

  const addRecipientGroupsToSubmissionMarkets = async () => {
    const groupKey = shortUUID().new();
    const addedMarkets: SubmissionMarketCreate[] =
      emailRecipientType === EmailType.UserNotification
        ? []
        : Object.values(recipientGroups)
            .filter((group) => isMarketOrganization(group.type))
            .map((market) => ({
              userMarketId: market.id,
              contactIds: market.recipients.map((recipient) => recipient.id),
              activityLogGroupKey: groupKey,
            }));

    return addSubmissionMarkets(addedMarkets, false);
  };

  function getRecipientGroup(message: EmailMessage) {
    const recipient = message.recipients.find(({ type }) => type === EmailMessageRecipientType.To);
    return recipientGroupsArray.find(({ recipients }) =>
      recipients.find(({ email }) => email.toLowerCase() === recipient?.address.toLowerCase()),
    );
  }

  function getRecipientOrganizationId(recipientGroup?: RecipientGroup) {
    if (isCustomerOrganization(recipientGroup?.type)) {
      // if we get here, recipientGroup cannot be undefined
      return recipientGroup!.id;
    }

    // group type is market
    const userMarket = userMarkets.find((item) => item.id === recipientGroup?.id)!;
    return userMarket?.organizationId;
  }

  function getUnresolvedBody(recipientGroup?: RecipientGroup) {
    if (!recipientGroup) {
      return '';
    }

    const templateInstance = getTemplateInstance(recipientGroup?.id);
    return templateInstance?.body || '';
  }

  function getLabel() {
    if (emailLabel) {
      return emailLabel;
    }

    if (preSelectedTemplateType) {
      return EmailTemplateConfig[preSelectedTemplateType].submissionEmailLabel;
    }

    return undefined;
  }

  async function addSubmissionEmails(sentEmails?: EmailMessageBatch) {
    const label = getLabel();

    return Promise.all(
      sentEmails?.messages.map((message) => {
        const recipientGroup = getRecipientGroup(message);
        const recipientOrganizationId = getRecipientOrganizationId(recipientGroup);
        const body = getUnresolvedBody(recipientGroup);

        return createSubmissionEmail.mutateAsync({
          data: {
            emailMessageId: message.id,
            unresolvedContent: body,
            submissionId: submission!.id,
            recipientOrganizationId,
            label,
            addTask,
          },
        });
      }) ?? [],
    );
  }

  async function postSendCallback(sentEmails?: EmailMessageBatch) {
    if (!sentEmails) {
      return;
    }

    if (!isBySubmissionType) {
      await postEmailsSent?.({
        recipientGroups,
        submissionMarketIdToEmailId: {},
        draftPreview: {
          subject: previewSubject,
          body: previewBody,
        },
      });
      return;
    }

    try {
      // First we create the submission emails which is the most important part of this callback function
      // Without the submission email the email will not be visible in the submission workspace
      const submissionEmails = (await addSubmissionEmails(sentEmails)).filter(isDefined);

      const updatedSubmissionMarket = await addRecipientGroupsToSubmissionMarkets();

      const submissionMarketIdToEmailId = buildSubmissionMarketIdToEmailId(submissionEmails, updatedSubmissionMarket);

      const marketsBeforeUpdate = markets;

      const layerId =
        preSelectedTemplateType &&
        [EmailTemplateType.RfqAttachmentPoint, EmailTemplateType.PrimaryMarketing].includes(preSelectedTemplateType)
          ? templateContextData.layer?.id
          : undefined;

      const marketRequests = Object.keys(submissionMarketIdToEmailId)
        .filter((submissionMarketId) => {
          if (emailLabel === SubmissionEmailLabel.SubmissionMarketing) {
            return true;
          }

          const market = marketsBeforeUpdate.find((item) => item.id === submissionMarketId);

          return (
            !market ||
            (market.marketRequests.length === 1 &&
              market.marketRequests[0].status === SubmissionMarketRequestStatus.ReadyToMarket)
          );
        })
        .map((submissionMarketId) => ({
          type: marketRequestType,
          submissionSent: true,
          submissionMarketId,
          layerId,
          submissionMethod: SubmissionMethod.Email,
          status: isBOR ? SubmissionMarketRequestStatus.AwaitingPolicy : SubmissionMarketRequestStatus.AwaitingQuote,
        }));
      await Promise.all([
        addSubmissionMarketRequests(marketRequests, false),
        await postEmailsSent?.({
          recipientGroups,
          submissionMarketIdToEmailId,
          emailTemplateType: preSelectedTemplateType,
          draftPreview: {
            subject: previewSubject,
            body: previewBody,
          },
        }),
      ]);
    } catch (e) {
      // Do nothing these are side effects of sending the email
      // We trigger an error message if these calls fail as part of the base client, but no need to fail the entire flow
    }
  }

  const onSendEmailClick = async () => {
    // isSubmittingEmail is used to prevent multiple clicks on the send button
    if (isSubmittingEmail) {
      return 0;
    }
    const sentEmailsCount = Object.keys(recipientGroups).length;

    setIsSubmittingEmail(true);
    try {
      await sendEmail(postSendCallback);
      setEmailsSent(true);
    } catch (e) {
      logger.log('error', e as Error);

      return 0;
    } finally {
      setIsSubmittingEmail(false);
      await invalidateNotification();
      await reFetchWorkspace();
    }

    return sentEmailsCount;
  };

  return { sendEmail: onSendEmailClick, resolvedMessages };
};
