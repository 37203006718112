import Markdown from 'markdown-to-jsx';
import { ReactElement } from 'react';
import { Box, Link, Stack, Tooltip, Typography } from '@common-components';
import { markdownStyles } from 'utils/markdown-styles';

interface CitationTooltipProps {
  children: ReactElement<any, any>;
  title: string;
  onLinkClick: () => void;
  content?: string;
}

export default function CitationTooltip({ children, title, content, onLinkClick }: CitationTooltipProps) {
  // Convert all \n to br element as for some reason the markdown-to-jsx library slows down when handling \n's
  const tooltipContent = content?.replaceAll('\n', '<br/>') || '';

  return (
    <Tooltip
      tooltipContent={
        <Stack textAlign="left" gap={0.5}>
          <Box borderRadius={1} bgcolor="grey.50" p={2} m={1} mb={0} style={{ whiteSpace: 'pre-wrap' }}>
            <Markdown
              options={{
                overrides: {
                  ...markdownStyles,
                },
              }}
            >
              {tooltipContent}
            </Markdown>
          </Box>
          <Link
            underline="hover"
            m={1}
            mt={0}
            sx={{ cursor: 'pointer' }}
            onClick={onLinkClick}
            component={Typography}
            variant="caption"
          >
            {title}
          </Link>
        </Stack>
      }
      placement="bottom"
    >
      {children}
    </Tooltip>
  );
}
