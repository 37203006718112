import { messages } from 'i18n';
import { PartialSubmission } from 'types';

export interface SubmissionFieldConfigProps {
  isStaticSubmissionRequiredField: boolean;
  label: string;
}

export type SubmissionFieldsConfig = { [key in keyof PartialSubmission]?: SubmissionFieldConfigProps };

export const submissionFieldsConfig: SubmissionFieldsConfig = {
  coverageLines: {
    isStaticSubmissionRequiredField: true,
    label: messages.submissionModal.coverageLines,
  },
  insuredName: {
    isStaticSubmissionRequiredField: true,
    label: messages.submissionModal.companyName,
  },
  insuredPrimaryAddress: {
    isStaticSubmissionRequiredField: true,
    label: messages.submissionModal.insuredPrimaryAddress,
  },
  insuredMailingAddress: {
    isStaticSubmissionRequiredField: false,
    label: messages.submissionModal.insuredMailingAddress,
  },
  needByDate: {
    isStaticSubmissionRequiredField: true,
    label: messages.submissionModal.needByDate,
  },
  dueDate: {
    isStaticSubmissionRequiredField: true,
    label: messages.submissionModal.dueDate,
  },
  limit: {
    isStaticSubmissionRequiredField: true,
    label: messages.submissionModal.limit,
  },
  insuredCompanyType: {
    isStaticSubmissionRequiredField: false,
    label: messages.submissionModal.insuredCompanyType,
  },
  insuredAnnualRevenue: {
    isStaticSubmissionRequiredField: false,
    label: messages.submissionModal.insuredAnnualRevenue,
  },
  insuredEmployeeCount: {
    isStaticSubmissionRequiredField: false,
    label: messages.submissionModal.insuredEmployeeCount,
  },
  industry: {
    isStaticSubmissionRequiredField: true,
    label: messages.submissionModal.industry,
  },
  insuredWebsite: {
    isStaticSubmissionRequiredField: false,
    label: messages.submissionModal.insuredWebsite,
  },
};
