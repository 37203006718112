import { QuoteClient } from 'clients';
import { QueryKeys } from 'enums';
import { useClient } from 'hooks/useClient';
import {
  AICreateThreadOutput,
  BinderPolicyComparisonInput,
  CreateBorPolicy,
  ExtractQuoteData,
  Quote,
  QuoteBinderComparisonInput,
  QuoteComparisonInput,
  QuoteInsightsInput,
  QuotePolicyComparisonInput,
} from 'types';
import { MutateProps, UseQueryBaseSearchOptions } from './query/types';
import { useMutate } from './query/useMutate';
import { useImperativeQuery, useQuerySearch } from './query/useQuery';

export function useSearchQuote(props?: UseQueryBaseSearchOptions<Quote>) {
  const { filter, queryOptions } = props || {};
  return useQuerySearch({
    queryKey: QueryKeys.Quotes,
    clientClass: QuoteClient,
    filter,
    queryOptions: {
      // Default submssion search query options should be passed here for example:   cacheTime: 10 * 60 * 60 * 1000,
      ...queryOptions,
    },
  });
}

export function useMutateQuote(options?: MutateProps) {
  const quoteClient = useClient(QuoteClient);

  const {
    create: createQuote,
    update: updateQuote,
    destroy: destroyQuote,
  } = useMutate<Quote, Quote, Partial<Quote>, QuoteClient>({
    queryKey: QueryKeys.Quotes,
    clientClass: QuoteClient,
    cancelInvalidation: options?.cancelInvalidation,
    createOptions: { throwOnError: true },
    updateOptions: { throwOnError: true },
    destroyOptions: { throwOnError: true },
  });

  const extractQuoteData = useImperativeQuery<void | null, ExtractQuoteData>({
    queryFn: ({ submissionId, fileId, extractionType }) =>
      quoteClient.extractQuoteData({ submissionId, fileId, extractionType }),
    queryKey: [QueryKeys.Quotes, 'extract'],
  });

  const createBorPolicy = useImperativeQuery<void | null, CreateBorPolicy>({
    queryFn: (props) => quoteClient.createBorPolicy(props),
    queryKey: [QueryKeys.Quotes, 'create-bor-policy'],
  });

  return { createQuote, updateQuote, destroyQuote, extractQuoteData, createBorPolicy };
}

export function useCompareQuotes() {
  const quoteClient = useClient(QuoteClient);

  const compareQuotes = useImperativeQuery<AICreateThreadOutput | null, QuoteComparisonInput>({
    queryFn: ({ quoteIds, expiringPolicyFileId }) => quoteClient.compareQuotes({ quoteIds, expiringPolicyFileId }),
    queryKey: [QueryKeys.Quotes, 'compare'],
  });

  const compareQuoteBinder = useImperativeQuery<AICreateThreadOutput | null, QuoteBinderComparisonInput>({
    queryFn: ({ quoteId, binderIds }) => quoteClient.compareQuoteBinder({ quoteId, binderIds }),
    queryKey: [QueryKeys.Quotes, 'compare-quote-binder'],
  });

  const compareBinderPolicy = useImperativeQuery<AICreateThreadOutput | null, BinderPolicyComparisonInput>({
    queryFn: ({ quoteId, policyIds }) => quoteClient.compareBinderPolicy({ quoteId, policyIds }),
    queryKey: [QueryKeys.Quotes, 'compare-binder-policy'],
  });

  const compareQuotePolicy = useImperativeQuery<AICreateThreadOutput | null, QuotePolicyComparisonInput>({
    queryFn: ({ quoteId, policyIds }) => quoteClient.compareQuotePolicy({ quoteId, policyIds }),
    queryKey: [QueryKeys.Quotes, 'compare-quote-policy'],
  });

  return {
    compareQuotes,
    compareQuoteBinder,
    compareBinderPolicy,
    compareQuotePolicy,
  };
}

export function useQuoteInsights() {
  const quoteClient = useClient(QuoteClient);

  const quoteInsights = useImperativeQuery<AICreateThreadOutput | null, QuoteInsightsInput>({
    queryFn: ({ fileIds, submissionId }) => quoteClient.getInsights({ fileIds, submissionId }),
    queryKey: [QueryKeys.Quotes, 'insights'],
  });

  return { quoteInsights };
}
