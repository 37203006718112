import * as pdfmake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
// eslint-disable-next-line import/no-unresolved
import { Content, PageOrientation, TDocumentDefinitions } from 'pdfmake/interfaces';
import { logger } from 'utils';
import { convertFontToBase64, getPdfContentWithTemplate, PdfContentProps } from './pdf-content-creator';

// Set the PDF fonts
// @ts-ignore
pdfmake.vfs = pdfFonts.pdfMake.vfs;

export function useGeneratePDF() {
  return async (pdfContentProps: PdfContentProps, pageOrientation?: PageOrientation): Promise<Blob> => {
    const { content } = pdfContentProps;
    logger.log('info', { message: 'start Generating PDF', content, pageOrientation });

    // Load custom font and add to vfs
    pdfmake.vfs['Inter-Regular.ttf'] = await convertFontToBase64('/font/Inter_18pt-Regular.ttf');
    pdfmake.vfs['Inter-Bold.ttf'] = await convertFontToBase64('/font/Inter_24pt-Bold.ttf');
    pdfmake.vfs['Inter-Italic.ttf'] = await convertFontToBase64('/font/Inter_18pt-Italic.ttf');
    pdfmake.vfs['Inter-BoldItalic.ttf'] = await convertFontToBase64('/font/Inter_24pt-BoldItalic.ttf');

    // Assign custom fonts. Since pdfmake.fonts is read-only, we cast pdfmake to any.
    (pdfmake as any).fonts = {
      Inter: {
        normal: 'Inter-Regular.ttf',
        bold: 'Inter-Bold.ttf',
        italics: 'Inter-Italic.ttf',
        bolditalics: 'Inter-BoldItalic.ttf',
      },
      // You can keep other fonts if needed.
    };

    // Create the document definition using your template function.
    const docDefinition: TDocumentDefinitions = await getPdfContentWithTemplate({
      ...pdfContentProps,
      pageOrientation,
    });
    const filePdf = pdfmake.createPdf(docDefinition);

    return new Promise<Blob>((resolve) => {
      filePdf.getBlob((filePdfItem) => {
        logger.log('info', { message: 'end Generated PDF', filePdfItem });
        resolve(filePdfItem);
      });
    });
  };
}

export const separatorLine: Content = {
  table: {
    widths: ['*'],
    body: [['']],
  },
  layout: {
    hLineWidth: (i, node) => (i === node.table.body.length ? 1 : 0),
    vLineWidth: () => 0,
    hLineColor: () => '#E8E3FF',
  },
  margin: [0, 30, 0, 30],
};
