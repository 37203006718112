import { isEmpty, isNil } from 'lodash';
import { FlowQuestionId, IndustryAutoCompleteOption, Submission } from '../../../../types';
import { flowQuestionsConfigMapper } from './flow-questions-config-mapper';
import { flowQuestionsConfig } from '../flow-questions-config';
import { HeraldUpdateApplication } from '../../../../types/herald/herald-types';
import { HeraldCategoryType } from '../../../../enums';
import { getHeraldQuestionIdCategory } from '../../../../utils';
import { normalizeDynamicFormValueAccordingToSchema } from '../../../normalizers/normalize-dynamic-form-value-according-to-schema';

export const mapFlowQuestionsToHerald = (submission: Submission): Omit<HeraldUpdateApplication, 'products'> => {
    const heraldUpdateApplication: Required<Omit<HeraldUpdateApplication, 'products'>> = {
        risk_values: [],
        coverage_values: [],
    };
    Object.entries(flowQuestionsConfigMapper).forEach(([flowQuestionId, mapperConfig]) => {
        const heraldConfig = mapperConfig.herald;
        if (!heraldConfig || isEmpty(heraldConfig)) {
            return;
        }
        const config = flowQuestionsConfig.find((q) => q.id === flowQuestionId);
        if (!config) {
            return;
        }
        let flowValue = config.getValue(submission);
        flowValue =
            flowQuestionId === FlowQuestionId.Industry
                ? (flowValue as IndustryAutoCompleteOption | undefined)?.heraldId
                : flowValue;
        const normalizedHeraldValue = normalizeDynamicFormValueAccordingToSchema(flowValue, config.schema);
        if (isNil(normalizedHeraldValue)) {
            return;
        }
        heraldConfig.forEach(({ id, instance }) => {
            if (getHeraldQuestionIdCategory(id) === HeraldCategoryType.RiskValues) {
                heraldUpdateApplication.risk_values.push({
                    risk_parameter_id: id,
                    instance,
                    value: normalizedHeraldValue,
                });
            } else {
                heraldUpdateApplication.coverage_values.push({
                    coverage_parameter_id: id,
                    instance,
                    value: normalizedHeraldValue,
                });
            }
        });
    });

    return heraldUpdateApplication;
};
