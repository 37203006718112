import { ExtractedAttribute } from '@common/types';
import { filterValidNumeric } from '@common/utils/extraction-utils';
import { compact, isEmpty } from 'lodash';
import { PromptResultFeatureType } from 'enums';
import { SuggestedValueProps } from 'hooks';
import { getValidNumberSuggestion } from 'broker/utils';
import { buildSuggestedValueForArrayProps, buildSuggestedValueProps } from './suggestion-values-utils';

// used for building a suggestion for a suggestion value with array of numeric strings. like hire years
export function extractValidMultipleNumericStringSuggestion(
  extractedAttribute: ExtractedAttribute<string[]>,
  promptResultFeature: PromptResultFeatureType,
  isFormArrayField: boolean,
): SuggestedValueProps | undefined {
  const numericValues = compact(extractedAttribute.value?.map((val) => filterValidNumeric(val)));
  if (!isEmpty(numericValues)) {
    return isFormArrayField
      ? buildSuggestedValueForArrayProps(
          {
            ...extractedAttribute,
            value: numericValues,
          },
          promptResultFeature,
        )
      : buildSuggestedValueProps(
          {
            ...extractedAttribute,
            value: numericValues,
          },
          promptResultFeature,
        );
  }
  return undefined;
}

export function extractValidNumberSuggestion(
  extractedAttribute: ExtractedAttribute<string>,
  promptResultFeature: PromptResultFeatureType,
  // check schema type of question id in herald appendix, only if it is number it allows decimal https://www.heraldapi.com/docs/input-types#number
  integerSchemaType = true,
): SuggestedValueProps | undefined {
  const validSuggestion = getValidNumberSuggestion(extractedAttribute, integerSchemaType);
  if (validSuggestion) {
    return buildSuggestedValueProps(validSuggestion, promptResultFeature);
  }
  return undefined;
}
