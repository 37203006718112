import { FileType } from '../enums';
import { messages } from '../messages';

interface FileTypeConfigProp {
    text: string;
}

type FileTypeConfig = Record<FileType, FileTypeConfigProp>;

const fileTypesLabelsMessages = messages.fileTypesLabels;

export const fileTypeConfig: FileTypeConfig = {
    [FileType.ExpiringProgram]: {
        text: fileTypesLabelsMessages.ExpiringProgram,
    },
    [FileType.MarketQuote]: {
        text: fileTypesLabelsMessages.MarketQuote,
    },
    [FileType.Submission]: {
        text: fileTypesLabelsMessages.Submission,
    },
    [FileType.MarketingReport]: {
        text: fileTypesLabelsMessages.MarketingReport,
    },
    [FileType.ConceptualTower]: {
        text: fileTypesLabelsMessages.ConceptualTower,
    },
    [FileType.CapitolaBinder]: {
        text: fileTypesLabelsMessages.CapitolaBinder,
    },
    [FileType.Policy]: {
        text: fileTypesLabelsMessages.Policy,
    },
    [FileType.CustomerEmail]: {
        text: fileTypesLabelsMessages.CustomerEmail,
    },
    [FileType.MarketingEmail]: {
        text: fileTypesLabelsMessages.MarketingEmail,
    },
    [FileType.PastApplication]: {
        text: fileTypesLabelsMessages.PastApplication,
    },
    [FileType.LossRuns]: {
        text: fileTypesLabelsMessages.LossRuns,
    },
    [FileType.Supplement]: {
        text: fileTypesLabelsMessages.Supplement,
    },
    [FileType.Proposal]: {
        text: fileTypesLabelsMessages.Proposal,
    },
    [FileType.InboundEmail]: {
        text: fileTypesLabelsMessages.InboundEmail,
    },
    [FileType.ReplyEmail]: {
        text: fileTypesLabelsMessages.ReplyEmail,
    },
    [FileType.RansomwareApplication]: {
        text: fileTypesLabelsMessages.RansomwareApplication,
    },
    [FileType.CapitolaQuote]: {
        text: fileTypesLabelsMessages.CapitolaQuote,
    },
    [FileType.MarketInvoice]: {
        text: fileTypesLabelsMessages.MarketInvoice,
    },
    [FileType.CapitolaInvoice]: {
        text: fileTypesLabelsMessages.CapitolaInvoice,
    },
    [FileType.MarketBinder]: {
        text: fileTypesLabelsMessages.MarketBinder,
    },
    [FileType.PolicyCoverLetter]: {
        text: fileTypesLabelsMessages.PolicyCoverLatter,
    },
    [FileType.Financials]: {
        text: fileTypesLabelsMessages.Financials,
    },
    [FileType.SignedApplication]: {
        text: fileTypesLabelsMessages.SignedApplication,
    },
    [FileType.DueDiligence]: {
        text: fileTypesLabelsMessages.DueDiligence,
    },
    [FileType.Declination]: {
        text: fileTypesLabelsMessages.Declination,
    },
    [FileType.OtherSurplusForms]: {
        text: fileTypesLabelsMessages.OtherSurplusForms,
    },
    [FileType.PolicySpecimen]: {
        text: fileTypesLabelsMessages.PolicySpecimen,
    },
    [FileType.Endorsement]: {
        text: fileTypesLabelsMessages.Endorsement,
    },
    [FileType.BOR]: {
        text: fileTypesLabelsMessages.BOR,
    },
    [FileType.Subjectivities]: {
        text: fileTypesLabelsMessages.Subjectivities,
    },
    [FileType.NoLossLetter]: {
        text: fileTypesLabelsMessages.NoLossLetter,
    },
    [FileType.CarrierSurplusForm]: {
        text: fileTypesLabelsMessages.CarrierSurplusForm,
    },
    [FileType.QuoteComparison]: {
        text: fileTypesLabelsMessages.QuoteComparison,
    },
    [FileType.FlowPolicy]: {
        text: fileTypesLabelsMessages.FlowPolicy,
    },
    [FileType.AOR]: {
        text: fileTypesLabelsMessages.AOR,
    },
    [FileType.StateCompliance]: {
        text: fileTypesLabelsMessages.StateCompliance,
    },
    [FileType.DNNR]: {
        text: fileTypesLabelsMessages.DNNR,
    },
    [FileType.DNCR]: {
        text: fileTypesLabelsMessages.DNCR,
    },
    [FileType.CompetingQuote]: {
        text: fileTypesLabelsMessages.CompetingQuote,
    },
    [FileType.PriorLossRuns]: {
        text: fileTypesLabelsMessages.PriorLossRuns,
    },
    [FileType.ExpiringEndorsement]: {
        text: fileTypesLabelsMessages.ExpiringEndorsement,
    },
    [FileType.UnderlyingPolicy]: {
        text: fileTypesLabelsMessages.UnderlyingPolicy,
    },
};
