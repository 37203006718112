// eslint-disable-next-line import/no-unresolved
import { Content, PageOrientation, TDocumentDefinitions } from 'pdfmake/interfaces';

export interface PdfContentProps {
  content: Content;
  pageOrientation?: PageOrientation;
  header?: (currentPage: any, pageCount: any, pageSize: any) => Content[];
  footer?: (currentPage: any, pageCount: any, pageSize: any) => Content[];
}

export async function convertImageToBase64(imgPath: string): Promise<string> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = imgPath;
    img.crossOrigin = 'anonymous';

    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext('2d');
      if (!ctx) {
        reject(new Error('Canvas not supported'));
        return;
      }

      ctx.drawImage(img, 0, 0);
      const base64 = canvas.toDataURL('image/png').split(',')[1];
      if (base64) {
        resolve(base64);
      } else {
        reject(new Error(`Image load error: ${imgPath}`));
      }
    };

    img.onerror = (err) => reject(new Error(`Image load error: ${err}`));
  });
}

export async function convertFontToBase64(fontPath: string): Promise<string> {
  const response = await fetch(fontPath);
  if (!response.ok) {
    throw new Error(`Font load error: ${fontPath} (status: ${response.status})`);
  }
  const arrayBuffer = await response.arrayBuffer();
  let binary = '';
  const bytes = new Uint8Array(arrayBuffer);
  for (let i = 0; i < bytes.length; i += 1) {
    binary += String.fromCharCode(bytes[i]);
  }
  return btoa(binary);
}

export const getPdfContentWithTemplate = async ({
  content,
  pageOrientation = 'portrait',
  header,
  footer,
}: PdfContentProps): Promise<TDocumentDefinitions> => {
  // Load logo as base64 string
  const headerImage = !header ? await convertImageToBase64('/img/pdf-header-bg.png') : undefined;
  const footerImage = !footer ? await convertImageToBase64('/img/pdf-footer-bg.png') : undefined;

  const defaultHeader = (_currentPage: any, _pageCount: any, pageSize: any) => [
    {
      // if you specify both width and height - image will be stretched
      image: `data:image/png;base64,${headerImage}`,
      width: pageSize.width,
      // width: 150,
    },
  ];

  const defaultFooter = (_currentPage: any, _pageCount: any, pageSize: any): Content[] => [
    {
      // if you specify both width and height - image will be stretched
      image: `data:image/png;base64,${footerImage}`,
      width: pageSize.width,
      height: 40,
      margin: [0, 30, 0, 0], // Moves the image up by 10 points
      // width: 150,
    },
  ];

  return {
    // left, top, right, bottom
    pageMargins: [30, 90, 30, 50],
    defaultStyle: {
      font: 'Inter', // Use your custom font here
      fontSize: 11,
      lineHeight: 1.2,
    },
    pageOrientation,

    header: header || defaultHeader,
    footer: footer || defaultFooter,
    content,
  };
};
