import { ExtractedData, Submission, SubmissionAdditionalData } from '../types';
import { getDefaultFieldValueMap, SubmissionDataDefaultsMetaData } from './submission-defaults';
import { SubmissionDataDestination } from './enums';
import { ExtractedDataField, submissionExtractedDataToSubmissionDataResolver } from './submission-extracted-data';
import { CoverageLine } from '../enums';

function getSubmissionCreateDataFromDefaultValues(metaData: SubmissionDataDefaultsMetaData): Partial<Submission> {
    const submissionDataDefaultValues = getDefaultFieldValueMap(SubmissionDataDestination.Submission, metaData);
    const submissionAdditionalDataDefaultValues = getDefaultFieldValueMap(
        SubmissionDataDestination.SubmissionAdditionalData,
        metaData,
    );
    return {
        ...submissionDataDefaultValues,
        additionalData: submissionAdditionalDataDefaultValues,
    };
}

function getSubmissionCreateDataFromDataExtraction(submissionExtractedData: ExtractedData): Partial<Submission> {
    let submissionData: Partial<Submission> = {};
    let submissionAdditionalData: Partial<SubmissionAdditionalData> = {};
    const submissionExtractedDataToSubmissionData =
        submissionExtractedDataToSubmissionDataResolver(submissionExtractedData);

    Object.keys(submissionExtractedDataToSubmissionData).forEach((key) => {
        const dataFields = submissionExtractedDataToSubmissionData[key as keyof ExtractedDataField];
        dataFields?.forEach((dataField) => {
            if (dataField?.destination === SubmissionDataDestination.Submission) {
                submissionData = {
                    ...submissionData,
                    [dataField.key]: dataField.value,
                };
            } else if (dataField?.destination === SubmissionDataDestination.SubmissionAdditionalData) {
                submissionAdditionalData = {
                    ...submissionAdditionalData,
                    [dataField.key]: dataField.value,
                };
            }
        });
    });

    return {
        ...submissionData,
        additionalData: submissionAdditionalData,
    };
}

export function getSubmissionCreateData(
    submissionExtractedData?: ExtractedData,
    selectedCoverageLines?: CoverageLine[],
): Partial<Submission> {
    const submissionCreateDataFromDataExtraction = submissionExtractedData
        ? getSubmissionCreateDataFromDataExtraction(submissionExtractedData)
        : {};
    const submissionCreateDataFromDefaultValues = getSubmissionCreateDataFromDefaultValues({
        extractedData: submissionExtractedData,
        selectedCoverageLines,
    });

    return {
        ...submissionCreateDataFromDefaultValues,
        ...submissionCreateDataFromDataExtraction,
        additionalData: {
            ...submissionCreateDataFromDefaultValues.additionalData,
            ...submissionCreateDataFromDataExtraction.additionalData,
        },
    };
}
