import { omit } from 'lodash';
import { Link, MarkedText, Typography } from '@common-components';
import { SubmissionMarketRequestStatus } from 'enums';
import { ActivityLog, ExtendedMarketRequest } from 'types';
import { ConditionalWrap, truncateMultiLineText } from 'utils';
import Indication from './IndicationPopUp/Indication';
import { useGetQuoteMetadataData } from './utils';

export interface MarketRequestMetaDataProps {
  marketRequest: ExtendedMarketRequest;
  emailActivity?: ActivityLog;
}

export default function MarketRequestMetaData({ marketRequest, emailActivity }: MarketRequestMetaDataProps) {
  const { title, description, titleTextAfterLink, linkProps, isAIExtracted } = useGetQuoteMetadataData(
    marketRequest,
    emailActivity,
  );

  if (
    marketRequest.status === SubmissionMarketRequestStatus.Declined ||
    marketRequest.status === SubmissionMarketRequestStatus.Indication
  ) {
    return <Indication marketRequest={marketRequest} />;
  }

  return (
    <>
      <Typography variant="body2" color="text.primary" title={title} sx={{ ...truncateMultiLineText(4) }}>
        <ConditionalWrap
          condition={!!isAIExtracted}
          wrap={(wrappedChildren) => <MarkedText>{wrappedChildren}</MarkedText>}
        >
          <>
            {title}{' '}
            {linkProps && (
              <Link color="primary" sx={{ cursor: 'pointer' }} {...omit(linkProps, 'linkText')}>
                {linkProps.linkText}
              </Link>
            )}
            {linkProps && titleTextAfterLink ? titleTextAfterLink : ''}
          </>
        </ConditionalWrap>
      </Typography>
      {description && <Typography variant="caption">{description}</Typography>}
    </>
  );
}
