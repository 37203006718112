import { HeraldSchema } from '../../../types/herald/herald-types';
import { StateCode } from '../../../enums';

export const DynamicFormAddressSchema: HeraldSchema = {
    title: '',
    type: 'object',
    properties: {
        line1: {
            type: 'string',
            title: 'Line 1',
            min_length: 1,
            max_length: 46,
        },
        line2: {
            type: 'string',
            title: 'Line 2',
            min_length: 0,
            max_length: 46,
        },
        line3: {
            type: 'string',
            title: 'Line 3',
            min_length: 0,
            max_length: 46,
        },
        city: {
            type: 'string',
            title: 'City',
            min_length: 1,
            max_length: 50,
        },
        state: {
            type: 'string',
            enum: Object.values(StateCode),
            title: 'State',
        },
        postal_code: {
            type: 'string',
            pattern: '^\\d{5}$',
            title: 'Postal code',
        },
        country_code: {
            type: 'string',
            enum: ['USA'] as string[],
            title: 'Country',
        },
    },
    required: ['line1', 'city', 'state', 'postal_code', 'country_code'] as string[],
} as const;
