import { HeraldData, heraldQuestionId } from '@common/types';
import { HeraldApiError, HeraldApplication, HeraldIndexEntry, QuoteExits } from '@common/types/herald/herald-types';
import { isEmpty, union } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { ForbiddenError } from 'clients/errors/ForbiddenError';
import { useHeraldApplicationApi, useHeraldIndustryClassificationApi, useRetrieveHeraldApplication } from 'hooks';
import { messages } from 'i18n';
import { PartialSubmission } from 'types';
import { logger } from 'utils';
import { DynamicFormState } from 'broker/pages/SubmissionWorkspacePage/components/DynamicForm/DynamicFormLayout/types';
import { DynamicFormFieldApiError } from 'broker/pages/SubmissionWorkspacePage/components/DynamicForm/types';
import heraldApplicationNormalizer from './mappers/herald-application-normalizer';
import mapFormStateToHerald from './mappers/map-form-state-to-herald';
import { HeraldApplicationNormalized } from './types';
import { getHeraldRequiredQuestionIds, getHeraldServerErrorFieldMessage } from './utils';

interface HeraldProviderProps {
  submission: PartialSubmission;
  isHeraldSubmission: boolean;
}

export function useGetHeraldProvider({ submission, isHeraldSubmission }: HeraldProviderProps) {
  // questions that were required before quote exit, should still be shown
  const forceShowQuestions = submission.heraldData?.requiredQuestionsBeforeQuoteExit;
  const heraldApplicationId = isHeraldSubmission ? submission.heraldData?.applicationId : undefined;
  const [heraldApplication, setHeraldApplication] = useState<HeraldApplication | undefined>();
  const [applicationIndustryDetails, setApplicationIndustryDetails] = useState<undefined | HeraldIndexEntry | null>();
  const [quoteExits, setQuoteExists] = useState<QuoteExits[] | undefined>();
  const [isLoadingHeraldApplication, setIsLoadingHeraldApplication] = useState(!!heraldApplicationId);

  const { refetch: fetchHeraldApplication } = useRetrieveHeraldApplication({
    id: heraldApplicationId!,
    enabled: false,
  });

  // if this is a herald submission, we start with loading the herald application
  const { upsertApplication } = useHeraldApplicationApi();

  const { retrieveIndustryClassificationByHeraldId } = useHeraldIndustryClassificationApi();

  const heraldApplicationNormalized: HeraldApplicationNormalized | undefined = useMemo(
    () =>
      heraldApplication
        ? heraldApplicationNormalizer(heraldApplication, applicationIndustryDetails || undefined, forceShowQuestions)
        : undefined,
    [applicationIndustryDetails, heraldApplication, forceShowQuestions],
  );

  const heraldDynamicQuestions = useMemo(
    () => [
      ...(heraldApplicationNormalized?.risk_values || []),
      ...(heraldApplicationNormalized?.coverage_values || []),
    ],
    [heraldApplicationNormalized?.coverage_values, heraldApplicationNormalized?.risk_values],
  );

  const updateHeraldApplicationStates = (
    heraldApplicationItem: HeraldApplication,
    industryIndexEntry: HeraldIndexEntry | null,
    heraldQuoteExits?: QuoteExits[],
  ) => {
    setApplicationIndustryDetails(industryIndexEntry);
    setQuoteExists(heraldQuoteExits);
    setHeraldApplication(heraldApplicationItem);
    setIsLoadingHeraldApplication(false);
  };

  const getIndustryIndexEntry = async (heraldApplicationItem: HeraldApplication) => {
    let industryIndexEntry: HeraldIndexEntry | null = null;
    const industry = heraldApplicationItem.risk_values.find(
      (riskValue) => riskValue.risk_parameter_id === heraldQuestionId.rsk_b3jm_2017_naics_index,
    );
    if (industry?.value) {
      industryIndexEntry = await retrieveIndustryClassificationByHeraldId(industry.value);
    }

    return industryIndexEntry;
  };

  useEffect(() => {
    if (heraldApplicationId) {
      fetchHeraldApplication().then(async (response) => {
        if (response.data) {
          const industryIndexEntry = await getIndustryIndexEntry(response.data.application);
          updateHeraldApplicationStates(response.data.application, industryIndexEntry, response.data.quote_exits);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [heraldApplicationId, fetchHeraldApplication]);

  const getRequiredQuestionsBeforeQuoteExit = (previousAppState: HeraldApplicationNormalized) => {
    // once we got to quote exit before we don't want to clean requiredQuestionsBeforeQuoteExit array because of an issue we have that there is no synchronize between heraldApplication state (inside herald form) with the requiredQuestionsOverride prop
    // otherwise it will cause a bug that if user gets out of quoteExit then there will be a cycle of heraldApplication in quote exit but with no requiredQuestionsOverride, which will cause issue that the sections will disappear
    if (isEmpty(quoteExits) && !submission.heraldData?.requiredQuestionsBeforeQuoteExit?.length) {
      return undefined;
    }
    const heraldRequiredQuestionIds = getHeraldRequiredQuestionIds(previousAppState);
    return submission.heraldData?.requiredQuestionsBeforeQuoteExit
      ? union(submission.heraldData.requiredQuestionsBeforeQuoteExit, heraldRequiredQuestionIds)
      : heraldRequiredQuestionIds;
  };

  const updateHeraldWithFormValues = async (formState: DynamicFormState): Promise<Partial<HeraldData> | undefined> => {
    const previousHeraldApplication = heraldApplicationNormalized;
    const formStateToHerald = mapFormStateToHerald(formState, heraldDynamicQuestions);
    try {
      const response = await upsertApplication.mutateAsync({
        existingApplicationId: heraldApplication!.id,
        upsertData: {
          products: heraldApplication!.products,
          ...formStateToHerald,
        },
        retryAfterValidationError: false,
      });
      if (response?.application) {
        const heraldData: Partial<HeraldData> = {
          status: response.application.status,
          requiredQuestionsBeforeQuoteExit: previousHeraldApplication
            ? getRequiredQuestionsBeforeQuoteExit(previousHeraldApplication)
            : undefined,
        };
        const industryIndexEntry = await getIndustryIndexEntry(response.application);
        updateHeraldApplicationStates(response.application, industryIndexEntry, response.quote_exits);
        return heraldData;
      }
      return undefined;
    } catch (e: any) {
      if (!(e instanceof ForbiddenError)) {
        const errorFieldMessage = getHeraldServerErrorFieldMessage(e as HeraldApiError, formStateToHerald);
        if (errorFieldMessage) {
          throw new DynamicFormFieldApiError(errorFieldMessage.fieldName, errorFieldMessage.message);
        }
      }
      logger.log('error', { message: messages.heraldForm.failedToUpdateApplication, error: e, formState });
      throw e;
    }
  };

  return {
    isLoadingHeraldApplication,
    heraldDynamicQuestions,
    updateHeraldWithFormValues,
    heraldProducts: heraldApplication?.products,
    quoteExits,
  };
}
