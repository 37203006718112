import { DynamicQuestion } from '@common/dynamic-form';
import { convertIndustryObjectToOption } from '@common/utils';
import { isNil, keyBy, mapValues } from 'lodash';
import { FormStateBaseProps } from 'broker/pages/SubmissionWorkspacePage/components/DynamicForm/DynamicFormLayout/types';

export default function mapDynamicQuestionsToFormState(dynamicQuestions: DynamicQuestion[]) {
  const state: Record<string, FormStateBaseProps | FormStateBaseProps[]> = {};

  const mapToFormState = (
    dynamicQuestion: DynamicQuestion,
    inArray = false,
  ): FormStateBaseProps | FormStateBaseProps[] | undefined => {
    if (dynamicQuestion.arrayElements) {
      return dynamicQuestion.arrayElements
        .filter((element) => !!element.value)
        .map((element) => ({
          main: element.value ?? undefined,
          ...(element.childValues && {
            children: mapValues(keyBy(element.childValues, 'id'), (childValue) => mapToFormState(childValue, true)),
          }),
        })) as FormStateBaseProps[];
    }

    let value = dynamicQuestion.value ?? undefined;
    if (dynamicQuestion.industryDetails) {
      value = convertIndustryObjectToOption({
        ...dynamicQuestion.industryDetails,
        naicsCode: dynamicQuestion.industryDetails.naics_2017_6_digit,
        heraldId: dynamicQuestion.industryDetails.id,
      });
    }

    // for repeated question that is isNil we don't want to add it as an empty element to FormStateBaseProps[]
    return inArray && isNil(value)
      ? undefined
      : ({
          main: value,
          ...(dynamicQuestion.childValues && {
            children: mapValues(keyBy(dynamicQuestion.childValues, 'id'), (childValue) => mapToFormState(childValue)),
          }),
        } as FormStateBaseProps);
  };

  dynamicQuestions.forEach((dynamicQuestion) => {
    state[dynamicQuestion.id] = mapToFormState(dynamicQuestion)!;
  });

  return state;
}
