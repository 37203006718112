import { DynamicFormCategoryType, DynamicFormQuestionProvider, DynamicQuestion } from '@common/dynamic-form';
import { heraldQuestionId } from '@common/types';
import { normalizeDateExtractionToFormValue } from '@common/utils/extraction-utils';
import { compact, isEmpty } from 'lodash';
import {
  HeraldApplication,
  HeraldCoverageParameterProps,
  HeraldFormType,
  HeraldIndexEntry,
  HeraldRiskParameterProps,
} from 'clients/types';
import { HeraldApplicationNormalized } from 'broker/pages/SubmissionWorkspacePage/components/DynamicForm/providers/herald/types';
import { mapHeraldInputTypeToDynamicInputType } from 'broker/pages/SubmissionWorkspacePage/components/DynamicForm/providers/herald/utils';

const normalizeHeraldValueToFormValue = (dynamicQuestion: DynamicQuestion) => {
  let normalizedValue = dynamicQuestion.value;

  if (dynamicQuestion.schema && ['integer', 'number'].includes(dynamicQuestion.schema?.type)) {
    // if the value is enum (select field) we are not working with text field therefore we shouldn't convert it to string
    normalizedValue = !isEmpty(dynamicQuestion.schema?.enum)
      ? dynamicQuestion.value
      : dynamicQuestion.value?.toString();
  }

  if (dynamicQuestion.schema?.format === 'date') {
    // 'YYYY-MM-DD' -> YYYY-MM-DD 00:00:00.000000 +00:00
    normalizedValue = normalizeDateExtractionToFormValue(dynamicQuestion.value);
  }

  return {
    ...dynamicQuestion,
    value: normalizedValue,
  };
};

function normalizeCoverageValuesParam(
  coverageValues: HeraldCoverageParameterProps[],
  forceShowQuestions?: string[],
): DynamicQuestion[] {
  return compact(
    coverageValues.map((coverageValue) => {
      const forceShowQuestion = forceShowQuestions?.includes(coverageValue.coverage_parameter_id);
      const isRequiredForThisFormType = coverageValue.required_for.includes(HeraldFormType.Quote);
      // form type: quote/binder/policy
      const isRequiredForAnyFormType = coverageValue.required_for.length > 0;
      // for coverage values, we also show the questions that aren't required for any formType (they will appear as optional)
      const isHidden = isRequiredForAnyFormType && !isRequiredForThisFormType && !forceShowQuestion;
      if (isHidden) {
        return null;
      }
      return normalizeHeraldValueToFormValue({
        id: coverageValue.coverage_parameter_id,
        value: coverageValue.value,
        instance: coverageValue.instance,
        fieldLabel: coverageValue.parameter_text.agent_facing_text,
        section: coverageValue.section,
        affectsOtherQuestions: coverageValue.affects_conditions,
        isFormArrayField: coverageValue.creates_array,
        schema: coverageValue.schema,
        childValues: coverageValue.child_coverage_values
          ? normalizeCoverageValuesParam(coverageValue.child_coverage_values)
          : undefined,
        category: DynamicFormCategoryType.CoverageValues,
        requiredForCompletion: isRequiredForThisFormType,
        additionalContent: coverageValue.parameter_text.applicant_agree_to_text,
        provider: DynamicFormQuestionProvider.Herald,
        inputType: mapHeraldInputTypeToDynamicInputType(coverageValue.coverage_parameter_id, coverageValue.input_type),
      });
    }),
  );
}

function normalizeRiskValuesParam(
  riskValues: HeraldRiskParameterProps[],
  applicationIndustryDetails?: HeraldIndexEntry,
  forceShowQuestions?: string[],
): DynamicQuestion[] {
  return compact(
    riskValues.map((riskValue) => {
      const isRequiredForThisFormType = riskValue.required_for.includes(HeraldFormType.Quote);
      const forceShowQuestion = forceShowQuestions?.includes(riskValue.risk_parameter_id);
      const isHidden = !isRequiredForThisFormType && !forceShowQuestion;
      if (isHidden) {
        return null;
      }
      return normalizeHeraldValueToFormValue({
        id: riskValue.risk_parameter_id,
        value: riskValue.value,
        instance: riskValue.instance,
        fieldLabel: riskValue.parameter_text.agent_facing_text,
        section: riskValue.section,
        affectsOtherQuestions: riskValue.affects_conditions,
        isFormArrayField: riskValue.creates_array,
        schema: riskValue.schema,
        childValues: riskValue.child_risk_values
          ? normalizeRiskValuesParam(riskValue.child_risk_values, applicationIndustryDetails)
          : undefined,
        category: DynamicFormCategoryType.RiskValues,
        industryDetails:
          riskValue.risk_parameter_id === heraldQuestionId.rsk_b3jm_2017_naics_index
            ? applicationIndustryDetails
            : undefined,
        requiredForCompletion: isRequiredForThisFormType,
        additionalContent: riskValue.parameter_text.applicant_agree_to_text,
        provider: DynamicFormQuestionProvider.Herald,
        inputType: mapHeraldInputTypeToDynamicInputType(riskValue.risk_parameter_id, riskValue.input_type),
      });
    }),
  );
}

function mergeHeraldArrayElements(heraldNormalizedParameters: DynamicQuestion[]) {
  const mergedHeraldNormalizedParameters: DynamicQuestion[] = [];
  const arrayElementsParameterIds: string[] = [];
  heraldNormalizedParameters.forEach((heraldNormalizedParameter) => {
    const childValues = heraldNormalizedParameter.childValues
      ? mergeHeraldArrayElements(heraldNormalizedParameter.childValues)
      : undefined;
    if (heraldNormalizedParameter.isFormArrayField) {
      if (arrayElementsParameterIds.includes(heraldNormalizedParameter.id)) {
        const foundIndex = mergedHeraldNormalizedParameters.findIndex(
          (mergedHeraldNormalizedParameter) => mergedHeraldNormalizedParameter.id === heraldNormalizedParameter.id,
        )!;

        mergedHeraldNormalizedParameters[foundIndex] = {
          ...mergedHeraldNormalizedParameters[foundIndex],
          arrayElements: [
            ...mergedHeraldNormalizedParameters[foundIndex].arrayElements!,
            {
              ...heraldNormalizedParameter,
              childValues,
            },
          ],
        };
      } else {
        arrayElementsParameterIds.push(heraldNormalizedParameter.id);
        mergedHeraldNormalizedParameters.push({
          ...heraldNormalizedParameter,
          arrayElements: [
            {
              ...heraldNormalizedParameter,
              childValues,
            },
          ],
        });
      }
    } else {
      mergedHeraldNormalizedParameters.push({
        ...heraldNormalizedParameter,
        childValues,
      });
    }
  });
  return mergedHeraldNormalizedParameters;
}

export default function heraldApplicationNormalizer(
  application: HeraldApplication,
  applicationIndustryDetails?: HeraldIndexEntry,
  forceShowQuestions?: string[],
): HeraldApplicationNormalized {
  return {
    ...application,
    coverage_values: mergeHeraldArrayElements(
      normalizeCoverageValuesParam(application.coverage_values, forceShowQuestions),
    ),
    risk_values: mergeHeraldArrayElements(
      normalizeRiskValuesParam(application.risk_values, applicationIndustryDetails, forceShowQuestions),
    ),
  };
}
