import { HeraldCreateApplication, HeraldUpdateApplication } from 'clients/types';
import { useHotjar, useSubmitMarketRequestsViaApi } from 'hooks';
import { PartialSubmission, SubmissionMarketRequest } from 'types';
import { HotjarEvents } from 'utils/hotjar-events';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';

// once creating/updating herald application after we were in a non-herald submission, we override the herald application with the data we already saved in the flow form
export function overrideHeraldApplicationDataWithSubmissionData(
  application: HeraldCreateApplication,
  heraldDataFromPrevNonHeraldSubmission: Omit<HeraldUpdateApplication, 'products'>,
) {
  const currentRiskValues = application.risk_values || [];
  const overrideRiskValues = heraldDataFromPrevNonHeraldSubmission.risk_values || [];
  const currentCoverageValues = application.coverage_values || [];
  const overrideCoverageValues = heraldDataFromPrevNonHeraldSubmission.coverage_values || [];

  // Merge risk values where override values will replace or add
  const mergedRiskValues = [
    ...currentRiskValues.filter(
      (currentRisk) =>
        !overrideRiskValues.some((overrideRisk) => overrideRisk.risk_parameter_id === currentRisk.risk_parameter_id),
    ),
    ...overrideRiskValues,
  ];

  // Merge coverage values where override values will replace or add
  const mergedCoverageValues = [
    ...currentCoverageValues.filter(
      (currentCoverage) =>
        !overrideCoverageValues.some(
          (overrideCoverage) => overrideCoverage.coverage_parameter_id === currentCoverage.coverage_parameter_id,
        ),
    ),
    ...overrideCoverageValues,
  ];

  // merge arrays in application with arrays in heraldDataFromPrevNonHeraldSubmission
  return {
    ...application,
    risk_values: mergedRiskValues,
    coverage_values: mergedCoverageValues,
  };
}

export function useOnSubmitMarketRequestsViaApi() {
  const { reFetchWorkspace } = useSubmissionsWorkspace();
  const submitMarketRequestsViaApi = useSubmitMarketRequestsViaApi();
  const hotjar = useHotjar();

  return async (submission: PartialSubmission, marketRequests: SubmissionMarketRequest[]) => {
    hotjar.event(HotjarEvents.SubmitProductViaApi);
    await submitMarketRequestsViaApi({
      submissionId: submission.id,
      marketRequestIds: marketRequests.map((marketRequest) => marketRequest.id),
    });
    await reFetchWorkspace();
  };
}
