import { formatAddress, getCoverageLinesText } from '@common/utils';
import { compact } from 'lodash';
// eslint-disable-next-line import/no-unresolved
import { Content } from 'pdfmake/interfaces';
import { PartialSubmission } from 'types';
import { formatPrice, logger } from 'utils';
import { QuoteFormCreate } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/Quote/form-methods/types';
import { separatorLine } from 'broker/pages/SubmissionWorkspacePage/utils/pdf-creator/pdf-creator-util';
import {
  currency,
  getCommission,
  getExpiresAtDate,
  getInsuredName,
  getPremium,
  getRetailerFullName,
  getTaxAndFees,
} from './helpers';

interface PdfContentProps {
  submission: PartialSubmission;
  quoteForm: QuoteFormCreate;
  marketName: string;
  leftDecorImage: string;
  rightDecorImage: string;
}

export const getPdfDetails = ({
  submission,
  quoteForm,
  marketName,
  leftDecorImage,
  rightDecorImage,
}: PdfContentProps): Content => {
  logger.log('info', { message: 'getPdfDetails arguments', submissionId: submission.id, quoteForm, marketName });

  const premium = getPremium(quoteForm);
  const taxAndFees = getTaxAndFees(quoteForm);
  const commission = getCommission(quoteForm);

  const expirationDateLine: Content = {
    text: [{ text: 'Quote Expiration Date: ', bold: true }, { text: getExpiresAtDate(quoteForm.expiresAt) }],
    alignment: 'right',
    font: 'Inter', // Make sure Inter is registered with pdfmake
    fontSize: 12,
    margin: [0, 0, 0, 10], // [left, top, right, bottom]
    color: 'black',
  };

  const leftBlock: Content = {
    stack: [
      { text: `Prepared on behalf of ${getRetailerFullName(submission.user)}`, fontSize: 12, bold: true },
      { text: submission.organizationName, fontSize: 12, bold: true },
      /*  {
        text: [
          { text: 'Policy Period: ', bold: true },
          { text: getPolicyPeriod(quoteForm.expiresAt, quoteForm.date), fontSize: 12 },
        ],
      }, */
    ],
    margin: [0, 0, 0, 0],
    font: 'Inter',
    color: 'black',
  };

  const quoteIntro: Content = {
    text: [
      { text: 'Flow Specialty is pleased to provide the following quote from ', fontSize: 12 },
      { text: `${marketName}`, fontSize: 12, bold: true },
      { text: ' for ', fontSize: 12 },
      { text: `${getCoverageLinesText(quoteForm.coverageLines)} coverage`, fontSize: 12, bold: true },
      {
        text: '.\n\n Please review this quote for accuracy and let us know if you have any questions or note any discrepancies prior to binding coverage.',
        fontSize: 12,
      },
    ],
    alignment: 'left',
    font: 'Inter',
    color: 'black',
    margin: [0, 10, 0, 10],
  };

  const insuredTable: Content = {
    table: {
      widths: ['auto', '*'],
      body: [
        [
          { text: 'Insured:', bold: true, fontSize: 12 },
          { text: getInsuredName(submission.insuredName), fontSize: 12, bold: true },
        ],
        [
          { text: 'Insured Address:', fontSize: 10 },
          {
            text: submission.insuredPrimaryAddress ? formatAddress(submission.insuredPrimaryAddress) : '',
            fontSize: 10,
          },
        ],
      ],
    },
    layout: 'noBorders', // Removes all table borders
    margin: [0, 0, 0, 10], // Adjust spacing around the table
  };

  // 1) Coverage Info Row (4 columns)
  const coverageRow: Content = {
    columns: compact([
      quoteForm.limit
        ? {
            stack: [
              { text: 'LIMIT', bold: true, fontSize: 10 },
              { text: currency + formatPrice(Number(quoteForm.limit)), fontSize: 17 },
            ],
            alignment: 'center',
          }
        : undefined,
      commission
        ? {
            stack: [
              { text: 'AGENT COMMISSION', bold: true, fontSize: 10 },
              { text: commission, fontSize: 17 },
            ],
            alignment: 'center',
          }
        : undefined,
      {
        stack: [
          { text: 'PREMIUM', bold: true, fontSize: 10 },
          { text: currency + formatPrice(premium, 2), fontSize: 17 },
        ],
        alignment: 'center',
      },
      {
        stack: [
          { text: 'TAXES AND FEES', bold: true, fontSize: 10 },
          {
            text: currency + formatPrice(taxAndFees, 2),
            fontSize: 17,
          },
        ],
        alignment: 'center',
      },
    ]),
    margin: [0, 0, 0, 30], // Adjust as needed
  };

  const getBracketedTotalRow: Content = {
    columns: [
      // Empty flexible space on the left
      { width: '*', text: '' },
      // Left bracket image
      {
        width: 'auto',
        stack: [
          {
            image: `data:image/png;base64,${leftDecorImage}`,
            width: 18,
          },
        ],
        margin: [0, -10, 0, 0],
      },
      // TOTAL text
      {
        width: 'auto',
        stack: [
          {
            text: `TOTAL ${currency}${formatPrice(premium + taxAndFees, 2)}`,
            bold: true,
            fontSize: 30,
            color: '#330A89', // Example color
            alignment: 'center',
          },
        ],
        margin: [5, 0, 5, 0],
      },
      // Right bracket image
      {
        width: 'auto',
        stack: [
          {
            image: `data:image/png;base64,${rightDecorImage}`,
            width: 18,
          },
        ],
        margin: [0, 35, 0, 0], // Moves it down by 10 points
      },
      // Empty flexible space on the right
      { width: '*', text: '' },
    ],
    alignment: 'center',
    margin: [0, 0, 0, 10], // Adjust as needed
  };

  // 8) Final note
  const finalNote: Content = {
    text: 'If we do not hear from you, it will be understood that the quote provides an accurate description of the coverage subject to the terms and conditions set forth herein.',
    margin: [0, 10, 0, 0],
    font: 'Inter',
    italics: true,
    fontSize: 10,
    color: 'black',
  };

  // Return the array of content, removing any undefined items
  return compact([
    expirationDateLine,
    leftBlock,
    separatorLine,
    quoteIntro,
    separatorLine,
    insuredTable,
    separatorLine,
    coverageRow,
    getBracketedTotalRow,
    separatorLine,
    finalNote,
  ]);
};
