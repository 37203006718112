import { ExtractedData } from '../../types';
import { CoverageLine } from '../../enums';

export type DynamicFormDefaultValue = string | string[] | number | number[];

export interface SubmissionDataDefaultsMetaData {
    extractedData?: ExtractedData;
    selectedCoverageLines?: CoverageLine[];
}

export const noneOfTheAboveOption = 'None of the above';
