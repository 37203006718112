import { getCoverageLinesText } from '@common/utils';
import { Box, GridColDef, Stack, Tooltip } from '@common-components';
import { SubmissionMarketRequestStatus } from 'enums';
import { messages } from 'i18n';
import { ExtendedMarketRequest } from 'types';
import { truncateMultiLineText } from 'utils';
import { QuoteCoverageLines } from 'broker/components/common/QuoteCoverageLines';
import Title from 'broker/components/common/Title';
import TitleSubTitle from 'broker/components/common/TitleSubTitle';
import MarketRequestItemLabels from 'broker/pages/SubmissionWorkspacePage/components/common/MarketRequestItem/MarketRequestItemLabels';
import { InformationRequestedLocationState } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/InformationRequested/types';
import {
  buildLayerTitle,
  MarketingReportMarketRequest,
} from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/MarketingReport/utils';
import { marketRequestsStatusConfig } from 'broker/pages/SubmissionWorkspacePage/market-request-status-config';
import AdditionalInfo from './AdditionalInfo';

function getColumns(
  showLoader: () => void,
  hideLoader: () => void,
  setMarketIdInEditMode: (id: string) => void,
  marketIdInEditMode: string,
  openInformationRequestedModal: (informationRequestedLocationState: InformationRequestedLocationState) => void,
  isInformationRequestsReport: boolean,
): GridColDef[] {
  return [
    {
      field: 'marketName',
      sortable: false,
      headerName: messages.marketingReportPage.columnHeaders.marketName,
      flex: 1,
      renderCell: (cellValues) => {
        const marketingReportMarketRequest: MarketingReportMarketRequest = cellValues.row;

        return (
          <Stack flexDirection="column" gap={0.2} marginBottom={1}>
            <Title sx={truncateMultiLineText(2)} titleText={marketingReportMarketRequest.marketName} />
            <MarketRequestItemLabels marketRequest={marketingReportMarketRequest as unknown as ExtendedMarketRequest} />
          </Stack>
        );
      },
    },
    {
      field: 'status',
      sortable: false,
      headerName: messages.marketingReportPage.columnHeaders.status,
      flex: 2,
      hide: isInformationRequestsReport,
      renderCell: (cellValues) => (
        <Box sx={{ py: 1.3 }}>
          <TitleSubTitle
            title={marketRequestsStatusConfig[cellValues.value as SubmissionMarketRequestStatus].title}
            subtitle={buildLayerTitle(cellValues.row)}
          />
        </Box>
      ),
    },
    {
      field: 'coverageLines',
      sortable: false,
      headerName: messages.marketingReportPage.columnHeaders.coverageLines,
      flex: 2,
      renderCell: (cellValues) => {
        const marketingReportMarketRequest = cellValues.row as MarketingReportMarketRequest;
        const coverageLines = getCoverageLinesText(marketingReportMarketRequest.coverageLines);
        const tooltipCoverageLines = marketingReportMarketRequest.coverageLines
          ? QuoteCoverageLines({ coverageLines: marketingReportMarketRequest.coverageLines })
          : '';

        return (
          <Tooltip tooltipContent={tooltipCoverageLines}>
            <Title sx={truncateMultiLineText(2)} titleText={coverageLines} />
          </Tooltip>
        );
      },
    },
    {
      field: 'additionalInfo',
      sortable: false,
      headerName: messages.marketingReportPage.columnHeaders.additionalInfo,
      flex: 2,
      renderCell: (cellValue) => {
        const marketingReportMarketRequest = cellValue.row as MarketingReportMarketRequest;
        return (
          <AdditionalInfo
            showReportLoader={showLoader}
            hideReportLoader={hideLoader}
            additionalInfoInitValue={marketingReportMarketRequest.additionalInfo}
            marketingReportNoteOverrideType={marketingReportMarketRequest.marketingReportNoteOverrideType}
            submissionMarketRequest={marketingReportMarketRequest}
            setMarketIdInEditMode={setMarketIdInEditMode}
            marketIdInEditMode={marketIdInEditMode}
            openInformationRequestedModal={openInformationRequestedModal}
          />
        );
      },
    },
  ];
}

export default getColumns;
