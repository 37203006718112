import { getDefaultFieldValueMap, SubmissionDataDestination } from '@common/submission-data';
import { HeraldData } from '@common/types';
import { getIsHeraldSubmission } from '@common/utils';
import { forwardRef, useMemo } from 'react';
import { LoaderOverlay } from '@common-components';
import { PartialSubmission } from 'types';
import { MainAreaDimensionsState } from 'broker/components/common/VerticalFormStepper/types';
import { SetNextStepsProps } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/EditSubmissionNew/useSetNextStep';
import { useSendPromptReport } from 'broker/utils/use-send-prompt-report';
import DynamicFormLayout from './DynamicFormLayout';
import { DynamicFormImperativeHandle, DynamicFormState, FormStateBaseProp } from './DynamicFormLayout/types';
import { useGetFlowProvider } from './providers/flow/useGetFlowProvider';
import { getDynamicQuestions } from './providers/get-dynamic-questions';
import { useGetHeraldProvider } from './providers/herald/useGetHeraldProvider';
import { useDynamicFormPersistentState } from './useDynamicFormPersistentState';
import { useGetExtractedData } from './useGetExtractedData';

interface DynamicFormProps {
  setIsDirty?: (isDirty: boolean) => void;
  onClose: () => void;
  applySuggestionsOnEmptyFields: boolean;
  mainAreaDimensionsState?: MainAreaDimensionsState;
  isAcknowledgmentFlow: boolean;
  setNextStep: (props?: SetNextStepsProps) => void;
  submission: PartialSubmission;
}

export const DynamicForm = forwardRef<DynamicFormImperativeHandle, DynamicFormProps>(
  (
    {
      setIsDirty,
      onClose,
      mainAreaDimensionsState,
      isAcknowledgmentFlow,
      applySuggestionsOnEmptyFields,
      setNextStep,
      submission,
    },
    ref,
  ) => {
    const sendPromptReport = useSendPromptReport();
    const isHeraldSubmission = getIsHeraldSubmission(submission);

    const {
      key,
      setKey,
      extractionValidationFields,
      setExtractionValidationFields,
      scrollPositionOnMount,
      setScrollPositionOnMount,
      scrollPosition,
      setScrollPosition,
      activeSection,
      sectionRefs,
      scrollableDivRef,
      onSetActiveSection,
    } = useDynamicFormPersistentState();

    const {
      isLoadingHeraldApplication,
      heraldDynamicQuestions,
      updateHeraldWithFormValues,
      heraldProducts,
      quoteExits,
    } = useGetHeraldProvider({
      isHeraldSubmission,
      submission,
    });

    const { flowDynamicQuestions, updateSubmissionWithFormValues } = useGetFlowProvider({
      submission,
      isHeraldSubmission,
      heraldDynamicQuestions,
      isLoadingHeraldApplication,
    });

    const dynamicQuestions = useMemo(
      () => getDynamicQuestions({ heraldDynamicQuestions, flowDynamicQuestions }),
      [flowDynamicQuestions, heraldDynamicQuestions],
    );

    const isLoading = isLoadingHeraldApplication;

    // dynamic questions
    const submissionExtractedData = useGetExtractedData(heraldDynamicQuestions, submission);

    const dynamicFormDefaultValues = useMemo(
      () =>
        getDefaultFieldValueMap(SubmissionDataDestination.SubmissionForm, {
          extractedData: submission.submissionExtractedData?.extractedData,
          selectedCoverageLines: submission.coverageLines,
        }),
      [submission],
    );

    const onFormSubmit = async (formState: DynamicFormState) => {
      let updatedHeraldData: Partial<HeraldData> | undefined;
      if (isHeraldSubmission) {
        updatedHeraldData = await updateHeraldWithFormValues(formState);
      }
      await updateSubmissionWithFormValues(formState, dynamicQuestions, updatedHeraldData);
      setKey((prev) => prev + 1);
      if (submissionExtractedData) {
        // no need to await for this as it should not block the submission update
        sendPromptReport({
          formState,
          suggestedValueProps: submissionExtractedData,
          entityId: submission.id,
          submissionId: submission.id,
          getValue: (formValue) => {
            if (Array.isArray(formValue)) {
              return formValue.map((value) => value[FormStateBaseProp.Main]);
            }
            return formValue?.main;
          },
        });
      }
    };

    if (isLoading) {
      return <LoaderOverlay hideOverlay />;
    }

    return (
      <DynamicFormLayout
        coverageLines={submission.coverageLines}
        dynamicQuestions={dynamicQuestions}
        setNextStep={setNextStep}
        submissionExtractedData={submissionExtractedData}
        isAcknowledgmentFlow={isAcknowledgmentFlow}
        key={key}
        onFormSubmit={onFormSubmit}
        setIsDirty={setIsDirty}
        sectionRefs={sectionRefs} // Pass refs to DynamicForm
        activeSection={activeSection}
        setActiveSection={onSetActiveSection} // Use onSetActiveSection for stepper clicks
        onClose={onClose}
        mainAreaDimensionsState={mainAreaDimensionsState}
        scrollPositionOnMount={scrollPositionOnMount}
        setScrollPositionOnMount={setScrollPositionOnMount}
        scrollPosition={scrollPosition}
        setScrollPosition={setScrollPosition}
        applySuggestionsOnEmptyFields={key === 0 && applySuggestionsOnEmptyFields}
        ref={ref}
        scrollableDivRef={scrollableDivRef}
        quoteExits={quoteExits}
        setExtractionValidationFields={setExtractionValidationFields}
        extractionValidationFields={extractionValidationFields}
        heraldProducts={heraldProducts}
        isHeraldSubmission={isHeraldSubmission}
        dynamicFormDefaultValues={dynamicFormDefaultValues}
      />
    );
  },
);

export default DynamicForm;
